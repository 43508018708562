import React from "react";
import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";


export const columnSearchProps = (title, dataIndex, {refInput, doSearch, doReset, doRender = (text) => text}) => ({
  title,
  dataIndex,
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{ padding: 8 }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={refInput}
        placeholder={`Suche ${title}`}filterSearch
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => doSearch(selectedKeys, confirm, dataIndex)}
        style={{marginBottom: 8, display: 'block',}}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => doSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          style={{ width: 90 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => clearFilters && doReset(clearFilters, confirm)}
          style={{ width: 90 }}
        >
          Löschen
        </Button>
        {/*
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false, })
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtern
          </Button>
*/}
        <Button
          type="link"
          size="small"
          onClick={() => {close()}}
        >
          Schließen
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',
  onFilterDropdownOpenChange: (visible) => {if (visible) setTimeout(() => {refInput?.current?.select()}, 100)},
  render: (text) => doRender(text, dataIndex),
  sorter: (a, b) => a[dataIndex].toLowerCase().localeCompare(b[dataIndex].toLowerCase())
})
