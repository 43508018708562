import React, {useEffect, useState} from 'react'
import {Button, Card, Drawer, Popconfirm, Table, Tooltip, Radio} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {NoteMetaEdit} from "./NodeMetaEdit";
import apiRequest from "../general/apiRequest";
import useCredentials from "../system/UseCredentials";

const apiUrlNodeMetaList        = "/nodeMeta/list"
const apiUrlNodeMetaDelete      = "/nodeMeta"
const apiUrlNodeMetaCreate      = "/nodeMeta"

const newNodeMeta = {
  id: null,
  active: true,
  position: -1,
  tenantId: null,
  name: "New Node",
  physicalName: "",
  description: "",
  moduleId: null,
  scopeId: null,
  dataSchemaTypeId: null,
  hasSchema: true,
}

export const NodeMetaList = (props) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  const [filterInfo, setFilterInfo] = useState("own")
//  const [filterValue, setFilterValue] =useState("00000000-0000-0000-0000-000000000000")
  const [nodeMetas, setNodeMetas] = useState([])
  const [visible, setVisible] = useState(false)
  const [selectedKey, setSelectedKey] = useState([])
  const handleCell = (row, ix) => {
    return {
      onClick: e => {selectionChange([row.id])},
      onDoubleClick: e => {setVisible(true)},
    }
  }
  // const rowShow = (value) => {
  //   if (withTenant && (value !== "00000000-0000-0000-0000-000000000000"))
  //   return false
  // }
  const doFilter = (value, tenantId) => {
//    return tenantId !== "00000000-0000-0000-0000-000000000000"
    switch (filterInfo) {
      case "global": return tenantId === "00000000-0000-0000-0000-000000000000"
      case "own": return tenantId !== "00000000-0000-0000-0000-000000000000"
      default: return true
    }
  }
  const columns = [
//    {dataIndex: "position", defaultSortOrder: "descend"},
    {title: "", dataIndex: "tenantId", key: 'tenantId', width: "5%",
      onCell: handleCell,
//      onFilter: (value, record) => rowShow(value),
//      filteredValue: filterInfo || "all",
//      onFilter: (value) => doFilter(value),
//      defaultFilteredValue: "00000000-0000-0000-0000-000000000000",
//      onFilter: (value, record) => record.tenantId === "00000000-0000-0000-0000-000000000000",
//      filters: [{text: "Globale", value: "00000000-0000-0000-0000-000000000000"}],
      filteredValue: ["00000000-0000-0000-0000-000000000000"],
//      filteredValue: [filterValue],
//      onFilter: (value, record) => {return record.tenantId === "00000000-0000-0000-0000-000000000000"},
      onFilter: (value, record) => {return doFilter(value, record.tenantId)},
      render: (txt, item) => (txt === "00000000-0000-0000-0000-000000000000")?<ExclamationCircleOutlined style={{color: "red"}} />:null,
    },
    {title: "Bezeichnung", dataIndex: "name", key: 'name', width: "20%",
      onCell: handleCell,
    },
    {title: "physicalName", dataIndex: "physicalName", key: 'physicalName', width: "10%",
//      onCell: (row, ix) => {return {onClick: e => {selectionChange([row.id])}}},
      onCell: handleCell,
    },
    {title: "description", dataIndex: "description", key: 'description', width: "20%",
      ellipsis: {showTitle: false,},
      render: (desc) => (<Tooltip placement="topLeft" title={desc}>{desc}</Tooltip>),
      onCell: handleCell,
    },
    // {
    //   title: "", dataIndex: "id", key: "id", align: "right",
    //   render: () => "",
    //   onCell: (row, ix) => {return {onClick: e => {selectionChange([row.id])}}},
    // },
    {title: (
      <Button
        type={"link"}
        shape={"round"}
        style={{padding: "0 10px"}}
        onClick={() => {addNodeType()}}
        icon={<PlusOutlined style={{fontSize: "2em"}} />}/>
      ),
      dataIndex: "id", key: "id", align: "right", // width: "10%",
      onCell: handleCell,
      render: id => (<>
        <Button
          type={"link"}
          shape={"round"}
          style={{padding: "0 10px"}}
          icon={<EditOutlined style={{fontSize: "2em"}} />}
//          onClick={() => {if (selectedKey[0]) dispatch(setNodeMetaDrawerVisible(true))}}
          onClick={() => {/*if (selectedKey[0]) */setVisible(true)}}
//          disabled={selectedKey[0] !== id}
        />
        <Popconfirm
          placement="topRight"
          title={"Möchten Sie den Datensatz löschen"}
          onConfirm={() => {deleteNodeType(id)}}
          okText="Ja"
          cancelText="Nein"
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true, ghost: true}}
          >
          <Button
            type={"link"}
            danger={true}
            shape={"round"}
            style={{padding: "0 10px"}}
            icon={<DeleteOutlined style={{fontSize: "2em"}} />}
          />
        </Popconfirm>
      </>)
    }
  ]
  const loadNodeMetas = () => {
    apiRequest.get(apiUrlNodeMetaList)
      .then(rd => {
        if (rd && rd.state && rd.data) setNodeMetas(rd.data)
      })
      .catch(error => console.log("loadNodeMetas in mrpNodeMetaList: " + error))
  }
  const addNodeType = () => {
    apiRequest.post(apiUrlNodeMetaCreate, JSON.stringify({...newNodeMeta, tenantId: tenantId}))
      .then(rd => {
        if (rd && rd.state && rd.data) loadNodeMetas()
      })
      .catch(error => console.log("addNodeType in mrpNodeMetaList: " + error))
  }
  const deleteNodeType = (item) => {
    apiRequest.delete(`${apiUrlNodeMetaDelete}/${item}`)
      .then(rd => {
        if (rd && rd.state) loadNodeMetas()
      })
      .catch(error => console.log("addNodeType in mrpNodeMetaList: " + error))

  }
  useEffect(() => {loadNodeMetas()}, [])
  const  selectionChange = (value) => {setSelectedKey(value)}
  return (
    <Card>
      <Drawer
        title="Atributsets"
        destroyOnClose={true}
        onClose={() => {setVisible(false)}}
        width={"90%"}
        open={visible}
      >
        {visible?<NoteMetaEdit nodeMetaId={selectedKey[0]} />:null}
      </Drawer>
      {/*<Switch onChange={() => setWithTenant(!withTenant)} checked={withTenant} />*/}
      {/*<span>{filterInfo}</span>*/}
      <Radio.Group onChange={(e) => {setFilterInfo(e.target.value)}} value={filterInfo}>
        <Radio.Button value="all">Alle</Radio.Button>
        <Radio.Button value="own">Eigene</Radio.Button>
        <Radio.Button value="global">Globale</Radio.Button>
      </Radio.Group>
      {/*<Input value={filterValue} onChange={(e) =>{setFilterValue(e.target.value)}}/>*/}
      <Table
        rowKey="id"
        columns={columns}
        dataSource={nodeMetas}
        rowClassName={(item, ix) => (item.tenantId === "00000000-0000-0000-0000-000000000000")?"systemNode":""}
        pagination={{position: ['topRight', 'bottomRight'],}}
        rowSelection={{
          type: "radio",
//          onChange: (selKey) => {setSelectedKey(selKey)},
          onChange: (selKey) => {selectionChange(selKey)},
          selectedRowKeys: selectedKey
      }}
      />
    </Card>
  )
}