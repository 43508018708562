import React, {useEffect, useState} from 'react'
import {Button, Space, Tooltip} from "antd";
import { ImportOutlined, PlusOutlined} from "@ant-design/icons";
import {ModalRoomChoose} from "../template/modalRoomChoose";
import apiRequest from "../../general/apiRequest";
import {
  tN,
  canShowAddTemplateButton,
  canShowAddStandardButton,
  isLigniteTenant
} from "../../system/checkTenants";
import {ModalFilter} from "../../dialog/modalFilter";
import {getNodeMetaId} from "../../general/Entities";
import useCredentials from "../../system/UseCredentials";
import {sysRight} from "../../common/cmnRights";

const apiUrlRoomRight                         = "/room/rights"
// const apiUrlRoomCreate                        = "/room"
// const apiUrlRoomCreateFromTemplate            = "/room/createFromTemplate"

export const RoomListMenu = ({create, filtered, isTemplate = false, parentId, projectId, ...props}) => {
  const [chooseOpen, setChooseOpen] = useState(false)
  const [rights, setRights] = useState([]);
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  useEffect (() => {
    getRights(parentId)
  }, [parentId])
  const getRights = (entityId) => {
    if (entityId) {
      apiRequest.get(apiUrlRoomRight + "/" + entityId)
        .then(rd => {
          if (rd && rd.state && rd.rights) setRights(rd.rights)
        })
        .catch(error => console.log("getRights in RoomListMenu: " + error))
    }
  }
  // const createRoom = (intProjectId, intParentId) => {
  //   const url = (projectId)
  //     ?apiUrlRoomCreate + "?projectId=" + intProjectId + "&parentId=" + intParentId + "&template=" + isTemplate
  //     :apiUrlRoomCreate + "?parentId=" + intParentId + "&template=" + isTemplate
  //   apiRequest.post(url)
  //     .then(rd => {
  //       if (rd && rd.state && rd.data) navigation("/ras/room/" + rd.data)
  //     })
  //     .catch(error => console.log("createRoom in RoomListMenu: " + error))
  // }

  // const duplicateRoom = (templateRoomId) => {
  //   if (templateRoomId.length >0) {
  //     const url = apiUrlRoomCreateFromTemplate + `?sourceRoomId=${templateRoomId}&newParentId=${parentId}&newProjectId=${projectId}&template=${isTemplate}`
  //     apiRequest.post(url)
  //       .then(rd => {
  //         if (rd && rd.state && rd.data) navigation("/ras/room/" + rd.data)
  //       })
  //       .catch(error => console.log("duplicateRoom in RoomListMenu: " + error))
  //   }
  // }

  // const handleOk = (value) => {
  //   duplicateRoom(value.selectedRowKeys)
  //   setChooseOpen(false)
  // }
  const createFromTemplate = (selectedTemplateId) => {
    if (selectedTemplateId) create({
      newData: null,
      urlExtension: `createFromTemplate`,
      urlParams: `sourceRoomId=${selectedTemplateId}&newParentId=${parentId}&newProjectId=${projectId}`,
      doNavigate: true,
    })
    setChooseOpen(false)
    /*
        if (selectedTemplateId.length >0) {
          const url = apiUrlBreakdownCreateFromTemplate + `?sourceBreakdownId=${selectedTemplateId}&newParentId=${parentId}&newProjectId=${projectId}&isTemplate=${isTemplate}`
          apiRequest.post(url)
            .then((rd) => {
              if (rd && rd.state && rd.data) navigation("/ras/breakdown/" + rd.data)
            })
            .catch(error => console.log("duplicateBreakdown in BreakdownListMenu: " + error))
        }
    */
  }
  return <Space>
    {(isLigniteTenant(tenantId))
      ?<ModalFilter
        filtered={filtered}
        nodeMeta={getNodeMetaId("room")}
        onFilter={props.onFilter}
      />
      :null
    }
    {canShowAddStandardButton(isTemplate, tenantId)
      ?<Tooltip title={tN(tenantId, "neuer Raum")} color={'cyan'} placement="topRight">
        <Button
          onClick={() => create({
            newData: null,
            urlExtension: null,
            urlParams: `projectId=${projectId}&parentId=${parentId}&isTemplate=${isTemplate}`,
            doNavigate: true,
          })}
          type={"link"}
          disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
          icon={<PlusOutlined style={{fontSize: "2em"}} />}
        />
      </Tooltip>
    :null}
    {(canShowAddTemplateButton(isTemplate, tenantId) && projectId)
      ?<>
        <Tooltip title={tN(tenantId, "neuer Raum aus Template...")} color={'cyan'} placement="topRight">
          <Button
            onClick={() => setChooseOpen(true)}
            type={"link"}
            disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
            icon={<ImportOutlined style={{fontSize: "2em"}} />}
          />
        </Tooltip>
      {(chooseOpen)?
        <ModalRoomChoose
          parentId={tenantId}
          open={chooseOpen}
          onCancel={() => setChooseOpen(false)}
          // onOk={handleOk}
          onOk={(value) => createFromTemplate(value.selectedRowKeys)}
        />
        :null}
      </>
      :null
    }
  </Space>
}