import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
//import {upsertOnePropertyGroup} from "./mrpNodeMetaSlice";

const propertyGroupAdapter = createEntityAdapter({
  selectId: model => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})



const propertyGroupSlice = createSlice({
  name: "propertyGroup",
//  initialState: {propertyGroup: propertyGroupAdapter.getInitialState()},
  initialState: {},
  reducers: {
    initialPropertyGroupContainer(state, action) {
      const {payload} = action
      state[payload.nodeMetaId] = propertyGroupAdapter.getInitialState()
    },
    upsertOnePropertyGroup(state, action) {
      const {payload} = action
      if (!state[payload.nodeMetaId])
        state[payload.nodeMetaId] = propertyGroupAdapter.getInitialState()
      propertyGroupAdapter.upsertOne(state.propertyGroup[payload.nodeMetaId], action)

      // propertyGroupAdapter.upsertOne(state.propertyGroup, action)
      // const {id, nodeMetaId} = action.payload
      // const existNM = state.nodeMeta.entities[nodeMetaId]
      // if (existNM && !existNM.propertyGroups[id]) {
      //   existNM.propertyGroups.push(id)
      // }
    },
    makePropertyGroups(state, action) {
      const {payload} = action
      state[payload.nodeMetaId] = [...payload.data]
    },
    upsertManyPropertyGroups(state, pgAction) {
      const {payload} = pgAction
      if (payload.length > 0) {
        const {nodeMetaId} = payload[0]
        if (!state[nodeMetaId]) {
//        const pg = {[nodeMetaId]: {...propertyGroupAdapter.getInitialState()}}
          const newState = {}
          newState[nodeMetaId] = propertyGroupAdapter.getInitialState()
          propertyGroupAdapter.upsertMany(newState[nodeMetaId], pgAction)
          state[nodeMetaId] = newState[nodeMetaId]

//          state[nodeMetaId] = propertyGroupAdapter.getInitialState()

//        const pg = {[nodeMetaId]: {...propertyGroupAdapter.getInitialState()}}
//        state.push(pg)

//        state.push(nodeMetaId)
//        state[nodeMetaId] = propertyGroupAdapter.getInitialState()
        }
//        propertyGroupAdapter.upsertMany(state[nodeMetaId], pgAction)
//         for (const pg of payload) {
//           const action = {type: pgAction.type, payload: {...pg}}
//           const propGroupNodeMetaState =  state[nodeMetaId]
// //        upsertOnePropertyGroup(state, action)
//           propertyGroupAdapter.upsertOne(propGroupNodeMetaState, action)
//         }
      }
    }
  }
})

export const selectPropertyGroups = (state, nodeMetaId) => {
  if (nodeMetaId && state.data.propertyGroup[nodeMetaId]) {
// //    return state.data.propertyGroup[nodeMetaId]
//     const {selectAll} = propertyGroupAdapter.getSelectors()
//     const ret = selectAll(state.data.propertyGroup[nodeMetaId])
    const ret = state.data.propertyGroup[nodeMetaId]
    return ret
  }
  return []
}

const selectPropertyGroups1 = (nodeMetaId) => createSelector(
  [
    state => state.data.propertyGroup,
    (state, nodeMetaId) => nodeMetaId
  ],
  (propertyGroup, nodeMetaId) => propertyGroup[nodeMetaId] || []
  )

export const { upsertOnePropertyGroup, upsertManyPropertyGroups, initialPropertyGroupContainer, makePropertyGroups } = propertyGroupSlice.actions
export const { selectIds: selectPropertyGroupIds, selectById: selectPropertyGroupById} = propertyGroupAdapter.getSelectors()

export default propertyGroupSlice.reducer