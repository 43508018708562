import React, {useEffect, useState} from 'react';
import {TreeSelect} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlBreakdownMultiAssignmentTree = "/breakdown/multiAssignment"

export const TreeSelectMultiAssignParent = ({allowClear, multiAssignmentId, parentType, projectId, readOnly = false, workId, onChange = undefined, onBlur = undefined,...props}) => {
  const [breakdowns, setBreakdowns] = useState([])
//  const {readOnly, workId, projectId, multiAssignmentId, parentType, ...rest} = props
  useEffect(() => {
    loadBreakdownTree(projectId)
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, multiAssignmentId])
  const loadBreakdownTree = (projectId) => {
    if (projectId && multiAssignmentId) {
//      const isOnlyBreakdown = (parentType !== "workpiece")
      apiRequest.get(`${apiUrlBreakdownMultiAssignmentTree}/${projectId}/${workId}?isOnlyBreakdown=${(parentType !== "workpiece")}`)
        .then((rd) => {
          if (rd && rd.state && rd.data) setBreakdowns(rd.data)
        })
        .catch(error => console.log("loadBreakdownTree in TreeSelectMultiAssignParent: " + error))
    }
  }
  return (
    <TreeSelect
      key={"CT" + workId}
      style={{ width: '100%' }}
      fieldNames={{label: "name", value: "id", children: "children"}}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      {...props}
      onChange={!readOnly ? onChange : undefined}
      onBlur={!readOnly ? onBlur : undefined}
      // onChange={!readOnly && onChange}
      // onBlur={!readOnly && onBlur}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      treeData={breakdowns}
    />
  )
}
