import React, {useEffect, useState} from 'react'
import {Tree} from "antd";
import {Link} from "react-router-dom";
import {BreakdownIcon, ProjectIcon, RoomIcon, WorkpieceIcon} from "../../rascal/bimProductTree";
import {mrpNodeMetaId} from "../../general/Entities";

import "../style/rave.css"
import {useFetchList} from "../../core/Fetch/useFetchList";
import {raveFavoriteMeta} from "../Favorite/FavoriteMeta";
import {useRaveItemMenu} from "../Menu/RaveItemMenu";
import {ContextMenu} from "../../core/ContextMenu";

const emptyTree = [{id: "bim0", name: "keine Favoriten"}]
const loadingTree = [{id: "load0", name: "keine Favoriten"}]

export const FavoriteBimTree =  () => {
  const {data} = useFetchList(raveFavoriteMeta, {
//    loading: loadingTree,
//    empty: emptyTree,
  })
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
  const [menuList, buildMenuList] = useRaveItemMenu()
  const [treeData, setTreeData] = useState(emptyTree)
  useEffect(() => {
    if (data) {
      const favoriteRootList = data.map(favoriteItem => ({
        data: {...favoriteItem},
        id: favoriteItem.id,
        itemId: favoriteItem.itemId,
        key: favoriteItem.id,
        name: createLink(favoriteItem),
        selectable: false,
        isLeaf: true,
        icon: null,
      }))
      if (favoriteRootList.length >0)
        setTreeData(prevState => favoriteRootList)
      else setTreeData(emptyTree)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const createLink = ({id, nodeMetaId, itemId, name}) => {
    const style = {color: "rgba(0, 0, 0, 0.7)", fontStyle: "inherited"}
    switch (nodeMetaId) {
      // case mrpNodeMetaId["project"]:
      //   return <Link to={"/ras/project/"+itemId} title={name} style={style}>
      //       <ProjectIcon style={{margin: "0 .2em",}} />
      //       {name}
      //       <Button type={"link"} style={{position: "absolute", bottom: 0, right: 0, }} icon={<DeleteOutlined style={{color: "red", }} />} />
      //   </Link>
      case mrpNodeMetaId["project"]: return <Link to={"/ras/project/"+itemId} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",}} />{name}</Link>
      case mrpNodeMetaId["breakdown"]: return <Link to={"/ras/breakdown/"+itemId} title={name} style={style}><BreakdownIcon style={{margin: "0 .2em",}} />{name}</Link>
      case mrpNodeMetaId["room"]: return <Link to={"/ras/room/"+itemId} title={name} style={style}><RoomIcon style={{margin: "0 .2em",}} />{name}</Link>
      case mrpNodeMetaId["workpiece"]: return <Link to={"/ras/workpiece/"+itemId} title={name} style={style}><WorkpieceIcon style={{margin: "0 .2em",}} />{name}</Link>
      default: return null
    }
  }
  const setActiveTreeRecord = (event, newTreeRecord) => {
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    buildMenuList({
      id: newTreeRecord.itemId,
      tenantId: newTreeRecord.data.tenantId,
      name: newTreeRecord.data.name,
      nodeMetaId: newTreeRecord.data.nodeMetaId
    })
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  return <>
    <ContextMenu items={menuList} {...contextMenuState} />
    <Tree
      rootClassName={"cses-product-tree"}
      showIcon={true}
      multiple={true}
      fieldNames={{title: "name", key: "id"}}
      treeData={treeData}
      onRightClick={({event, node}) => setActiveTreeRecord(event, node)}
    />
  </>
}
