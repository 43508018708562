/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 02.01.2024
 *
 * Project: csescaclient
 *
 * FetchDefault in package  (project: csescaclient ) © 02.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */

export const DEFAULT_BASE_URL = ""
export const DEFAULT_OPTIONS = {
  headers: { "Content-Type": "application/json" },
}
