import React, {useState} from "react";
import apiRequest from "../../general/apiRequest";
import {DEFAULT_BASE_URL, DEFAULT_OPTIONS} from "./FetchDefault";
import useSWR from "swr";
import {sysRight} from "../../common/cmnRights";
import {useNavigate} from "react-router-dom";


export const useFetchData = (meta, id, {
  baseUrl = DEFAULT_BASE_URL,
  url,
  options = DEFAULT_OPTIONS
}) => {
  const fetcher = (url) => apiRequest.get(url).then(r => r/*.data*/)
  const domainKey = `${meta.module.name}/${meta.domain}`
  const navigation = useNavigate()
  const swrKey = `/${domainKey}/${id}`
  const [state, setState] = useState(0)
  const initialData = {data: {...meta.defaultData}, rights: []}
  const {data, mutate, error, isLoading, isValidating} = useSWR(swrKey, fetcher, {keepPreviousData: true, fallbackData: initialData, revalidateOnFocus: false,})
  const recast = (name, value) => {
    if (state < 2 && data?.rights.indexOf(sysRight.UPDATE) >= 0) {
      const newData = {...data, data: {...data.data, [name]: value}}
      mutate(newData, {revalidate: false, optimisticData: newData})
//      setIsChanged(true)
      setState(1)
//      .then(_ =>  setIsChanged(_ => true))
    }
  }
  const persist = () => {
    if (state === 1 && data?.rights.indexOf(sysRight.UPDATE) >= 0) {
      setState(_ => 2)
      mutate(
        async () => {
          await apiRequest.put(`/${domainKey}`, JSON.stringify(data.data))
          setState(_ => 0)
        },
        {revalidate: true}
      )
    }
  }
  const create = (newData = null, urlExtension = null, urlParams = null) => {
    const url = `/${domainKey}${urlExtension?`/${urlExtension.trimLeft('/')}`:''}${urlParams?`?${urlParams}`:''}`
    console.log(`Create: ${JSON.stringify(newData)}\n/${url}`)
    apiRequest.post(url, newData?JSON.stringify(newData):undefined)
      .then(result => {
        navigation(`${domainKey.replace('rave','ras')}/${result.data}`)
      })
  }
  return {data: data.data, rights: data.rights, persist, recast}
}