/**
 * Test for future use
 * @param url
 * @param sndData
 * @param init
 * @param setData
 * @param onError
 */
import {apiUrl} from "./general/apiRequest";

export function fetchInit(method, sndData) {
  return {
    method: method.toUpperCase(),
    credentials: "include",
    headers: {
      'Content-Type': 'application/json', /*, 'Csrf-Token': csrfToken */
      "Access-Control-Request-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Request-Method": "*",
    },
    body: sndData
  }
}

function initNominatim(method, sndData) {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json', /*, 'Csrf-Token': csrfToken */
      "Access-Control-Request-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Request-Method": "*",
    },
    mode: 'cors',
    body: sndData
  }
}

function doSuccessNominatim(data){console.log("No Nominatim-Success-Funktion \n" + data)}
function doErrorNominatim(error){console.log("No Nominatim-Error-Funktion \n" + error)}

export function apiNominatim(urn, onSuccess = (resData) => {doSuccessNominatim(resData)}, sndData = null, method = "get", onError = (error) => {doErrorNominatim(error)}) {
  const fUrn = (!urn.startsWith("http")) ? apiUrl + urn : urn
  fetch(fUrn, initNominatim(method, sndData))
    .then(resData =>{
      if (resData.status === 200) {
        try {
//          console.log("resData" + resData)
          return resData.json()
        } catch (errJSON) {
          onError("Error API Nominatim JSON:\n" + urn)
        }
      } else {
        onError("Error Nominatim Call:\n" + urn)
      }
    })
    .then(jsonData => onSuccess(jsonData))
    .catch(errFetch => {
      onError("Error API Nominatim Fetch\n" + urn + "\n" + errFetch)
    })
}
