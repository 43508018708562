import React, {useCallback, useEffect, useState} from 'react';
import {Collapse as Accordion} from "antd";
import {FavoriteBimTree, MainBimTree, SharedBimTree, TemplateBimTree} from "../../../rave";
import {isSharedExt, isTempExt} from "../../../system/checkTenants";
import useCredentials from "../../../system/UseCredentials";

export const Aside = ({/*asideRef, sidebarWidth,*/ sidebarCollapsed, ...props}) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id

  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(null);
  const startResizing = useCallback((mouseDownEvent) => {
    if (!sidebarWidth) setSidebarWidth(mouseDownEvent.clientX)
    setIsResizing(true);
  }, [sidebarWidth]);
  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);
  const resize = useCallback((mouseMoveEvent) => {
    if (isResizing) {
//      console.log("move" + asideRef.current.getBoundingClientRect().left)
      setSidebarWidth(prevState => mouseMoveEvent.clientX /*- asideRef.current.getBoundingClientRect().left*/)
    }
  },[isResizing])
  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);
//  console.log("Render aside ...")
  return <aside
    //    ref={asideRef}
    id="main-sidebar"
    className={"main-sidebar"}
    style={(sidebarWidth && !sidebarCollapsed) ? {width: sidebarWidth + "px",} : {}}
    onMouseDown={(e) => e.preventDefault()}
  >
    <Accordion
      className="ces-sidebar-accordion"
      defaultActiveKey={["MainBimTree"]}
      ghost
      destroyInactivePanel
      items={[
        {key: "FavoriteBimTree", label: <b>Favoriten</b>, children: <FavoriteBimTree/>,},
        {
          key: "MainBimTree", label: <b>Eigene Projekte</b>, children: <MainBimTree/>,
          // extra: <div onClick={e => e.stopPropagation()}>
          //   <Button type={"text"} icon={<PlusOutlined />} />
          //   <Button type={"text"} icon={<ReloadOutlined/>}
          //     onClick={() => {if (treeRef.current && treeRef.current.loadProjects) treeRef.current.loadProjects()}}
          //   />
          // </div>
        },
        isSharedExt(tenantId) ? {
          key: "SharedBimTree",
          label: <b>mir freigegebene Elemente</b>,
          children: <SharedBimTree/>,
        } : null,
        isTempExt(tenantId) ? {
          key: "TemplateBimTree",
          label: <b>Template Projekte</b>,
          children: <TemplateBimTree/>,
        } : null,
      ].filter(it => it != null)
      }
    />
    <div id="main-sidebar-resizer" onMouseDown={startResizing}/>
  </aside>
}