import React, {useEffect, useState} from "react"
import { enumOptionsValueForIndex } from "@rjsf/utils"
import {Select} from "antd";
import {CloseCircleTwoTone} from "@ant-design/icons";
import apiRequest from "../../../general/apiRequest";

const apiUrlSelectCatalog         = "/catalog/showByRefOrderByName"

const SELECT_STYLE = {width: "100%",};

const LookupSelect = (props) => {
  const {
    autofocus,
      disabled,
      formContext,
      id,
      // label,
      // multiple,
      onBlur,
      onChange,
      onFocus,
      options,
      placeholder,
      readonly,
      // required,
      schema,
      value,
  } = props
  const [catalog, setCatalog] = useState()
  useEffect(() => {
    loadSelect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema.lookupListId])
  const loadSelect = ()  => {
    if (schema.lookupListId) {
      apiRequest.get(`${apiUrlSelectCatalog}/${schema.lookupListId}`)
        .then(rd => {
          if (rd && rd.state && rd.data) setCatalog(rd.data)
        })
        .catch(error => console.log("Error on loadSelect LookupSelect"+ error))
    }
  }
  const { readonlyAsDisabled = true } = formContext
  const handleChange = (nextValue) => {
    onChange(typeof nextValue !== "undefined" ? nextValue : null)
  }
  const handleBlur = () => {
    onBlur()
  }
  const handleFocus = () => onFocus(id, enumOptionsValueForIndex(schema, value, options))
  const getPopupContainer = (node) => node.parentNode


  return <Select
    autoFocus={autofocus}
    disabled={disabled || (readonlyAsDisabled && readonly)}
    getPopupContainer={getPopupContainer}
    id={id}
//    mode={typeof multiple !== "undefined" ? "multiple" : undefined}
    name={id}
    onBlur={!readonly ? handleBlur : undefined}
    onChange={!readonly ? handleChange : undefined}
    onFocus={!readonly ? handleFocus : undefined}
//    onClear={!readonly ? handleBlur : undefined}
    placeholder={placeholder}
    fieldNames={{label: "name", value: "id"}}
    options={catalog}
    allowClear={{clearIcon:<CloseCircleTwoTone twoToneColor="#eb2f96" />}}
    // allowClear={true}
    // clearIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
    style={SELECT_STYLE}
    value={typeof value !== "undefined" ? value : null}
  />
}

LookupSelect.defaultProps = {
  formContext: {},
}

export {LookupSelect};