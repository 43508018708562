import React, {useContext, useEffect, useState} from 'react'
import {Tree} from "antd";
import apiRequest from "../../general/apiRequest";
import {Link} from "react-router-dom";
import useCredentials from "../../system/UseCredentials";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {BreakdownIcon, ProjectIcon, RoomIcon, WorkpieceIcon} from "../../rascal/bimProductTree";
import {BC_RAVE_SHARED} from "../Breadcrumb";
import {useRaveItemMenu} from "../Menu/RaveItemMenu";
import {mrpNodeMetaId} from "../../general/Entities";
import {ContextMenu} from "../../core/ContextMenu";

const apiUrlSharedList                     = "rave/sharedList"
const apiUrlBreakDownListDirectParentOnly         = "/breakdown/byDirectParentOnly"

const apiUrlBreadcrumbList = "breadcrumb"

const emptyTree = [{id: "bim0", name: "keine Freigaben"}]

export const SharedBimTree =  ({shared, ...props}) => {
  const [state] = useContext(CsescaContext)
  const [credentials] = useCredentials()
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
  const [menuList, buildMenuList] = useRaveItemMenu()
  const [treeData, setTreeData] = useState(emptyTree)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  useEffect(() => {
    loadSharedList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.user.id])
  useEffect(() => {
    if (checkEntityName()) loadBreadcrumbPath({id: state.entityId, name: state.entityName})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entityId])
  const checkEntityName = () => {
    switch (state.entityName) {
      case "project":
      case "breakdown":
      case "room":
      case "workpiece":
        return (state.breadcrumbType === BC_RAVE_SHARED)
      default:
        return false
    }
  }
  const loadBreadcrumbPath = (entity) => {
    if (entity.id && entity.name) {
      apiRequest.get(`${apiUrlBreadcrumbList}/${entity.name}/${entity.id}`)
        .then(rd => {
          if (rd && rd.state && rd.data && rd.data.length > 0) expandedItems(rd.data, false)
        })
    }
  }
  const updateTreeData = (list, key, children) => list.map((node) => {
    if (node.id === key) return {...node, children,}
    if (node.children) return {...node, children: updateTreeData(node.children, key, children),}
    return node
  })
  const loadSharedList = () => {
    setExpandedKeys(prevState => [])
    setTreeData(prevState => [])
    apiRequest.get(apiUrlSharedList)
      .then( rd => {
        if (rd && rd.state && rd.data) {
          const sharedRootList = rd.data.map(sharedItem =>({data: {...sharedItem}, id: sharedItem.id, key: sharedItem.id, name: createLink(sharedItem.bType, sharedItem.id, sharedItem.name), selectable: false, isLeaf: false, icon: null, }))
          if (sharedRootList.length > 0) {
            setTreeData(prevState => sharedRootList)
            setExpandedKeys(prevState => [...selectedKeys])
          }
          else setTreeData(emptyTree)
        }
      })
  }
  const createLink = (bType, id, name) => {
    const style = {color: "rgba(0, 0, 0, 0.7)", fontStyle: "inherited"}
    switch (bType) {
      case "project": return <Link to={"/ras/project/"+id} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",}} />{name}</Link>
      case "breakdown": return <Link to={"/ras/breakdown/"+id} title={name} style={style}><BreakdownIcon style={{margin: "0 .2em",}} />{name}</Link>
      case "room": return <Link to={"/ras/room/"+id} title={name} style={style}><RoomIcon style={{margin: "0 .2em",}} />{name}</Link>
      case "workpiece": return <Link to={"/ras/workpiece/"+id} title={name} style={style}><WorkpieceIcon style={{margin: "0 .2em",}} />{name}</Link>
      default: return null
    }
  }
  const loadNodes = async (parentId)  => {
    const rd = await apiRequest.get(apiUrlBreakDownListDirectParentOnly + "/" + parentId + "?template=" + false)
    if (rd && rd.state && rd.data) return rd.data.map(b => ({
      data: {...b},
      id: b.id,
      name: createLink(b.bType, b.id, b.name, b.multiAssigned),
      selectable: false,
      icon: null,
      isLeaf: (b.bType === "workpiece" || b.childrenAmount < 1)
    }))
  return []
  }
  const doExpanded = (expandedItemKeys, {expanded, node}) => {
    if (expanded) {
      loadNodes(node.key)
        .then(children => {
          setTreeData((prevState) => updateTreeData(prevState, node.key, children))
          setExpandedKeys(expandedItemKeys)
        })
    } else setExpandedKeys(expandedItemKeys)
  }

  const expandedItems = (bcList = [], reset = false) => {
    const expand = (list = []) => {
      const item = list.shift()
      if (item) {
        // const found = expandedKeys.find((element) => element === item.id)
        // if (!found) {
        if (!expandedKeys.find((element) => element === item.id)) {
          loadNodes(item.id)
            .then(children => {
              setTreeData((prevState) => updateTreeData(prevState, item.id, children))
              setExpandedKeys((prevState) => [...prevState, item.id])
              setSelectedKeys((prevState) => [...prevState, item.id])
              if (list.length > 0) expand(list)
            })
        } else {
          setSelectedKeys((prevState) => [...prevState, item.id])
          expand(list)
        }
      }
    }
    setSelectedKeys(prevState => [])
    expand([...bcList])
  }
  const setActiveTreeRecord = (event, newTreeRecord) => {
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    buildMenuList({
      id: newTreeRecord.id,
      tenantId: newTreeRecord.data.tenantId,
      name: newTreeRecord.data.name,
      nodeMetaId: mrpNodeMetaId[newTreeRecord.data.bType]
    })
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  return <>
    <ContextMenu items={menuList} {...contextMenuState} />
    <Tree
      rootClassName={"cses-product-tree"}
      showIcon={true}
      onExpand={doExpanded}
      expandedKeys={expandedKeys}
      multiple={true}
      selectedKeys={selectedKeys}
      fieldNames={{title: "name", key: "id"}}
      treeData={treeData}
      onRightClick={({event, node}) => setActiveTreeRecord(event, node)}
    />
  </>
}
