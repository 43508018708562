import React, {useState} from 'react'
import {Button, Card, Form, Input, Space} from "antd"
import {Link, useNavigate} from "react-router-dom"
import {apiLogin} from "../general/apiRequest"

const apiUrlForgetPassword     = "/forgetPassword"
const initialForgetPassword = {username:""}

export const ModalForgetPassword = (props) => {
  const navigate = useNavigate()
  const [send, setSend ] = useState(false)
  const [forgetPassword, setForgetPassword] = useState({...initialForgetPassword})
  const handlePasswordRequest = () => {
    apiLogin.post(apiUrlForgetPassword, JSON.stringify(forgetPassword))
      .then(rd => {
        if (rd && rd.state) setSend(true)
      })
      .catch(error =>  console.log("Error in Forget Password: " + error))
  }
  return (
    <Card
      title={
        <div>
          <div className={"login-header"}>
            <div className={"logo128"}/>
            <div className={"logo-tenant"}>
              <h2 className={"logo-tenant"} > </h2>
            </div>
          </div>
        </div>
      }
    >
      {(!send)
      ?<>
        <div className="login-box">
          <div className="login-box-msg" title="Login">Ihre Email Adresse</div>
          <Form name="forget-password-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{marginTop: 10}}
          >
            <Form.Item label="Email Adresse" name="username" style={{margin: "10px 5px 5px 5px"}}>
              <Input
                name="username"
                value={forgetPassword.username}
                placeholder="Email Adresse"
                style={{margin: "5px 5px"}}
                autoComplete="off"
                onChange={(evt) => {setForgetPassword({...forgetPassword, username: evt.target.value})}}
              />
            </Form.Item>
          </Form>
        </div>
        <div style={{textAlign:"right"}}>
          <Space>
            <Link to={"/login"}>zum Login</Link>
            <Button type="primary" shape="round" onClick={handlePasswordRequest}>Absenden</Button>
          </Space>
        </div>
      </>
      :<>
          <div className="login-box">
            <div className="login-box-msg" title="Login">Sendebestätigung</div>
            <div style={{margin: "1em auto 3em", textAlign: "center"}}>Ihre Passwortanfrage wurde gesendet</div>
          </div>
          <div style={{textAlign:"right"}}>
            <Space>
              <Button type="primary" shape="round" onClick={() => {navigate("/login")}}>zum Login</Button>
            </Space>
          </div>
      </>
      }
    </Card>
  )
}