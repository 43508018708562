import React, {useRef, useState} from "react"
import {ModalDraggable} from "../../../dialog/ModalDraggable";
import {Col, Row } from "antd";
import {
  ImportSchemaContainer,
  ImportSchemaTable
} from "../importSchema/ImportSchema";

export const ModalImportSchema = (props) => {
//  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedImportSchemaRowKey, setSelectedImportSchemaRowKey] = useState(null)
  const [selectedRowTypeRowKey, setSelectedRowTypeRowKey] = useState(null)
  const anchorRefId = useRef(null)
  const handleClose = (e) => {
    setSelectedRowTypeRowKey(null)
    setSelectedImportSchemaRowKey(null)
//    setSelectedRowKeys([])
    props.doOpen(!props.open)
    // props.doImportSchemaVisible(!props.open)
  }
  const doSelectionChange  = (value) => {
    setSelectedRowTypeRowKey(null)
    setSelectedImportSchemaRowKey(value)
  }
  return <>
    {(props.open)?
    <ModalDraggable
      titleName="Import Schemata Verwalten"
      width={"1200px"}
      style={{ top: 20, left: 50}}
      footer={null}
      open={props.open}
      onCancel={handleClose}
      onOk={handleClose}
      destroyOnClose
    >
      <Row gutter={[16,16]}>
        <Col span={6}>
          <ImportSchemaTable
            anchorRefId={anchorRefId}
            selectedImportSchemaRowKey={selectedImportSchemaRowKey}
            setSelectedImportSchemaRowKey={doSelectionChange}
          />
        </Col>
        <Col span={18} id={"ImportSchemaDrawerParent"}>
          <ImportSchemaContainer
            anchorRefId={anchorRefId}
//            schemaId={selectedImportSchemaRowKey}
            selectedImportSchemaRowKey={selectedImportSchemaRowKey}
            selectedRowTypeRowKey={selectedRowTypeRowKey}
            setSelectedRowTypeRowKey={setSelectedRowTypeRowKey}
          />
{/*
          <Row>
            <Col span={24}>
              <ImportSchemaEdit
//                schemaId={selectedRowKeys}
                schemaId={selectedImportSchemaRowKey}
              />

            </Col>
            <Col span={24}>
              <ImportSchemaRowTypeTable
//                schemaId={selectedRowKeys}
                schemaId={selectedImportSchemaRowKey}
                selectedRowTypeRowKey={selectedRowTypeRowKey}
                setSelectedRowTypeRowKey={setSelectedRowTypeRowKey}
              />
            </Col>
          </Row>
*/}
        </Col>
      </Row>
{/*
      <ImportSchemaRowTypeDrawer
        importSchemaAnchor={anchorRefId}
        selectedRowTypeRowKey={selectedRowTypeRowKey}
        setSelectedRowTypeRowKey={setSelectedRowTypeRowKey}
        destroyOnClose
      />
*/}
    </ModalDraggable>
    :null}
  </>
}
