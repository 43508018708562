import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../../general/apiRequest";

const apiUrlImportSchemaTargetListQuellNodeBySelf     = "/importSchemaTarget/listQNBySelf"

export const SelectTargetEdgeMeta = (props) => {
  const {targetId, ...rest} = props
  const [quellNodes, setQuellNodes] = useState([])
  useEffect(() => {
    loadParentTargets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId])
  const loadParentTargets = () => {
    if (targetId) {
      apiRequest.get(`${apiUrlImportSchemaTargetListQuellNodeBySelf}/${targetId}`)
        .then((rd) => {
          if (rd && rd.state && rd.data) setQuellNodes(rd.data)
         })
        .catch(error => console.log("loadParentTargets in SelectTargetEdgeMeta: " + error))
    }
  }
  return <Select
    key={"RtrSelectQuellNodeTarget"+targetId}
    {...rest}
    allowClear
    options={quellNodes}
    fieldNames={{label: "name", value: "id"}}
    dropdownAlign={{ points: ["tr", "br"] }}
  />
}
