/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 04.07.2023
 *
 * Project: csescaclient
 *
 * panelRaveMultiAsignmentRef in package  (project: csescaclient ) © 04.07.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import React, {useEffect, useState} from 'react'
import {Button, Col, Input, notification, Row, Space} from "antd";
import {PlusOutlined, SelectOutlined} from "@ant-design/icons";
import apiRequest from "../general/apiRequest";
import {SelectRaveAssignmentFunction} from "../common/SelectRaveAssignmentFunction";
import {TitleImage} from "../images/imageMapHover";
import {covImgFunc} from "../rascal/rascalApiDef";
import {useNavigate} from "react-router-dom";

const apiUrlRaveMultiAssignmentShow               = "/raveMultiAssignment/show"

const apiUrlRaveMultiAssignmentRefShow            = "/raveMultiAssignmentRef/show"
const apiUrlRaveMultiAssignmentRefItem            = "/raveMultiAssignmentRef/item"

const apiUrlRaveMultiAssignmentRefAssignmentList  = "raveMultiAssignmentRef/assignmentList"

export const RaveMultiAssignmentPanelRefList = ({parentType, parentNodeTypeId, workId, ...props}) => {
  // const {parentType, parentNodeTypeId, workId, ...rest} = props
  const [assignmentRefIds, setAssignmentRefIds] = useState([])
  useEffect(() => {loadMultiAssignmentPanelRefList(workId)}, [workId])
  const loadMultiAssignmentPanelRefList = async (id) => {
    if (id) {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefAssignmentList}/${id}`)
      if (rd && rd.state && rd.data) {
        setAssignmentRefIds(rd.data)
      }
    }
  }
  return ((assignmentRefIds.length > 0)
    ?<>
      {assignmentRefIds.map(
          (assignmentRefId) => <RaveMultiAssignmentPanelRef
            assignmentRefId={assignmentRefId}
            parentType={parentType}
            parentNodeTypeId={parentNodeTypeId}
            // update={update}
            // editable={editable}
          />)}
    </>
    :null
  )
}

const defaultRaveMultiAssignment = {
  id: null,
  parentId: null,
  assignmentId: null,
  description: "",
  showAsPanel: false,
}
const defaultRaveMultiAssignmentItem = {
  id: "",
  projectId: null,
  codeNumber: "",
  name: "",
  itemType: "",
}
const RaveMultiAssignmentPanelRef = ({readOnly, editable, update, assignmentRefId, parentType, parentNodeTypeId, ...props}) => {
  const navigate = useNavigate()
//  const {readOnly, editable, update, assignmentRefId, parentType, parentNodeTypeId, ...rest} = props
  const [multiAssignmentRef, setMultiAssignmentRef] = useState(defaultRaveMultiAssignment)
  const [multiAssignment, setMultiAssignment] = useState(defaultRaveMultiAssignment)
  const [multiAssignmentItem, setMultiAssignmentItem] = useState(defaultRaveMultiAssignmentItem)
  useEffect(() => {loadMultiAssignmentRef(assignmentRefId)}
// eslint-disable-next-line react-hooks/exhaustive-deps
    , [assignmentRefId])
  const loadMultiAssignmentRef = async (id) => {
    if (id) {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefShow}/${id}`)
      if (rd && rd.state && rd.data) {
        setMultiAssignmentRef(rd.data)
//        setMultiAssignmentRef(prevState => {return {...rd.data}})
        await loadMultiAssignment(rd.data.parentId)
        // await loadMultiAssignment(multiAssignmentRef.parentId)
      }
    }
  }
  const loadMultiAssignment = async (id) => {
    if (id && id !== "") {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentShow}/${id}`)
      if (rd && rd.state && rd.data) {
        setMultiAssignment(rd.data)
        await loadMultiAssignmentItem(rd.data.parentId)
      }
    }
  }
  const loadMultiAssignmentItem = async (id) => {
    if (id) {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefItem}/${id}`)
      if (rd && rd.state && rd.data) {
        setMultiAssignmentItem(rd.data)
      }
    }
  }
  const gotoItemLink = () => {
    if (multiAssignmentItem.itemType !== "" && multiAssignmentItem.id !== "") {
      navigate(`/ras/${multiAssignmentItem.itemType}/${multiAssignmentItem.id}`)
    }
  }
  const showAllAssignments = () => {
    notification.info({
      message: "Open all Assignments",
      description: "Information to open all referenced assignments",
      placement:"top",
      duration: 5,
      top: 200,
    })
  }
  const imgContext = {
    refererId: multiAssignmentItem.id,
    projectId: multiAssignmentItem.projectId,
  }
  return (
    <div className="draggable-panel-block">
      <div className={"ant-card ant-card-bordered ant-card-small"}>
        <div className={"ant-card-head"}>
          <div className={"ant-card-head-wrapper"}>
            <div className={"ant-card-head-title"}>
              <Row gutter={[16,16]}>
  {/*
                {(!fromTemplate && editable)
                  ?<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
                  :null
                }
  */}
                <Col span={5}>
                  <Input
                    name={"name"}
                    value={multiAssignment.name}
                    readOnly={!editable || !update}
                    disabled={!update}
                  />
                </Col>
                <Col span={6}>
                  <SelectRaveAssignmentFunction
                    value={multiAssignment.functionId}
                    nodeId={parentNodeTypeId}
                    readOnly={!editable || !update}
                    disabled={!update}
                  />
                </Col>
              </Row>
            </div>
            <div className={"ant-card-head-extra"}>
  {/*
              {(editable)?props.extra:null}
  */}
            </div>
          </div>
        </div>
        <div className={"ant-card-body"}>
          <Row gutter={[16,16]}>
            <Col
              span={24}
            >
              <Input.TextArea
                name={"description"}
                value={multiAssignmentRef.description}
                showCount={editable}
                autoSize={editable?{ minRows: 6, maxRows: 10 }:{ minRows: 4, maxRows: 124 }}
                style={{resize: "none"}}
               readOnly={!editable || !update}
               disabled={!update}
              />
            </Col>
            <Col span={4}>
              <TitleImage
                key={"RMA_" + assignmentRefId}
                imgFunction={covImgFunc}
                covImg={imgContext}
                rightsParent={[]}
              />
            </Col>
            <Col span={20}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Input
                    value={multiAssignmentItem.codeNumber}
                    placeholder="Nr"
                    readOnly={!editable || !update}
                    disabled={!update}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    value={multiAssignmentItem.name}
                    placeholder="Name"
                    readOnly={!editable || !update}
                    disabled={!update}

                  />
                </Col>
                <Col span={6} style={{textAlign: "right"}}>
                  <Button icon={<SelectOutlined rotate={90} />} onClick={gotoItemLink} />
                </Col>
                <Col span={24}>
                  <Input.TextArea
                    name={"description"}
                    value={multiAssignment.description}
  //                  autoSize={false}
                    style={{resize: "none"}}
                    readOnly={!editable || !update}
                    disabled={!update}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{textAlign: "right"}}>
              <Space
                style={{border: "1px solid grey", padding: "5px"}}
              >
                <span>Alle Zuordungen Anzeigen:</span>
                <Button
                  icon={<PlusOutlined />}
                  onClick={showAllAssignments}
                />
              </Space>
            </Col>
          </Row>
        </div>
      </div>
      <hr />
    </div>
  )
}

RaveMultiAssignmentPanelRef.defaultProps = {
  assignmentId: null,
  readOnly: false,
  editable: false,
  update: true
}
