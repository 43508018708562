
export const getEntityId = (entityName) => {
  return entities[entityName] || "00000000-0000-0000-0000-000000000000"
}

const entities = {
  project:      "47e1222e-b950-4057-a1f5-da5bf4d2894a",
  breakdown:    "840c05e4-3366-4e4d-bd26-e416a9242a7e",
  room:         "ee431783-0fbd-4939-b285-7026334fafb7",
  workpiece:    "509ff0f6-acfe-453b-8a49-4e9e5b763c76",
  capture:      "5584e6eb-0fcc-4322-91df-b8659b111957",
  planing:      "fb6eba8c-45c3-4ede-a6b1-4c1f5e463ec2",
  restoration:  "d0075f7b-9fb0-4d0e-ba9d-130f7a0c6d8e",
  media:        "3d910bca-5225-4ed7-a973-250819bdd5b4",
  image:        "fd953649-2862-4c03-8d19-da74c5df2001",
//                      return "00000000-0000-0000-0000-000000000000"
}

export const getNodeMetaId = (nodeMetaName) => {
  return mrpNodeMetaId[nodeMetaName] || "00000000-0000-0000-0000-000000000000"
}
export const mrpNodeMetaId = {
  project:      "63bbfd74-ac46-4f52-9599-71e41ffcd7cc",
  breakdown:    "e5ef1268-ea3b-4e04-ad9c-7f7f91aa6282",
  room:         "fc1a5bd0-9b05-404d-95ff-a40c923b924c",
  workpiece:    "071779d8-89c8-423a-b242-5fc2763d4c19",
  capture:      "bd5290d2-593d-437e-9c8b-ea523c75ae1a",
  planing:      "de77ecec-2b3d-49ff-b903-774054295298",
  restoration:  "b68cd461-8273-48d9-b701-1326c88ab42c",
//  media:        "3d910bca-5225-4ed7-a973-250819bdd5b4",
//  image:        "fd953649-2862-4c03-8d19-da74c5df2001",
}
