import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlScopeList         = "/scope/list"

export const SelectScope = (props) => {
  const [scopes, setScopes] = useState([])
  const loadScopes = async () => {
    const rd = await apiRequest.get(apiUrlScopeList)
    if (rd && rd.state && rd.data) setScopes(rd.data)
  }
  useEffect(() => {loadScopes()}, [])
  return (
    <Select
      key={"coreSelectScope"}
      allowClear
      fieldNames={{label: "name", value: "id"}}
      dropdownAlign={{ points: ["tr", "br"] }}
      {...props}
      options={scopes}
    />
  )
}
