import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlCountryList         = "/country/list"

export const SelectCountry = ({allowClear, catalogGroup, onBlur, onChange, readOnly = false, ...props}) => {
  const [countries, setCountries] = useState([])
  useEffect(() => {
    loadCountries()
  }, [])
  const loadCountries = () => {
    apiRequest(apiUrlCountryList)
      .then((rd) => {
        if (rd && rd.state && rd.data) {
          setCountries(rd.data)
        }
      })
      .catch(error => console.log("loadCountries in SelectCountry: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    optionLabelProp={"alpha2"}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={countries}
  />
/*
    <SelectOption key={"0"} value={"0"} disabled={true}>{"Please Choose"}</SelectOption>
    {countries.map(country => (<SelectOption key={country.id} value={country.id} alpha2={country.alpha2}>{country.alpha2 + " " + country.name}</SelectOption>))}
  </Select>
*/
}