import React, {useEffect, useState} from "react";
import apiRequest from "../../../general/apiRequest";
import {Button, notification} from "antd";
import {Link} from "react-router-dom";

const apiUrlGetAllAssignedParentItems = "/raveMultiAssignmentRef/allItem"

const arrayLimitShow = 3

export const WorkpieceAssigned = ({workpieceId, ...props}) => {
  const [assignments, setAssignments] = useState([])
  useEffect(() => {
    loadAssignments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workpieceId])
  const loadAssignments = () => {
    if (workpieceId)
      apiRequest.get(`${apiUrlGetAllAssignedParentItems}/${workpieceId}?count=${arrayLimitShow + 1}`)
        .then(rd => {
          if (rd && rd.state && rd.data) setAssignments(rd.data)
        })
        .catch(error => console.log("Error in WorkpieceAssigned: " + error))
  }
  const handleClick = () => {
    notification.warning({
      message: <b style={{fontSize: "2em"}}>Information</b>,
      description: <i style={{fontSize: "1.5em"}}><br/>Hier wird ein Informationsdialog geöffnet<br/></i>,
      placement: "top",
      duration: 5,
      top: 200,
    })
  }
  return <>
    <ul
      style={{marginBottom: "0em",paddingLeft: "1em"}}
    >
      {assignments.slice(0, arrayLimitShow).map((assign, index) => <li className={"assigned"}>
        {(assign.itemType === 'project')
          ?<Link key={assign.id} to={`/ras/${assign.itemType}/${assign.id}`} > {assign.codeNumber}<br/>{assign.name} </Link>
          :<Link key={assign.id} to={`/ras/${assign.itemType}/${assign.id}`} > {assign.codeNumber}<br/>{assign.name} </Link>
        }
      </li>)}
    </ul>
    {(assignments.length > arrayLimitShow)
      ?<Button
        type={"link"}
        className={"assigned"}
        onClick={handleClick}
        style={{padding: 0,}}
      >&rArr; mehr ...</Button>
      :null}
  </>
}

