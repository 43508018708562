import React, {useContext, useEffect, useState} from 'react'
import {Dropdown, Tree} from "antd";
import apiRequest from "../../general/apiRequest";
import {Link} from "react-router-dom";
import useCredentials from "../../system/UseCredentials";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {BreakdownIcon, ProjectIcon, RoomIcon, WorkpieceIcon} from "../../rascal/bimProductTree";
import {tN} from "../../system/checkTenants";
import {BC_RAVE_TEMPLATE} from "../Breadcrumb";
import {useRaveItemMenu} from "../Menu/RaveItemMenu";

// const apiUrlProjectList                           = "/project/list"
// const apiUrlProjectListShared                     = "/project/listShared"
// const apiUrlBreakDownListDirectParentOnly         = "/breakdown/byDirectParentOnly"

const apiUrlBreadcrumbList = "breadcrumb"

export const TemplateBimTree =  ({shared, ...props}) => {
  const [state] = useContext(CsescaContext)
  const [credentials] = useCredentials()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const tenantId = credentials?.tenant.id
  const [menuList, buildMenuList] = useRaveItemMenu(/*rightClickTreeRecord?.id*/)
  const [treeData, setTreeData] = useState([/*{id: "bim0", name: "Empty Tree"}*/])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  useEffect(() => {
    loadTemplateList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.user.id])

  useEffect(() => {
    if (checkEntityName()) loadBreadcrumbPath({id: state.entityId, name: state.entityName})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entityId])

  const checkEntityName = () => {
    switch (state.entityName) {
      case "project":
      case "breakdown":
      case "room":
      case "workpiece":
        return (state.breadcrumbType === BC_RAVE_TEMPLATE)
      default:
        return false
    }
  }

  const loadBreadcrumbPath = (entity) => {
    if (entity.id && entity.name) {
      apiRequest.get(`${apiUrlBreadcrumbList}/${entity.name}/${entity.id}`)
        .then(rd => {
          if (rd && rd.state && rd.data && rd.data.length > 0)  expandedItems(rd.data, false)
        })
    }
  }

  const updateTreeData = (list, key, children) => list.map((node) => {
    if (node.id === key) return {...node, children,}
    if (node.children) return {...node, children: updateTreeData(node.children, key, children),}
    return node
  })

  const loadTemplateList = () => {
    setExpandedKeys(_ => [])
    setTreeData(_ => [])
    const style = {color: "rgba(0, 0, 0, 0.7)", fontStyle: "inherited"}
    const rootNodes = [
      {id: "tempProjectRoot", name: <Link to={"/ras/projectTemplateList"} title={tN(tenantId, "Projekt")} style={style}><ProjectIcon style={{margin: "0 .2em",}} />{tN(tenantId, "Projekt")}</Link>, selectable: false, isLeaf: false,},
      {id: "tempBreakdownRoot", name: <Link to={"/ras/breakdownTemplateList"} title={tN(tenantId, "Struktur")} style={style}><BreakdownIcon style={{margin: "0 .2em",}} />{tN(tenantId, "Struktur")}</Link>, selectable: false, isLeaf: false,},
      {id: "tempRoomRoot", name: <Link to={"/ras/roomTemplateList"} title={tN(tenantId, "Raum")} style={style}><RoomIcon style={{margin: "0 .2em",}} />{tN(tenantId, "Raum")}</Link>, selectable: false, isLeaf: false,},
      {id: "tempWorkpieceRoot", name: <Link to={"/ras/workpieceTemplateList"} title={tN(tenantId, "Objekt")} style={style}><WorkpieceIcon style={{margin: "0 .2em",}} />{tN(tenantId, "Objekt")}</Link>, selectable: false, isLeaf: false,},
    ]
//    const templateRootList = rootNodes.map(templateItems => ({}))
    setTreeData(_ => rootNodes)
    setExpandedKeys(_ => [...selectedKeys])
  //   setExpandedKeys(prevState => [])
  //   setTreeData(prevState => [])
  //   const url = (!shared)?`${apiUrlProjectList}?template=false`:apiUrlProjectListShared
  //   apiRequest.get(url)
  //     .then( rd => {
  //       if (rd && rd.state && rd.data) {
  //         const nrd = rd.data.map(p => {
  //           return {data: {...p}, id: p.id, key: p.id, name: createLink("project", p.id, p.name, false), selectable: false, isLeaf: false, icon: null, }
  //         })
  //         setTreeData(prevState => nrd)
  //         setExpandedKeys(prevState => [...selectedKeys])
  //       }
  //     })
  }

  // const createLink = (bType, id, name, multiAssigned) => {
  //   const style = {color: "rgba(0, 0, 0, 0.7)", fontStyle: "inherited"}
  //   switch (bType) {
  //     case "project": return <Link to={"/ras/project/"+id} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",}} />{name}</Link>
  //     case "breakdown": return <Link to={"/ras/breakdown/"+id} title={name} style={style}><BreakdownIcon style={{margin: "0 .2em",}} />{name}</Link>
  //     case "room": return <Link to={"/ras/room/"+id} title={name} style={style}><RoomIcon style={{margin: "0 .2em",}} />{name}</Link>
  //     case "workpiece": return <Link to={"/ras/workpiece/"+id} title={name} style={style}><WorkpieceIcon style={{margin: "0 .2em",}} />{name}</Link>
  //     default: return null
  //   }
  // }

  const loadNodes = async parentId  => {
//TODO implement for loding
    // const rd = await apiRequest.get(apiUrlBreakDownListDirectParentOnly + "/" + parentId + "?template=" + false)
    // if (rd && rd.state && rd.data) return rd.data.map(b => ({
    //   data: {...b},
    //   id: b.id,
    //   name: createLink(b.bType, b.id, b.name, b.multiAssigned),
    //   selectable: false,
    //   icon: null,
    //   isLeaf: (b.bType === "workpiece" || b.childrenAmount < 1)
    // }))
    return []
  }

  const doExpanded = (expandedItemKeys, {expanded, node}) => {
    if (expanded) {
      loadNodes(node.key)
        .then(children => {
          setTreeData((prevState) => updateTreeData(prevState, node.key, children))
          setExpandedKeys(expandedItemKeys)
        })
    } else setExpandedKeys(expandedItemKeys)
  }

  const expandedItems = (bcList = [], reset = false) => {
    const expand = (list = []) => {
      const item = list.shift()
      if (item) {
        if (!expandedKeys.find((element) => element === item.id)) {
          loadNodes(item.id)
            .then(children => {
              setTreeData((prevState) => updateTreeData(prevState, item.id, children))
              setExpandedKeys((prevState) => [...prevState, item.id])
              setSelectedKeys((prevState) => [...prevState, item.id])
              if (list.length > 0)  expand(list)
            })
        } else {
          setSelectedKeys((prevState) => [...prevState, item.id])
          expand(list)
        }
      }
    }
    setSelectedKeys(prevState => [])
    expand([...bcList])
  }
  return <Dropdown menu={{items: menuList }} trigger={['contextMenu']} open={dropdownOpen}>
    <Tree
      rootClassName={"cses-product-tree"}
      showIcon={true}
      onExpand={doExpanded}
      expandedKeys={expandedKeys}
      multiple={true}
      selectedKeys={selectedKeys}
      fieldNames={{title: "name", key: "id"}}
      treeData={treeData}
//      onRightClick={({event, node}) => setActiveTreeRecord(event, node)}
    />
  </Dropdown>
}
