import React, {useEffect, useState} from 'react'
import apiRequest from "../../general/apiRequest";
import {Select} from "antd";

const apiUrlNodeMetas = "/nodeMeta/list"

export const SelectNodeMeta = ({allowClear, showEmpty = false, disableEmpty = false, onBlur, onChange, readOnly = false, textEmpty = null, valueEmpty = "Please Choose", ...props}) => {
  const [nodeMetas, setNodeMetas] = useState([])
  useEffect(() => {
    loadNodeMetas()
  }, [])
  const loadNodeMetas = () => {
    apiRequest.get(apiUrlNodeMetas)
      .then((rd) => {
        if (rd && rd.state && rd.data) setNodeMetas(rd.data)
      })
      .catch(error => console.log("loadNodeMetas in SelectNodeMeta: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    style={{width: "100%",}}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={nodeMetas}
  />
}
/*
    {(showempty) ?<SelectOption key={"scope0"} value={valueEmpty} disabled={disableempty}>{textempty}</SelectOption>:null}
    {nodeMetas.map(item =><SelectOption key={item.id} value={item.id}>{item.name}</SelectOption>)}
  </Select>
*/
