import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";

const nodeMetaDrawerSlice = createSlice({
  name: "nodeMetaDrawer",
  initialState: {visible: false},
  reducers: {
    setVisible(state, {payload}) {
      state.visible = payload
    }
  }
})

export default nodeMetaDrawerSlice.reducer
export const { setVisible: setNodeMetaDrawerVisible } = nodeMetaDrawerSlice.actions

export const selectNodeMetaDrawerVisible = state => state.ui.attr.nodeMetaDrawer

