import React, {useEffect, useState} from 'react'
import {Card, Form, Input, InputNumber} from 'antd'
import { Row, Col } from 'antd'
import {SelectMeasureUnit} from "../common/cmnMeasureUnitSelect"
import {sysRight} from "../common/cmnRights"
import apiRequest from "../general/apiRequest"
import {DownOutlined, DragOutlined,} from "@ant-design/icons";

const apiUrlMeasureShow        = "/measure/show"
const apiUrlMeasureSave        = "/measure"
// const apiUrlMeasureUnitAll     = "/measureunit/listAll"

const defaultMeasure = {
  id          : "",
  active      : true,
  position    : -1,
  parentId   : "",
  name        : "loading...",
  gaebType   : "",
  formulaNr  : "",
  formula     : "",
  comment     : "",
  length      : 0,
  width       : 0,
  height      : 0,
  depth       : 0,
  diameter    : 0,
  area        : 0,
  volume      : 0
}
export const MeasurePanel = ({editable, fromTemplate = false, rightsParent, ...props}) => {
  const [measure, setMeasure] = useState(defaultMeasure)
  // const [measureUnits, setMeasureUnits] = useState([])
  const [isChanged, setIsChanged] = useState(false)
  useEffect(() => {
    loadMeasure(props.itemId)
    // loadMeasureUnits()

// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadMeasure = (measureId) => {
    apiRequest.get(apiUrlMeasureShow + "/" + measureId)
      .then(rd => {
        if (rd && rd.state && rd.data) setMeasure(rd.data)
      })
      .catch(error => console.log("loadMeasure in MeasurePanel " + error))
  }
  // const loadMeasureUnits = () => {
  //   const rd = apiRequest.get(apiUrlMeasureUnitAll)
  //     .then(rd => {
  //       if (rd && rd.state && rd.data) setMeasureUnits(rd.data)
  //     })
  //     .catch(error => console.log("loadMeasureUnits in MeasurePanel " + error))
  // }
  const handleSave = () => {
    if (isChanged) {
      apiRequest.put(apiUrlMeasureSave, JSON.stringify({...measure}))
        .then(rd => setIsChanged(false))
        .catch(error => console.log("handleSave in MeasurePanel " + error))
    }
  }
  const handleChildBlur = () => {handleSave() }
  const handleChildChange = (name, value) => {
    if (editable) {
      // console.log("Change Value")
      setMeasure(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleChildChangeSelect = (name, value) => {
    if (editable) {
      setMeasure(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const renderAdditionalHeaderComponents = (editable, update, fromTemplate, noBorderStyle, measure) => {
    return <Input
      name={"name"}
      value={measure.name}
      style={{width: "100%"/*, color: "rgba(0, 0, 0, 0.85)"*/, ...noBorderStyle}}
      onChange={(e) => {handleChildChange("name", e.target.value)}}
      onBlur={() => {handleChildBlur()}}
      readOnly={!editable || !update}
      disabled={!update}
    />

  }
  const renderCardTitle = (editable, update, fromTemplate, noBorderStyle, measure) => {
    if (editable)
    return {title: <Row gutter={[16,16]}>
        {(!fromTemplate && editable)
          ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
          :null
        }
        <Col span={5}>
          <Input
            name={"name"}
            value={measure.name}
            style={{width: "100%", color: "rgba(0, 0, 0, 0.85)", ...noBorderStyle}}
            onChange={(e) => {handleChildChange("name", e.target.value)}}
            onBlur={() => {handleChildBlur()}}
            readOnly={!editable || !update || fromTemplate}
            disabled={!update || fromTemplate}
          />
        </Col>
      </Row>,
      extra: props.extra
    }
    else return {title: null}
  }
    const noBorderStyle = /*(!editable)?{borderColor: "transparent"}:*/{}
    const inputStyle = {display: "inline"/*, ...noBorderStyle*/}
    const selectStyle = {width: "4em"/*, ...noBorderStyle*/}
    const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
    return (
      <Card
/*
        title={
          <Row gutter={[16,16]}>
            {(!this.props.fromTemplate && editable)
              ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
              :null
            }
            <Col span={5}>
              <Input
                name={"name"}
                value={measure.name}
                style={{width: "100%", color: "rgba(0, 0, 0, 0.85)", ...noBorderStyle}}
                onChange={(e) => {this.handleChildChange("name", e.target.value)}}
                onBlur={() => {this.handleChildBlur()}}
                readOnly={!this.props.editable || !update || this.props.fromTemplate}
                disabled={!update || this.props.fromTemplate}
              />
            </Col>
          </Row>
      }
      extra={<>
        {this.props.extra}
      </>
      }
*/
        {...renderCardTitle(editable, update, fromTemplate, noBorderStyle, measure)}
      >
        <Form name="measure-panel-form"
          layout={"vertical"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={[12,0]} >
            {(!editable)
              ?<>
                <Col span={8}>
                  <Form.Item label={"Bezeichnung"} style={{ marginBottom: 0 }}>
                    {renderAdditionalHeaderComponents(editable, update, fromTemplate, noBorderStyle, measure)}
                  </Form.Item>
                </Col>
                <Col span={16} />
                </>
              :null
            }
            <Col span={4}>
              <Form.Item label="Länge" style={{ marginBottom: 0 }}>
                <InputNumber
                  name={"length"} value={measure.length}
                  className="rascalnumber"
                  style={{...inputStyle}}
                  controls={false}
                  onChange={(value) => {handleChildChange("length", value)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update} disabled={!update}
                  addonAfter={<Form.Item noStyle>
                    <SelectMeasureUnit
                      allowClear={!editable || !update}
                      name={"uLengthId"} value={measure.uLengthId}
                      style={selectStyle}
                      suffixIcon={editable?<DownOutlined />:null}
                      open={(!editable)?false:undefined}
                      popupMatchSelectWidth={false}
                      unitGroup="6BA69A11-B71F-48F9-9969-CBA1C11CE45A"
                      onChange={(value) => {handleChildChangeSelect("uLengthId", value)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update}
                      disabled={!update}
                    />
                  </Form.Item>}
                />
              </Form.Item>
              <Form.Item label="Breite" style={{ marginBottom: 0 }}>
                <InputNumber
                  name={"width"} value={measure.width}
                  className="rascalnumber"
                  style={inputStyle}
                  onChange={(value) => {handleChildChange("width", value)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update} disabled={!update}
                  addonAfter={<Form.Item noStyle>
                    <SelectMeasureUnit
                      allowClear
                      name={"uWidthId"} value={measure.uWidthId}
                      style={selectStyle}
                      suffixIcon={editable?<DownOutlined />:null}
                      open={(!editable)?false:undefined}
                      popupMatchSelectWidth={false}
                      unitGroup="6BA69A11-B71F-48F9-9969-CBA1C11CE45A"
                      onChange={(value) => {handleChildChangeSelect("uWidthId", value)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update} disabled={!update}
                    />
                  </Form.Item>}
                />
              </Form.Item>
              <Form.Item label="Höhe" style={{ marginBottom: 0 }}>
                <InputNumber
                  name={"height"} value={measure.height}
                  className="rascalnumber"
                  style={inputStyle}
                  onChange={(value) => {handleChildChange("height", value)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update} disabled={!update}
                  addonAfter={<Form.Item noStyle>
                    <SelectMeasureUnit
                      allowClear
                      name={"uHeightId"} value={measure.uHeightId}
                      style={selectStyle}
                      suffixIcon={editable?<DownOutlined />:null}
                      open={(!editable)?false:undefined}
                      popupMatchSelectWidth={false}
                      unitGroup="6BA69A11-B71F-48F9-9969-CBA1C11CE45A"
                      onChange={(value) => {handleChildChangeSelect("uHeightId", value)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update} disabled={!update}
                    />
                  </Form.Item>}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Durchmesser" style={{ marginBottom: 0 }}>
                <InputNumber
                  name={"diameter"} value={measure.diameter}
                  className="rascalnumber"
                  style={inputStyle}
                  onChange={(value) => {handleChildChange("diameter", value)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update} disabled={!update}
                  addonAfter={<Form.Item noStyle>
                    <SelectMeasureUnit
                      allowClear
                      name={"uDiameterId"} value={measure.uDiameterId}
                      style={selectStyle}
                      suffixIcon={editable?<DownOutlined />:null}
                      open={(!editable)?false:undefined}
                      popupMatchSelectWidth={false}
                      unitGroup="6BA69A11-B71F-48F9-9969-CBA1C11CE45A"
                      onChange={(value) => {handleChildChangeSelect("uDiameterId", value)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update} disabled={!update}
                    />
                  </Form.Item>}
                />
              </Form.Item>
              <Form.Item label="Fläche" style={{ marginBottom: 0 }}>
                <InputNumber
                  name={"area"} value={measure.area}
                  className="rascalnumber"
                  style={inputStyle}
                  onChange={(value) => {handleChildChange("area", value)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!props.editable || !update} disabled={!update}
                  addonAfter={<Form.Item noStyle>
                    <SelectMeasureUnit
                      allowClear
                      name={"uAreaId"} value={measure.uAreaId}
                      style={selectStyle}
                      suffixIcon={editable?<DownOutlined />:null}
                      open={(!editable)?false:undefined}
                      popupMatchSelectWidth={false}
                      unitGroup="d5f2c9ea-51d7-41b9-bc72-1ae6f5aed781"
                      onChange={(value) => {handleChildChangeSelect("uAreaId", value)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update} disabled={!update}
                    />
                  </Form.Item>}
                />
              </Form.Item>
              <Form.Item label="Volumen" style={{ marginBottom: 0 }}>
                <InputNumber
                  name={"volume"} value={measure.volume}
                  className="rascalnumber"
                  style={inputStyle}
                  onChange={(value) => {handleChildChange("volume", value)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update} disabled={!update}
                  addonAfter={<Form.Item noStyle>
                    <SelectMeasureUnit
                      allowClear
                      name={"uVolumeId"} value={measure.uVolumeId}
                      style={selectStyle}
                      suffixIcon={editable?<DownOutlined />:null}
                      open={(!editable)?false:undefined}
                      popupMatchSelectWidth={false}
                      unitGroup="75fec0c5-302e-4474-beaa-2e014bc4c1ec"
                      onChange={(value) => {handleChildChangeSelect("uVolumeId", value)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update} disabled={!update}
                    />
                  </Form.Item>}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    )
}
