import React, {useContext, useEffect, useRef, useState} from 'react';
import {Table, Space, Checkbox, Card} from "antd";
import Highlighter from "react-highlight-words";
import {useNavigate, useSearchParams} from "react-router-dom";
import {sysRight} from "../../common/cmnRights";
import {WorkpieceListMenu} from "./workpieceListMenu";
import apiRequest from "../../general/apiRequest";
import {isLigniteTenant, tN} from "../../system/checkTenants";
import {covImgFunc, mapImgFunc} from "../rascalApiDef";
import {TitleImage} from "../../images/imageMapHover";

import "../style/rave.css"
import {useFetchList} from "../../core/Fetch/useFetchList";
import {raveWorkpieceMeta} from "../../rave";
import {WorkpieceParent} from "../../rave";
import {WorkpieceLocation} from "../../rave";
import {WorkpieceAssigned} from "../../rave";
import {columnSearchProps} from "../../util/ColumnSearchProps/columnSearchProps";
import {parentTypes} from "../../panel/panelDndContainer";
import {BC_RAVE_TEMPLATE} from "../../rave";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {mrpNodeMetaId} from "../../general/Entities";
import {RowDropMenu} from "../../core/RowDropMenu";
import {useRaveItemMenu} from "../../rave/Menu/RaveItemMenu";
import {ContextMenu} from "../../core/ContextMenu";
import {DeepCopySelect} from "../../dialog/modalDeepCopySelect";

const apiUrlWorkpieceRight        = "/workpiece/rights"
// const apiUrlWorkpieceList         = "/workpiece/byParent"
// const apiUrlWorkpieceCreate       = "/workpiece"
// const apiUrlWorkpieceDuplicate    = "/workpiece/duplicate"
// const apiUrlWorkpieceDelete       = "/workpiece"

const elementMeta = raveWorkpieceMeta

export const WorkpieceList  = ({isTemplate = false, workId, parentType, ...props}) =>  {
  const [ , setState] = useContext(CsescaContext)
  const [params] = useSearchParams()
  const isTemplateInt = (params?.isTemplate)?true:isTemplate
  const navigation = useNavigate()
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
  const [deepCopyProps, setDeepCopyProps] = useState({open: false, sourceId: null});
  const [menuList, buildMenuList] = useRaveItemMenu()
  const fetchParams = (!isTemplateInt)
    ?{referenceId: workId,}
    :{referenceId: workId, queryParams: `isTemplate=${isTemplateInt}`,}
  const searchInput = useRef(null);
//  const {data: workpieceList, revalidate: loadWorkpieceList, remove, create} = useFetchList(
  const fetchList = useFetchList(
    elementMeta,
    fetchParams,
  )
  useEffect(() => {
    if (isTemplateInt && parentType === parentTypes.tenant) setState(prevState => {return {
      ...prevState,
      breadcrumbType: BC_RAVE_TEMPLATE,
      entityName: "workpieceList",
      pageType: "rave",
      entityId: null,
      state: "done",
      breadCrumbList: [],
    }})
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentType]);
  const filterObjects =  {
    refInput: searchInput,
    doSearch:(selectedKeys, confirm, dataIndex) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    },
    doReset:(clearFilters, confirm) => {
      clearFilters()
      confirm()
      setSearchText('')
    },
    doRender:(text, dataIndex) => searchedColumn === dataIndex
      ? <Highlighter
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
      : text,
  }
  const duplicateWorkpiece = (deepCopy) => {
    fetchList.create({
      newData: null,
      urlExtension: `duplicate/${deepCopy.sourceId}`,
      urlParams: `isTemplate=${isTemplate}`,
      doNavigate: true,
    })
  }
  const handleDoubleClick = (value) => {
    apiRequest.get(apiUrlWorkpieceRight + "/" + value)
      .then((rd) => {
        if (rd && rd.state && rd.rights && (rd.rights.indexOf(sysRight.READ) >= 0)) navigation("/ras/workpiece/" + value)
      })
      .catch(error => console.log("handleDoubleClick in WorkpieceList: " + error))
  }
  const buildImageDef = (record, imgFunction) => ({covImg: {refererId: record.id, projectId: record.projectId,}, rightsParent: [], parentId: record.id, imgFunction})
  const newCols = () => {
    const cols = [
      { title: "Bild", dataIndex: "mediaId", width: 160,
//        render: (value, lineItem) => <TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.projectId,}} rightsParent={[]} parentId={lineItem.id} imgFunction={covImgFunc} />
        render: (_, record) => <TitleImage {...buildImageDef(record, covImgFunc)} />,
      },
      !isLigniteTenant(props.tenantId)
      ?{ title: "Flurplan", dataIndex: "mediaId", width: 160,
//        render: (value, lineItem) => <TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.projectId,}} rightsParent={[]} parentId={lineItem.id} imgFunction={mapImgFunc} />
        render: (_, record) => <TitleImage {...buildImageDef(record, mapImgFunc)} />,
      }:null,
      // { title: tN(props.tenantId, "Objekt"), dataIndex: "name", width: 300, ...getColumnSearchProps('name'), sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()), sortDirections: ['descend', 'ascend']},
      { ...columnSearchProps(tN(props.tenantId, "Objekt"), 'name', filterObjects), width: 300, },
      // { title: tN(props.tenantId, "Kennzeichnungsnr."), dataIndex: "codeNumber", width: 150, ...getColumnSearchProps('codeNumber'), sorter: (a, b) => a.codeNumber.toLowerCase().localeCompare(b.codeNumber.toLowerCase()), sortDirections: ['descend', 'ascend']},
      { ...columnSearchProps(tN(props.tenantId, "Kennzeichnungsnr."), 'codeNumber', filterObjects), width: 150, },
      { title: tN(props.tenantId, "Struktur/Raum"), width: 160, render: (data, record, ix) => <WorkpieceParent workpieceId={record.id} /> },
      !isLigniteTenant(props.tenantId)
      ?{title: "Lagerort", dataIndex: "activeRelocationId", width: 160,
        render: (value, record, ix) => (!value)?"nicht ausgelagert":<WorkpieceLocation workpieceId={record.id} />
      }:null,
      !isLigniteTenant(props.tenantId)
      ?{title: "Zuordnung", dataIndex: "assignmentCount", width: 160,
        render: (value, record, ix) => (value < 1)?"keine Zuordnung":<WorkpieceAssigned workpieceId={record.id} />
      }:null,
      { title: "Planung", dataIndex: "conservation", width: 25,
        filterMultiple: false,
        filters: [{text: "ja", value: true},{text: "nein", value: false}],
        onFilter: (value, record) => record.conservation === value,
        render: (value) => <Checkbox checked={value}
        /> },
      { title: ""},
      { title: "Bearbeiten", dataIndex: "id", width: 15, render: (_, record) =>
          // <RowDropMenu contextMenuInfo={{id: lineItem.id, tenantId: lineItem.tenantId, name: lineItem.name, fetchList, nodeMetaId: mrpNodeMetaId["workpiece"]}} />
          <RowDropMenu contextMenuInfo={buildMenuListDefinition(record)} />,
      }
    ]
    return cols.filter((items) => items !== null)
  }
  const getRowClassName = (record) => {
    switch (true) {
      case (record.assignmentCount > 0 && record.activeRelocationId):
        return "rave-assigned-relocated"
      case (record.assignmentCount > 0):
        return "rave-assigned"
      case (record.activeRelocationId):
        return "rave-relocated"
      default :
        return ""
    }
  }
  const buildMenuListDefinition = (record) => ({doCopy: setDeepCopyProps, id: record.id, name: record.name, fetchList, nodeMetaId: mrpNodeMetaId["workpiece"], tenantId: record.tenantId})
  const handleContextMenu = (event, record) => {
    event.preventDefault()
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    // buildMenuList({id: record.id, tenantId: record.tenantId, name: record.name, fetchList, nodeMetaId})
    buildMenuList(buildMenuListDefinition(record))
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  const onRow = (record) => ({
    onDoubleClick: () => {handleDoubleClick(record.id)},
    onContextMenu: (event) => handleContextMenu(event, record)
  })
  return <Card
    extra={<Space>
      <WorkpieceListMenu
        isTemplate={isTemplateInt}
        projectId={props.projectId}
        parentId={workId}
        create={fetchList.create}
      />
    </Space>}
  >
    <ContextMenu items={menuList} {...contextMenuState}/>
    <Table
      className={"ces-rave-table"}
      pagination={{position: ['topRight', 'bottomRight'],}}
      dataSource={fetchList.data}
      onRow={onRow}
//       onRow={(row, index) => {return {onDoubleClick: (e) =>{handleDoubleClick(row.id)}}}}
      rowClassName={(record) => getRowClassName(record)}
      columns={newCols()}
      rowKey={(record) => record.id}
    />
    <DeepCopySelect
//      open={deepCopyProps.open}
      deepCopyProps={deepCopyProps}
      onResult={duplicateWorkpiece}
      onCancel={() => setDeepCopyProps({open: false, sourceId: null})}
    />
  </Card>
}
