import React, {useState} from 'react';
import Draggable from 'react-draggable';
import {Modal} from "antd";

export const ModalDraggable = (props) => {
  const [dragState, setDragState] = useState({
      open: false,
      disabled: true,
      bonds: {left: 0, top: 0, bottom: 0, right: 0},
  })
  const draggableRef = React.useRef(null)
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement
    const targetRect = draggableRef?.current?.getBoundingClientRect()
    if  (!targetRect) return
    setDragState({...dragState, bonds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      }})
  }
  const { bounds, disabled/*, open*/ } = dragState
  return (
    <Modal
      {...props}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {setDragState({...dragState, disabled: false,})}
          }}
          onMouseOut={() => {setDragState({...dragState, disabled: true,})}}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onFocus={() => {}}
          onBlur={() => {}}
          // end
        >
          {(props.titleName)?props.titleName:"Draggable Modal"}
        </div>
      }
      destroyOnClose={true}
      modalRender={modal => (
        <Draggable
          nodeRef={draggableRef}
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}>
    </Modal>
  )
}
