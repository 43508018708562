import React, {useEffect, useState} from "react"
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Table,
  Tooltip
} from "antd";
import apiRequest from "../../../general/apiRequest";
import {CloseOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {SelectCatalog} from "../../../common/cmnSelectCatalog";
//import {SelectTypeRole} from "../../../common/cmnTypeRuleSelect";
//import {SelectTargetWithoutSelf} from "../importWidgets/SelectTargetWithoutSelf";
//import {SelectTargetEdgeMeta} from "../importWidgets/SelectTargetEdgeMeta";
//import {SelectIdentProperty} from "../importWidgets/SelectTargetIdentProperty";
//import {SelectScope} from "../../../attribute/select/SelectScope";
//import {SelectCatalog} from "../../../common/cmnSelectCatalog";

const apiUrl = {
  importSchemaDefinitionShow:                 "/importSchemaDefinition/show",
  importSchemaDefinitionSave:                 "/importSchemaDefinition",
  importSchemaDefinitionListByTarget:         "/importSchemaDefinition/listByTarget",
  importSchemaDefinitionCreate:               "/importSchemaDefinition",
  importSchemaDefinitionDelete:               "/importSchemaDefinition",
}

const importSchemaDefinitionNew = {
  id: null,
  active: true,
  importTargetId: null,
  position: -1,
  name: "Neuer Eintrag",
  description: "",
  targetPropertyId: null,
  targetPropertyName: "",
  targetPropertyTypeId: null,
  expression: "",
  insertPolicyId: null,
  blankCellPolicyId: null,
  missingCatalogPolicyId: null,
  cellOverflowPolicyId: null,
}
export const ImportSchemaDefinitionTable = (props) => {
  const [importSchemaDefinitions, setImportSchemaDefinitions] = useState([])
  const [selectedDefinitionRowKey, setSelectedDefinitionRowKey] = useState(null)
  const {importSchemaAnchor} = props
  useEffect(() => {loadImportDefinitions(props.refId)}, [props.refId])
  const loadImportDefinitions = async (parentId) => {
    if (parentId) {
      const res = await apiRequest.get(`${apiUrl.importSchemaDefinitionListByTarget}/${parentId}`)
      if (res && res.state && res.data) setImportSchemaDefinitions(res.data)
    }
  }
  const createImportDefinition = async () => {
    if (props.refId) {
      const targetId = props.refId
      const res = await apiRequest.post(apiUrl.importSchemaDefinitionCreate, JSON.stringify({...importSchemaDefinitionNew, importTargetId: targetId}))
      if (res && res.state && res.data) {
        loadImportDefinitions(props.refId)
      }
    }
  }
  const deleteDefinition = async (definitionId) => {
    await apiRequest.delete(`${apiUrl.importSchemaDefinitionDelete}/${definitionId}`)
    await loadImportDefinitions(props.refId)
  }
  const defaultColumns = [
    {title: "Definition", dataIndex: "name", ellipsis:true, width: 140,
      onCell: (row) => {return {onClick: e => {setSelectedDefinitionRowKey(row.id)}}}
    },
    {title: <Tooltip
        title={"Zeilentyp hinzufügen"}>
        <Button
          type={"link"}
//          disabled={(props.schemaId.length <= 0)}
          icon={<PlusOutlined />}
          onClick={() => {createImportDefinition()}}
        />
    </Tooltip>,
      align: "right",
      render: (data, row, ix ) =>
        <Popconfirm
          placement={"top"}
          title={"Möchten Sie den Datensatz löschen"}
          onConfirm={() => {deleteDefinition(row.id)}}
          okText="Ja"
          cancelText="Nein"
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true, ghost: true}}
        >
          <Button type={"link"} danger icon={<DeleteOutlined />} />
        </Popconfirm>
    },
  ]
  return <>
    <Table
      rowKey={"id"}
      rowSelection={{
        type: "radio",
        onChange: (selectedKeys) => {setSelectedDefinitionRowKey((selectedKeys.length > 0)?selectedKeys[0]:null)},
        selectedRowKeys: [selectedDefinitionRowKey],
        columnWidth: 0,
        renderCell: () => "",
      }}
      columns={defaultColumns}
      dataSource={importSchemaDefinitions}
    />
    <ImportSchemaDefinitionDrawer
//      openDrawer={selectedTargetKey.length > 0}
      importSchemaAnchor={importSchemaAnchor}
      selectedDefinitionRowKey={selectedDefinitionRowKey}
      setSelectedDefinitionRowKey={setSelectedDefinitionRowKey}
//      destroyOnClose
    />
  </>
}
export const ImportSchemaDefinitionDrawer = (props) => {
  const {selectedDefinitionRowKey, setSelectedDefinitionRowKey, importSchemaAnchor} = props
  return <Drawer
    title={"Definition"}
    open={!!selectedDefinitionRowKey}
    //    destroyOnClose
    closable={false}
    onClose={() => {setSelectedDefinitionRowKey(null)}}
    getContainer={() => document.getElementById("ImportSchemaDrawerParent")}
//    getContainer={false}
    width={1000}
    extra={<Button type={"text"} icon={<CloseOutlined />} onClick={() => {setSelectedDefinitionRowKey(null)}} />}
    {...props}
  >
    <ImportSchemaDefinitionEdit
      importSchemaDefinitionId={selectedDefinitionRowKey}
      importSchemaAnchor={importSchemaAnchor}
    />
  </Drawer>
}

const ImportSchemaDefinitionEdit = (props) => {
  const [importSchemaDefinition, setImportSchemaDefinition] = useState({changed: false, data: {}})
//  const {importSchemaAnchor} = props
  useEffect(() => {loadImportSchemaDefinition(props.importSchemaDefinitionId)}, [props.importSchemaDefinitionId])
  const loadImportSchemaDefinition = async importSchemaDefinitionId => {
    if (importSchemaDefinitionId) {
      const res = await apiRequest.get(`${apiUrl.importSchemaDefinitionShow}/${importSchemaDefinitionId}`)
      if (res && res.state && res.data)
        setImportSchemaDefinition({changed: false, data: res.data})
    }
  }
  const saveRowType = async () => {
    if (importSchemaDefinition.changed) {
      const res = apiRequest.put(apiUrl.importSchemaDefinitionSave, JSON.stringify(importSchemaDefinition.data))
      if (res && res.state && res.data) setImportSchemaDefinition({changed: false, data: res.data})
    }
  }
  const handleChange = (name, value) => {
    setImportSchemaDefinition({changed: true, data:{...importSchemaDefinition.data, [name]: value}})
  }
  const handleBlur = () => saveRowType()
  return <Card title={null}>
    <Form name="import-schema-definition-form" layout={"vertical"} preserve={false}>
      <Row gutter={[8, 0]}>
        <Col span={8}>
          <Form.Item label={"Name"} style={{ marginBottom: "0px" /*, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0'*/, }}>
            <Input
              name={"name"}
              value={importSchemaDefinition.data.name}
              maxLength={40}
              onChange={(e) => {handleChange(e.target.name, e.target.value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={16}/>
        <Col span={8}>
          <Form.Item label={"Beschreibung"} style={{ marginBottom: "0px" /*, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0'*/, }}>
            <Input.TextArea
              name={"description"}
              value={importSchemaDefinition.data.description}
              maxLength={2000}
              autoSize={{ minRows: 3, maxRows: 6 }}
              style={{resize: "none"}}
              onChange={(e) => {handleChange(e.target.name, e.target.value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label={"Ausdruck"} style={{ marginBottom: "0px" /*, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 4px'*/, }}>
            <Input.TextArea
              name={"expression"}
              value={importSchemaDefinition.data.expression}
              maxLength={2000}
              autoSize={{ minRows: 3, maxRows: 6 }}
              style={{resize: "none"}}
              onChange={(e) => {handleChange(e.target.name, e.target.value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"targetPropertyId"} style={{ marginBottom: "0px", }}>
            <SelectCatalog
              name={"targetPropertyId"}
              value={importSchemaDefinition.data.targetPropertyId}
              catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
              fieldNames={{label: "name", value: "id"}}
              onChange={(value) => {handleChange("targetPropertyId", value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"targetPropertyTypeId"} style={{ marginBottom: "0px", }}>
            <SelectCatalog
              name={"targetPropertyTypeId"}
              value={importSchemaDefinition.data.targetPropertyTypeId}
              catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
              fieldNames={{label: "name", value: "id"}}
              onChange={(value) => {handleChange("targetPropertyTypeId", value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"blankCellPolicyId"} style={{ marginBottom: "0px", }}>
            <SelectCatalog
              name={"blankCellPolicyId"}
              value={importSchemaDefinition.data.blankCellPolicyId}
              catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
              fieldNames={{label: "name", value: "id"}}
              onChange={(value) => {handleChange("blankCellPolicyId", value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"targetPropertyName"} style={{ marginBottom: "0px", }}>
            <Input
              name={"targetPropertyName"}
              value={importSchemaDefinition.data.targetPropertyName}
              maxLength={40}
              onChange={(e) => {handleChange(e.target.name, e.target.value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"missingCatalogPolicyId"} style={{ marginBottom: "0px", }}>
            <SelectCatalog
              name={"missingCatalogPolicyId"}
              value={importSchemaDefinition.data.missingCatalogPolicyId}
              catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
              fieldNames={{label: "name", value: "id"}}
              onChange={(value) => {handleChange("missingCatalogPolicyId", value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"cellOverflowPolicyId"} style={{ marginBottom: "0px", }}>
            <SelectCatalog
              name={"cellOverflowPolicyId"}
              value={importSchemaDefinition.data.cellOverflowPolicyId}
              catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
              fieldNames={{label: "name", value: "id"}}
              onChange={(value) => {handleChange("cellOverflowPolicyId", value)}}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
{/*
      <Form.Item label={"Identitätsausdruck"} style={{ marginBottom: 0, }}>
        <Input.TextArea
          name={"identExpression"}
          value={importSchemaDefinition.data.identExpression}
          maxLength={2000}
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{resize: "none"}}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Übergeordnetes Target"} style={{ marginBottom: 0 }}>
        <SelectTargetWithoutSelf
          targetId={importSchemaDefinition.data.id}
          name={"parentTargetId"}
          value={importSchemaDefinition.data.parentTargetId}
          style={{resize: "none"}}
          onChange={(value) => {handleChange("parentTargetId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Scope"} style={{ marginBottom: 0, }}>
        <SelectScope
          name={"scopeId"}
          value={importSchemaDefinition.data.scopeId}
          fieldNames={{label: "name", value: "id"}}
          onChange={(value) => {handleChange("scopeId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"TargetEdgemeta"} style={{ marginBottom: 0, }}>
        <SelectTargetEdgeMeta
          name={"targetEdgemetaId"}
//          value={importSchemaAnchor.current?.anchorNodeMetaId}
          value={importSchemaDefinition.data.targetEdgemetaId}
          onChange={(value) => {handleChange("targetEdgemetaId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"IdentProperty"} style={{ marginBottom: 0, }}>
        <SelectIdentProperty
          name={"identPropertyId"}
          value={importSchemaDefinition.data.identPropertyId}
          fieldNames={{label: "name", value: "id"}}
          onChange={(value) => {handleChange("identPropertyId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"InsertPolicy"} style={{ marginBottom: 0, }}>
        <SelectCatalog
          name={"insertPolicyId"}
          value={importSchemaDefinition.data.insertPolicyId}
          catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
          fieldNames={{label: "name", value: "id"}}
          onChange={(value) => {handleChange("insertPolicyId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
*/}
    </Form>
  </Card>
}