import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlCommTypeList         = "/commtype/list"

export const SelectCommType = ({allowClear, onBlur, onChange, readOnly = false, ...props}) => {
  const [commTypes, setCommTypes] = useState([])
  useEffect(() => {
    loadCommType()
  }, [])
  const loadCommType = () => {
    apiRequest.get(apiUrlCommTypeList)
      .then((rd) => {
        setCommTypes(rd.data)
      })
      .catch(error => console.log("loadCommType in SelectCommType: " + error))
  }
  return<Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={commTypes}
  />
/*
    <SelectOption key={"0"} value={"0"} disabled={true}>{"Please Choose"}</SelectOption>
    {commTypes.map(commType => (<SelectOption key={commType.id} value={commType.id} >{commType.name}</SelectOption>))}
  </Select>
*/
}
