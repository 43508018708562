/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 12.06.2023
 *
 * Project: csescaclient
 *
 * LoggedInUser in package  (project: csescaclient ) © 12.06.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {useEffect, useState} from "react";
import apiRequest from "../general/apiRequest";
import {useDispatch, useSelector} from "react-redux";
import {setLoggedInUser} from "../store/loggedInUserSlice"

const initCredentials = {
  user: null,
  user2: null,
  tenant: null,
  organisation: null,
}
const useCredentials = () => {
  const userCredentials = useSelector(state => state.data).loggedInUser
  const dispatch = useDispatch()
  const [credentials, setCredentials] = useState(userCredentials || initCredentials)
  const setItem = (newCredentials) => {
    setCredentials(newCredentials)
    dispatch(setLoggedInUser(newCredentials))
    return newCredentials
  }
  useEffect(() => {
    startUp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
//  const equalCredentials = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
  const isEmpty = (value) => {
    const ret = (value.user?.id && !value.user2?.id && value.tenant?.id && value.organisation?.id)
    return ret
  }
  const startUp = () => {
//    const newCredentials = window.localStorage.getItem(key)
    if (isEmpty(credentials)) {
      apiRequest.get("/refreshUser")
        .then(rd => {
          if (rd && rd.state && rd.data) {
            setItem({
              user: rd.data.user,
              user2: rd.data.user2,
              tenant: rd.data.tenant,
              organisation: rd.data.organisation,
            })
          } else {
            setCredentials(initCredentials)
          }
        })
        .catch(error => console.log("LoggedInUser Error on Startup " + error))
    }
  }
  return [userCredentials]
  // return [credentials]
}

export default useCredentials