import {Tabs} from "antd";
import {isTenantIdLigniteTenant, isUserIdLigniteAdmin, tN} from "../../system/checkTenants";
import {PanelCloud} from "../../panelCloud";
import PanelDndContainer, {parentTypes} from "../../panel/panelDndContainer";
import {BreakdownList} from "../breakdown/rascalBreakdownList";
import {RoomList} from "../room/rascalRoomList";
import {RestorationGoalList} from "../rascalRestorationGoal";
import {ParticipantList} from "../Participant";
import React, {useEffect, useState} from "react";
import useCredentials from "../../system/UseCredentials";
import {WorkpieceList} from "../workpiece/rascalWorkpieceList";

export const ProjectTabs = ({editable, ...props}) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const userId = credentials?.user.id
  const [activeTab, setActiveTab] = useState("PJ0")
  useEffect(() => setActiveTab("PJ0"), [props.project.id])
  const panelTypes = ["text", "media", "measure", "location", "subjectPerson", "subjectOrganisation", "nodeData"]
  const handleChangeTab = (activeKey) => setActiveTab(activeKey)
  const tabItems = () => {
    const items = []
    items.push(
      {
        key: "PJ0", label: "Übersicht", destroyInactiveTabPane: true,
        children: <>
          {!isTenantIdLigniteTenant(tenantId) ?<PanelCloud key="PanelProjectCloudUrl" cloudURL={props.project.cloudUrl}/> : null}
          <PanelDndContainer
            key="PanelProjectBaseShow"
            rightsParent={props.rights}
            editable={false}
            projectId={props.project.id}
            workId={props.project.id}
            workParentId={props.project.tenantId}
            parentType={parentTypes.project}
            panelTypes={panelTypes}
          />
        </>,
      },
      {
        key: "PJ1", label: "Projektdaten", destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelProjectBase"
          rightsParent={props.rights}
          editable={editable}
          projectId={props.project.id}
          workId={props.project.id}
          workParentId={props.project.tenantId}
          parentType={parentTypes.project}
          panelTypes={panelTypes}
        />,
      },
      {
        key: "PJ2", label: tN(tenantId, "Struktur"), destroyInactiveTabPane: true,
        children: <BreakdownList
          tenantId={tenantId}
          workId={props.project.id}
          projectId={props.project.id}
        />,
      },
      {
        key: "PJ3", label: tN(tenantId, "Räume"), destroyInactiveTabPane: true,
        children: <RoomList
          tenantId={tenantId}
          rightsParent={props.rights}
          projectId={props.project.id}
          workId={props.project.id}
          parentType={parentTypes.project}
        />,
      },
      {
        key: "PJ4", label: tN(tenantId, "Objekte"), destroyInactiveTabPane: true,
        children: <WorkpieceList
          tenantId={tenantId}
          rightsParent={props.rights}
          projectId={props.project.id}
          workId={props.project.id}
          parentType={parentTypes.project}
        />,
      },
     )
    if (!isTenantIdLigniteTenant(tenantId))
      items.push({
        key: "Media3", label: "Medien", destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelProjectMedia"
          rightsParent={[]}
          projectId={props.project.id}
          workId={props.project.id}
          workParentId={props.project.tenantId}
          parentType={parentTypes.project}
          panelTypes={["media"]}
        />,
      }, {
        key: "PJRS", label: "Restaurierungskonzept", destroyInactiveTabPane: true,
        children: <RestorationGoalList
          rightsParent={props.rights}
          projectId={props.project.id}
          workId={props.project.id}
          parentType={parentTypes.project}
        />,
      })
    if (!isTenantIdLigniteTenant(tenantId) || isUserIdLigniteAdmin(userId))
      items.push({key: "PJPCT", label: "Mitwirkende", destroyInactiveTabPane: true,
        children:<ParticipantList
          rightsParent={props.rights}
          projectId={props.project.id}
          workId={props.project.id}
          parentType={parentTypes.project}
        />,
      })
    return items
  }
  return <Tabs
    destroyInactiveTabPane={true}
    activeKey={activeTab}
    onChange={handleChangeTab}
    items={tabItems()}
  />
}