import React, {useEffect, useRef, useState} from 'react'
import {Button, Input, message, Popconfirm, Space, Table} from "antd";
import {SearchOutlined, UserAddOutlined, UserDeleteOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {PersonChoose} from "../dialog/modalPersonChoose";
import {SelectRole} from "../common/cmnRoleSelect";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";

const apiUrlParticipantList         = "/participant/list"
const apiUrlParticipantAdd          = "/participant/add"
const apiUrlParticipantRoleUpdate   = "/participant/updateRole"
const apiUrlParticipantDel          = "/participant/del"


export const ParticipantList = ({workId, rightsParent, ...props}) => {
  const [participants, setParticipants] = useState([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  useEffect(() => {
    loadParticipants(workId)
  }, [workId]);
  const loadParticipants = (myWorkId) => {
    apiRequest.get(apiUrlParticipantList + "/" + myWorkId)
      .then((rd) => {
        setParticipants(rd.data)
      })
      .catch(error => console.log("loadParticipants in ParticipantList: " + error))
  }
  const deleteParticipant = async (rowId) => {
// //TODO Check the Result
//       (res) => {this.loadParticipants(workId)}
//       )
    apiRequest.get(apiUrlParticipantDel + "/" + rowId)
      .then((rd) => {
        loadParticipants(workId)
      })
      .catch(error => console.log("deleteParticipant in ParticipantList: " + error))
  }
  const addParticipant = (a, personId, roleId) => {
// //TODO Check the Result
//       (res) => {this.loadParticipants(workId)}
//       )
    apiRequest.post(apiUrlParticipantAdd + "/" + workId + "/" + roleId + "/" + personId)
      .then((rd) => {
        loadParticipants(workId)
      })
      .catch(error => console.log("addParticipant in ParticipantList: " + error))
  }
  const saveModalDialog = (values) => {
    if (values.result === "ok") {
      if (values.values.subjectId !== "" && values.values.roleId !== "")
        addParticipant(values.kind, values.values.subjectId, values.values.roleId)
      else
        message.error({content: "Die Auswahl war nicht korrekt!", style: {marginTop: "20vh"}})
    }
  }
  const selectUpdateRole = (rowItem, value) => {
    apiRequest(apiUrlParticipantRoleUpdate + "/" + rowItem.subjectId + "/" + value)
      .then((rd) => {
        if (rd && rd.state) loadParticipants(workId)
      })
      .catch(error => console.log("addParticipant in ParticipantList: " + error))
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Suchen ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suchen
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Löschen
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
              // this.setState({
              //   searchText: selectedKeys[0],
              //   searchedColumn: dataIndex,
              // })
            }}
          >
            Filtern
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: visible => {
      if (visible)  setTimeout(() => searchInput.select(), 100)
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // })
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
//  const read = (rightsParent.indexOf(sysRight.READ) >= 0)
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  return <div>
    <h2>Mitwirkende</h2>
    <Table
      rowKey={(record) => { return ("SP"+ record.subjectId)}}
      dataSource={participants}
      columns={[
        { title: "Anrede", dataIndex: "title",
          width: 100,
          ...getColumnSearchProps('title'),
          sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          sortDirections: ['descend', 'ascend'],
        },
        { title: "Titel", dataIndex: "academicTitle",
          width: 100,
          ...getColumnSearchProps('academicTitle'),
          sorter: (a, b) => a.academicTitle.toLowerCase().localeCompare(b.academicTitle.toLowerCase()),
          sortDirections: ['descend', 'ascend'],
        },
        { title: "Vorname", dataIndex: "firstName",
          width: 150,
          ...getColumnSearchProps('firstName'),
          sorter: (a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          sortDirections: ['descend', 'ascend'],
        },
        { title: "Name", dataIndex: "lastName",
          width: 200,
          ...getColumnSearchProps('lastName'),
          sorter: (a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()),
          sortDirections: ['descend', 'ascend'],
        },
        { title: "Rolle", dataIndex: "roleId",
          width: 200,
          // ...this.getColumnSearchProps('roleName'),
          // sorter: (a, b) => a.roleName.toLowerCase().localeCompare(b.roleName.toLowerCase()),
          // sortDirections: ['descend', 'ascend'],
          render: (txt, row, ix) => {
          return(
            <SelectRole
              style={{ width: "100%" }}
              onChange={(value) => {selectUpdateRole(row, value)}}
              defaultValue={txt}
              readOnly={!update} disabled={!update}
            />
        )}
        },
        { title: ""},
        { title: /*<Button type="primary" ghost={true} shape={"round"}
                         onClick={() => {this.addParticipant()}}><UserAddOutlined  /></Button>,*/
            <PersonChoose
              onResult={(v) => {saveModalDialog(v)}}
              panelTypes={new Set(["subjectPerson"])}
              chooseRole={true}
              buttonText=""
              buttonType="link"
              buttonIcon={<UserAddOutlined />}
              buttonGhost={true}
              buttonShape="round"
              buttonDisabled={!update}
            />,
          align: "right",
          render: (data, row, ix) => {return (
            <Popconfirm
              placement="topRight"
              title={"Möchten Sie den/die Mitwirkende/n löschen"}
              onConfirm={() => {deleteParticipant(row.subjectId)}}
              okText="Ja"
              cancelText="Nein"
              cancelButtonProps={{danger: false, type: "primary"}}
              okButtonProps={{danger: true, ghost: true}}
            >
              <Button
                type="link"
                danger
                shape={"round"}
                icon={<UserDeleteOutlined/>}
                readOnly={!update} disabled={!update}
              />
            </Popconfirm>
          )}
        },
      ]}
    />
  </div>
}