import React, {useEffect, useState} from "react"
import { enumOptionsValueForIndex } from "@rjsf/utils"
import {TreeSelect} from "antd"
import {CloseCircleTwoTone} from "@ant-design/icons"
import apiRequest from "../../../general/apiRequest";

const apiUrlCatalogTree         = "/catalog/treeOrderByName"

const SELECT_STYLE = { width: "100%",}

const LookupTree = (props) => {
  const {
    autofocus,
      disabled,
      formContext,
      id,
      // label,
      // multiple,
      onBlur,
      onChange,
      onFocus,
      options,
      placeholder,
      readonly,
      // required,
      schema,
      value,
  } = props
  const [catalog, setCatalog] = useState()
  useEffect(() => {
    const loadTree = async ()  => {
      if (schema.lookupTreeId) {
        const rd = await apiRequest.get(`${apiUrlCatalogTree}/${schema.lookupTreeId}`)
        if (rd && rd.state && rd.data) setCatalog(rd.data)
      }
    }
    loadTree()
  }, [schema.lookupTreeId])

  const { readonlyAsDisabled = true } = formContext
//  const handleChange = (nextValue) => onChange(enumOptionsValueForIndex(schema, nextValue, options));
  const handleChange = (nextValue) => onChange(nextValue)
  const handleBlur = () => {
    onBlur()
//    onBlur(id, enumOptionsValueForIndex(schema, value, options))
  }
  const handleFocus = () => onFocus(id, enumOptionsValueForIndex(schema, value, options))
  const getPopupContainer = (node) => node.parentNode

  return <TreeSelect
    autoFocus={autofocus}
    disabled={disabled || (readonlyAsDisabled && readonly)}
    getPopupContainer={getPopupContainer}
    id={id}
//    mode={typeof multiple !== "undefined" ? "multiple" : undefined}
    name={id}
    onBlur={!readonly ? handleBlur : undefined}
    onChange={!readonly ? handleChange : undefined}
    onFocus={!readonly ? handleFocus : undefined}
    placeholder={placeholder}
    fieldNames={{label: "name", value: "id", children: "children"}}
    treeData={catalog}
    allowClear={{clearIcon:<CloseCircleTwoTone twoToneColor="#eb2f96" />}}
    // allowClear={true}
    // clearIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
    // onClear={() => {return false}}
    style={SELECT_STYLE}
    value={typeof value !== "undefined" ? value : undefined}
  />
}

LookupTree.defaultProps = {
  formContext: {},
}

export {LookupTree};