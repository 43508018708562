import React from 'react'
import {Tabs} from "antd";
import {ProjectList} from "../project/rascalProjectList";
import {BreakdownList} from "../breakdown/rascalBreakdownList";
import {parentTypes} from "../../panel/panelDndContainer"
import {RoomList} from "../room/rascalRoomList";
import {WorkpieceList} from "../workpiece/rascalWorkpieceList";
import {tN} from "../../system/checkTenants";
import useCredentials from "../../system/UseCredentials";

export const Templates = (props) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  return (
    <section className={"col-lg-12"}>
      <div className="card">
        <div className="card-header">
          <h2>Templates</h2>
          <Tabs defaultActiveKey="project" destroyInactiveTabPane>
            <Tabs.TabPane key="project" tab="Projekt">
              <ProjectList
                isTemplate={true}
                workId={tenantId}
                parentType={parentTypes.tenant}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="breakdown" tab={tN(tenantId,"Struktur")}>
              <BreakdownList
                isTemplate={true}
                projectId=""
                workId={tenantId}
//                workId={data.tenantId}
                parentType={parentTypes.tenant}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="room" tab={tN(tenantId,"Raum")}>
              <RoomList
                isTemplate={true}
                projectId=""
                workId={tenantId}
//                workId={data.tenantId}
                parentType={parentTypes.tenant}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="workpiece" tab={tN(tenantId,"Objekt")}>
              <WorkpieceList
                isTemplate={true}
                projectId=""
                workId={tenantId}
//                workId={data.tenantId}
                parentType={parentTypes.tenant}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </section>
  )
}