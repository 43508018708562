import React, {useEffect, useState} from 'react'
import {Card, Col, Row} from "antd";
import apiRequest from "./apiRequest";

export const TestData = (props) => {
  const [testData, setTestData] = useState([])
//  const fetchData = () => {
    const loadTestData = async () => {
      const rd = await apiRequest.get('/communicationType/list?test=login')//.then(r => r.data)
      if (rd && rd.state && rd.data) setTestData(rd.data)
//      console.log("TestAPI: " + JSON.stringify(testRes))
    }
//  }
  useEffect(() => {loadTestData()}, [])
  return (
    <Card title={"TestAPI"}>
        {testData.map( dta =>
          <Row key={dta.id}>
            <Col span={1}>id</Col>
            <Col span={4}>{dta.id}</Col>
            <Col span={1}>name</Col>
            <Col span={8}>{dta.name}</Col>
            <Col span={10}/>
          </Row>
        )}
    </Card>
  )
}