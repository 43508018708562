import React, {useContext, useEffect, useState} from 'react'
import {BC_RAVE_DEFAULT} from "../Breadcrumb";
import {Tree} from "antd";
import apiRequest from "../../general/apiRequest";
import {Link} from "react-router-dom";
import useCredentials from "../../system/UseCredentials";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {BreakdownIcon, ProjectIcon, RoomIcon, WorkpieceIcon} from "../../rascal/bimProductTree";
import {mrpNodeMetaId} from "../../general/Entities";
import {useRaveItemMenu} from "../Menu/RaveItemMenu";
import {ContextMenu} from "../../core/ContextMenu";

const apiUrlProjectList                           = "/project/list"
const apiUrlProjectListShared                     = "/project/listShared"
const apiUrlBreakDownListDirectParentOnly         = "/breakdown/byDirectParentOnly"

const apiUrlBreadcrumbList = "breadcrumb"


export const MainBimTree =  ({shared, ...props}) => {
  const [state/*, setState*/] = useContext(CsescaContext)
  const [credentials] = useCredentials()
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
  const [menuList, buildMenuList] = useRaveItemMenu(/*rightClickTreeRecord?.id*/)
  const [treeData, setTreeData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  useEffect(() => {
    loadProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.user.id])
  useEffect(() => {
    if (state.breadcrumbType !== BC_RAVE_DEFAULT) setSelectedKeys([])
    if (checkEntityName() && state.breadcrumbType === BC_RAVE_DEFAULT) loadBreadcrumbPath({id: state.entityId, name: state.entityName})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entityId])
  const checkEntityName = () => {
    switch (state.entityName) {
      case "project":
      case "breakdown":
      case "room":
      case "workpiece":
        return (state.breadcrumbType === BC_RAVE_DEFAULT)
      default:
        return false
    }
  }
  const loadBreadcrumbPath = (entity) => {
    if (entity.id && entity.name) {
      apiRequest.get(`${apiUrlBreadcrumbList}/${entity.name}/${entity.id}`)
        .then(rd => {
          if (rd && rd.state && rd.data && rd.data.length > 0) expandedItems(rd.data, false)
        })
        .catch(error => console.error(`Error MainBimTree in loadBreadcrumbPath\n${error}`))
    }
  }
  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.id === key) return {...node, children,}
      if (node.children) return {...node, children: updateTreeData(node.children, key, children),}
      return node
    })
  }
  const loadProjects = () => {
    setExpandedKeys(prevState => [])
    setTreeData(prevState => [])
    const url = (!shared)?`${apiUrlProjectList}?template=false`:apiUrlProjectListShared
    apiRequest.get(url)
      .then( rd => {
        if (rd && rd.state && rd.data) {
          const nrd = rd.data.map(p => {
            return {data: {...p}, id: p.id, key: p.id, bType: "project", name: createLink("project", p.id, p.name, false), selectable: false, isLeaf: false, icon: null, }
          })
          setTreeData(prevState => nrd)
          setExpandedKeys(prevState => [...selectedKeys])
        }
      })
      .catch(error => console.error(`Error MainBimTree in loadProjects\n${error}`))
  }
  const createLink = (bType, id, name, multiAssigned, data) => {
    // const style = {color: (multiAssigned)?"red":"#1890ff"}
    const style = (!multiAssigned)
      ?{color: "rgba(0, 0, 0, 0.7)", fontStyle: "inherited"}
      :{color: "rgba(0, 0, 191, 0.7)", fontStyle: "italic"}
    switch (bType) {
      // case "project": return <Link to={"/ras/project/"+id} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",strokeWidth: "30", fontSize: "50px",color: "#408021", stroke: "white"}} />{name}</Link>
      case "project": return <Link to={"/ras/project/"+id} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",}} />{name}</Link>
      case "breakdown": return <Link to={"/ras/breakdown/" + id} title={name} style={style}><BreakdownIcon style={{margin: "0 .2em",}} />{name}</Link>
      case "room": return <Link to={"/ras/room/"+id} title={name} style={style}><RoomIcon style={{margin: "0 .2em",}} />{name}</Link>
      case "workpiece": return <Link to={"/ras/workpiece/"+id} title={name} style={style}><WorkpieceIcon style={{margin: "0 .2em",}} />{name}</Link>
      default: return null
    }
  }
  const loadNodes = async parentId  => {
    const rd = await apiRequest.get(apiUrlBreakDownListDirectParentOnly + "/" + parentId + "?template=" + false)
//      const nrd = rd.data.map (b => {return {data: {...b}, id: b.id, name: createLink(b.bType, b.id, b.name, b.multiAssigned), selectable: false, icon: getIcon(b.bType), isLeaf: (b.bType === "workpiece") }})
    if (rd && rd.state && rd.data) return rd.data.map (b => ({
      data: {...b},
      id: b.id,
      bType: b.bType,
      name: createLink(b.bType, b.id, b.name, b.multiAssigned, b),
      selectable: false,
      icon: null,
      isLeaf: (b.bType === "workpiece" || b.childrenAmount < 1),
    }))
    return []
  }
  const doExpanded = (expandedItemKeys, {expanded, node}) => {
    if (expanded) {
      loadNodes(node.key)
        .then(children => {
          setTreeData((prevState) => updateTreeData(prevState, node.key, children))
          setExpandedKeys(expandedItemKeys)
        })
    } else setExpandedKeys(expandedItemKeys)
  }
  const expandedItems = (bcList = [], reset = false) => {
    const expand = (list = []) => {
      const item = list.shift()
      if (item) {
        if (!expandedKeys.find((element) => element === item.id)) {
          loadNodes(item.id)
            .then(children => {
              setTreeData((prevState) => updateTreeData(prevState, item.id, children))
              setExpandedKeys((prevState) => [...prevState, item.id])
              setSelectedKeys((prevState) => [...prevState, item.id])
              if (list.length > 0) expand(list)
            })
        } else {
          setSelectedKeys((prevState) => [...prevState, item.id])
          expand(list)
        }
      }
    }
    setSelectedKeys(prevState => [])
    expand([...bcList])
  }
  const setActiveTreeRecord = (event, newTreeRecord) => {
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    buildMenuList({
      id: newTreeRecord.id,
      tenantId: newTreeRecord.data.tenantId,
      name: newTreeRecord.data.name,
      nodeMetaId: mrpNodeMetaId[newTreeRecord.bType]
    })
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  return <>
    <ContextMenu items={menuList} {...contextMenuState} />
    <Tree
      rootClassName={"cses-product-tree"}
      showIcon={true}
      expandedKeys={expandedKeys}
      multiple={true}
      selectedKeys={selectedKeys}
      fieldNames={{title: "name", key: "id"}}
      treeData={treeData}
      onExpand={doExpanded}
      onRightClick={({event, node}) => setActiveTreeRecord(event, node)}
    />
  </>
}
