import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlOrganisationList         = "/org/list"

export const SelectOrganisation = (props) => {
  const [organisations, setOrganisations] = useState([])
  useEffect(() => {
    loadOrganisation()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loadOrganisation = () => {
    apiRequest.get(apiUrlOrganisationList)
      .then((rd) => {
        setOrganisations(rd.data)
      })
      .catch(error => console.log("loadOrganisation in SelectOrganisation: " + error))
  }
  return <Select
    {...props}
    optionLabelProp={"name1"}
    showSearch
    placeholder="Auswahlsuche"
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    filterSort={(optionA, optionB) =>
      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
    }
  >
    <Select.Option key={"0"} value={"0"} disabled={true}>{"Please Choose"}</Select.Option>
    {organisations?.map(organisation => (
      <Select.Option key={organisation.id} value={organisation.id} name1={organisation.name1}>
        {organisation.name1 + " " + organisation.name2}
      </Select.Option>
    ))}
  </Select>
}
