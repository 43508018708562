import React from 'react';
import {Select} from "antd";

export const SelectYesNo = (props) => {
  return <Select
    options={[{label: 'ja', value: true},{label: 'nein', value: false},]}
    dropdownAlign={{ points: ["tr", "br"] }}
    {...props}
  />
}
