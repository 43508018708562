/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 12.01.2024
 *
 * Project: csescaclient
 *
 * BreadcrumRegistry in package  (project: csescaclient ) © 12.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */ import {useContext, useEffect} from "react";
import CsescaContext from "../AppContext/CsescaContext";

const _registry = {}

export const registerComponent = (name, Component) => {
  if (name && Component) {
    if (!(_registry.hasOwnProperty(name)))
      _registry[name] = Component
    else console.warn("Name already exist")
  }
  else console.warn("Name or Component not valid")
}
const ErrorBreadcrumb = ({text}) => text
export const CsescaBreadcrumb = ({name, ...props}) => {
  const [state] = useContext(CsescaContext)
  useEffect(() => {
//    console.log("Change Breadcrumb")
  }, [state.breadcrumbType])
  if (typeof state.breadcrumbType !== "string" || name === "") return <ErrorBreadcrumb text="...No or empty String" />
  if (!(_registry.hasOwnProperty(state.breadcrumbType))) return <ErrorBreadcrumb text="...No Breadcrumb" />
  // if (typeof name !== "string" || name === "") return <ErrorBreadcrumb text="...No or empty String" />
  // if (!(_registry.hasOwnProperty(name))) return <ErrorBreadcrumb text="...No Breadcrumb" />
  const Component = _registry[state.breadcrumbType]
  return <Component {...props} />
}
