import React, {useState} from "react";
import {covImgFunc, mapImgFunc} from "../rascalApiDef";
import {sysRight} from "../../common/cmnRights";
import {Button, Card, Checkbox, Col, Form, Input, Row} from "antd";
import {TitleImage} from "../../images/imageMapHover";
import {isLigniteTenant, isTenantIdLigniteTenant, tN} from "../../system/checkTenants";
import {TreeSelectCatalog} from "../../common/cmnTreeSelectCatalog";
import {SelectCatalog} from "../../common/cmnSelectCatalog";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {TreeSelectBreakdown} from "./cmnTreeSelectBreakdown";
import useCredentials from "../../system/UseCredentials";

export const WorkpieceData = (props) => {
  const {collapseDetail} = props
  const [collapse, setCollapse] = useState(false)
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const imgContext = {
    refererId: props.workpiece.id,
    tenantId: props.workpiece.tenantId,
    projectId: props.workpiece.projectId,
    functionId: covImgFunc,
  }
  const update = (props.rights.indexOf(sysRight.UPDATE) >= 0)
  const showSelectParent = !(props.workpiece.parentId === props.workpiece.tenantId && props.workpiece.isTemplate)

  return <Card
    style={(!!props.workpiece.templateId)?{backgroundColor:"#FFFFFA"}: {}}
    className={(!collapseDetail)?"":"ces-collapse"}
  >
    <Row gutter={[16, 12]}>
      <Col span={4} className="data-container">
        {props.workpiece.id ?
          <TitleImage
            key={"CI" + props.workpiece.id}
            imgFunction={covImgFunc}
            covImg={imgContext}
            rightsParent={props.rights}
          /> : null}
      </Col>
      <Col span={4} className="data-container">
        {(props.workpiece.id && !isLigniteTenant(props.workpiece.tenantId)) ?
          <TitleImage
            key={"CI" + props.workpiece.id}
            imgFunction={mapImgFunc}
            covImg={imgContext}
            rightsParent={props.rights}
          /> : null}
      </Col>
      <Col span={10}>
        <Form name="workpiece-data-form"
          layout={"vertical"}
          // labelCol={{ span: 24 }}
          // wrapperCol={{ span: 24 }}
        >
          <Row gutter={[16, 12]}>
            <Col span={12}>
              <Form.Item label={tN(tenantId, "Objekt") + "-Nr."} style={{marginBottom: 0}}>
                <Input
                  name="codeNumber" value={props.workpiece.codeNumber}
                  maxLength={20}
                  onChange={(evt) => {
                    props.handleChildChange(evt)
                  }}
                  onBlur={(evt) => {
                    props.handleChildBlur(evt)
                  }}
                  readOnly={!update} disabled={!update}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={tN(tenantId, "Objekt")} style={{marginBottom: 0}}>
                <Input
                  name="name" value={props.workpiece.name}
                  maxLength={40}
                  onChange={(evt) => {
                    props.handleChildChange(evt)
                  }}
                  onBlur={(evt) => {
                    props.handleChildBlur(evt)
                  }}
                  readOnly={!update}
                  disabled={!update}
                />
              </Form.Item>
            </Col>
          </Row>
          {!isTenantIdLigniteTenant(tenantId) ?
            <>
              <Row gutter={[16, 12]}>
                <Col span={12}>
                  <Form.Item label="Struktur" style={{marginBottom: 0}}>
                    <TreeSelectBreakdown
                      name={"parentId"}
                      value={(showSelectParent) ? props.workpiece.parentId : null}
                      projectId={props.workpiece.projectId}
                      workId={props.workpiece.id}
                      allowClear={false}
                      placeholder={"Bitte auswählen"}
                      treeDefaultExpandAll
                      fieldNames={{label: 'name', value: 'id', children: 'children'}}
                      dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                      onChange={(tsData, tsLabel, tsExtra) => {
                        props.handleSelectChange("parentId", tsData)
                      }}
                      onBlur={(evt) => props.handleChildBlur(evt)}
                      readOnly={!update}
                      disabled={!update || !showSelectParent}
                      withParent={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label=" " style={{marginBottom: 0}}>
                    <Checkbox
                      name={"conservation"} checked={props.workpiece.conservation}
                      onChange={(evt) => {
                        props.handleChildChange(evt)
                      }}
                      onBlur={(evt) => {
                        props.handleChildBlur(evt)
                      }}
                      readOnly={!update} disabled={!update}
                    /><label style={{paddingLeft: "1em"}}>Planung</label>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 12]}>
                <Col span={12}>
                  {!isTenantIdLigniteTenant(tenantId) ?
                    <Form.Item label="Gewerk / Unterkategorie" style={{marginBottom: 0}}>
                      <TreeSelectCatalog
                        catalogTree={"3b99bd67-b26a-4a78-ba6f-99f55442f9b9"}
                        textempty="--- keine Auswahl ---"
                        allowClear={true}
                        value={props.workpiece.categoryId}
                        onChange={(tsData, tsLable, tsExtra) => {
                          props.handleSelectChange("categoryId", tsData)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    : null}
                </Col>
                <Col span={12}>
                  {(props.workpiece.enClassification) ?
                    <Form.Item label="Zustandsklasse (CC)" style={{marginBottom: 0}}>
                      <SelectCatalog
                        name="cClassId"
                        catalogGroup={"7357714A-2DF5-4806-9F02-4798DAF3F0E0"}
                        value={props.workpiece.cClassId}
                        onChange={(value) => {
                          props.handleSelectChange("cClassId", value)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    : null}
                </Col>
              </Row>
              <Row gutter={[16, 12]}>
                <Col span={12}>
                  <Form.Item label="Detail-/Fachplanung" style={{marginBottom: 0}}>
                    <SelectCatalog
                      name="detailPlanningId"
                      catalogGroup={"2da9a404-cf38-49d8-b24a-e1254a1502f5"}
                      value={props.workpiece.detailPlanningId}
                      onChange={(value) => {
                        props.handleSelectChange("detailPlanningId", value)
                      }}
                      onBlur={(evt) => {
                        props.handleChildBlur(evt)
                      }}
                      readOnly={!update} disabled={!update}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {(props.workpiece.enClassification) ?
                    <Form.Item label="Dringlichkeitsklasse (UC)" style={{marginBottom: 0}}>
                      <SelectCatalog
                        name="uClassId"
                        catalogGroup={"09c402e2-bf84-44b2-bc51-26a192e9d83c"}
                        value={props.workpiece.uClassId}
                        onChange={(value) => {
                          props.handleSelectChange("uClassId", value)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    : null}
                </Col>
              </Row>
            </>
            : null}
          {!isTenantIdLigniteTenant(props.workpiece.tenantId) ?
            <Row>
              <Col span={24} offset={0} style={{textAlign: "right"}}>
                <Button type="link" onClick={() => setCollapse(!collapse)}>
                  {collapse ? <UpOutlined/> : <DownOutlined/>}Ein/Aus
                </Button>
                {(collapse) ?
                  <div>
                    <Form.Item label="Cloud-URL" style={{marginBottom: 0}}>
                      <input name="cloudUrl" value={props.workpiece.cloudUrl} style={{width: "100%"}}
                             onChange={(evt) => {
                               props.handleChildChange(evt)
                             }}
                             onBlur={(evt) => {
                               props.handleChildBlur(evt)
                             }}
                      />
                    </Form.Item>
                  </div>
                  : null
                }
              </Col>
            </Row>
            : null}
        </Form>
      </Col>
    </Row>
  </Card>
}

