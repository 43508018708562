import React, {useEffect, useState} from 'react';
import {TreeSelect} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlCatalogTreeByRef         = "/catalog/tree"

export const TreeSelectCatalog = ({allowClear, catalogTree = "", onBlur, onChange, readOnly = false, ...props}) => {
  const [catalogs, setCatalogs] = useState([])
  useEffect(() => {
    loadCatalogTree()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogTree])
  const loadCatalogTree = () => {
    apiRequest.get(apiUrlCatalogTreeByRef + "/" + catalogTree)
      .then((rd) => {
        if (rd && rd.state && rd.data) setCatalogs(rd.data)
      })
      .catch(error => console.log("loadCatalogTree in TreeSelectCatalog: " + error))
  }
  return (
    <TreeSelect
      style={{ width: '100%' }}
      fieldNames={{label: "name", value: "id", children: "children"}}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      {...props}
      onChange={!readOnly && onChange}
      onBlur={!readOnly && onBlur}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      treeData={catalogs}
    />
  )
}

