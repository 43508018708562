/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 09.01.2024
 *
 * Project: csescaclient
 *
 * ProjectMeta in package  (project: csescaclient ) © 09.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {raveMeta} from "../raveMeta";

export const raveFavoriteMeta = {
  ...raveMeta,
  domain: "favorite",
  module: {
    ...raveMeta.module,
    routeDomain: null,
    name: "base",
  },
  defaultData: {
    ...raveMeta.defaultData,
    active:       true,
    position:     -1,
    personId:     null,
    nodeMetaId:   null,
    itemId:       null,
    name:         "Test",
  }
}
