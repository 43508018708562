import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlMeasureUnitList         = "/measureunit/list"

export const SelectMeasureUnit = ({allowClear, onChange, onBlur, readOnly, unitGroup = "00000000-0000-0000-0000-000000000000", ...props}) => {
  const [measureUnits, setMeasureUnits] = useState([])
  useEffect(() => {
    loadMeasureUnit()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.unitGroup])
  const loadMeasureUnit = () => {
    apiRequest.get(apiUrlMeasureUnitList + "/" + unitGroup)
      .then((rd) => {
        setMeasureUnits(rd.data)
      })
      .catch(error => console.log("loadMeasureUnit in SelectMeasureUnit: " + error))
  }
//  const handleChange = (value) => !readOnly ? onChange(value) : undefined
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
//    onChange={handleChange}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    optionLabelProp={"shortname"}
    open={readOnly ? false : undefined}
    options={measureUnits}
    />
}
/*
      <SelectOption key={"0"} value={"0"} disabled={true}>{"Please Choose"}</SelectOption>
      {measureUnits?.map(measureUnit => (<SelectOption key={measureUnit.id} value={measureUnit.id} shortname={measureUnit.shortname}style={{textAlign: "right"}}>{measureUnit.name}</SelectOption>))}
    </Select>
*/
