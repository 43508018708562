/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 12.07.2023
 *
 * Project: csescaclient
 *
 * CsescaUpload in package  (project: csescaclient ) © 12.07.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import React from "react";

export const CsescaUpload = (props) => {
  return <Upload.Dragger
    {...props}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text" style={{fontSize: ".8em"}}>Klicken oder ziehen Sie die Datei<br />zum Hochladen in diesen Bereich</p>
    <p className="ant-upload-hint" style={{fontSize: ".8em"}}>Unterstützung für Upload.</p>
  </Upload.Dragger>
}

