import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlDataSchemaTypeList         = "/dataSchemaType/list"

export const SelectDataSchemeType = ({allowClear, showEmpty = false, disableEmpty = false, onBlur, onChange, readOnly = false, textEmpty = null, valueEmpty = "Please Choose", ...props}) => {
  const [dataSchemeTypes, setDataSchemeTypes] = useState([])
  useEffect(() => {
    loadDataSchemeTypes()
  }, [])
  const loadDataSchemeTypes = () => {
    apiRequest.get(apiUrlDataSchemaTypeList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setDataSchemeTypes(rd.data)
      })
      .catch(error => console.log("loadDataSchemeTypes in SelectDataSchemeType: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={dataSchemeTypes}
  />
}
/*
    {(showempty) ?<SelectOption key={"dst0"} value={valueEmpty} disabled={disableempty}>{textempty}</SelectOption>:null}
    {dataSchemeTypes.map(item =><SelectOption key={item.id} value={item.id}>{item.name}</SelectOption>)}
  </Select>
*/
