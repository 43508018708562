import React from 'react';
import {Collapse} from "antd";

export const PanelCloud = (props) => {
//    const urlReg = "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
  const width   = 1000
  const height  = 800
  const viewPortWith = 1000
  const viewPortHeight = 750
  let cloudURL = (props.cloudURL.toLowerCase().includes("https://my.matterport.com/show/?m"))?props.cloudURL:""
  return <div>
    <Collapse
//      defaultActiveKey={(props.cloudURL !== "")?"1":undefined}
      accordion={true}
      items={[
        {
          key: "1", label: "Matterport",
          children: (cloudURL !== "")
            ? <svg
              version="1.1" xmlns="http://www.w3.org/2000/svg"
              viewBox={`0 0 ${viewPortWith} ${viewPortHeight}`}
              width={width}
              height={height}
            >
              <foreignObject
                viewBox={`0 0 ${viewPortWith} ${viewPortHeight}`}
                x={0}
                y={0}
                width={"100%"}
                height={"100%"}
              >
                <iframe
                  xmlns="http://www.w3.org/1999/xhtml"
                  title={"Matterport"}
                  src={cloudURL}
                  allowFullScreen={true}
                  width={"100%"}
                  height={"100%"}

                  style={{
                    border: "none",
                    scrolling: "omit",
                    overflow: "hidden !important",
                    margin: 0,
                  }}
                  referrerPolicy={"no-referrer"}
                />
              </foreignObject>
            </svg>
            : <h3>Keine Daten vorhanden</h3>
        },
      ]}
    />
  </div>
}

// <Collapse.Panel key={"1"} header={"Matterport"} style={{textAlign: "center"}}>
//   {(cloudURL !== "")
//     ? <>
//       {/*
//           <figure
//             style={{
//               textAlign: "center",
//               margin: "0 auto",
//               // width: "600px",
//               // height: "450px",
//               // width: width + "px",
//               // height: height + "px",
//             }}
//           >
// */}
//       <svg
//         version="1.1" xmlns="http://www.w3.org/2000/svg"
//         viewBox={`0 0 ${viewPortWith} ${viewPortHeight}`}
//         // width={"600"}
//         // height={"450"}
//         width={width}
//         height={height}
//         //              style={{position: "relative", display: "inline-block", }}
//       >
//         <foreignObject
//           viewBox={`0 0 ${viewPortWith} ${viewPortHeight}`}
//           x={0}
//           y={0}
//           width={"100%"}
//           height={"100%"}
//           // width={"600"}
//           // height={"450"}
//         >
//           <iframe
//             xmlns="http://www.w3.org/1999/xhtml"
//             title={"Matterport"}
//             src={cloudURL}
//             allowFullScreen={true}
//             // width={width}
//             // height={height}
//             width={"100%"}
//             height={"100%"}
//
//             //            frameBorder={0}
//             //            scrolling={"no"}
//             //            marginWidth={0}
//             //            marginHeight={0}
//             style={{
//               border: "none",
//               scrolling: "omit",
//               overflow: "hidden !important",
//               margin: 0,
//             }}
//             referrerPolicy={"no-referrer"}
//           />
//         </foreignObject>
//         {/*
//               <svg
//                viewBox={`0 0 ${viewPortWith} ${viewPortHeight}`}
//               >
//                 <a href="/ras/breakdown/66389b4d-3ca8-4bdb-80b3-df6d934b59b4" title="Erdgeschoss -Badehaus 2"><rect x="225" y="125" width="88" height="155" opacity="0.5" fill={"blue"} strokeWidth="2" stroke="#FF0000"><title>Erdgeschoss -Badehaus 2</title></rect></a>
//                 <a href="/ras/breakdown/c7912b5b-921d-4667-9b51-b10abde06506" title="Keller und Behälterraum BH2"><rect x="230" y="280" width="105" height="65" opacity="0.5" fill={"red"} strokeWidth="2" stroke="#0000FF"><title>Keller und Behälterraum BH2</title></rect></a>
//               </svg>
// */}
//       </svg>
//       {/*
//           </figure>
//             <Button
//               type={"default"}
//               onClick={(e) => {window.alert("Click on SVG"); e.stopPropagation()}}
//               style={{position: "relative", left: 0, bottom: 0}}
//               >
//               Test
//             </Button>
// */}
//     </>
//     :<h3>Keine Daten vorhanden</h3>
//   }
// </Collapse.Panel>
