/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 26.01.2024
 *
 * Project: csescaclient
 *
 * RightMeta in package  (project: csescaclient ) © 26.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {coreMeta} from "../utils/coreMeta";


export const raveRightMeta = {
  ...coreMeta,
  domain: "right",
  domainList: null,
  module: {
    ...coreMeta.module,
    routeDomain: null,
  },
  defaultData: {
    ...coreMeta.defaultData,
  }
}
