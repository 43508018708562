import React from 'react';
import {Select} from "antd";
import {useFetchSelect} from "../core/Fetch/useFetchSelect";
import {baseCatalogMeta} from "../core/domain/CatalogMeta";

//const apiUrlCatalogByRef         =  "/catalog/showByRef"

export const SelectCatalog = ({allowClear, catalogGroup, onBlur, onChange, readOnly = false, ...props}) => {
  const {data: catalogList} = useFetchSelect(baseCatalogMeta, {referenceId: catalogGroup, referenceName: "byReference"})
//  const [catalogs, setCatalogs] = useState([])
//   useEffect(() => {
//     loadCatalog()
// // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [catalogGroup])
//   const loadCatalog = () => {
//     apiRequest(apiUrlCatalogByRef + "/" + catalogGroup)
//       .then((rd) => {
//         if (rd && rd.state && rd.data) setCatalogs(rd.data)
//       })
//       .catch(error => console.log("loadCatalog in SelectCatalog: " + error))
//   }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    // options={catalogs}
    options={catalogList}
  />
}
