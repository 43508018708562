import React from 'react'
import {DatePicker as AntDatePicker} from "antd"
import dayjs from 'dayjs';

const DATE_PICKER_STYLE = {width: "100%",}

const DatePicker = (props) => {
  const {
    disabled,
    formContext,
    id,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    readonly,
    value,
  } = props
  const dateFormat = 'DD.MM.YYYY'
  const { readonlyAsDisabled = true } = formContext
  const handleChange = (nextValue) => {
    const newValue = nextValue?dayjs(nextValue).format():null
    onChange(newValue)
  }
  const handleBlur = () => {
    onBlur()
  }
  const handleFocus = () => onFocus(id, value)
  const getPopupContainer = node => node.parentNode
  const valueToDayjs = (transValue) => {
    return transValue?dayjs(transValue):null
  }
  return <AntDatePicker
    format={dateFormat}
    disabled={disabled || (readonlyAsDisabled && readonly)}
    getPopupContainer={getPopupContainer}
    id={id}
    name={id}
    onBlur={!readonly ? handleBlur : undefined}
    onChange={!readonly ? handleChange : undefined}
    onFocus={!readonly ? handleFocus : undefined}
    placeholder={placeholder}
    showTime={false}
    style={DATE_PICKER_STYLE}
//    value={defaultValue()}
    value={valueToDayjs(value)}
//    value={value && dayjs(value)}
//    aria-describedby={ariaDescribedByIds<T>(id)}
  />
}

export {DatePicker}