/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 09.01.2024
 *
 * Project: csescaclient
 *
 * ProjectMeta in package  (project: csescaclient ) © 09.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {raveMeta} from "../raveMeta";
import {coreMeta} from "../../core/utils/coreMeta";

export const raveProjectMeta = {
  ...raveMeta,
  domain: "project",
  module: {
    ...raveMeta.module,
    routeDomain: "project",
  },
  defaultData: {
    ...coreMeta.defaultData,
    codeNumber: "0815",
    name: "Test",
    projectBeginText: "",
    projectEndText: "1812",
    enClassification: false,
    isTemplate: false,
    templateId: null,
    cloudUrl: "",
  }
}
// const raveProjectMetaBackup = {
//   domain: "project",
//   module: {
//     routeDomain: "project",
//   },
//   defaultData: {
//     codeNumber: "0815",
//     name: "Test",
//     projectBeginText: "",
//     projectEndText: "1812",
//     enClassification: false,
//     isTemplate: false,
//     templateId: null,
//     cloudUrl: "",
//   }
// }

