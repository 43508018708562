import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visible: false,
  title: "",
  message: "",
  code: "",
  status: "",
  statusText: "",
}

const msgUnauthorizedSlice = createSlice({
  name: 'unauthorized',
  initialState,
  reducers: {
    show(state, action) {
//      const newState = {...action.payload, visible: true}
//      state = newState
      state.visible = true
      state.title = action.payload.title
      state.message = action.payload.message
      state.code = action.payload.code
      state.status = action.payload.status
      state.statusText = action.payload.statusText
    },
    hide(state, action) {
//      state = {...initialState}
      state.visible = false
      state.title = ""
      state.message = ""
      state.message = ""
      state.code = ""
      state.status = ""
      state.statusText = ""
    },
  },
})

export const { show, hide } = msgUnauthorizedSlice.actions
export default msgUnauthorizedSlice.reducer