import React, {useEffect, useState} from 'react';
import {TreeSelect} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlBreakdownParentTree = "/breakdown/allParentTree"

export const TreeSelectBreakdown = (props) => {
  const [breakdowns, setBreakdowns] = useState([])
//  const [selectedValue, setSelectedValue] = useState(null)
  const {readOnly, workId, projectId, withParent, defaultActiveFirstOption, onChange, ...rest} = props
  useEffect(() => {
    loadBreakdownTree(props.projectId)
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId])
  const loadBreakdownTree = (projectId) => {
    if (projectId) {
      apiRequest.get(`${apiUrlBreakdownParentTree}/${projectId}?withParent=${withParent}`)
        .then(rd => {
          if (rd && rd.state && rd.data) {
            setBreakdowns(rd.data)
            if (defaultActiveFirstOption && !props.value && rd.data.length>0) handleChange(rd.data[0].id)
          }
        })
        .catch(error => console.log("Error in TreeSelectBreakdown: " + error))
    }
  }
  const handleChange = (value, node, extra) => {
    if (!readOnly && onChange) onChange(value)
  }
  return (
    <TreeSelect
      key={"CT" + workId}
      style={{ width: '100%' }}
      fieldNames={{label: "name", value: "id", children: "children"}}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      {...rest}
      // onChange={!readOnly && props.onChange}
      onChange={handleChange}
      onBlur={!readOnly && props.onBlur}
      allowClear={!readOnly && props.allowClear}
      open={readOnly ? false : undefined}
      treeData={breakdowns}
    />
  )
}

TreeSelectBreakdown.defaultProps = {
  readOnly: false,
  defaultActiveFirstOption: false,
  withParent: false,
}

