import React, {useEffect, useState} from 'react'
import {Row, Col, Input} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {sysRight} from "../common/cmnRights"
import apiRequest from "../general/apiRequest"
import {DragOutlined} from "@ant-design/icons";
import {isLigniteTenant} from "../system/checkTenants";
import {TreeSelectCatalog} from "../common/cmnTreeSelectCatalog";
import {SelectAlternationType} from "../common/SelectAlternationType";
import {TitleImage} from "../images/imageMapHover";
import {raveDamageImgFunc} from "../rascal/rascalApiDef";
import useCredentials from "../system/UseCredentials";


const apiUrlRaveDamageShow               = "/raveDamage/show"
const apiUrlRaveDamageSave               = "/raveDamage"

const defaultRaveDamage = {
  id: null,
  active: true,
  position: -1,
  parentId: null,
  name: "",
  alterationTypeId: null,
  alterationId: null,
  materialId: null,
  description: ""
}
export const RaveDamagePanel = (props) => {
  const [raveDamage, setRaveDamage] = useState({...defaultRaveDamage, parentId: props.itemId})
  const [isChanged, setIsChanged] = useState(false)
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  const {editable, fromTemplate} = props
  const update = (props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
  const maxLength = (!isLigniteTenant(tenantId)) ? null : 1000
  useEffect(() => {
    loadRaveDamage(props.itemId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loadRaveDamage = async raveDamageId => {
//    if (raveDamageId) {
      const rd = await apiRequest.get(`${apiUrlRaveDamageShow}/${raveDamageId}`)
      if (rd && rd.state && rd.data) setRaveDamage(rd.data)
//    }
  }
  const handleSave = async () => {
    if (isChanged && raveDamage.parentId) {
      await apiRequest.put(apiUrlRaveDamageSave, JSON.stringify(raveDamage))
      setIsChanged(false)
    }
  }
  const handleChildBlur = () => { handleSave() }
  const handleChildChange = (evt) => {
    if (props.editable) {
      const name = evt.target.name
      const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
      setRaveDamage(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleSelectChange = (name, value) => {
    setRaveDamage(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
  }
  const imgContext = {
    refererId: props.itemId,
    tenantId: null,
    projectId: props.projectId,
    functionId: raveDamageImgFunc,
  }
  return (
    <div className={"ant-card ant-card-bordered ant-card-small"}>
      <div className={"ant-card-head"}>
        <div className={"ant-card-head-wrapper"}>
          <div className={"ant-card-head-title"}>
            <Row gutter={[16,16]}>
              {(!fromTemplate && editable)
                ?<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
                :null
              }
              <Col span={5}>
                <Input
                  name={"name"}
                  value={raveDamage.name}
                  onBlur={() => {handleChildBlur()}}
                  onChange={(evt) => {handleChildChange(evt)}}
                  readOnly={!props.editable || !update}
                  disabled={!update}
                />
              </Col>
              <Col span={6}>
                <SelectAlternationType
                  catalogTree={"3b99bd67-b26a-4a78-ba6f-99f55442f9b9"}
                  allowClear={true}
                  value={raveDamage.alterationTypeId}
                  onBlur={() => {handleChildBlur()}}
                  onChange={(tsData, tsLabel, tsExtra) => {handleSelectChange("alterationTypeId", tsData)}}
                  readOnly={!props.editable || !update}
                  disabled={!update}
                />
              </Col>
              <Col span={4}>DIN CEN/TS 17135 - Veränderungen an Objekten</Col>
            </Row>
          </div>
          <div className={"ant-card-head-extra"}>
            {(editable)?props.extra:null}
          </div>
        </div>
      </div>
      <div className={"ant-card-body"}>
        <Row gutter={[16,16]}>
          <Col
            span={6}
          >
            <TreeSelectCatalog
              catalogTree={"8a9ec110-5b29-420d-a678-a556c600038b"}
              multiple={false}
              allowClear={true}
              value={raveDamage.alterationId}
              onBlur={() => {handleChildBlur()}}
              onChange={(tsData, tsLabel, tsExtra) => {handleSelectChange("alterationId", tsData)}}
              readOnly={!props.editable || !update}
              disabled={!update}
            />
          </Col>
          <Col
            span={6}
          >
            <TreeSelectCatalog
              catalogTree={"3b99bd67-b26a-4a78-ba6f-99f55442f9b9"}
              multiple={false}
              allowClear={true}
              value={raveDamage.materialId}
              onBlur={() => {handleChildBlur()}}
              onChange={(tsData, tsLabel, tsExtra) => {handleSelectChange("materialId", tsData)}}
              readOnly={!props.editable || !update}
              disabled={!update}
            />
          </Col>
          <Col
            span={12}
          />
{/*
        </Row>
        <Row>
*/}
          <Col
            span={6}
          >
            <TitleImage
              key={"RD" + raveDamage.id}
              imgFunction={raveDamageImgFunc}
              covImg={imgContext}
              rightsParent={props.rightsParent}
            />
          </Col>
          <Col span={18}>
              <Input.TextArea
                 name={"description"}
                 value={raveDamage.description}
//                 rows={6}
                 maxLength={maxLength}
                 showCount={editable}
                 autoSize={editable?{ minRows: 6, maxRows: 10 }:{ minRows: 4, maxRows: 124 }}
                 style={{resize: "none"}}
                 onChange={(evt) => {handleChildChange(evt)}}
                 onBlur={() => {handleChildBlur()}}
                 readOnly={!props.editable || !update}
                 disabled={!update}
               />
          </Col>
        </Row>
      </div>
    </div>
  )
}

RaveDamagePanel.defaultProps = {
  fromTemplate: false,
}
