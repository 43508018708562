import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visible: false,
  title: "",
  message: "",
}

const msgForbiddenSlice = createSlice({
  name: 'forbidden',
  initialState,
  reducers: {
    show(state, action) {
      state.visible = true
      state.title = action.payload.title
      state.message = action.payload.message
    },
    hide(state, action) {
      state.visible = false
      state.title = ""
      state.message = ""
    },
  },
})

export const { show, hide } = msgForbiddenSlice.actions
export default msgForbiddenSlice.reducer