import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlModuleList         = "/module/list"

export const SelectModule = ({allowClear, showEmpty = false, disableEmpty = false, onBlur, onChange, readOnly = false, textEmpty = null, valueEmpty = "Please Choose", ...props}) => {
  const [modules, setModules] = useState([])
  useEffect(() => {
    loadModule()
  }, [])
  const loadModule = () => {
    apiRequest.get(apiUrlModuleList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setModules(rd.data)
      })
      .catch(error => console.log("loadModule in SelectModule: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={modules}
  />
/*
    {(showempty) ?<SelectOption key={"module0"} value={valueEmpty} disabled={disableempty}>{textempty}</SelectOption>:null}
    {modules.map(item =><SelectOption key={item.id} value={item.id}>{item.name}</SelectOption>)}
  </Select>
*/
}