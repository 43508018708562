import React, {useContext, useEffect, useRef, useState} from 'react';
import {Table, Space, Card} from "antd";
import Highlighter from "react-highlight-words";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RoomListMenu} from "./roomListMenu";
import apiRequest from "../../general/apiRequest";
import {isLigniteTenant, tN} from "../../system/checkTenants";
import {covImgFunc, mapImgFunc} from "../rascalApiDef";
import {TitleImage} from "../../images/imageMapHover";
import {sysRight} from "../../common/cmnRights";
import {useFetchList} from "../../core/Fetch/useFetchList";
import {columnSearchProps} from "../../util/ColumnSearchProps/columnSearchProps";
import {raveRoomMeta} from "../../rave/Room/RoomMeta";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {parentTypes} from "../../panel/panelDndContainer";
import {BC_RAVE_TEMPLATE} from "../../rave";
import {mrpNodeMetaId} from "../../general/Entities";
import {useRaveItemMenu} from "../../rave/Menu/RaveItemMenu";
import {ContextMenu} from "../../core/ContextMenu";
import {RowDropMenu} from "../../core/RowDropMenu";
import {DeepCopySelect} from "../../dialog/modalDeepCopySelect";

const apiUrlRoomRight                = "/room/rights"
// const apiUrlRoomList                 = "/room/byParent"
// const apiUrlRoomCreate               = "/room"
// const apiUrlRoomDuplicate            = "/room/duplicate"
// const apiUrlRoomDelete               = "/room"
// const apiUrlRoomFilter               = "/room/filter"

// const apiUrlCreateWorkpiece          = "/workpiece/create"
const elementMeta= raveRoomMeta

export const RoomList  = ({isTemplate = false, projectId, workId, parentType, ...props}) =>  {
  const [ , setState] = useContext(CsescaContext)
  const [params] = useSearchParams()
  const isTemplateInt = (params?.isTemplate)?true:isTemplate
  const navigation = useNavigate()
  const [filter, setFilter] = useState(null)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
//  const [deepCopyProps, setDeepCopyProps] = useState({open: false, sourceId: null});
  const [menuList, buildMenuList] = useRaveItemMenu()
  const fetchParams = (!isTemplateInt)
    ?{referenceId: workId, filter}
    :{referenceId: workId, filter, queryParams: `isTemplate=${isTemplateInt}`,}
  const searchInput = useRef(null);
//  const {data: rooms, revalidate: loadRooms, remove, create} = useFetchList(
  const fetchList = useFetchList(
    elementMeta,
    fetchParams,
  )
  useEffect(() => {
    if (isTemplateInt && parentType === parentTypes.tenant) setState(prevState => {return {
      ...prevState,
      breadcrumbType: BC_RAVE_TEMPLATE,
      entityName: "roomList",
      pageType: "rave",
      entityId: null,
      state: "done",
      breadCrumbList: [],
    }})
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentType]);
  const filterObjects =  {
    // refSearch: searchInput,
    refInput: searchInput,
    doSearch:(selectedKeys, confirm, dataIndex) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    },
    doReset:(clearFilters, confirm) => {
      clearFilters()
      confirm()
      setSearchText('')
    },
    doRender:(text, dataIndex) => searchedColumn === dataIndex
      ? <Highlighter
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
      : text,
  }
  const duplicateRoom = (deepCopy) => {
    fetchList.create({
      newData: null,
      urlExtension: `duplicate/${deepCopy.sourceId}`,
      urlParams: `isTemplate=${isTemplate}`,
      doNavigate: true,
    })
  }
  const handleDoubleClick = (value) => {
    if (value) {
      apiRequest.get(apiUrlRoomRight + "/" + value)
        .then(rd => {
          if (rd && rd.state && rd.rights && (rd.rights.indexOf(sysRight.READ) >= 0)) navigation("/ras/room/" + value)
        })
        .catch(error => console.log("loadRooms in handleDoubleClick: " + error))
    }
  }
  const handleFilter = (filter) => setFilter(filter)
//   const handleFilter = (filter) => {
//     setFilter(filter)
//     apiRequest.post(`${apiUrlRoomFilter}/${workId}`, JSON.stringify(filter))
//       .then((rd) => {
//        if (rd && rd.state && rd.data) setRooms(rd.data)
//       })
//       .catch(error => console.log("handleFilter in RoomList: " + error))
//   }
   const buildImageDef = (record, imgFunction) => ({covImg: {refererId: record.id, projectId: record.projectId,}, rightsParent: [], parentId: record.id, imgFunction})
  const newCols = () => {
    const cols = [
      { title: "Bild", dataIndex: "mediaId", width: 160,
//        render: (_, lineItem) =><TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.projectId,}} rightsParent={[]} parentId={lineItem.id} imgFunction={covImgFunc} />
        render: (_, record) => <TitleImage {...buildImageDef(record, covImgFunc)} />,
      },
      !isLigniteTenant(props.tenantId)
      ?{ title: "Flurplan", dataIndex: "mediaId", width: 160,
//        render: (_, lineItem) =><TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.projectId,}} rightsParent={[]} parentId={lineItem.id} imgFunction={mapImgFunc} />
        render: (_, record) => <TitleImage {...buildImageDef(record, mapImgFunc)} />,
      }:null,
//      { title: tN(props.tenantId, "Raum"), dataIndex: "name", width: 300, ...getColumnSearchProps('name'), sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()), sortDirections: ['descend', 'ascend']},
      {...columnSearchProps(tN(props.tenantId, 'Raum'),'name', filterObjects), },
      // { title: tN(props.tenantId, "Raum") + "nr.", dataIndex: "codeNumber", width: 150, ...getColumnSearchProps('codeNumber'), sorter: (a, b) => a.codeNumber.toLowerCase().localeCompare(b.codeNumber.toLowerCase()), sortDirections: ['descend', 'ascend']},
      {...columnSearchProps(tN(props.tenantId, 'Raum'), 'codeNumber', filterObjects), },
//      { title: "Bau Beginn", dataIndex: "constrYearBeginText", width: 25, ...getColumnSearchProps('constrYearBeginText'), sorter: (a, b) => a.constrYearBeginText.toLowerCase().localeCompare(b.constrYearBeginText.toLowerCase()), sortDirections: ['descend', 'ascend'] },
      {...columnSearchProps('Bau Beginn', 'constrYearBeginText', filterObjects), },
//      { title: "Bau Ende", dataIndex: "constrYearEndText", width: 25, ...getColumnSearchProps('constrYearEndText'), sorter: (a, b) => a.constrYearEndText.toLowerCase().localeCompare(b.constrYearEndText.toLowerCase()), sortDirections: ['descend', 'ascend'] },
      {...columnSearchProps('Bau Ende', 'constrYearEndText', filterObjects), },
      { title: ""},
      { title: "Bearbeiten", dataIndex: "id", width: 150, render: (_, record) =>
          <RowDropMenu contextMenuInfo={buildMenuListDefinition(record)} />,
      }
    ]
    return cols.filter((items) => items !== null)
  }
  // const buildMenuListDefinition = (record) => ({doCopy: setDeepCopyProps, id: record.id, name: record.name, fetchList, nodeMetaId: mrpNodeMetaId["workpiece"], tenantId: record.tenantId})
  const buildMenuListDefinition = (record) => ({doCopy: duplicateRoom, id: record.id, name: record.name, fetchList, nodeMetaId: mrpNodeMetaId["workpiece"], tenantId: record.tenantId})
  const handleContextMenu = (event, record) => {
    event.preventDefault()
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    buildMenuList(buildMenuListDefinition(record))
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  const onRow = (record) => ({
    onDoubleClick: () => {handleDoubleClick(record.id)},
    onContextMenu: (event) => handleContextMenu(event, record)
  })
  return <Card extra={<Space>
    <RoomListMenu
      filtered={!!filter}
      isTemplate={isTemplateInt}
      projectId={projectId}
      parentId={workId}
      onFilter={handleFilter}
      create={fetchList.create}
    />
    </Space>}
  >
    <ContextMenu items={menuList} {...contextMenuState}/>
    <Table
      className={"ces-rave-table"}
      dataSource={fetchList.data}
      pagination={{position: ['topRight', 'bottomRight'],}}
      onRow={onRow}
      columns={newCols()}
      rowKey={record => record.id}
    />
{/*
    <DeepCopySelect
      deepCopyProps={deepCopyProps}
      onResult={(deepCopy) => {duplicateRoom(deepCopy)}}
      onCancel={() => setDeepCopyProps({open: false, sourceId: null})}
    />
*/}
  </Card>
}
