/**
 * modal window to choose rights
 */

import React from 'react';
import {Button, Form, Input, message, Select, Table, Tag} from "antd";
import {UserAddOutlined} from "@ant-design/icons";
import {ModalDraggable} from "./ModalDraggable";
import apiRequest from "../general/apiRequest";
//import {PersonChoose} from "./modalPersonChoose";

// const apiRightList          = "/api/right/list"
// const apiEntityList         = "/api/entity/list"

const apiUrlRightList          = "/right/list"
// const apiUrlEntityList         = "/entity/list"

export class RightChoose extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      roleName: "",
      rights: [],
      entities: [],
      rightModalVisible: false,
      selectedRowKeys: [],
    }
  }
  componentDidMount() { this.loadRights();this.loadEntities() }
  loadRights = async () => {
    const resData = await apiRequest.get(apiUrlRightList)
    this.setState({rights: resData.data})
  }
  loadEntities = async () => {
    const resData = await apiRequest.get(apiUrlRightList)
    this.setState({entities: resData.data})
  }
  doSelectionChange = (value) => { this.setState({selectedRowKeys: value}) }
  onOk = () => {
    this.setState({rightModalVisible: false})
    this.props.onResult({
      result: "ok",
      values:{name: this.state.roleName, rights: this.state.rights}
    })
    this.setState({selectedRowKeys:[], roleName: ""})
  }
  onCancel = () => {
    this.setState({rightModalVisible: false})
    this.setState({selectedRowKeys:[], roleName: ""})
  }
  renderTag = (props) => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = event => { event.preventDefault(); event.stopPropagation() }
    return (
      <Tag
        color={"gold"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    )
  }
  render() {
    return(
      <>
        <Button type="primary" ghost={true} shape={"round"} onClick={() => {this.setState({rightModalVisible: true})}} icon={<UserAddOutlined  />} />
        <ModalDraggable
          titleName="Rechteauswahl"
          width={"900px"}
          style={{ top: 20, left: 50}}
          open={this.state.rightModalVisible}
          onCancel={this.onCancel}
          cancelText="Abbrechen"
          onOk={this.onOk}
          okText="Speichern"
          okButtonProps={{disabled: this.state.roleName === ""}}
//          footer={null}
        >
          <h2>Rechte auswählen</h2>
          <Form.Item label="Name" name={"name"} >
            <Input
              name={"name"} style={{width: "50%"}}
              onChange={(e) => this.setState({roleName: e.target.value})}
            />
          </Form.Item>
          <Table
            dataSource={this.state.entities}
            pagination={false}
            rowSelection={{type: "checkbox",
              onChange: (selectedRowKeys) => {this.doSelectionChange(selectedRowKeys)},
              selectedRowKeys: this.state.selectedRowKeys,
            }}
            rowKey={record => record.id}
            columns={[
              { title: "Entity", dataIndex: "name",
                width: 100,
              },
              { title: "Rechte", dataIndex: "right",
                width: 50,
                render: (data, row, ix) => {
                  return (
                  <Select
                    optionLabelProp={"code"}
                    popupMatchSelectWidth={true}
                    mode="multiple"
//                    showArrow
                    tagRender={this.renderTag}
                    onSelect={this.addRight}
                    onDeselect={this.delRight}
                    style={{ width: '100%' }}
//                    value={["099ef0a7-249f-4d0a-a53a-1e252c8270c4","7312e725-5e73-4098-a474-17bad318ed3e"]}
//                    options={this.state.rights}
                  >
                    {this.state.rights.map(right => (
                      <Select.Option key={right.id} value={right.id} code={right.code} >
                        {right.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              }
            ]}
          />
        </ModalDraggable>
      </>
    )
  }
}
RightChoose.defaultProps = {
  onResult: (value) => {
    message.error(<p>Funktion nicht angelegt</p>,5)
  },
}