/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 10.01.2024
 *
 * Project: csescaclient
 *
 * Catalog in package  (project: csescaclient ) © 10.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */

export const baseCatalogMeta = {
  keyId: "id",
  domain: "catalog",
  domainList: "List",
  module: {
    name: "base",
    show: "/",
    routeBase: null,
    routeDomain: null,
  },
  routes: {
    create: "",
    delete: "",
    save: "",
  },
  defaultData: {
    id: null,
    active: true,
    position: -1,
    tenantId: null,
    catalogId: null,
    name: "",
  }
}
