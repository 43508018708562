import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Input, Space} from "antd"
import {useNavigate, useParams} from "react-router-dom"
import {apiLogin} from "../general/apiRequest"
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const apiUrlResetPassword     = "/resetPassword"
const initialResetPassword = {password: "", passwordRepeat: "", resetHash: "", }
const initialShowPassword = {password: false, passwordRepeat: false}

export const ModalResetPassword = (props) => {
  const {resetHash} = useParams()
  const navigate = useNavigate()
//  const [activeButton, setActiveButton] = useState(false)
  const [send, setSend] = useState(false)
  const [resetPassword, setResetPassword] = useState({...initialResetPassword})
  const [showPassword, setShowPasswords] = useState( {...initialShowPassword})
  useEffect(() => {setResetPassword(prevState => {
    return {...prevState, resetHash: resetHash}
  })}, [resetHash])
  const handleResetPasswordRequest = () => {
    if (activeButton())
      apiLogin.post(apiUrlResetPassword, JSON.stringify(resetPassword))
        .then(rd => {
//TODO Information über Inkorrektes passwort
          if (rd && rd.state) setSend(true)
        })
        .catch(error =>  console.log("Error in Reset Password: " + error))
  }
  const handleShowChange = (elemName) => {
    setShowPasswords(prevState => {
      const newVal = prevState[elemName]
      return {...prevState, [elemName]: !newVal}})
  }
  const doNothing = (e) => {
    e.preventDefault();return false
  }
  const handleChange = (evt) => {
    setResetPassword(prevState => {return {...prevState, [evt.target.name]: evt.target.value}})
//    setActiveButton(resetPassword.password.length >= 8 && resetPassword.password === resetPassword.passwordRepeat)
  }
  const activeButton = () => (resetPassword.password.length >= 8 && (resetPassword.password === resetPassword.passwordRepeat))
  return (
    <Card
      title={
        <div>
          <div className={"login-header"}>
            <div className={"logo128"}/>
            <div className={"logo-tenant"}>
              <h2 className={"logo-tenant"} > </h2>
            </div>
          </div>
        </div>
      }
    >
      {(!send)
      ?<>
        <div className="login-box">
          <div className="login-box-msg" title="Login">Ihre Email Adresse</div>
          <Form
            name="reset-password-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{marginTop: 10}}
          >
            <Form.Item
              label="neues Password"
              name="password"
              style={{margin: ".3em"}}
            >
              <Input
                name="password"
                value={resetPassword.password}
//                placeholder="neues Passwort"
                onChange={handleChange}
                // onCopy={doNothing}
                // onPaste={doNothing}
                // onCut={doNothing}
                style={(!showPassword.password)?{
                  textSecurity: "disc",
                  WebkitTextSecurity: "disc",
                  MozTextSecurity:"disc",
                  margin: "5px 5px",
                }:{margin: "5px 5px",}}
                suffix={
                  (!showPassword.password)
                    ?<EyeOutlined onClick={() => {handleShowChange("password")}} />
                    :<EyeInvisibleOutlined onClick={() => {handleShowChange("password")}} />
                }
              />
            </Form.Item>
            <Form.Item
              label="wiederholen"
              name="passwordRepeat"
              style={{margin: ".3em"}}
              validateStatus={(resetPassword.passwordRepeat.length >= resetPassword.password.length && resetPassword.password !== resetPassword.passwordRepeat)?"error":""}
              help={(resetPassword.passwordRepeat.length >= resetPassword.password.length && resetPassword.password !== resetPassword.passwordRepeat)
                ?"unterschieliche Passwörter"
                :""
              }
            >
              <Input
                name="passwordRepeat"
                value={resetPassword.passwordRepeat}
                //                placeholder="wiederholen"
                onChange={handleChange}
                // onCopy={doNothing}
                // onPaste={doNothing}
                // onCut={doNothing}
                style={(!showPassword.passwordRepeat)?{
                  textSecurity: "disc",
                  WebkitTextSecurity: "disc",
                  MozTextSecurity:"disc",
                  margin: "5px 5px",
                }:{margin: "5px 5px",}}
                suffix={
                  (!showPassword.passwordRepeat)
                    ?<EyeOutlined onClick={() => {handleShowChange("passwordRepeat")}} />
                    :<EyeInvisibleOutlined onClick={() => {handleShowChange("passwordRepeat")}} />
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div style={{textAlign:"right"}}>
          <Space>
            <Button type="primary" shape="round" disabled={!activeButton()} onClick={handleResetPasswordRequest}>Absenden</Button>
          </Space>
        </div>
      </>
      :<>
          <div className="login-box">
            <div className="login-box-msg" title="Login">Änderungsbestätigung</div>
            <div style={{margin: "1em auto 3em", textAlign: "center"}}>Ihre Passwort wurde geändert</div>
          </div>
          <div style={{textAlign:"right"}}>
            <Space>
              <Button type="primary" shape="round" onClick={() => {navigate("/login")}}>zum Login</Button>
            </Space>
          </div>
      </>
      }
    </Card>
  )
}