/**
 * panel for view persons and organisations
 */

import React, {useEffect, useState} from 'react';
import {Row, Col, Card, } from 'antd';
import {PersonChoose} from "../dialog/modalPersonChoose";
import apiRequest from "../general/apiRequest";
import {
  DragOutlined,
  SearchOutlined
} from "@ant-design/icons";

const apiUrlPersonShow                  = "/person/showBySubjectRef"
const apiUrlOrganisationShow            = "/org/showBySubjectRef"

const apiUrlLocationAddressList         = "/locationAddress/byParent"
const apiUrlCommunicationList           = "/communication/byIdParent"
const apiUrlCommunicationTypeList       = "/commtype/list"

const apiUrlChangeSubject                = "/panel/changeSubject"

const defaultPerson = {
  id                : "",
  active            : true,
  position          : -1,
  tenantId         : "",
  title             : "",
  salutation        : "",
  firstName        : "",
  middleName       : "",
  lastName         : "",
  academicTitle    : "",
  officialTitle    : "",
  nobilityTitle    : "",
  birthDate        : "",
  organisationId   : "",
}
const defaultOrganisation = {
  id                : "",
  active            : true,
  position          : -1,
  tenantId         : "",
  name1             : "",
  name2             : ""
}

export const PersonPanel = ({editable, extra, viewEntities = "large", panelType, fromTemplate, itemId, ...props}) => {
  const panelTypes = new Set(props.panelTypes)
  const [communicationTypes, setCommunicationTypes] = useState([])
  const [communications, setCommunications] = useState([])
  const [locationAddresses, setLocationAddresses] = useState([])
  const [person, setPerson] = useState({...defaultPerson})
  const [organisation, setOrganisation] = useState({...defaultOrganisation})
  useEffect(() => {
    loadPanelData(itemId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])
  const loadPanelData = (dataId) => {
    apiRequest.get(apiUrlCommunicationTypeList)
      .then(rd => {
        if (rd && rd.state && rd.data) setCommunicationTypes(rd.data)
      })
      .catch(error => console.log("loadPanelData in panelPerson (communicationTypes): " + error))
    switch (panelType) {
      case "subjectPerson":
        apiRequest.get(apiUrlPersonShow + "/" + dataId)
          .then(rd => {
            if (rd && rd.state && rd.data) {
              setPerson(rd.data)
              loadLocationAddress(rd.data.id)
            }
          })
          .catch(error => console.log("loadPanelData in panelPerson (person): " + error))
        break
      case "subjectOrganisation":
        apiRequest.get(apiUrlOrganisationShow + "/" + dataId)
          .then(rd => {
            if (rd && rd.state && rd.data) {
              setOrganisation(rd.data)
              loadLocationAddress(rd.data.id)
            }
          })
          .catch(error => console.log("loadPanelData in panelPerson (organisation): " + error))
        break
      default:
    }
  }
  const loadLocationAddress = (personId) => {
    if (personId) {
      apiRequest.get(apiUrlLocationAddressList + "/" + personId)
        .then(rd => {
          if (rd && rd.state && rd.data) setLocationAddresses(rd.data)
        })
        .catch(error => console.log("loadLocationAddress in panelPerson (locationAddresses): " + error))
      apiRequest.get(apiUrlCommunicationList + "/" + personId)
        .then(rd => {
          if (rd && rd.state && rd.data) setCommunications(rd.data)
        })
        .catch(error => console.log("loadLocationAddress in panelPerson (communications): " + error))
    }
  }
  const saveChosenAddress = (retVal) => {
    if (retVal.result === "ok") {
      apiRequest.put(`${apiUrlChangeSubject}?id=${itemId}&subjectId=${retVal.values.subjectId}`)
        .then(rd => {
          if (rd && rd.state) loadPanelData(itemId)
        })
        .catch(error => console.log("saveChosenAddress in panelPerson: " + error))
    }
  }
  const renderSubjectPart = () => {
    switch (panelType) {
      case "subjectPerson": return(
        <div key={"rsPPers" + person.id}>
        <span>{person.title}{(person.officialTitle) ? <>&nbsp;{person.officialTitle}</> : null}<br /></span>
{/*
          {(this.state.person.academicTitle) ? <span>{this.state.person.academicTitle}<br/></span> : null}
          {(this.state.person.officialTitle) ? <span>{this.state.person.officialTitle}<br/></span> : null}
*/}
        <span>{(person.academicTitle) ? <>{person.academicTitle}&nbsp;</> : null}{person.firstName}&nbsp;{person.middleName}&nbsp;{person.lastName}</span>
        </div>)
      case "subjectOrganisation":return(
        <div key={"rsPOrg" + organisation.id}>
        <span>{organisation.name1}<br /></span>
        {(organisation.name2 ) ? <span>{organisation.name2}<br /></span>:null}
        </div>)
      default:
    }
  }
  function comType (item) {return (item && item.length > 0) ? item[0].name : "" }
  return (
    <Card
      title={<Row gutter={[16,16]}>
        {(!fromTemplate && editable)
          ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
          :null
        }
          {/*<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>*/}
          {/*<Col span={4}><SelectOperationalRole style={{ width: "100%" }} /></Col>*/}
        </Row>
      }
      extra={<>
          <PersonChoose
            buttonText={null}
            buttonIcon={<SearchOutlined />}
            buttonType="text"
            onResult={(values) => {saveChosenAddress(values)}}
            panelTypes={panelTypes}
          />
        {extra}
        </>
      }

    >
          <Row gutter={[12,12]}>
            <Col span={4}>
              {renderSubjectPart()}
            </Col>
            {(viewEntities === "large" || viewEntities === "address") ?
              <Col span={4}>
                {locationAddresses.map((address, ix) => (
                  <div key={address.id}>
                    <div>{address.street}&nbsp;{address.street2}</div>
                    <div>{address.zip}&nbsp;{address.city}&nbsp;{address.citydistrict}</div>
                    {(locationAddresses.length - 1 !== ix) ? <hr/> : null}
                  </div>
                ))}
              </Col>
            : null}
            {(viewEntities === "large" || viewEntities === "communication") ?
            <Col span={8}>
              {communications.map((communication, ix) => (
                <div key={communication.id}>{comType(communicationTypes.filter(item => item.id === communication.commTypeId))}:&nbsp;{communication.commAddr}</div>
              ))}
            </Col>
              : null}
          </Row>
    </Card>
  )
}