import React from "react";
import {Button, Dropdown} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";

/*{key: "Empty", label: "Empty", icon: <LoadingOutlined />},*/

export const ContextMenu = ({items= [], open = false, pos = {x: 0, y: 0}, ...props}) => {
  return open &&  <Dropdown
    {...props}
    menu={{items: items}}
//    getPopupContainer={() => contextRef.current}
    open
    overlayStyle={{ left: `${pos.x}px`, top: `${pos.y}px` }} >
   <></>
  </Dropdown>
}