import {DEFAULT_BASE_URL, DEFAULT_OPTIONS} from "./FetchDefault";
import apiRequest from "../../general/apiRequest";
import useSWR from "swr";
import {useNavigate} from "react-router-dom";


export const useFetchSelect = (meta, {referenceId = null, referenceName = "", baseUrl = DEFAULT_BASE_URL, options = DEFAULT_OPTIONS, dependencies = []}) => {
  const fetcher = (url) => apiRequest.get(url).then(r => r.data)
  const domainKey = `${meta.module.name}/${meta.domain}`
  const navigation = useNavigate()
  const swrKey = `/${domainKey}${meta.domainList }${referenceName?`/${referenceName}`:''}${referenceId?`/${referenceId}`:''}`
  const {data, mutate, revalidate, error, isLoading, isValidating} = useSWR(swrKey, fetcher, {keepPreviousData: true})
  const remove = (id) => {
    console.log(`Delete ${id}`)
    mutate(
      async () => {await apiRequest.delete(`/${domainKey}/${id}`)},
      {revalidate: true, /*optimisticData: (currentData, displayedData) => currentData*/}
    )
    // mutate(anync () => {const result = await apiRequest.delete(`${intKey}/${id}`),true).then(r => r.data)}
  }
  const create = (newData = null, urlExtension = null, urlParams = null) => {
    const url = `/${domainKey}${urlExtension?`/${urlExtension.trimLeft('/')}`:''}${urlParams?`?${urlParams}`:''}`
    console.log(`Create: ${JSON.stringify(newData)}\n/${url}`)
    apiRequest.post(url, newData?JSON.stringify(newData):undefined)
      .then(result => {
        // navigation(`${domainKey}/${result.data}`)
        navigation(`${domainKey.replace('rave','ras')}/${result.data}`)
      })
//    mutate(async () => {await apiRequest.post(`/${domainKey}`, JSON.stringify(newData))}, {revalidate: true,})
    //navigation(`/${domainKey}/`)
  }
  return {data, revalidate, remove, create}
}
