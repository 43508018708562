import {BC_RAVE_DEFAULT, BC_RAVE_SHARED, BC_RAVE_TEMPLATE} from "./const";
import useCredentials from "../../system/UseCredentials";

export const getRaveBreadcrumbRoot = () => {
  return BC_RAVE_DEFAULT
}
// export const getRaveBreadcrumbType = (itemData, tenantId) => {
export function getRaveBreadcrumbType2 (itemData, tenantId) {
  if (!tenantId) return null
  const result = (itemData.tenantId === tenantId)
    ? (itemData.isTemplate !== null)
      ? (!itemData.isTemplate)
        ? BC_RAVE_DEFAULT
        : BC_RAVE_TEMPLATE
      : null
    : (itemData.tenantId !== null)
      ? BC_RAVE_SHARED
      : null
  return result
}

export function getRaveBreadcrumbType (itemData, tenantId) {
  if (!tenantId || !itemData.tenantId) {
    return null
  }
  if (itemData.tenantId === tenantId) {
    if (itemData.isTemplate) {
      return BC_RAVE_TEMPLATE
    } else {
      return BC_RAVE_DEFAULT
    }
  } else {
    return BC_RAVE_SHARED
  }
}
