import React, {useEffect, useState} from 'react'
import {Button, Card, Checkbox, Col, Input, InputNumber, List, Popconfirm, Row, Tooltip} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {SelectPropertyMetaType} from "./select/SelectPropertyMetaType";
import apiRequest from "../general/apiRequest";
import {SelectCatalogForProperty} from "./select/SelectCatalogForProperty";

// const apiUrlPropertyMetaShow          = "/propertyMeta/show"
// const apiUrlPropertyMetaSave          = "/propertyMeta"
// const apiUrlPropertyMetaGetBySourceId = "/propertyMeta/show"

const newPropertyMeta = {
  id: null,
  active: true,
  position: -1,
  parentId: null,
  name: "...loding",
  physicalName: "",
  propertyTypeId: null,
  catalogId: null,
  length: 0,
  precision: 0,
  mandatory: true,
  default: "",
  description: "",
  hidden: false,
  readOnly: false,
  propertyGroupId: null,
}

export const PropertyMetaEditList = (props) => {
  const [propertyMetaIdList, setPropertyMetaIdList] = useState([])
  useEffect(() => {
    loadPropertyMetaListId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeMetaId])
  const loadPropertyMetaListId = async () => {
    if (props.nodeMetaId) {
      const rd = await apiRequest.get("/propertyMeta/idList/" + props.nodeMetaId)
      if (rd && rd.state && rd.data) setPropertyMetaIdList(rd.data)
    }
  }
  const addPropertyMeta = async () => {
    const rd = await apiRequest.post("/propertyMeta", JSON.stringify({...newPropertyMeta, parentId: props.nodeMetaId, name: "New Property"}))
    if (rd && rd.state && rd.data) await loadPropertyMetaListId()
  }
  const deletePropertyMeta = async id => {
    await apiRequest.delete("/propertyMeta/" + id)
    await loadPropertyMetaListId()
  }
  return(<Card title={"Property Meta"}>
      <List
      >
        <List.Item
          key={"PmliHeader"}
          style={{padding: "2px 0"}}
        >
          <Row gutter={2} style={{paddingLeft: "4px", width: "100%"}}>
            <Col span={4}><Tooltip title={<>Name<br/>(Description)</>}>Name</Tooltip></Col>
            <Col span={4}><Tooltip title="Physikalischer Name">Phys.-Name</Tooltip></Col>
            <Col span={4}><Tooltip title="Property Meta Type">Typ</Tooltip></Col>
{/*
            <Col span={4}>Description</Col>
*/}
            <Col span={1}><Tooltip title="Länge">Länge</Tooltip></Col>
            <Col span={1}><Tooltip title="Genauigkeit">Gen.</Tooltip></Col>
            <Col span={1}><Tooltip title="Pflichtfeld">erf.</Tooltip></Col>
            <Col span={1}><Tooltip title="nur lesen">schr.</Tooltip></Col>
            <Col span={1}><Tooltip title="versteckt">vers.</Tooltip></Col>
            <Col span={4}><Tooltip title="Standardwert">Standardwert</Tooltip></Col>
            <Col span={0}>&nbsp;</Col>
            <Col offset={2} span={1}>
              <Button
                type={"link"}
                shape={"round"}
                onClick={addPropertyMeta}
                icon={<PlusOutlined />} />
            </Col>
          </Row>
        </List.Item>
        {propertyMetaIdList.map((item) =>
          <PropertyMetaEdit
            propertyMetaId={item}
            deletePropertyMeta={deletePropertyMeta}
          />
        )}
      </List>
    </Card>
  )
}

const PropertyMetaEdit = (props) => {
  const [propertyMeta, setPropertyMeta] = useState({})
  const [changed, setChanged] = useState(false)
  const [textFocus, setTextFocus] = useState({ minRows: 1, maxRows: 1 })
  useEffect(() => {
    const loadPropertyMeta = async () => {
      if (props.propertyMetaId) {
        const rd = await apiRequest.get("/propertyMeta/show/" + props.propertyMetaId)
        if (rd && rd.state && rd.data) setPropertyMeta(rd.data)
      }
    }
    loadPropertyMeta()
  }, [props.propertyMetaId])
  const handleChange = (evt) => {
    const fieldName = evt.target.name
    const fieldValue = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    setPropertyMeta({...propertyMeta, [fieldName]: fieldValue})
    setChanged(true)
  }
  const handleChangeNumber = (name, value) => {
    setPropertyMeta({...propertyMeta, [name]: value})
    setChanged(true)
  }
  const handleChangeSpecialChar = (evt) => {
//    const value = evt.target.value.match(/(A-Z)|([a-zA-Z0-9_]*)/)
    const value = evt.target.value.match(/[a-zA-Z0-9_]*/)
    if (value) {
      console.log("Value: "  + value)
      setPropertyMeta({...propertyMeta, [evt.target.name]: value.toString()})
      setChanged(true)
    }
  }
  const handleSelectChange = (name, value) => {
    setPropertyMeta({...propertyMeta, [name]: value})
    setChanged(true)
  }
  const handleBlur = async () => {
    if (changed) {
      const res = await apiRequest.put("/propertyMeta", JSON.stringify(propertyMeta))
      if (res && res.state && res.data)
        setPropertyMeta(res.data)
    }
    setTextFocus({ minRows: 1, maxRows: 1 })
  }
  return(<List.Item key={"PMLI_" + props.propertyMetaId}style={{padding: "2px 0", display:"flow-root"}}>
      <div>
      <Row gutter={2} style={{width: "100%"}} align={"middle"}>
        <Col span={4}>
          <Input
            name="name"
            value={propertyMeta.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={4}>
          <Input
            name="physicalName"
            value={propertyMeta.physicalName}
//            autoSize={{ minRows: 1, maxRows: 6 }}
//            maxLength={2000}
//            onChange={handleChange}
            onChange={handleChangeSpecialChar}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={4}>
          <SelectPropertyMetaType
            name="propertyTypeId"
            value={propertyMeta.propertyTypeId}
            showEmpty={true}
            disableEmpty={true}
            textEmpty="- leer -"
            onChange={(value) => {handleSelectChange("propertyTypeId", value)}}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={1}>
          <InputNumber
            name="length"
            value={propertyMeta.length}
            onChange={(value => {handleChangeNumber("length", value)})}
            onBlur={handleBlur}
            controls={false}
            max={2000}
            maxLength={4}
            precision={0}
            style={{width: "100%", textAlign: "right"}}
          />
        </Col>
        <Col span={1}>
          <InputNumber
            name="precision"
            value={propertyMeta.precision}
            onChange={(value => {handleChangeNumber("precision", value)})}
            onBlur={handleBlur}
            controls={false}
            max={8}
            maxLength={2}
            precision={0}
            style={{width: "100%", textAlign: "right"}}
          />
        </Col>
        <Col span={1} style={{textAlign: "center"}}>
          <Checkbox
            name="mandatory"
            checked={propertyMeta.mandatory}
            onChange={handleChange}
            onBlur={handleBlur}
//            style={{margin: "0 .3em"}}
          />
        </Col>
        <Col span={1} style={{textAlign: "center"}}>
          <Checkbox
            name="readOnly"
            checked={propertyMeta.readOnly}
            onChange={handleChange}
            onBlur={handleBlur}
//            style={{margin: "0 .3em"}}
          />
        </Col>
        <Col span={1} style={{textAlign: "center"}}>
          <Checkbox
            name="hidden"
            checked={propertyMeta.hidden}
            onChange={handleChange}
            onBlur={handleBlur}
//            style={{margin: "0 .3em"}}
          />
        </Col>
        <Col span={4}>
          <Input
            name="default"
            value={propertyMeta.default}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </div>
    <div>
      <Row gutter={2} style={{width: "100%"}}>
        <Col span={8}>
          <Input.TextArea
            name="description"
            value={propertyMeta.description}
            autoSize={textFocus}
            maxLength={2000}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={() => {setTextFocus({ minRows: 2, maxRows: 6 })}}
          />
        </Col>
        <Col span={4}>
          <SelectCatalogForProperty
            name="catalogId"
            value={propertyMeta.catalogId}
            onChange={(value) => {handleSelectChange("catalogId", value)}}
            onBlur={handleBlur}
          />
        </Col>
{/*
        <Col span={8}>
          <Input
            name="description"
            value={propertyMeta.description}
            maxLength={2000}
            onChange={handleChange}
            onBlur={handleBlur}
            addonAfter={<Button onClick={() => {window.alert("Button was Clicked")}} icon={<SettingOutlined />} />}
          />
        </Col>
*/}
        <Col span={11}>&nbsp;</Col>
        <Col
          offset={0}
          span={1} style={{textAlign: "right"}}>
          <Popconfirm
            placement="topRight"
            title={"Möchten Sie den Datensatz löschen"}
            onConfirm={() => {props.deletePropertyMeta(propertyMeta.id)}}
            okText="Ja"
            cancelText="Nein"
            cancelButtonProps={{danger: false, type: "primary"}}
            okButtonProps={{danger: true, ghost: true}}
          >
            <Button
              type={"link"}
              danger={true}
              shape={"round"}
              style={{padding: "0 10px"}}
              icon={<DeleteOutlined style={{fontSize: "2em"}}
              />}
            />
          </Popconfirm>

        </Col>
      </Row>
    </div>
    </List.Item>
  )
}