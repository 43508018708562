import {Tabs} from "antd";
import {isTenantIdLigniteTenant, isUserIdLigniteAdmin, tN} from "../../system/checkTenants";
import {PanelCloud} from "../../panelCloud";
import PanelDndContainer, {parentTypes} from "../../panel/panelDndContainer";
import {Capture} from "../rascalCapture";
import {Planning} from "../rascalPlaning";
import {Restoration} from "../rascalRestoration";
import {ParticipantList} from "../Participant";
import React, {useEffect, useState} from "react";
import useCredentials from "../../system/UseCredentials";

export const WorkpieceTabs = ({editable, ...props}) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const userId = credentials?.user.id
  const [activeTab, setActiveTab] = useState("WP0")
  useEffect(() => {
    setActiveTab("WP0")
  }, [props.workpiece.id, userId])
  const panelTypes = ["text", "media", "measure", "location", "subjectPerson", "subjectOrganisation", "nodeData", "raveMultiAssignment", "raveRelocation"]
  const tabItems = () => {
    const items = []
    items.push(
      {
        key: "WP0", label: "Übersicht", destroyInactiveTabPane: true,
        children: <>
          {!isTenantIdLigniteTenant(tenantId)
          ?<PanelCloud
            key="PanelProjectCloudURL"
            cloudURL={props.workpiece.cloudUrl}
          />
          : null}
          <PanelDndContainer
            key="PanelProjectBaseShow"
            fromTemplate={!!props.workpiece.templateId}
            rightsParent={props.rights}
            editable={false}
            projectId={props.workpiece.projectId}
            workId={props.workpiece.id}
            workParentId={props.workpiece.parentId}
            parentType={parentTypes.workpiece}
            panelTypes={panelTypes}
          />
        </>,
      },
      {
        key: "WP1", label: tN(tenantId, "Objektdaten"), destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelWorkpieceBase"
          fromTemplate={!!props.workpiece.templateId}
          editable={editable}
          rightsParent={props.rights}
          projectId={props.workpiece.projectId}
          workId={props.workpiece.id}
          workParentId={props.workpiece.parentId}
          parentType={parentTypes.workpiece}
          panelTypes={panelTypes}
        />,
      },
    )
    if (!isTenantIdLigniteTenant(tenantId)) items.push(
      {
        key: "Capture2", label: "Veränderungen/Schäden", destroyInactiveTabPane: true,
        children: <Capture
          key="CaptureEdit"
          rightsParent={props.rights}
          editable={editable}
          projectId={props.workpiece.projectId}
          parentId={props.workpiece.id}
          parentType={parentTypes.workpiece}
        />,
      },
      {
        key: "Planing2", label: "Planung", destroyInactiveTabPane: true,
        children: <Planning
          key="PlanningEdit"
          rightsParent={props.rights}
          editable={editable}
          projectId={props.workpiece.projectId}
          parentId={props.workpiece.id}
          cantegoryId={props.workpiece.categoryId}
          parentType={parentTypes.workpiece}
        />,
      },
      {
        key: "WP3", label: "Restaurierung", destroyInactiveTabPane: true,
        children: <Restoration
          key="RestorationEdit"
          rightsParent={props.rights}
          editable={editable}
          projectId={props.workpiece.projectId}
          parentId={props.workpiece.id}
          parentType={parentTypes.workpiece}
        />,
      },
      {
        key: "Media3", label: "Medien", destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelWorkpieceMedia"
          rightsParent={[]}
          projectId={props.workpiece.projectId}
          workId={props.workpiece.id}
          workParentId={props.workpiece.parentId}
          parentType={parentTypes.workpiece}
          panelTypes={["media"]}
        />,
      },
    )
    if (!isTenantIdLigniteTenant(tenantId) || isUserIdLigniteAdmin(userId)) items.push(
      {
        key: "WPPCT", label: "Mitwirkende", destroyInactiveTabPane: true,
        children: <ParticipantList
          key="WorkpieceParticipant"
          rightsParent={props.rights}
          projectId={props.workpiece.projectId}
          workId={props.workpiece.id}
          parentType={parentTypes.workpiece}
        />,
      },
    )
    return items
  }
  const handleChangeTab = (activeKey) => setActiveTab(activeKey)
  return <Tabs
    destroyInactiveTabPane
    activeKey={activeTab}
    onChange={handleChangeTab}
    items={tabItems()}
  />
}