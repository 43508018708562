import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

const propertyMetaAdapter = createEntityAdapter({
  selectId: model => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const propertyMetaSlice = createSlice({
  name: "propertyMeta",
  initialState: propertyMetaAdapter.getInitialState({
    loading: "idle"
  }),
  // initialState: propertyMetaAdapter.getInitialState({
  //   loading: "idle"
  // }),
  reducers: {
    // addOnePropertyMeta: propertyMetaAdapter.addOne,
    // setOnePropertyMeta: propertyMetaAdapter.setOne,
    upsertOnePropertyMeta: propertyMetaAdapter.upsertOne,
/*
    upsertOnePropertyMeta(state, action) {
      propertyMetaAdapter.upsertOne(state.propertyMeta, action)
      const {id, parentId} = action.payload
      const existNM = state.nodeMeta.entities[parentId]
      if (existNM && !existNM.propertyMetas[id]) {
        existNM.propertyMetas.push(id)
      }
    },
*/
    // updOnePropertyMeta: propertyMetaAdapter.updateOne,
    // delOnePropertyMeta: propertyMetaAdapter.removeOne,
    // setAllPropertyMeta: propertyMetaAdapter.setAll,
  }
})

// export const { addOnePropertyMeta, setOnePropertyMeta, upsertOnePropertyMeta, updateParent, updOnePropertyMeta, delOnePropertyMeta, setAllPropertyMeta } = propertyMetaSlice.actions
//export const { upsertOnePropertyMeta } = propertyMetaSlice.actions

export const {selectAll: selectAllPropertyMeta, selectById: selectPropertyMetaById} = propertyMetaAdapter.getSelectors(state => state.data.propertyMeta)
export const selectPropertyGroups = (state, propertyMetaIds) => {
  if (state.propertyMeta.entities) {
    propertyMetaIds?.map(item => {
      if (state.propertyMeta.entities[item])
        return state.propertyMeta.entities[item]
      return null
    })
//    return selectAllPropertyMeta(state.nodeMeta.entities[itemId].propertyGroups)
  }
  else return []
//  else null
}


export default propertyMetaSlice.reducer

//export const {initialStatePropertyMeta} = propertyMetaSlice.getInitialState
export const {propertyMeta} = propertyMetaSlice.getInitialState()