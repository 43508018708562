import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {isTenantIdLigniteTenant, isUserIdLigniteAdmin, tN} from "../../system/checkTenants";
import {PanelCloud} from "../../panelCloud";
import PanelDndContainer, {parentTypes} from "../../panel/panelDndContainer";
import {BreakdownList} from "./rascalBreakdownList";
import {RoomList} from "../room/rascalRoomList";
import {WorkpieceList} from "../workpiece/rascalWorkpieceList";
import {ParticipantList} from "../Participant";
import useCredentials from "../../system/UseCredentials";

export const BreakdownTabs = ({editable, ...props}) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const userId = credentials?.user.id
  const [activeTab, setActiveTab] = useState("BD0")
  useEffect(() => {
    setActiveTab("BD0")
  }, [props.breakdown.id])
  const panelTypes = ["text", "media", "measure", "location", "subjectPerson", "subjectOrganisation", "nodeData", "raveMultiAssignment"]
  const handleChangeTab = (activeKey) => setActiveTab(activeKey)
  const tabItems = () => {
    const items = []
    items.push(
      {
        key: "BD0", label: "Übersicht", destroyInactiveTabPane: true,
        children: <>
          {!isTenantIdLigniteTenant(tenantId)
            ?<PanelCloud
              key="PanelBreakdownCloudUrl"
              cloudURL={props.breakdown.cloudUrl}
            />
            :null}
          <PanelDndContainer
            key="PanelBreakdownBaseShow"
            fromTemplate={!!props.breakdown.templateId}
            rightsParent={props.rights}
            editable={false}
            projectId={props.breakdown.projectId}
            workId={props.breakdown.id}
            workParentId={props.breakdown.parentId}
            parentType={parentTypes.breakdown}
            panelTypes={panelTypes}
          />
        </>,
      },
      {
        key: "PJ1", label: tN(tenantId, "Strukturdaten"), destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelBreakdownBase"
          rightsParent={props.rights}
          editable={editable}
          fromTemplate={!!props.breakdown.templateId}
          projectId={props.breakdown.projectId}
          workId={props.breakdown.id}
          workParentId={props.breakdown.parentId}
          parentType={parentTypes.breakdown}
          panelTypes={panelTypes}
        />,
      },
      {
        key: "BD2", label: tN(tenantId, "Struktur"), destroyInactiveTabPane: true,
        children: <BreakdownList
          key="BreakdownBreakdowns"
          rightsParent={props.rights}
          projectId={props.breakdown.projectId}
          tenantId={tenantId}
          workId={props.breakdown.id}
          parentType={parentTypes.breakdown}
        />,
      },
      {
        key: "BD3", label: tN(tenantId, "Räume"), destroyInactiveTabPane: true,
        children: <RoomList
          key="BreakdownRooms"
          rightsParent={props.rights}
          projectId={props.breakdown.projectId}
          tenantId={tenantId}
          workId={props.breakdown.id}
          parentType={parentTypes.breakdown}
        />,
      },
      {
        key: "BD4", label: tN(tenantId, "Objekte"), destroyInactiveTabPane: true,
        children: <WorkpieceList
          key="BreakdownWorkpieces"
          rightsParent={props.rights}
          projectId={props.breakdown.projectId}
          tenantId={tenantId}
          workId={props.breakdown.id}
          parentType={parentTypes.breakdown}
        />,
      },
    )
    if (!isTenantIdLigniteTenant(tenantId)) items.push({
        key: "Media3", label: "Medien", destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelBreakdownMedia"
          rightsParent={[]}
          projectId={props.breakdown.projectId}
          workId={props.breakdown.id}
          workParentId={props.breakdown.parentId}
          parentType={parentTypes.breakdown}
          panelTypes={["media"]}
        />,
      },)
    if (!isTenantIdLigniteTenant(tenantId) || isUserIdLigniteAdmin(userId)) items.push({
        key: "BDPCT", label: "Mitwirkende", destroyInactiveTabPane: true,
        children: <ParticipantList
          key="BreakdownParticipant"
          rightsParent={props.rights}
          projectId={props.breakdown.projectId}
          workId={props.breakdown.id}
          parentType={parentTypes.breakdown}
        />,
      },)
    return items
  }
  return <Tabs
    destroyInactiveTabPane
    activeKey={activeTab}
    onChange={handleChangeTab}
    items={tabItems()}
  />
}
