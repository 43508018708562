import React, {useState} from 'react'
import {Button, Card, Form, Input, Space} from "antd"
import {useLocation, useNavigate} from "react-router-dom"
import {apiLogin} from "../general/apiRequest"
import {useDispatch} from "react-redux";
import {hide} from "../general/msgUnauthorizedSlice";

const apiUrlLogin     = "/login"
const initialLogin = {userName:"", password: "", role: ""}

export const ModalLogin = ({formName = "modal-login-form", showRole = false, mainLogin, ...props}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [login, setLogin] = useState({userName: "", password: "", role: ""})
  const dispatch = useDispatch()
//  useEffect(() => {if (visible !== props.visible) setVisible(props.visible)}, [props.visible])
  const handleLogin = async () => {
    const rd = await apiLogin.post(apiUrlLogin, JSON.stringify(login))
    if (rd && rd.state && rd.data) {
      if (props && props.handleClose) props.handleClose()
      if (location.pathname !== "/login") {
        if (!props?.routeTo) {
          navigate(location.pathname + "?reload=" + (new Date()).getTime().toString())
        }
      } else navigate("/ras/projectlist")
    } else {
      setLogin({...initialLogin})
    }
  }
  const handleRefClick = e => {
    setLogin({...initialLogin})
    dispatch(hide())
    navigate("/login")
  }
  const LoginTitle = <div>
    <div className={"login-header"}>
      <div className={"logo128"}/>
      <div className={"logo-tenant"}>
        <h2 className={"logo-tenant"}> </h2>
      </div>
    </div>
  </div>
  return (<Card title={LoginTitle}>
    <div className="login-box">
      <div className="login-box-msg" title="Login">Bitte melden Sie sich an</div>
      <Form name={formName}
        colon=":"
        layout="horizontal"
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        style={{marginTop: 10}}
      >
        <Form.Item label="Benutzer" name="username" style={{margin: "10px 5px 5px 5px"}}>
          <Input
            name="username"
            value={login.username}
            placeholder="Benutzer"
            style={{margin: "5px 5px"}}
            autoComplete="off"
            onChange={(evt) => {
              setLogin({...login, username: evt.target.value})
            }}
          />
        </Form.Item>
        <Form.Item label="Passwort" name="password" style={{margin: "5px 5px 10px"}}>
          <Input.Password
            name="password"
            value={login.password}
            placeholder="Password"
            style={{margin: "5px 5px"}}
            autoComplete="off"
            onChange={(evt) => {
              setLogin({...login, password: evt.target.value})
            }}
          />
        </Form.Item>
        {(showRole && process.env.NODE_ENV !== 'production')
          ? <Form.Item label="Rolle" name="role" style={{margin: "5px 5px 10px"}}>
            <Input.Password
              name="role"
              value={login.password}
              placeholder="Rolle"
              style={{margin: "5px 5px"}}
              autoComplete="off"
              onChange={(evt) => {
                setLogin({...login, role: evt.target.value})
              }}
            />
          </Form.Item>
          : null}
      </Form>
    </div>
    <div style={{textAlign: "right"}}>
      <Space>
        {(!mainLogin)
          ? <Button type="link" shape="round" onClick={handleRefClick}>zur Loginseite</Button>
          : <Button type="link" shape="round" onClick={() => {
            navigate("/forgetPassword")
          }}>Passwort Vergessen</Button>
        }
        <Button type="primary" shape="round" onClick={handleLogin}>Anmelden</Button>
      </Space>
    </div>
  </Card>)
}
