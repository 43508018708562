import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlPropertyTypeList         = "/propertyType/list"

export const SelectPropertyMetaType = ({allowClear, showEmpty = false, disableEmpty = false, onBlur, onChange, readOnly = false, textEmpty = null, valueEmpty = "Please Choose", ...props}) => {
  const [propertyMetaTypes, setPropertyMetaTypes] = useState([])
  useEffect(() => {
    loadPropertyMetaType()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loadPropertyMetaType = () => {
    apiRequest.get(apiUrlPropertyTypeList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setPropertyMetaTypes(rd.data)
      })
      .catch(error => console.log("loadScopes in SelectPropertyMetaType: " + error))
  }
  return <Select
//    key={"SelectPropertyMetaType"}
    fieldNames={{label: "name", value: "id"}}
    style={{width: "100%"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={propertyMetaTypes}
  />
/*
    {(showEmpty)?<SelectOption key={"dst0"} value={valueEmpty} disabled={disableEmpty}>{textEmpty}</SelectOption>:null}
    {propertyMetaTypes.map(item =>
      <SelectOption key={item.id} value={item.id}>{item.name}</SelectOption>
    )}
  </Select>
*/
}