import React from 'react';
import {Card, Form, Input} from 'antd';
import { Row, Col } from 'antd';
import {SelectCountry} from "../common/cmnCountrySelect";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import {DragOutlined} from "@ant-design/icons";

const apiUrlLocationAddressShow          = "/locationAddress/show"
const apiUrlLocationAddressSave          = "/locationAddress"

//const routeLocationAddressShow          = "/api/locationAddress/show"
//const routeLocationAddressSave          = "/api/locationAddress/save"

export class AddressPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        id:           "",
        active:       true,
        position:     -1,
        parentId:    "",
        functionId:  "",
        street:       "",
        street2:      "",
        zip:          "",
        city:         "",
        citydistrict: "",
        countryId:      ""
      }
    }
    this.isChanged = false
  }
  componentDidMount = () => { this.loadAddress(this.props.itemId) }
  loadAddress = async (addressId) => {
    const rd = await apiRequest.get(apiUrlLocationAddressShow + "/" + addressId)
    if (rd && rd.state && rd.data) this.setState({address: rd.data})
  }
  handleSave = async () => {
    if (this.isChanged) {
      await apiRequest.put(apiUrlLocationAddressSave, JSON.stringify({...this.state.address}))
      this.isChanged = false
    }
  }
  handleChange = evt => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    this.setState({address: {...this.state.address, [evt.target.name]: value}})
    this.isChanged = true;
  }
  handleChangeSelect = (name, value) => {
    this.setState({address: {...this.state.address, [name]: value}})
    this.isChanged = true;
  }
  render = () => {
    const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
//    const colSpan = 12 //(this.props.panelStyle === "list") ? 12 : 8
    return (
      <Card
        title={
          <Row gutter={[16,16]}>
            <Col span={1}>{(!this.props.fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
            <Col span={5}>Adresse</Col>
          </Row>
        }
        extra={<>
          {this.props.extra}
        </>}
      >
        <Form name="address-panel-form"
          layout={"vertical"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={[8,0]}>
                <Col span={24}>
                  <Form.Item style={{ marginBottom: 0 }} >
                    <Form.Item label={"Straße - Nr."} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0'}} >
                      <Input
                        name={"street"} value={this.state.address.street} style={{width: "100%"}}
                        onChange={(e) => {this.handleChange(e)}}
                        onBlur={() => this.handleSave()}
                        readOnly={!this.props.editable || !update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Zusatz"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 4px'}} >
                      <Input
                        name={"street2"} value={this.state.address.street2} style={{width: "100%"}}
                        onChange={(e) => {this.handleChange(e)}}
                        onBlur={() => this.handleSave()}
                        readOnly={!this.props.editable || !update} disabled={!update}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item style={{ marginBottom: 0 }} >
                    <Form.Item label={"PLZ"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(20% - 4px)', margin: '0 4px 0 0'}} >
                      <Input
                        name={"zip"} value={this.state.address.zip} style={{width: "100%"}}
                        onChange={(e) => {this.handleChange(e)}}
                        onBlur={() => this.handleSave()}
                        readOnly={!this.props.editable || !update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Ort"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(34% - 8px)', margin: '0 4px'}} >
                      <Input
                        name={"city"} value={this.state.address.city} style={{width: "100%"}}
                        onChange={(e) => {this.handleChange(e)}}
                        onBlur={() => this.handleSave()}
                        readOnly={!this.props.editable || !update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Bezirk"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(34% - 8px)', margin: '0 4px'}} >
                      <Input
                        name={"citydistrict"} value={this.state.address.citydistrict} style={{width: "100%"}}
                        onChange={(e) => {this.handleChange(e)}}
                        onBlur={() => this.handleSave()}
                        readOnly={!this.props.editable || !update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Land"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(12% - 4px)', margin: '0 0 0 4px' }} >
                      <SelectCountry
                        name={"countryId"} value={this.state.address.countryId} style={{width: "100%"}}
                        popupMatchSelectWidth={false}
                        onChange={(v , o) => {this.handleChangeSelect("countryId", v)}}
                        onBlur={() => this.handleSave()}
                        readOnly={!this.props.editable || !update} disabled={!update}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
          </Row>
        </Form>
      </Card>
    )
  }
}
AddressPanel.defaultProps = {
  rightsParent: [],
  panelStyle: "default"
}