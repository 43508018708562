/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 09.01.2024
 *
 * Project: csescaclient
 *
 * ProjectMeta in package  (project: csescaclient ) © 09.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {raveMeta} from "../raveMeta";

export const raveBreakdownMeta = {
  ...raveMeta,
  domain: "breakdown",
  module: {
    ...raveMeta.module,
    routeDomain: "breakdown",
  },
  defaultData: {
    ...raveMeta.defaultData,
    codeNumber: "0815",
    name: "Test",
    projectBeginText: "",
    projectEndText: "1812",
    enClassification: false,
    isTemplate: null,
    templateId: null,
    cloudUrl: "",
  }
}
// const raveBreakdownMetaBackup = {
//   keyId: "id",
//   domain: "breakdown",
//   domainList: "List",
//   module: {
//     name: "rave",
//     show: "/",
//     routeBase: "ras",
//     routeDomain: "breakdown",
//   },
//   routes: {
//     create: "",
//     delete: "",
//     save: "",
//   },
//   defaultData: {
//     id: "",
//     tenantId: "",
//     active: true,
//     position: -1,
//     codeNumber: "0815",
//     name: "Test",
//     projectBeginText: "",
//     projectEndText: "1812",
//     enClassification: false,
//     isTemplate: false,
//     templateId: null,
//     cloudUrl: "",
//   }
// }
