import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";

const nodeMetaAdapter = createEntityAdapter({
  selectId: model => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const propertyMetaAdapter = createEntityAdapter({
  selectId: model => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const propertyGroupAdapter = createEntityAdapter({
  selectId: model => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const nodeMetaSlice = createSlice({
  name: "nodeMeta",
  initialState: nodeMetaAdapter.getInitialState({
      loading: "idle",
  }),
  reducers: {
     upsertOneNodeMeta(state, nmAction) {
      const action = {
        type: nmAction.type,
        payload:{...nmAction.payload,
          propertyMetas: [], //propertyMetaAdapter.getInitialState(),
          propertyGroups: [], //propertyGroupAdapter.getInitialState(),
        }}
      nodeMetaAdapter.upsertOne(state, action)
    },
    updateOneNodeMetaElement(state, nmAction) {
//      const {payload} = nmAction
      // nodeMetaAdapter.updateOne(state, {...state, [payload.name]: payload.value})
      nodeMetaAdapter.upsertOne(state, nmAction)
    },
    removeOneNodeMeta(state, rAction) {
      nodeMetaAdapter.removeOne(state, rAction)
    },
//    removeOneNodeMeta: propertyMetaAdapter.removeOne,
    upsertOneNodeMetaPropertyMetaList(state, pmAction) {
      const {payload} = pmAction
      const {propertyMetas} = state.entities[payload.parentId]
      if (!propertyMetas[payload.id]) propertyMetas.push(payload.id)
      const action = {type: pmAction, pmAction}
//      nodeMetaAdapter.upsertOne(state, action)
      // if (state.nodeMeta.entities[pmAction.payload.parentId])
      //   propertyMetaAdapter.upsertOne(state.nodeMeta.entities[pmAction.payload.parentId].propertyMetas, pmAction)
    },
    upsertOnePropertyGroupList(state, pgAction) {
      if (state.nodeMeta.entities[pgAction.payload.nodeMetaId])
      propertyGroupAdapter.upsertOne(state.nodeMeta.entities[pgAction.payload.nodeMetaId].propertyGroups, pgAction)
    },
  }
})

export const { upsertOneNodeMeta, removeOneNodeMeta, /*upsertOnePropertyGroup, upsertOnePropertyMeta,*/ updateOneNodeMetaElement, upsertOneNodeMetaPropertyMetaList } = nodeMetaSlice.actions
//export const { upsertMany } = nodeMetaAdapter
// export const { addOneNodeMeta, setOneNodeMeta, upsertOneNodeMeta, addPropertyMetaToList, updOneNodeMeta, delOneNodeMeta, setAllNodeMeta } = nodeMetaSlice.actions

//export const {selectAll: selectAllNodeMeta, selectById: selectNodeMetaById} = nodeMetaAdapter.getSelectors((state) => state.nodeMeta)
export const {selectAll: selectAllNodeMeta, selectById: selectNodeMetaById} = nodeMetaAdapter.getSelectors(state => state.data.nodeMeta)
const {selectAll: selectAllPropertyGroups} = propertyGroupAdapter.getSelectors()
// const {selectAll: selectAllPropertyGroups} = propertyGroupAdapter.getSelectors(state => state.propertyGroups)

export const selectPropertyGroups = (state, itemId) => {
  if (state.nodeMeta.entities[itemId])
    return selectAllPropertyGroups(state.nodeMeta.entities[itemId].propertyGroups)
  else return []
//  else null
}

// //const selectItemId = (state, itemId) => itemId
// export const selectPropertyGroups = (state, itemId) => /*createSelector*/(
//   [
// //    state => state.nodeMeta,
// //    (state, itemId) => itemId
//     (state, itemId) => state.nodeMeta.entities[itemId] //.propertyGroups
//   ],
//   propertyGroups =>
//     nodeMetaEntity => selectAllPropertyGroups(nodeMetaEntity)
// //  (nodeMeta, itemId) => nodeMeta.entities[itemId].propertyGroups
// )
//
export default nodeMetaSlice.reducer
export const {nodeMeta} = nodeMetaSlice.getInitialState()