/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 12.01.2024
 *
 * Project: csescaclient
 *
 * meta in package  (project: csescaclient ) © 12.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */

export const coreMeta = {
  keyId: "id",
  domain: null,
  domainList: "List",
  module: {
    name: "core",
    show: "/",
    routeBase: "ras",
  },
  routes: {
    create: "",
    delete: "",
    save: "",
  },
  defaultData: {
    id: "",
  }
}