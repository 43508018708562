import React, {useState} from "react";
import {covImgFunc, mapImgFunc} from "../rascalApiDef";
import {sysRight} from "../../common/cmnRights";
import {Button, Card, Checkbox, Col, Form, Input, Row} from "antd";
import {TitleImage} from "../../images/imageMapHover";
import {isLigniteTenant, isTenantIdLigniteTenant, tN} from "../../system/checkTenants";
import {SelectCatalog} from "../../common/cmnSelectCatalog";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import useCredentials from "../../system/UseCredentials";

export const RoomData = (props) => {
  const {collapseDetail} = props
  const [collapse, setCollapse] = useState(false)
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const imgContext = {
    refererId: props.room.id,
    tenantId: props.room.tenantId,
    projectId: props.room.projectId,
    functionId: covImgFunc,
  }
//    const read = this.props.rights.indexOf(sysRight.READ) >= 0
  const update = !(props.rights.indexOf(sysRight.UPDATE) >= 0)
  return (
    <Card
      style={(!!props.room.templateId)?{backgroundColor:"#FFFFFA"}: {}}
      className={(!collapseDetail)?"":"ces-collapse"}
    >
      <Row gutter={[16, 12]}>
        <Col span={4} className="data-container">
          {props.room.id ?
            <TitleImage
              key={"CI" + props.room.id}
              imgFunction={covImgFunc}
              covImg={imgContext}
              rightsParent={props.rights}
            /> : null}
        </Col>
        <Col span={4} className="data-container">
          {(props.room.id && !isLigniteTenant(props.room.tenantId)) ?
            <TitleImage
              key={"MI" + props.room.id}
              imgFunction={mapImgFunc}
              covImg={imgContext}
              rightsParent={props.rights}
            /> : null}
        </Col>
        <Col span={10}>
          <Form name="room-data-form"
            layout={"vertical"}
            // labelCol={{ span: 24 }}
            // wrapperCol={{ span: 24 }}
          >
            <Row gutter={[16, 12]}>
              <Col span={12}>
                <Form.Item label={tN(tenantId, "Raum") + "-Nr. / Bez."} style={{marginBottom: 0}}>
                  <Input
                    name="codeNumber" value={props.room.codeNumber}
                    maxLength={20}
                    onChange={(evt) => {
                      props.handleChildChange(evt)
                    }}
                    onBlur={(evt) => {
                      props.handleChildBlur(evt)
                    }}
                    readOnly={update} disabled={update}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Name" style={{marginBottom: 0}}>
                  <Input
                    name="name" value={props.room.name}
                    maxLength={255}
                    onChange={(evt) => {
                      props.handleChildChange(evt)
                    }}
                    onBlur={(evt) => {
                      props.handleChildBlur(evt)
                    }}
                    readOnly={update} disabled={update}
                  />
                </Form.Item>
              </Col>
            </Row>
            {!isTenantIdLigniteTenant(tenantId) ?
              <>
                <Row gutter={[16, 12]}>
                  <Col span={12}>
                    <Form.Item label="alte Nummer / Bez." style={{marginBottom: 0}}>
                      <Input
                        name="oldNumber" value={props.room.oldNumber}
                        maxLength={20}
                        onChange={(evt) => {
                          props.handleChildChange(evt)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={update} disabled={update}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="alter Name" style={{marginBottom: 0}}>
                      <Input
                        name="oldName" value={props.room.oldName}
                        maxLength={40}
                        onChange={(evt) => {
                          props.handleChildChange(evt)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={update} disabled={update}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 12]}>
                  <Col span={12}>
                    <Form.Item label="Erstellungsjahr (von)" style={{marginBottom: 0}}>
                      <Input
                        name="constrYearBeginText" value={props.room.constrYearBeginText}
                        maxLength={40}
                        onChange={(evt) => {
                          props.handleChildChange(evt)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={update} disabled={update}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Erstellungsjahr (bis)" style={{marginBottom: 0}}>
                      <Input
                        name="constrYearEndText" value={props.room.constrYearEndText}
                        maxLength={40}
                        onChange={(evt) => {
                          props.handleChildChange(evt)
                        }}
                        onBlur={(evt) => {
                          props.handleChildBlur(evt)
                        }}
                        readOnly={update} disabled={update}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {(props.room.enClassification) ?
                  <Row gutter={[16, 12]}>
                    <Col span={12}>
                      <Form.Item label="Gesamtklassifizierung (RC)" style={{marginBottom: 0}}>
                        <SelectCatalog
                          name="rClassId"
                          catalogGroup={"e50d4336-a1b5-48c0-94b1-7840fac2a74a"}
                          value={props.room.rClassId}
                          onChange={(value) => {
                            props.handleSelectChanges("rClassId", value)
                          }}
                          onBlur={(evt) => {
                            props.handleChildBlur(evt)
                          }}
                          readOnly={update} disabled={update}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Zustandsklasse (CC)" style={{marginBottom: 0}}>
                        <SelectCatalog
                          name="cClassId"
                          catalogGroup={"7357714A-2DF5-4806-9F02-4798DAF3F0E0"}
                          value={props.room.cClassId}
                          onChange={(value) => {
                            props.handleSelectChanges("cClassId", value)
                          }}
                          onBlur={(evt) => {
                            props.handleChildBlur(evt)
                          }}
                          readOnly={update} disabled={update}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  : null}
                {(props.room.enClassification) ?
                  <Row gutter={[16, 12]}>
                    <Col span={12}>
                      <Form.Item label="Dringlichkeitsklasse (UC)" style={{marginBottom: 0}}>
                        <SelectCatalog
                          name="uClassId"
                          catalogGroup={"09c402e2-bf84-44b2-bc51-26a192e9d83c"}
                          value={props.room.uClassId}
                          onChange={(value) => {
                            props.handleSelectChanges("uClassId", value)
                          }}
                          onBlur={(evt) => {
                            props.handleChildBlur(evt)
                          }}
                          readOnly={update} disabled={update}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Öffentlich" style={{marginBottom: 0}}>
                        <Checkbox
                          name={"publicAccess"} checked={props.room.publicAccess}
                          onChange={(evt) => {
                            props.handleChildChange(evt)
                          }}
                          onBlur={(evt) => {
                            props.handleChildBlur(evt)
                          }}
                          readOnly={update} disabled={update}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  : null}
              </>
              : null}
            {!isTenantIdLigniteTenant(props.room.tenantId) ?
              <Row>
                <Col span={24} offset={0} style={{textAlign: "right"}}>
                  <Button type="link" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <UpOutlined/> : <DownOutlined/>}Ein/Aus
                  </Button>
                  {(collapse) ?
                    <div>
                      <Form.Item label="Cloud-URL" style={{marginBottom: 0}}>
                        <input
                          name="cloudUrl" value={props.room.cloudUrl} style={{width: "100%"}}
                          onChange={(evt) => {
                            props.handleChildChange(evt)
                          }}
                          onBlur={(evt) => {
                            props.handleChildBlur(evt)
                          }}
                          readOnly={update} disabled={update}
                        />
                      </Form.Item>
                    </div>
                    : null
                  }
                </Col>
              </Row>
              : null}
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

