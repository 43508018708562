import React, {useEffect, useState} from 'react'
import {Button, Popconfirm, Space} from "antd";
import {sysRight} from "./cmnRights";
import {DeleteOutlined, EditOutlined, FolderAddOutlined, ReadOutlined} from "@ant-design/icons";
import apiRequest from "../general/apiRequest";

export const ButtonRights = (props) => {
  const [rights, setRights] = useState([])
  useEffect(() => {
    const loadRights = async () => {
      if (checkEntity()) {
        let id = ""
        if (props.entityId !== "") id += "/" + props.entityId
        const urn = "/" + props.entityName + "/rights" + id
        const rd = await apiRequest.get(urn)
        if (rd && rd.state && rd.rights) setRights(rd.data)
      }
    }
    loadRights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*props.entityId, props.entityName*/])
  const checkEntity = () => {
    if (props.entityName) {
      switch (props.entityName) {
        case "project":
          return (props.entityId || (props.rightTypes.indexOf(sysRight.CREATE) >= 0))
        case "breakdown":
        case "room":
        case "workpiece":
          return (props.entityId)
        default:
          return true
      }
    } else return false
  }
  const shape = "round"
  return (
    <Space>
      {(props.rightTypes.indexOf(sysRight.LIST) !== -1)?
        <Button
          title={(props.buttonText === "")? (props.titlePrefix + " List").trimStart():null}
          type={"link"}
          //          disabled={rights.indexOf(sysRight.CREATE) < 0}
          disabled={rights.indexOf(sysRight.CREATE) < 0}
          shape={shape}
          icon={(props.buttonText === "")? (props.icon)? props.icon :<ReadOutlined style={{ fontSize: '1.5em'}} />: null}
          onClick={props.handleList}
        >{(props.buttonText !== "")? props.buttonText:""}</Button>
        :null}
      {(props.rightTypes.indexOf(sysRight.CREATE) !== -1)?
        <Button
          title={(props.buttonText === "")? (props.titlePrefix + " Anlagen").trimStart():null}
          type={"link"}
//          disabled={rights.indexOf(sysRight.CREATE) < 0}
          disabled={rights.indexOf(sysRight.CREATE) < 0}
          shape={shape}
          icon={(props.buttonText === "")? (props.icon)? props.icon :<FolderAddOutlined style={{ fontSize: '1.5em'}} />: null}
          onClick={props.handleCreate}
        >{(props.buttonText !== "")? props.buttonText:""}</Button>
        :null}
      {(props.rightTypes.indexOf(sysRight.READ) !== -1)?
        <Button
          title={(props.titlePrefix + " Bearbeiten").trimStart()}
          type={"link"}
          disabled={rights.indexOf(sysRight.READ) < 0}
          shape={shape}
          icon={<EditOutlined style={{ fontSize: '1.5em'}}/>}
          onClick={props.handleShow}
        />
        :null}
      {(props.rightTypes.indexOf(sysRight.DELETE) !== -1)?
        <Popconfirm
          placement="topRight"
          title={"Möchten Sie den Datensatz löschen"}
          onConfirm={props.handleDelete}
          okText="Ja"
          cancelText="Nein"
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true}}
        >
          <Button
            title={(props.titlePrefix + " Löschen").trimStart()}
            type={"link"}
            danger={true}
            disabled={rights.indexOf(sysRight.DELETE) < 0}
            shape={shape}
            icon={(props.icon)? props.icon :<DeleteOutlined style={{ fontSize: '1.5em'}}/>}
          />
        </Popconfirm>
        :null}
    </Space>
  )
}

ButtonRights.defaultProps = {
  titlePrefix: "",
  buttonText: "",
  rightTypes: [],
  rights: [],
  entityName: "",
  entityId: ""
}
