import React, {useState} from 'react';
import {Button, Checkbox, Col, Form, message, Modal, Row, Tooltip} from "antd";
import {PrinterOutlined} from "@ant-design/icons";

const printProps = {
  newReport: false,
  caption: false,
  objectData: true,
  planing: false,
  restoration: false,
  visible: false,
}
export const PrintWorkpieceChoose = (
  {
    buttonType = "link",
    buttonIcon = <PrinterOutlined />,
    buttonShape = "default",
    onResult = (value) => message.error(<p>Funktion nicht angelegt</p>,5),
    ...props
  }) => {
  const [state, setState] = useState(printProps)
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     visible: false,
  //     objectData: true,
  //     caption: false,
  //     planing: false,
  //     restoration: false,
  //   }
  // }
  const showModal = (event) => setState({...state, visible: true, newReport: event.ctrlKey})
  const handleCancel = () => setState({...printProps})
  const handleOk = () => {
//    const {objectData, caption, planing, restoration} = state
//    setState((prevState) => {return {...prevState, visible: false}})
    onResult({
      result: "ok",
      newReport: state.newReport,
      values: {
        objectData: state.objectData,
        caption: state.caption,
        planing: state.planing,
        restoration: state.restoration,
      }
    })
    setState(printProps)
  }
  const handleChanges = ({target}) =>  setState({...state, [target.name]: (target.type === "checkbox") ? target.checked : target.value})
  // const handleChanges = (event) => {
  //   const name = event.target.name
  //   const value = event.target.type === "checkbox" ? event.target.checked : event.target.value
  //   setState((prevState) => ({...prevState, [name]: value}))
  // }
  return <>
    <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
    <Button
      type={buttonType}
      icon={buttonIcon}
      shape={buttonShape}
      onClick={showModal}
      />
    </Tooltip>
    {(state.visible)?
    <Modal
      destroyOnClose={true}
      open={state.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <h5>Druckauswahl</h5>
          <Form name="print-choose-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="Objektdaten" name="objectData" style={{marginBottom: 0}}>
              <Checkbox
                name="objectData"
                checked={state.objectData}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Veränderungen/Schäden" name="caption" style={{marginBottom: 0}}>
              <Checkbox
                name="caption"
                checked={state.caption}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Planung" name="planing" style={{marginBottom: 0}}>
              <Checkbox
                name="planing"
                checked={state.planing}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Restaurierung" name="restoration" style={{marginBottom: 0}}>
              <Checkbox
                name="restoration"
                checked={state.restoration}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
      :null}
  </>
}
export class PrintChoose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      objectData: true,
      caption: false,
      planing: false,
      restoration: false,
    }
  }
  showModal = () => { this.setState({visible: true}) }
  handleCancel = () => { this.setState({visible: false}) }
  handleOk = () => {
    const {objectData, caption, planing, restoration} = this.state
    this.setState({visible: false})
    this.props.onResult({
      result: "ok",
      values: {
        objectData: objectData,
        caption: caption,
        planing: planing,
        restoration: restoration,
      }
    })
    this.setState({objectData: true, caption: false, planing: false, restoration: false,})
  }
  handleChanges = (evt) => {
    const name = evt.target.name
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    this.setState({[name]: value})
  }
  render = () => {
    const { visible } = this.state;
    return (
      <>
        <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
        <Button
          type={this.props.buttonType}
          icon={this.props.buttonIcon}
          shape={this.props.buttonShape}
          onClick={this.showModal}
          />
        </Tooltip>
        {(this.state.visible)?
        <Modal
          destroyOnClose={true}
          open={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row>
            <Col span={24}>
              <h5>Druckauswahl</h5>
              <Form name="print-choose-form"
                colon=":"
                layout="horizontal"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
              >
                <Form.Item label="Objektdaten" name="objectData" style={{marginBottom: 0}}>
                  <Checkbox
                    name="objectData"
                    checked={this.state.objectData}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
                <Form.Item label="Veränderungen/Schäden" name="caption" style={{marginBottom: 0}}>
                  <Checkbox
                    name="caption"
                    checked={this.state.caption}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
                <Form.Item label="Planung" name="planing" style={{marginBottom: 0}}>
                  <Checkbox
                    name="planing"
                    checked={this.state.planing}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
                <Form.Item label="Restaurierung" name="restoration" style={{marginBottom: 0}}>
                  <Checkbox
                    name="restoration"
                    checked={this.state.restoration}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
          :null}
      </>
    )
  }
}
PrintChoose.defaultProps = {
  buttonType: "link",
  buttonIcon: <PrinterOutlined />,
  buttonText: "PDF Datenblatt...",
  buttonGhost: false,
  buttonShape: "default",
  onResult: (value) => {
    message.error(<p>Funktion nicht angelegt</p>,5)
  },
}