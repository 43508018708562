/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 12.01.2024
 *
 * Project: csescaclient
 *
 * raveMeta in package  (project: csescaclient ) © 12.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {coreMeta} from "../core/utils/coreMeta";

export const raveMeta = {
  ...coreMeta,
  module: {
    ...coreMeta.module,
    name: "rave",
  },
  defaultData: {
    ...coreMeta.defaultData,
    active: true,
    position: -1,
    tenantId: null,
  }
}