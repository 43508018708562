import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Space, Tooltip} from "antd";
import {DownOutlined, FilePdfOutlined, ToTopOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import apiRequest from "../../general/apiRequest";
import {tN} from "../../system/checkTenants";
import {BreakdownTabs} from "./breakdownTabs";
import {BreakdownData} from "./breakdownData";
import useCredentials from "../../system/UseCredentials";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {useFetchData} from "../../core/Fetch/useFetchData";
import {sysRight} from "../../common/cmnRights";
import {raveBreakdownMeta} from "../../rave/Breakdown/BreakdownMeta";

import {getRaveBreadcrumbType} from "../../rave";

export const BreakdownMain = ({/*hdlBreadcrumb,*/ ...props}) => {
  const [state, setState] = useContext(CsescaContext)
  const [collapseDetail, setCollapseDetail] = useState(false)
  // const [breakdown, setBreakdown] = useState({...defaultBreakdown})
  // const [rights, setRights] = useState([])
  // const [isChanged, setIsChanged] = useState(false)
  const navigation = useNavigate()
  const params = useParams()
  const paramsId = params.id
  const {data, rights, persist, recast} = useFetchData(raveBreakdownMeta, params.id, {})
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const breadcrumbType = getRaveBreadcrumbType(data, tenantId)
  useEffect(() => {
    doState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])
  const doState = () => setState({
    ...state,
    breadcrumbType: breadcrumbType,
    entityName: "breakdown",
    pageType: "rave",
    entityId: params.id,
    state: "done",
    breadCrumbList: [],
  })
  const onPrint = (event) => {
    const reportUrl = ((!event.ctrlKey)?"/breakdown/sheet/":"/jasper/breakdownSheet/")
//    apiRequest.post("/breakdown/sheet/" + data.id, null,{responseType: "blob"})
    apiRequest.post(reportUrl + data.id, null,{responseType: "blob"})
      .then(doc => {
        const docURL = URL.createObjectURL(doc)
        const anchor = document.createElement("a")
        anchor.href = docURL
        anchor.download = "BD-Doc_" + data.codeNumber + "_" + data.name + ".pdf"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(docURL)
      })
  }
  const handleChildChange = ({target}) => {
    // const update = (rights.indexOf(sysRight.UPDATE) >= 0)
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(target.name, target.type === "checkbox" ? target.checked : target.value)
  }
  const handleSelectChanges = (name, value) => {
    // const update = (rights.indexOf(sysRight.UPDATE) >= 0)
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(name, value)
  }
  const handleSave = () => {
    // const update = (rights.indexOf(sysRight.UPDATE) >= 0)
    if (rights.indexOf(sysRight.UPDATE) >= 0) persist()
  }
  return <Card
    className={"ces-rave-card"}
    style={(!!data.templateId)?{backgroundColor:"#FFFFFA"}: {}}
    title={<><h2><Button type={"text"} icon={<DownOutlined rotate={(!collapseDetail)?0:-90} />} onClick={() => setCollapseDetail(!collapseDetail)} />{tN(tenantId, "Struktur")}</h2><i>{data.codeNumber}</i>{data.name}</>}
    extra={<Space>
      {(data.projectId)
        ?<>
          <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
            <Button
              type="link"
              icon={<FilePdfOutlined style={{fontSize: "2em"}} />}
              onClick={onPrint}
            />
          </Tooltip>
          <Tooltip title={"Übergeordnetes Element"} color={'cyan'} placement="topRight">
            <Button
              type={"link"}
              icon={<ToTopOutlined style={{fontSize: "2em"}} />}
              onClick={() => {
                if (data.projectId === data.parentId)
                  navigation(`/ras/project/${data.parentId}`)
                else
                  navigation(`/ras/data/${data.parentId}`)
              }}
            />
          </Tooltip>
        </>
        :null}
    </Space>}
  >
    <BreakdownData
      collapseDetail={collapseDetail}
      breakdown={data}
      data={data}
      rights={rights}
      handleChildChange={(evt) => {handleChildChange(evt)}}
      handleSelectChanges={(name, value) => {handleSelectChanges(name, value)}}
      handleChildBlur={() => {handleSave()}}
    />
    <BreakdownTabs
      breakdown={data}
      data={data}
      editable={rights.indexOf(sysRight.UPDATE) >= 0}
      rights={rights}
    />
  </Card>
}
