import React from 'react';
//import './index.css';
import {App} from './App';
import {createRoot} from "react-dom/client";
//import reportWebVitals from './reportWebVitals';

createRoot(
  document.getElementById('root')
)
  .render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )

/*
ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
