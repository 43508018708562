import React, {useContext, useEffect, useRef, useState} from 'react';
import {Card, Checkbox, Space, Table} from "antd"
import Highlighter from "react-highlight-words";
import {useNavigate, useSearchParams} from "react-router-dom";
import {sysRight} from "../../common/cmnRights";
import {ProjectListMenu} from "./projectListMenu";
import apiRequest from "../../general/apiRequest";
import {BC_RAVE_ROOT, BC_RAVE_TEMPLATE} from "../../rave";
import {covImgFunc, mapImgFunc} from "../rascalApiDef";
import {TitleImage} from "../../images/imageMapHover";
import {isLigniteTenant} from "../../system/checkTenants";
import useCredentials from "../../system/UseCredentials";
import {columnSearchProps} from "../../util/ColumnSearchProps/columnSearchProps";
import {useFetchList} from "../../core/Fetch/useFetchList";
import {raveProjectMeta} from "../../rave/Project/ProjectMeta";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {ContextMenu} from "../../core/ContextMenu";
import {useRaveItemMenu} from "../../rave/Menu/RaveItemMenu";
import {mrpNodeMetaId} from "../../general/Entities";
import {RowDropMenu} from "../../core/RowDropMenu";

// const apiUrlProjectRight        = "/project/rights"
// const apiUrlProjectList         = "/project/list"
// const apiUrlProjects         = "/rave/projectList"
// const apiUrlProjectDeleteList   = "/project/deletelist"
// const apiUrlCreateProject       = "/project"

const elementMeta = raveProjectMeta
const CsescaRow = ({index, ...props}) => {
  return <tr {...props} />
}
const CsescaCell = ({record, title, dataIndex, children, ...rest}) => {
  return <td {...rest} title={title}>{children}</td>
}
export const ProjectList = ({isTemplate = false, ...props}) => {
//  const rowRef = useRef(null)
  const [params] = useSearchParams()
  const isTemplateInt = (params?.isTemplate)?true:isTemplate
  const [state, setState] = useContext(CsescaContext)
  const navigation = useNavigate()
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
  const [menuList, buildMenuList] = useRaveItemMenu()
  const fetchParams = (!isTemplateInt)
    ?{}:{queryParams: `isTemplate=${isTemplateInt}`,}
  // const {data: projects, create} = useFetchList(
  const fetchList = useFetchList(
    elementMeta,
    fetchParams
  )
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  // const userId = credentials?.user.id
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      breadcrumbType: (!isTemplateInt)?BC_RAVE_ROOT:BC_RAVE_TEMPLATE,
      entityName: "projectList",
      pageType: "rave",
      entityId: null,
      state: "done",
      breadCrumbList: [],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.breadcrumbType]);
  const filterObjects =  {
    // refSearch: searchInput,
    refInput: searchInput,
    doSearch:(selectedKeys, confirm, dataIndex) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    },
    doReset:(clearFilters, confirm) => {
      clearFilters()
      confirm()
      setSearchText('')
    },
    doRender:(text, dataIndex) => searchedColumn === dataIndex
      ? <Highlighter
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
      : text,
  }
  const handleDoubleClick = (value) => {
    apiRequest.get(`/project/rights/${value}`)
      .then((rd) => {
        if (rd && rd.state && rd.rights && (rd.rights.indexOf(sysRight.READ) >= 0)) navigation(`/ras/project/${value}`)
      })
      .catch(error => console.log("handleDoubleClick in ProjectList: " + error))
  }
  const newCols = () => {
    const cols = [
      { title: "Bild", dataIndex: "mediaId", width: 160,
        render: (data, lineItem) => <TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.id,}} rightsParent={[]} parentId={lineItem.id} imgFunction={covImgFunc} />
      },
      !isLigniteTenant(tenantId)
      ?{ title: "Flurplan", dataIndex: "mediaId", width: 160,
          render: (data, lineItem) => <TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.id,}} rightsParent={[]} parentId={lineItem.id} imgFunction={mapImgFunc} />
        }:null,
      { ...columnSearchProps('Projektname', 'name', filterObjects), width: 300},
      { ...columnSearchProps('Projektnr.', 'codeNumber', filterObjects), width: 150},
      !isLigniteTenant(tenantId)
        ?{ title: "EN", dataIndex: "enClassification", width: 50,
          render: (data) => <Checkbox checked={data} />,
          filterMultiple: false,
          filters: [{text: 'ja', value: true},{text: 'nein', value: false}],
          onFilter: (value, record) => record.enClassification === value,}:null,
      { ...columnSearchProps('Ende', 'projectEndText', filterObjects), width: 50},
      { title: ""},
      { title: "Bearbeiten", dataIndex: "id", width: 150, render: (data, lineItem) =>
        <RowDropMenu contextMenuInfo={{id: lineItem.id, tenantId: lineItem.tenantId, name: lineItem.name, fetchList, nodeMetaId: mrpNodeMetaId["project"]}} />
      }
    ]
    return cols.filter((items) => items !== null).map((col) => ({
      ...col,
      onCell: (record) => ({ record, dataIndex: col.dataIndex, title: col.title }),
  }))}
  const handleContextMenu = (event, record) => {
    event.preventDefault()
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    buildMenuList({id: record.id, tenantId: record.tenantId, name: record.name, fetchList, nodeMetaId: mrpNodeMetaId["project"]})
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  const onRow = (record) => ({
    onDoubleClick: () => {handleDoubleClick(record.id)},
    onContextMenu: (event) => handleContextMenu(event, record)
  })
  return <Card
    extra={<Space>
      <ProjectListMenu
        isTemplate={isTemplateInt}
        projectId={props.projectId}
        parentId={props.workId}
        create={fetchList.create}
      />
    </Space>}
  >
    <ContextMenu items={menuList} {...contextMenuState}/>
      <Table
        className={"ces-rave-table"}
        dataSource={fetchList.data}
        pagination={{position: ['topRight', 'bottomRight'],}}
        onRow={onRow}
        // onRow={(row, index) => { return {
        //   onDoubleClick: () => {handleDoubleClick(row.id)},
        // }}}
        columns={newCols()}
        rowKey={record => record.id}
        components={{body: {row: CsescaRow, cell: CsescaCell,},}}
      />
  </Card>
}
