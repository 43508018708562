import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlCatalogListForProperty         = "/catalog/getListForProperty"

export const SelectCatalogForProperty = (props) => {
  const [catalogListForProperty, setCatalogListForProperty] = useState([])
  const loadCatalogListForProperty = async () => {
    const rd = await apiRequest.get(apiUrlCatalogListForProperty)
    if (rd && rd.state && rd.data) setCatalogListForProperty(rd.data)
  }
  useEffect(() => {loadCatalogListForProperty()}, [])
  return (
    <Select
      key={"coreSelectScope"}
      {...props}
      style={{width: "100%"}}
      allowClear
      fieldNames={{label: "name", value: "id"}}
      dropdownAlign={{ points: ["tr", "br"] }}
      options={catalogListForProperty}
    />
  )
}
