import React, {useEffect, useState} from 'react'
import {ModalDraggable} from "./ModalDraggable";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  List,
  message,
  notification,
  Row,
  Select,
  Space,
  Tooltip
} from "antd";
import apiRequest from "../general/apiRequest";
import {
  DeleteOutlined,
  FilterOutlined,
  GroupOutlined,
  LeftOutlined,
  MergeCellsOutlined,
  SaveOutlined,
  SubnodeOutlined
} from "@ant-design/icons";
import {SelectCatalog} from "../common/cmnSelectCatalog";
import {TreeSelectCatalog} from "../common/cmnTreeSelectCatalog";

import dayjs from 'dayjs';
import 'dayjs/locale/de';

import localeDE from 'antd/es/date-picker/locale/de_DE';

dayjs.locale('de');

const apiUrl = {
  filterGroupListIds:     "edgeMeta/filter",
  filterGroupShow:        "edgeMeta/filterShow",
  filterGroupItemList:    "propertyMeta/list",
  nodeFilterShow:         "nodeFilter",
  nodeFilterList:         "nodeFilter/list",
  nodeFilterSave:         "nodeFilter",
  nodeFilterCreate:       "nodeFilter",
//  nodeFilterDelete:       "/nodeFilter",
}



const ptNumber      =  "4ed7bc59-6879-4451-83f6-71af14ce3da8"
const ptInteger     =  "5016297f-bfbf-4006-b8d6-943cc520c178"
const ptBoolean     =  "b7c98497-7c9c-427f-a20b-5c1e8efa739b"
const ptDate        =  "de315736-b7b1-4a37-8b8d-232d249305d9"
const ptDateTime    =  "33eb39cd-5dc5-46e0-8fcb-d7eb63b06e65"
const ptString      =  "344b5fb4-81a3-4ec5-baf3-e2a1c77ecf5c"
const ptUID         =  "84075c53-4bf2-4a8b-a135-de68cc82612e"
const ptLookupList  =  "4b37b599-a06c-4a86-8baf-faa143c5019c"
const ptLookupTree  =  "49ebea75-3833-484a-9dfc-6c16b7f85b15"
const ptBlob        =  "4a63552d-fde0-4584-8b58-34a9bd1760cb"
const ptClob        =  "6fcd279b-8e1c-484a-93c4-3b3328f41fa5"

// const propertyTypes = {
//   [ptNumber]                  : ptNumber,     // asString:  "Number",      "Number"    ,
//   [ptInteger]                 : ptInteger,    // asString:  "Integer",     "Integer"   ,
//   [ptBoolean]                 : ptBoolean,    // asString:  "Boolean",     "Boolean"   ,
//   [ptDateTime]                : ptDateTime,   // asString:  "DateTime",    "DateTime"  ,
//   [ptDate]                    : ptDate,       // asString:  "Date",        "Date"      ,
//   [ptString]                  : ptString,     // asString:  "String",      "String"    ,
//   [ptUID]                     : ptUID,        // asString:  "UID",         "UID"       ,
//   [ptLookupList]              : ptLookupList, // asString:  "LOOKUPLIST",  "LOOKUPLIST",
//   [ptLookupTree]              : ptLookupTree, // asString:  "LOOKUPTREE",  "LOOKUPTREE",
//   [ptBlob]                    : ptBlob,       // asString:  "BLOB",        "BLOB"      ,
//   [ptClob]                    : ptClob,       // asString:  "CLOB",        "CLOB"      ,
// }

const ctEqual           = "6e6fc39b-23bf-4cc4-977d-d3877583fc2b"
const ctNotEqual        = "298a0e34-5892-4fcd-a768-370dd4e2b30a"
const ctLess            = "2b2650f1-7d6d-43f7-9e1f-b45a6e68363e"
const ctLessThan        = "131612df-eb25-4cd6-9958-51b817e0c8d5"
const ctGreater         = "37039204-016f-4e1e-9030-8768b0485047"
const ctGreaterThan     = "48e6c47d-d3ec-49ea-b7a8-d2ce45634e30"
const ctLike            = "cad5bec6-6bdb-4cb4-ba26-2ac33508c75c"
const ctBetween         = "790c60f1-d58a-4d1e-8335-3b2fae91b415"
const ctIn              = "d816660b-793f-47ef-9a05-b12d129a3be3"

const ctTypes = {
  [ctEqual]                    : {id: ctEqual,        sign: "="},
  [ctNotEqual]                 : {id: ctNotEqual,     sign: "!=" },
  [ctLess]                     : {id: ctLess,         sign: "<" },
  [ctLessThan]                 : {id: ctLessThan,     sign: "<=" },
  [ctGreater]                  : {id: ctGreater,      sign: ">"},
  [ctGreaterThan]              : {id: ctGreaterThan,  sign: ">="},
  [ctLike]                     : {id: ctLike,         sign: "like" },
  [ctBetween]                  : {id: ctBetween,      sign: "between"},
  [ctIn]                       : {id: ctIn,           sign: "in"},
}
const ptNumberComparator    = [ctTypes[ctEqual], ctTypes[ctNotEqual], ctTypes[ctLess], ctTypes[ctLessThan], ctTypes[ctGreater], ctTypes[ctGreaterThan], ctTypes[ctBetween],]
const ptIntegerComparator   = [...ptNumberComparator,]
const ptBooleanComparator   = [ctTypes[ctEqual], ctTypes[ctNotEqual],]
const ptDateTimeComparator  = [ctTypes[ctEqual], ctTypes[ctNotEqual], ctTypes[ctLess], ctTypes[ctLessThan], ctTypes[ctGreater], ctTypes[ctGreaterThan], ctTypes[ctBetween],]
const ptDateComparator      = [...ptDateTimeComparator,]
const ptStringComparator    = [ctTypes[ctEqual], ctTypes[ctNotEqual], ctTypes[ctLess], ctTypes[ctLessThan], ctTypes[ctGreater], ctTypes[ctGreaterThan], ctTypes[ctBetween],]
const ptUIDComparator       = [ctTypes[ctEqual], ctTypes[ctIn],]
const ptLookupListComparator     = [...ptUIDComparator,]
const ptLookupTreeComparator= [...ptUIDComparator,]
const ptBlobListComparator  = [...ptStringComparator,]
const ptClobTreeComparator  = [...ptStringComparator,]

const selectComparators = [
  {id: "6e6fc39b-23bf-4cc4-977d-d3877583fc2b", sign: "="        },
  {id: "298a0e34-5892-4fcd-a768-370dd4e2b30a", sign: "!="       },
  {id: "2b2650f1-7d6d-43f7-9e1f-b45a6e68363e", sign: "<"        },
  {id: "131612df-eb25-4cd6-9958-51b817e0c8d5", sign: "<="       },
  {id: "37039204-016f-4e1e-9030-8768b0485047", sign: ">"        },
  {id: "48e6c47d-d3ec-49ea-b7a8-d2ce45634e30", sign: ">="       },
  {id: "cad5bec6-6bdb-4cb4-ba26-2ac33508c75c", sign: "like"     },
  {id: "790c60f1-d58a-4d1e-8335-3b2fae91b415", sign: "between"  },
  {id: "d816660b-793f-47ef-9a05-b12d129a3be3", sign: "in"  },
]
const propComparator = {
  [ptNumber]:     ptNumberComparator,
  [ptInteger]:    ptIntegerComparator,
  [ptBoolean]:    ptBooleanComparator,
  [ptDate]:       ptDateTimeComparator,
  [ptDateTime]:   ptDateComparator,
  [ptString]:     ptStringComparator,
  [ptUID]:        ptUIDComparator,
  [ptLookupList]: ptLookupListComparator,
  [ptLookupTree]: ptLookupTreeComparator,
  [ptBlob]:       ptBlobListComparator,
  [ptClob]:       ptClobTreeComparator,
}

const defaultComparatorId = "6e6fc39b-23bf-4cc4-977d-d3877583fc2b"
const SelectComparators = ({comparators, ...props}) => {
  return <Select
    fieldNames={{label: "sign", value: "id"}}
    {...props}
    options={comparators ? comparators : selectComparators}
  />
}

const ChooseSelectFilter = (props) => {
  const [filters, setFilters] = useState([])
  const [searchVal, setSearchVal] = useState("")
  const {nodeMetaId, storeSaveValue: [storeValue, setStoreValue], selectedFilter, ...rest } = props
//  const [storeValue, setStoreValue] = storeSaveValue
  useEffect(() => {
    loadFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter])
  const loadFilters = async () => {
    const rd = await apiRequest.get(`${apiUrl.nodeFilterList}/${nodeMetaId}`)
    if (rd && rd.state && rd.data) setFilters(rd.data)
  }
  const handleSearch = (value) => setSearchVal(prevState =>  value)
  const handleDropdownVisibleChange = (value) => (!value)?setStoreValue(searchVal):null
  const handleFocus = () => (storeValue)?setSearchVal(storeValue):null
//  const so = (saveOption)?[...options, saveOption]:options
  return <Select
    onSearch={handleSearch}
    onDropdownVisibleChange={handleDropdownVisibleChange}

    onFocus={handleFocus}
    onChange={props.onChange}
    value={selectedFilter}
    style={{width: "100%"}}
    searchValue={searchVal}
    {...rest}
    showSearch
    placeholder={(storeValue)?storeValue:"Choose/New Name..."}
    defaultActiveFirstOption={false}
    notFoundContent={null}
    fieldNames={{value: "id", label: "name"}}
//    options={csfData}
    options={filters}
    />
}
const newGroupNode = () => {return {
  id: crypto.randomUUID(),
  group: true,
  type: "group",
  not: false,
  logicalOperator: "and",
  children: [],
}}
const newLeafNode = () => {return {
  id: crypto.randomUUID(),
  type: "item",
  not: false,
  comparatorId: defaultComparatorId,
  propertyTypeId: "",
  fieldTypeId: "",
  name: 'Leaf 5',
  value: [],
}}
const emptyFilterElements = {"allIds":[],"byId":{}}
export const ModalFilter = ({filtered = false, ...props}) => {
  const [itemIds, setItemIds] = useState([])
  const [visible, setVisible] = useState(false)
  const [grouped, setGrouped] = useState(true)
  const [selectedNode, setSelectedNode] = useState(null)
  const [filterElements, setFilterElements] = useState({...emptyFilterElements})
//  const [filterElements, setFilterElements] = useState(data1)
  const [saveValue, setSaveValue] = useState("")
  const [selectedFilter, setSelectedFilter] = useState(null)
  useEffect(() => {
    loadItemIds(props.nodeMeta)},
    [props.nodeMeta]
  )
  // const denormalizeFilter = () => {
  //   const addChildren = (node) => {
  //     if (node?.children && node.children.length > 0) {
  //       return node.children.map(filterId => {
  //         const filterElement = {...filterElements.byId[filterId]}
  //         const childs = addChildren(filterElement)
  //         delete filterElement['children']
  //         return (childs)?{...filterElement, childs: childs}:{...filterElement}
  //       })
  //     } else {
  //       return null
  //     }
  //   }
  //   if (filterElements.allIds.length > 0) {
  //     const df = filterElements.allIds.map(filterId => {
  //       const filterElement = {...filterElements.byId[filterId]}
  //       const childs = addChildren(filterElement)
  //       delete filterElement['children']
  //       return (childs)?{...filterElement, childs: childs}:{...filterElement}
  //     })
  //     return df
  //   } else return {empty: "not good"}
  // }
  // const normalizeFilter = (value) => {
  //   return (value)?value.name:"empty"
  // }
  const addNode = (parentNode, newNode) => {
    if (parentNode) {
      const newParentNode = (parentNode.children)
        ?{...parentNode, children: [...parentNode.children, newNode.id]}
        :{...parentNode, children: [newNode.id]}
      setFilterElements(prevState => {
        return {
          allIds: [...prevState.allIds],
          byId: {...prevState.byId, [newParentNode.id]: newParentNode, [newNode.id]: newNode}
        }
      })
    } else {
      setFilterElements(prevState => {
        return {
          allIds: [...prevState.allIds, newNode.id],
          byId: {...prevState.byId, [newNode.id]: newNode}
        }
      })
    }
  }
  const addLeaf = (parentNode/*Id*/, newNode) => {
    // const newParentNode1 = (parentNode.children)
    //   ?{...parentNode, children: [...parentNode.children, newNode.id]}
    //   :{...parentNode, children: [newNode.id]}
    const newParentNode = (parentNode.children)
      ?{...parentNode, children: [...parentNode.children, newNode.id]}
      :{...parentNode, children: [newNode.id]}
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [newParentNode.id]: newParentNode, [newNode.id]: newNode}}
    })
    setSelectedNode(newParentNode)
  }
  const handleGroup = () => setGrouped(prevState => !prevState)
  const loadItemIds = async (nodeMeta) => {
    if (nodeMeta) {
 //     const rd = await apiRequest.get(`${apiUrl.filterGroupListIds}/${binding}/${nodeMeta}`)
      const rd = await apiRequest.get(`${apiUrl.filterGroupListIds}/${nodeMeta}`)
      if (rd && rd.state && rd.data) setItemIds(rd.data)
    }
  }
  const loadFilter = async (filterId) => {
    if (filterId) {
      const rd = await apiRequest.get(`${apiUrl.nodeFilterShow}/${filterId}`)
      if (rd && rd.state && rd.data)
        setSelectedFilter(rd.data)
    }
  }
  const saveFilter = async () => {
    if (selectedFilter) {
      const saveData = {...selectedFilter, data: {...filterElements}}
      const rd = await apiRequest.put(apiUrl.nodeFilterSave, JSON.stringify(saveData))
      if (!(rd && rd.state)) message.warning("Filter Not Saved")
    } else if (saveValue) {
      const saveData = {active: true, name: saveValue, nodeId: props.nodeMeta, data: {...filterElements}}
      console.log(JSON.stringify(saveData))
      const rd = await apiRequest.post(apiUrl.nodeFilterCreate, JSON.stringify(saveData))
      if (rd && rd.state && rd.data)
        loadFilter(rd.data)
      else
        message.warning("Filter Not Saved")
    } else message.warning("Filter Not Saved")
  }
  const addFilterItem = (newLeaf) => {
    if (selectedNode) {
      addLeaf(selectedNode, newLeaf)
////      selectedNode.setChildren(newItem)
////      window.alert("Adding posible")
    } else
      notification.warning({
        message: "Filter nicht ausgewählt",
        description: "bitte rechts einen Filter auswählen!",
        placement: "top",
      })
  }
  const handleComparatorChange = (changedNode, newComparator) => {
    const newNode = {...changedNode, comparatorId: newComparator}
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [changedNode.id]: newNode}}
    })
  }
  const handleLogicalOperatorClick = (node/*Id*/) => {
    const changeNode = node // filterElements.byId[nodeId]
    const newNode = {...changeNode, logicalOperator: (changeNode.logicalOperator === "and"?"or":"and")}
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [changeNode.id]: newNode}}
    })
  }
  const handleNot = (node) => {
    const newNode = {...node, not: !node.not}
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [node.id]: newNode}}
    })
  }
  const handleChooseFilterChange = (value, option) => {
    setSelectedFilter(option)
    setFilterElements((option)?option.data:{...emptyFilterElements})
    setSelectedNode(prevState => null)
//    message.info("Filter Name: " + normalizeFilter(option), 5)
//    message.info(/*"Filter Change: Value " + value + */"Option: " + JSON.stringify(option), 5)
  }
  // const handleSaveFirstDate1 = (date, dateString) => {
  //   return dayjs(new (Date("2020-12-25")))
  // }
  const handleSaveFirstValue = (node, value) => {
    const newValues = [...node.value]
    newValues[0] = value
    const newNode = {...node, value: newValues }
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [node.id]: newNode}}
    })
  }
  // const handleSaveSecondValue = (node, value) => {
  //   const newValues = [...node.value]
  //   newValues[1] = value
  //   const newNode = {...node, value: newValues }
  //   setFilterElements(prevState => {
  //     return {allIds: prevState.allIds, byId: {...prevState.byId, [node.id]: newNode}}
  //   })
  // }
  const handleSaveListValue = (node, value) => {
//    const newValues =(value.isArray)?[...value]:[value]
    const newValues = Array.isArray(value)?[...value]:[value]
    const newNode = {...node, value: newValues }
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [node.id]: newNode}}
    })
  }
  const dayjs2utcISOString = (dDate) => (dDate) ? dDate.utc().toISOString() : null
  const utcISOString2dayjs = (dString) => dayjs.utc(dString)
  const getFirstDate = (node) => {
    const values = filterElements.byId[node.id].value
    return (values.length > 0 && values[0])
      ? utcISOString2dayjs(values[0])
      : null
  }
  const getSecondDate = (node) => {
    const values = filterElements.byId[node.id].value
    return (values.length > 1 && values[1])
      ? utcISOString2dayjs(values[1])
      : null
  }
  const handleSaveBeginDate = (node, date, dateString) => {
    const newDate = [...node.value]
    newDate[0] = (date)
        ? date.utc().startOf('d').toISOString()
        :null
    const newNode = {...node, value: newDate }
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [node.id]: newNode}}
    })
  }
  const handleSaveSecondDate = (node, date, dateString) => {
    const newDate = [...node.value]
    newDate[1] = dayjs2utcISOString(date.endOf('d'))
    const newNode = {...node, value: newDate }
    setFilterElements(prevState => {
      return {allIds: prevState.allIds, byId: {...prevState.byId, [node.id]: newNode}}
    })
  }
  // const getFirstValue = (node) => filterElements.byId[node.id].value[0]
  // const getSecondValue = (node) => filterElements.byId[node.id].value[1]
  const getListValue = (node, single) => {
    const value = filterElements.byId[node.id].value
    if (single)
      return (value.length >= 0)?value:""
    else
      return value
  }
  const renderGroupNode = ({node}) => {
    const className = "ces-filter-group" + ((selectedNode?.id === node.id)?" ces-filter-selected":"")
    return <>
{/*
      <Row><Col span={24}><Button style={{width: "100%", height: "3px", backgroundColor: "red"}}>-</Button></Col></Row>
*/}
      <Row className={className} >
        <Col span={24} style={{height: "5px"}}>
          <Button
            style={{width: "100%", height: "5px", backgroundColor: (node.not)?"black":"initial", }}
            onClick={() => {handleNot(node)}}
          >&nbsp;</Button>
        </Col>
        <Col
          onClick={ () => {setSelectedNode(prevState => (prevState === node)?null:node)}}
          span={20}
        >
          {/*node.name*/}
        </Col>
      <Col span={3} style={{textAlign: "right"}}>
        <span
          style={{backgroundColor: "gold", padding: "0 .3em .1em", borderRadius: ".4em", cursor: "pointer" }}
          onClick={() => {handleLogicalOperatorClick(node)}}
        >{node.logicalOperator}</span>
      </Col>
        {(node.children && node.children.length > 0)
          ?node.children.map(item => {
            const nodeItem = filterElements.byId[item]
            return (nodeItem.group)
              ?renderGroupNode({node: nodeItem})
              :renderItemNode({node: nodeItem})
          })
          :null}
      </Row>
    </>
  }
  const renderSearchValueInput = (node) => {
    const renderInput = (node) => {
      switch (node.propertyTypeId) {
        case ptNumber:
        case ptInteger: return <InputNumber />
        case ptBoolean: return <Checkbox />
        case ptDateTime:
        case ptDate:
          if (node.comparatorId !== ctBetween) {
            const aVal = getFirstDate(node)
            return <DatePicker
              placeholder={"Datum auswählen"}
              value={aVal}
              defaultPickerValue={() => dayjs.utc(new Date()).startOf('d')}
              format={"DD.MM.YYYY"}
              onChange={(date, dateString) => {handleSaveBeginDate(node, date, dateString)}}
              style={{width: "100%"}}
              inputReadOnly={true}
              local={localeDE}
            />
          }
           else {
            const aVal = getFirstDate(node)
            const aVal2 = getSecondDate(node)
            return <>
              <DatePicker
                placeholder={"Start auswählen"}
                value={aVal}
                defaultPickerValue={() => dayjs.utc(new Date()).startOf('d')}
                format={"DD.MM.YYYY"}
                onChange={(date, dateString) => {handleSaveBeginDate(node, date, dateString)}}
                style={{width: "100%"}}
                inputReadOnly={true}
                local={localeDE}
              />
              <DatePicker
                placeholder={"Ende auswählen"}
                defaultPickerValue={dayjs.utc(new Date()).startOf('d').add(24,'h')}
                value={aVal2}
                format={"DD.MM.YYYY"}
                onChange={(date, dateString) => {handleSaveSecondDate(node, date, dateString)}}
                style={{width: "100%"}}
                inputReadOnly={true}
                local={localeDE}
              />
            </>
          }
//        case ptLookupList: return <Select style={{width: "100%"}} multi={(node.comparatorId=== ctIn)?"multi":null} />
        case ptLookupList:
          return <SelectCatalog
            catalogGroup={node.catalogId}
            style={{width: "100%"}}
            mode={(node.comparatorId === ctIn)?"multiple":null}
//            onChange={(value) => handleSaveFirstValue(node, value)}
            onChange={(value) => (node.comparatorId === ctIn)?handleSaveListValue(node, value):handleSaveFirstValue(node, value)}
            value={getListValue(node, node.comparatorId === ctIn)}
          />
        case ptLookupTree:
          return <TreeSelectCatalog
            catalogTree={node.catalogId}
            style={{width: "100%"}}
            onChange={(value) => handleSaveFirstValue(node, value)}
            value={getListValue(node)}
          />
        case ptString: return <Input />
        default: return <Input />
      }
    }
    switch (node.comparatorId) {
      case ctIn: return renderInput(node)
      case ctBetween: return renderInput(node)
      default: return renderInput(node)
    }
  }
  const renderItemNode = ({node}) => <Row
    className={"ces-filter-item"}
  >
    <Col span={24} style={{height: "5px"}}>
      <Button
        style={{width: "100%", height: "5px", backgroundColor: (node.not)?"black":"initial", }}
        onClick={() => {handleNot(node)}}
      >&nbsp;</Button>
    </Col>
    <Col span={9} className={"ces-filter-item-name"} >{node.name}</Col>
    <Col span={4}>
      <SelectComparators
        value={node.comparatorId}
        defaultValue={defaultComparatorId}
        onChange={(value) => {handleComparatorChange(node, value)}}
        placeholder="choose"
        suffixIcon={null}
        // showArrow={false}
        popupMatchSelectWidth={false}
        style={{width: "100%", textAlign: "center"}}
        comparators={propComparator[node.propertyTypeId]}
//        comparators={selectComparators}
      />
    </Col>
    <Col span={11}>{renderSearchValueInput(node)}</Col>
  </Row>
  const render = () => {
    return filterElements.allIds.map((nodeId) => {
      const node = filterElements.byId[nodeId]
      return (node.group)?renderGroupNode({node}):renderItemNode({node})
    })
  }
//  const buttonColor = (filtered)?{color: "green",}:{}
  return <>
    <Tooltip title={"Filter"} color={'cyan'} placement="topRight" >
      <Button
        type={"link"}
        icon={<FilterOutlined style={{fontSize: "2em", ...(filtered)?{color: "green",}:{}, }} />}
        onClick={() => {setVisible(true)}}
      >{props.buttonText}</Button>
    </Tooltip>
    {visible
      ?<ModalDraggable
        titleName="Filter"
        width={"720px"}
//        width={"1040px"}
        style={{ top: 20, left: 50}}
//        footer={null}
        open={visible}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => {setVisible(false)}}
        onOk={() => {
          setVisible(false);
          if (props.onFilter) props.onFilter({...filterElements})
        }}
//        onOk={() => {props.doCatalogVisible(!props.visible)}}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              className={"cses-filter-container"}
              title={<ChooseSelectFilter
                nodeMetaId={props.nodeMeta}
                storeSaveValue={[saveValue, setSaveValue]}
                allowClear
                autoClearSearchValue={false}
                style={{width: "100%"}}
                selectedFilter={selectedFilter?.id/*(selectedFilter)?selectedFilter.id:null*/}
                onChange={handleChooseFilterChange}
              />}
              extra={<Space size={0}>
                  <Tooltip title={"Filter speichern"}>
                    <Button
                      type={"text"}
                      icon={<SaveOutlined />}
                      onClick={saveFilter}
                      disabled={(!(saveValue || selectedFilter))}
                    />
                  </Tooltip>
                  <Tooltip title={"Filter löschen"}>
                    <Button
                      type={"text"}
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setFilterElements(prevState => {return {...emptyFilterElements}})
                        setSelectedNode(prevState => null)
                    }}
                    />
                  </Tooltip>
                  <Tooltip title={"Filter hinzufügen"}>
                    <Button type={"text"} icon={<SubnodeOutlined />} onClick={() => {addNode(selectedNode, newGroupNode())}}
                    />
                  </Tooltip>
                </Space>}
            >
              {render()}
            </Card>
          </Col>
{/*
//TODO rest of the filter search
          <Col span={8}>
          </Col>
*/}
          <Col span={12}>
            <Card
              title={"NodeMeta"}
              className={"cses-filter-container"}
              extra={(grouped)
                ?<Button type={"text"} icon={<MergeCellsOutlined rotate={90} />} onClick={handleGroup} />
                :<Button type={"text"}  icon={<GroupOutlined />} onClick={handleGroup} />
            }
            >
              {itemIds.map(itemId => {
                return <FilterGroupPick
                  addFilterItem={addFilterItem}
                  filterGroupId={itemId}
                  grouped={grouped}
                />
              })}
            </Card>
          </Col>
        </Row>
      </ModalDraggable>
      :null
    }
  </>

}

const FilterGroupPick = (props) => {
  const [filterGroup, setFilterGroup] = useState({})
  const [filterGroupItems, setFilterGroupItems] = useState([])
  useEffect(() => {
    loadFilterGroup(props.filterGroupId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loadFilterGroup = async (filterGroupId) => {
    if (filterGroupId) {
      const rd = await apiRequest.get(`${apiUrl.filterGroupShow}/${filterGroupId}`)
      if (rd && rd.state && rd.data) {
        setFilterGroup(rd.data)
        const rd1 = await apiRequest.get(`${apiUrl.filterGroupItemList}/${rd.data.targetNodeMetaId}`)
        if (rd1 && rd1.state && rd1.data) setFilterGroupItems(rd1.data.map(item => {return {edgeId: filterGroupId, ...item, }}))
      }
    }
  }
  const addToSelected = (values) => {
    if (props.addFilterItem)
      props.addFilterItem({...newLeafNode(), ...values })
  }
  return <Card
    title={(!props.grouped)?filterGroup.name:null}
    type={"inner"}
    className={"cses-filter-card-inner"}
    bordered={false}
  >
    <List
      className="cses-filter-list"
      itemLayout="vertical"
      dataSource={filterGroupItems}
      locale={{emptyText: "No Data"}}
      rowKey="id"
      renderItem={(item) => <List.Item
        key={item.id}
        style={{margin: 0, padding: 0}}
        extra={
        <Button
          type={"text"}
          icon={<LeftOutlined />}
          onClick={() => addToSelected({edgeMetaId: item.edgeId, edgeId: item.edgeId, propertyMetaId: item.id, name: item.name, propertyTypeId: item.propertyTypeId, fieldTypeId: item.propertyTypeId, physicalName: item.physicalName, catalogId: item.catalogId,})}
        />}
      >{item.name}</List.Item>}
    />
  </Card>
}

ModalFilter.defaultProps = {
  open: false,
}