import React from 'react';
import { Modal } from 'antd'
import {useDispatch, useSelector} from 'react-redux';
import {hide} from "./msgUnauthorizedSlice"
import {ModalLogin} from "../dialog/modalLogin";

export const MessageUnauthorized = (props) => {
  const apiMsg = useSelector(state => state.ui.messages.unauthorized)
  const dispatch = useDispatch()
  return <Modal
    title={null}
    closable={false}
    footer={null}
    open={apiMsg.visible}
    onCancel={() => {dispatch(hide())}}
    onOk={() => {dispatch(hide())}}
    maskClosable={false}
    width={420}
    zIndex={10000}
    style={{borderTop: ".2em solid #1890ff"/*, borderRadius: "2em 2em 0 0",*/}}
    // maskStyle={(apiMsg.status)?{backdropFilter: "blur(10px)",}:{}}
    // // bodyStyle={{borderRadius: "2em 2em 0 0",/*padding: 0, margin: "24px",*/}}
    // bodyStyle={{padding: 0,}}
    styles={{body: {padding: 0,}, mask:(apiMsg.status)?{backdropFilter: "blur(10px)",}:{}}}
    >
      <ModalLogin handleClose={() => dispatch(hide())} routeTo={null} />
    </Modal>
}
