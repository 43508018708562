/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 13.01.2024
 *
 * Project: csescaclient
 *
 * index.js in package  (project: csescaclient ) © 13.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */

import {registerComponent} from "../../core/BreadcrumbService/BreadcrumRegistry";
import {BC_RAVE_DEFAULT, BC_RAVE_ROOT, BC_RAVE_SHARED, BC_RAVE_TEMPLATE} from "./const.js";
import {MainBreadcrumb} from "./MainBreadcrumb";
import {RootBreadcrumb} from "./RootBreadcrumb";
import {SharedBreadcrumb} from "./SharedBreadcrumb";
import {TemplateBreadcrumb} from "./TemplateBreadcrumb";

registerComponent(BC_RAVE_DEFAULT, MainBreadcrumb)
registerComponent(BC_RAVE_ROOT, RootBreadcrumb)
registerComponent(BC_RAVE_SHARED, SharedBreadcrumb)
registerComponent(BC_RAVE_TEMPLATE, TemplateBreadcrumb)

export {BC_RAVE_DEFAULT, BC_RAVE_ROOT, BC_RAVE_SHARED, BC_RAVE_TEMPLATE, MainBreadcrumb, RootBreadcrumb, SharedBreadcrumb}
export {getRaveBreadcrumbRoot, getRaveBreadcrumbType} from "./functions.js"