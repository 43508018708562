import React, {useEffect, useState} from 'react'
import { ImportOutlined, PlusOutlined} from "@ant-design/icons";
import {ModalBreakdownChoose} from "../template/modalBreakdownChoose";
import apiRequest from "../../general/apiRequest";
import {
  tN,
  canShowAddStandardButton,
  canShowAddTemplateButton,
  isLigniteTenant
} from "../../system/checkTenants";
import {Button, Space, Tooltip} from "antd";
import {ModalFilter} from "../../dialog/modalFilter";
import {getNodeMetaId} from "../../general/Entities";
import useCredentials from "../../system/UseCredentials";
import {sysRight} from "../../common/cmnRights";

const apiUrlBreakdownRight                = "/breakdown/rights"
// const apiUrlBreakdownCreate               = "/breakdown"
// const apiUrlBreakdownCreateFromTemplate   = "/breakdown/createFromTemplate"

export const BreakdownListMenu = ({create, filtered, isTemplate = false, parentId, projectId, ...props}) => {
  const [chooseOpen, setChooseOpen] = useState(false)
  const [rights, setRights] = useState([]);
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  useEffect (() => {
    getRights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId])
  const getRights = () => {
    apiRequest.get(apiUrlBreakdownRight + "/" + parentId)
      .then((rd) => {
        if (rd && rd.state && rd.rights) setRights(rd.rights)
      })
      .catch(error => console.log("getRights in BreakdownListMenu: " + error))
  }
  const createFromTemplate = (selectedTemplateId) => {
    if (selectedTemplateId) create({
      newData: null,
      urlExtension: `createFromTemplate`,
      urlParams: `sourceBreakdownId=${selectedTemplateId}&newParentId=${parentId}&newProjectId=${projectId}`,
      doNavigate: true,
    })
    setChooseOpen(false)
  }
  return <Space>
    {(isLigniteTenant(tenantId))
      ?<ModalFilter
        filtered={filtered}
        nodeMeta={getNodeMetaId("breakdown")}
        onFilter={props.onFilter}
      />
      :null
    }
    {canShowAddStandardButton(isTemplate, tenantId)
      ?<Tooltip title={tN(tenantId, "neue Struktur")} color={'cyan'} placement="topRight">
        <Button
          onClick={() => create({
            newData: null,
            urlExtension: null,
            urlParams: `projectId=${projectId}&parentId=${parentId}&isTemplate=${isTemplate}`,
            doNavigate: true,
          })}
          type={"link"}
          disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
          icon={<PlusOutlined style={{fontSize: "2em"}} />}
        />
      </Tooltip>
      :null}
    {(canShowAddTemplateButton(isTemplate, tenantId) && projectId)
      ?<>
        <Tooltip title={tN(tenantId, "neue Struktur aus Template...")} color={'cyan'} placement="topRight">
          <Button
            onClick={() => setChooseOpen(true)}
            type={"link"}
            disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
            icon={<ImportOutlined style={{fontSize: "2em"}} />}
          />
        </Tooltip>
      {(chooseOpen)?
        <ModalBreakdownChoose
          parentId={tenantId}
          // parentId={parentId}
          open={chooseOpen}
          onCancel={() => setChooseOpen(false)}
          onOk={(value) => createFromTemplate(value.selectedRowKeys)}
        />
        :null}
      </>
      :null
    }
  </Space>
}