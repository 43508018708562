import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

//const routeRoleList                 = "/api/role/list"
const apiUrlRoleList                 = "/role/list"

export const SelectRole = ({allowClear, onBlur, onChange, readOnly = false, ...props}) => {
  const [roles, setRoles] = useState([])
  useEffect(() => {
    loadRole()
  }, [])
  const loadRole = () => {
    apiRequest.get(apiUrlRoleList)
      .then((rd) => {
        setRoles(rd.data)
      })
      .catch(error => console.log("loadRole in SelectRole: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={roles}
  />
}
/*   {roles.map(role => (<SelectOption key={role.id} value={role.id} >{role.name}</SelectOption>))}
  </Select>
*/
