/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 13.01.2024
 *
 * Project: csescaclient
 *
 * UtilsBreadcrumb in package  (project: csescaclient ) © 13.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */

export const BC_RAVE_DEFAULT  = "bcRaveDefault"
export const BC_RAVE_ROOT  = "bcRaveRoot"
export const BC_RAVE_SHARED   = "bcRaveShared"
export const BC_RAVE_TEMPLATE = "bcRaveTemplate"

