import React from 'react';
import {Link} from 'react-router-dom'
import {Card} from "antd";

export const DefaultSite = (props) => {
  return (
    <Card
      title={<div>
          <div className={"login-header"}>
            <div className={"logo128"}/>
            <div className={"logo-tenant"}>
              <h2 className={"logo-tenant"} > </h2>
            </div>
          </div>
        </div>
      }
    >
      <div className="login-box">
        <div className="login-box-msg" title="Login">zum Login</div>
        <div style={{textAlign: "center"}}>
          <Link to="/login" >zum Login</Link>
        </div>
      </div>
    </Card>
  )
}