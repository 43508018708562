import React from 'react';
import { Modal } from 'antd'
import {useDispatch, useSelector} from 'react-redux';
import {hide} from "./msgForbiddenSlice"
import {ModalLogin} from "../dialog/modalLogin";

export const MessageForbidden = (props) => {
  const apiMsg = useSelector(state => state.ui.messages.forbidden)
  const dispatch = useDispatch()
    return <Modal
      className="un-auth"
      title={null}
      open={apiMsg.visible}
      onCancel={() => {dispatch(hide())}}
      onOk={() => {dispatch(hide())}}
      maskClosable={false}
 //     maskStyle={(apiMsg.status)?{backdropFilter: "blur(10px)",}:{}}
      styles={{mask:(apiMsg.status)?{backdropFilter: "blur(10px)",}:{}}}
      zIndex={10000}
    >
      <ModalLogin handleClose={() => dispatch(hide())} routeTo={null} showRole={true} />
    </Modal>
}
