/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 02.06.2023
 *
 * Project: csescaclient
 *
 * rascalApiDef in package  (project: csescaclient ) © 02.06.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */

export const apiGet        = "core/get"
export const apiSave       = "core/save"
export const apiCreate     = "core/create"
export const apiDelete     = "core/delete"
export const apiRight      = "core/right"
export const apiList       = "core/list"

export const nodeProject        = "63bbfd74-ac46-4f52-9599-71e41ffcd7cc"
export const nodeBreakdown      = "e5ef1268-ea3b-4e04-ad9c-7f7f91aa6282"
export const nodeRoom           = "fc1a5bd0-9b05-404d-95ff-a40c923b924c"
export const nodeWorkpiece      = "071779d8-89c8-423a-b242-5fc2763d4c19"


export const covImgFunc = "a07a37ff-33b3-44c8-8ebc-31c3aa2c2ff3"
export const mapImgFunc = "15b079fa-ccda-4f6f-953b-6543c4d4d949"

export const raveDamageImgFunc = "986b2f62-2e04-4413-80e8-0df37ea92df9"

export const layout = {
  xs: {span: 24},
  sm: {span: 24},
  md: {span: 24},
  lg: {span: 24},
  xl: {span: 12},
  xxl: {span: 12},
}
export const layoutForm = {
  span: 12,
  xs: {span: 24},
  sm: {span: 24},
  md: {span: 24},
  lg: {span: 12},
  xl: {span: 12},
  xxl: {span: 12},
}
export const layoutImg1 = {
  span: 4,
  xs: {span: 24},
  sm: {span: 20},
  md: {span: 16},
  lg: {span: 10},
  xl: {span: 6},
  xxl: {span: 4},
}
export const layoutImg2 = {
  ...layoutImg1
  // span: 4,
  // xs: {span: 24},
  // sm: {span: 20},
  // md: {span: 16},
  // lg: {span: 10},
  // xl: {span: 6},
  // xxl: {span: 4},
}
