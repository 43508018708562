import React, {useContext, useEffect, useRef, useState} from 'react';
import {Table, Card, Space} from "antd";
import Highlighter from "react-highlight-words";
import {useNavigate, useSearchParams} from "react-router-dom";
import {sysRight} from "../../common/cmnRights";
import {BreakdownListMenu} from "./breakdownListMenu"
import apiRequest from "../../general/apiRequest";
import {isLigniteTenant, tN} from "../../system/checkTenants";
import {covImgFunc, mapImgFunc} from "../rascalApiDef";
import {TitleImage} from "../../images/imageMapHover";
import {useFetchList} from "../../core/Fetch/useFetchList";
import {columnSearchProps} from "../../util/ColumnSearchProps/columnSearchProps";
import {raveBreakdownMeta} from "../../rave/Breakdown/BreakdownMeta";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {BC_RAVE_TEMPLATE} from "../../rave";
import {parentTypes} from "../../panel/panelDndContainer";
import {mrpNodeMetaId} from "../../general/Entities";
import {useRaveItemMenu} from "../../rave/Menu/RaveItemMenu";
import {RowDropMenu} from "../../core/RowDropMenu";
import {ContextMenu} from "../../core/ContextMenu";

const apiUrlBreakdownRight       = "/breakdown/rights"
// const apiUrlBreakdownList        = "/breakdown/byParent"
// const apiUrlBreakdownCreate      = "/breakdown"
// const apiUrlBreakdownDelete      = "/breakdown"
// const apiUrlCreateRoom           = "/room/create"
// const apiUrlBreakdownFilter      = "/breakdown/filter"

const elementMeta = raveBreakdownMeta

export const BreakdownList = ({isTemplate = false, workId, parentType, ...props}) => {
  const [ , setApiState] = useContext(CsescaContext)
  const [params] = useSearchParams()
  const isTemplateInt = (params?.isTemplate)?true:isTemplate
  const navigation = useNavigate()
  const [filter, setFilter] = useState(null)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [contextMenuState, setContextMenuState] = useState({open: false, pos: {x: 0, y: 0}})
  const [menuList, buildMenuList] = useRaveItemMenu()
  const fetchParams = (!isTemplateInt)
    ?{referenceId: workId, filter,}
    :{referenceId: workId, filter, queryParams: `isTemplate=${isTemplateInt}`,}
//  const [breakdowns, setBreakdowns] = useState([])
  const searchInput = useRef(null);
  // const {data: breakdowns, /*revalidate: loadBreakdowns, remove,*/ create} = useFetchList(
  const fetchList = useFetchList(
    elementMeta,
    fetchParams,
  )
  useEffect(() => {
    if (isTemplateInt && parentType === parentTypes.tenant) setApiState(prevState => { return {
      ...prevState,
      breadcrumbType: BC_RAVE_TEMPLATE,
      entityName: "breakdownList",
      pageType: "rave",
      entityId: null,
      state: "done",
      breadCrumbList: [],
    }})
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentType]);
  const filterObjects =  {
    // refSearch: searchInput,
    refInput: searchInput,
    doSearch:(selectedKeys, confirm, dataIndex) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    },
    doReset:(clearFilters, confirm) => {
      clearFilters()
      confirm()
      setSearchText('')
    },
    doRender:(text, dataIndex) => searchedColumn === dataIndex
      ? <Highlighter
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
      : text,
  }
  const duplicateBreakdown = (deepCopy) => {
    fetchList.create({
      newData: null,
      urlExtension: `duplicate/${deepCopy.sourceId}`,
      urlParams: `isTemplate=${isTemplate}`,
      doNavigate: true,
    })
    // apiRequest.post(`${apiUrlBreakdownDuplicate}/${props.itemId}?isTemplate=${isTemplate}`)
    //   .then((rd) => {
    //     if (rd && rd.state) navigation("/ras/breakdown/" + rd.data.id)
    //   })
    //   .catch(error => console.log("duplicateBreakdown in BreakdownListItemMenu: " + error))
  }
  const handleDoubleClick = (value) => {
    apiRequest.get(apiUrlBreakdownRight + "/" + value)
      .then((rd) => {
//    if (rd && rd.state && rd.rights && (rd.rights.indexOf(sysRight.READ) >= 0))
        if (rd && rd.state && rd.rights && (rd.rights.indexOf(sysRight.READ) >= 0)) navigation("/ras/breakdown/" + value)
      })
      .catch(error => console.log("handleDoubleClick in BreakdownList: " + error))
  }
  const handleFilter = (filter) => setFilter(filter)
  const buildImageDef = (record, imgFunction) => ({covImg: {refererId: record.id, projectId: record.projectId,}, rightsParent: [], parentId: record.id, imgFunction})
  const newCols = () => {
    const cols = [
      { title: "", width: 1, },
      { title: "Bild", dataIndex: "mediaId", width: 160,
//        render: (data, lineItem) => <TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.projectId,}} rightsParent={[]} parentId={lineItem.id} imgFunction={covImgFunc} />
        render: (_, record) => <TitleImage {...buildImageDef(record, covImgFunc)} />,
      },
      !isLigniteTenant(props.tenantId)
        ?{ title: "Flurplan", dataIndex: "mediaId", width: 160,
//          render: (data, lineItem) => <TitleImage covImg={{refererId: lineItem.id, projectId: lineItem.projectId,}} rightsParent={[]} parentId={lineItem.id} imgFunction={mapImgFunc} />
          render: (_, record) => <TitleImage {...buildImageDef(record, mapImgFunc)} />,
        }:null,
      {...columnSearchProps(tN(props.tenantId,"Struktur"), "name", filterObjects), },
      {...columnSearchProps(tN(props.tenantId,"Struktur") + "nr.", "codeNumber", filterObjects), },
      {...columnSearchProps(tN(props.tenantId,"Bau Beginn") + "nr.", "constrYearBeginText", filterObjects), },
      {...columnSearchProps(tN(props.tenantId,"Bau Ende") + "nr.", "constrYearEndText", filterObjects), },
      { title: ""},
      { title: "Bearbeiten", dataIndex: "id", width: 150, render: (_, record) =>
          <RowDropMenu contextMenuInfo={buildMenuListDefinition(record)} />
      }
    ]
    return cols.filter((items) => items !== null)
  }
  const buildMenuListDefinition = (record) => ({doCopy: duplicateBreakdown, id: record.id, name: record.name, fetchList, nodeMetaId: mrpNodeMetaId["breakdown"], tenantId: record.tenantId})
  const handleContextMenu = (event, record) => {
    event.preventDefault()
    if (!contextMenuState.open) {
      document.addEventListener('click', function onClickOutside() {
        setContextMenuState(prevState => ({open: false, pos: prevState.pos}))
        document.removeEventListener(`click`, onClickOutside)
      })
    }
    buildMenuList(buildMenuListDefinition(record))
    setContextMenuState({open: true, pos: {x: event.clientX, y: event.clientY}})
  }
  const onRow= (record) => ({
    onDoubleClick: () => {handleDoubleClick(record.id)},
    onContextMenu: (event) =>  handleContextMenu(event, record)
  })
  return <Card extra={<Space>
    <BreakdownListMenu
      filtered={!!filter}
      isTemplate={isTemplateInt}
      projectId={props.projectId}
      parentId={workId}
      onFilter={handleFilter}
      create={fetchList.create}
    />
  </Space>}
  >
    <ContextMenu items={menuList} {...contextMenuState}/>
    <Table
      className={"ces-rave-table"}
      pagination={{position: ['topRight', 'bottomRight'],}}
      dataSource={fetchList.data}
      onRow={onRow}
      columns={newCols()}
      rowKey={record => record.id}/>
  </Card>
}
