import React, {useState} from 'react'
import {Button, Form, Input, Space} from "antd"
import apiRequest from "../general/apiRequest"
import {ModalDraggable} from "./ModalDraggable";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const apiUrlChangePassword     = "/changePassword"
const initialChangePassword = {oldPassword: "", newPassword: "", newPasswordRepeat: ""}
const initialShowPassword = {oldPassword: false, newPassword: false, newPasswordRepeat: false}

export const ModalChangePassword = ({open, doOpen, visible, doVisible, ...props}) => {
  const [changePassword, setChangePassword] = useState({...initialChangePassword})
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [showPassword, setShowPasswords] = useState( {...initialShowPassword})
  const saveChangePassword = () => {
    apiRequest.post(apiUrlChangePassword, JSON.stringify(changePassword))
      .then(rd => {
        if (rd && rd.state) setPasswordChanged(_ => true)
      })
      .catch(error =>  console.log("Error in Change Password: " + error))
  }
//  const saveChangePasswordTest = () => setPasswordChanged(_ => true)
  const doClose = () => {
    setChangePassword(_ => {return {...initialChangePassword}})
    setPasswordChanged(_ => false)
    setShowPasswords(_ =>{return {...initialShowPassword}})
    doOpen(false)
    // doVisible(false)
  }
  const handleChange = (evt) => setChangePassword(prevState => {return {...prevState, [evt.target.name]: evt.target.value}})
  const changeShowPassword = (elemName) => {
    setShowPasswords(prevState => {
      const newVal = prevState[elemName]
      return {...prevState, [elemName]: !newVal}})
  }
  return ((visible)
    ?<ModalDraggable
      titleName="Password ändern"
      width={"25em"}
      style={{ top: 20, left: 50}}
      footer={null}
      open={visible}
      onCancel={() => {doOpen(!visible)}}
      // onCancel={() => {doVisible(!visible)}}
      onOk={() => {doOpen(!visible)}}
      // onOk={() => {doVisible(!visible)}}
      maskClosable={false}
    >{!passwordChanged
      ?<>
        <div className="login-box">
          <Form name="change-password-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            style={{marginTop: 10}}
          >
            <Form.Item label="altes Password" name="oldPassword" style={{margin: ".3em"}}>
              <Input
                name="oldPassword"
                value={changePassword.oldPassword}
//                placeholder="altes Passwort"
                onChange={handleChange}
                style={(changePassword.oldPassword.length > 0 && !showPassword.oldPassword)?{
                  textSecurity: "disc",
                  WebkitTextSecurity: "disc",
                  MozTextSecurity:"disc",
                  margin: "5px 5px",
                }:{margin: "5px 5px",}}
                suffix={
                  (!showPassword.oldPassword)
                    ?<EyeOutlined onClick={() => {changeShowPassword("oldPassword")}} />
                    :<EyeInvisibleOutlined onClick={() => {changeShowPassword("oldPassword")}} />
                }
              />
            </Form.Item>
            <Form.Item
              label="neues Password"
              name="newPassword"
              style={{margin: ".3em"}}
              validateStatus={(changePassword.oldPassword.length > 0 && changePassword.oldPassword === changePassword.newPassword)?"error":""}
              help={(changePassword.oldPassword.length > 0 && changePassword.oldPassword === changePassword.newPassword)
                ?"gleich wie altes Passwort"
                :""
              }
            >
              <Input
                name="newPassword"
                value={changePassword.newPassword}
//                placeholder="neues Passwort"
                onChange={handleChange}
                style={(!showPassword.newPassword)?{
                  textSecurity: "disc",
                  WebkitTextSecurity: "disc",
                  MozTextSecurity:"disc",
                  margin: "5px 5px",
                }:{margin: "5px 5px",}}
                suffix={
                  (!showPassword.newPassword)
                    ?<EyeOutlined onClick={() => {changeShowPassword("newPassword")}} />
                    :<EyeInvisibleOutlined onClick={() => {changeShowPassword("newPassword")}} />
                }
              />
            </Form.Item>
            <Form.Item
              label="wiederholen"
              name="newPasswordRepeat"
              style={{margin: ".3em"}}
              validateStatus={(changePassword.newPasswordRepeat.length >= changePassword.newPassword && changePassword.newPassword !== changePassword.newPasswordRepeat)?"error":""}
              help={(changePassword.newPasswordRepeat.length >= changePassword.newPassword && changePassword.newPassword !== changePassword.newPasswordRepeat)
                ?"unterschieliche Passwörter"
                :""
              }
            >
              <Input
                name="newPasswordRepeat"
                value={changePassword.newPasswordRepeat}
//                placeholder="wiederholen"
                onChange={handleChange}
                style={(!showPassword.newPasswordRepeat)?{
                  textSecurity: "disc",
                  WebkitTextSecurity: "disc",
                  MozTextSecurity:"disc",
                  margin: "5px 5px",
                }:{margin: "5px 5px",}}
                suffix={
                  (!showPassword.newPasswordRepeat)
                  ?<EyeOutlined onClick={() => {changeShowPassword("newPasswordRepeat")}} />
                  :<EyeInvisibleOutlined onClick={() => {changeShowPassword("newPasswordRepeat")}} />
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div style={{textAlign:"right", marginTop: ".3em"}}>
          <Space>
            <Button type="primary" shape="round" onClick={saveChangePassword}>Speichern</Button>
          </Space>
        </div>
      </>
      :<>
        <div className="login-box">
          Password wurde geändert
        </div>
        <div style={{textAlign:"right", marginTop: ".3em"}}>
          <Space>
            <Button type="primary" shape="round" onClick={()=> {doClose(false)}}>Schließen</Button>
          </Space>
        </div>
      </>
      }
    </ModalDraggable>
    :null
  )
}
ModalChangePassword.defaultProps = {
  visible: false,
  doVisible: () => {window.alert("Not yet implemented")},
  doOpen: () => {window.alert("Not yet implemented")},
}
