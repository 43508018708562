import React, {useContext, useEffect, useState} from "react";
import {BC_RAVE_ROOT} from "./const";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {Link} from "react-router-dom";
import {Breadcrumb} from "antd";

export const RootBreadcrumb = ({root = {key: "dashboard", title: "Dashboard"}, ...props}) => {
  const [state] = useContext(CsescaContext)
  const [breadcrumbs, setBreadcrumbs] = useState([{...root}, {
    key: "bc-projects",
    title: <Link to={`/ras/projectlist`}>Projekte</Link>
  }])
  useEffect(() => {
    loadBreadcrumb({name: state.entityName, id: state.entityId, breadcrumbType: state.breadcrumbType})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entityId])
  const loadBreadcrumb = (entity) => {
    if (entity.id && entity.name && entity?.breadcrumbType === BC_RAVE_ROOT) {
      const startBC = [{...root}, {key: "bc-projects", title: <Link to={`/ras/projectlist`}>Projekte</Link>}]
      setBreadcrumbs(_ => [...startBC])
    }
  }
  return <div className={"ces-breadcrumb-container"}>
    <Breadcrumb
      className="ces-breadcrumb"
      separator={"|"}
      items={breadcrumbs}
    />
  </div>
}
