import React, {useEffect, useState} from 'react'
import {ImportOutlined, PlusOutlined} from "@ant-design/icons";
import {ModalProjectChoose} from "../template/modalProjectChoose";
import apiRequest from "../../general/apiRequest";
import {
  canShowAddStandardButtonProject,
  canShowAddTemplateButtonProject,
} from "../../system/checkTenants";
import {Button, Space, Tooltip} from "antd";
import useCredentials from "../../system/UseCredentials";
import {sysRight} from "../../common/cmnRights";

const apiUrlProjectRight                 = "/project/rights"
// const apiUrlProjectCreate                = "/project"
// const apiUrlProjectCreateFromTemplate    = "/project/createFromTemplate"

export const ProjectListMenu = ({isTemplate = false, parentId, projectId, create, ...props}) => {
  const [chooseOpen, setChooseOpen] = useState(false)
  const [rights, setRights] = useState([]);
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  const userId = credentials?.user?.id
  useEffect (() => {
    getRights()
  }, [])
  const getRights = () => {
    apiRequest.get(apiUrlProjectRight)
      .then((rd) => {
        if (rd && rd.state && rd.rights) setRights(rd.rights)
      })
      .catch(error => console.log("getRights in ProjectListMenu: " + error))
  }
  const createProject = () => {
      create({
        newData: null,
        urlExtension: null,
        urlParams: `isTemplate=${isTemplate}`,
        doNavigate: true,
      })
/*
    apiRequest.post(apiUrlProjectCreate + "?isTemplate=" + isTemplate)
      .then((rd) => {
        if (rd && rd.state && rd.data) {
          const urn = `/ras/project/${rd.data}`
//          console.log(urn)
          navigation(urn)
        }
      })
      .catch(error => console.log("createProject in ProjectListMenu: " + error))
*/
  }
  const duplicateProject = (selectedTemplateId) => {
    if (selectedTemplateId) create({
      newData: null,
      urlExtension: `createFromTemplate`,
      urlParams: `sourceProjectId=${selectedTemplateId}`,
      doNavigate: true,
    })
/*
    apiRequest.put(apiUrlProjectCreateFromTemplate + `?sourceProjectId=${selectedTemplate}`)
      .then((rd) => {
        if (rd && rd.state && rd.data) {
          const urn = `/ras/project/${rd.data}`
          console.log(urn)
          navigation(urn)
        }
      })
      .catch(error => console.log("createProject in duplicateProject: " + error))
*/
  }
  const handleOk = (value) => {
    duplicateProject(value.selectedRowKeys/*, props.projectId, props.parentId, false*/)
    setChooseOpen(false)
  }
  const createFromTemplate = (selectedTemplateId) => {
    if (selectedTemplateId) create({
      newData: null,
      urlExtension: `createFromTemplate`,
      urlParams: `sourceProjectId=${selectedTemplateId}`,
      doNavigate: true,
    })
    setChooseOpen(false)
    /*
        if (selectedTemplateId.length >0) {
          const url = apiUrlBreakdownCreateFromTemplate + `?sourceBreakdownId=${selectedTemplateId}&newParentId=${parentId}&newProjectId=${projectId}&isTemplate=${isTemplate}`
          apiRequest.post(url)
            .then((rd) => {
              if (rd && rd.state && rd.data) navigation("/ras/breakdown/" + rd.data)
            })
            .catch(error => console.log("duplicateBreakdown in BreakdownListMenu: " + error))
        }
    */
  }
  return <Space>
    {canShowAddStandardButtonProject(isTemplate, tenantId, userId)
      ?<Tooltip title={"neues Projekt anlegen"} color={'cyan'} placement="topRight">
        <Button
          onClick={() => createProject()}
          type={"link"}
          disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
          icon={<PlusOutlined style={{fontSize: "2em"}} />}
        />
      </Tooltip>
    :null}
    {(canShowAddTemplateButtonProject(isTemplate, tenantId, userId) /*&& props.projectId*/)
      ?<>
        <Tooltip title={"Projekt aus Templates anlegen"} color={'cyan'} placement="topRight">
          <Button
            onClick={() => setChooseOpen(true)}
            type={"link"}
            disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
            icon={<ImportOutlined style={{fontSize: "2em"}} />}
          />
        </Tooltip>
        {(chooseOpen)?
        <ModalProjectChoose
          open={chooseOpen}
          onCancel={() => setChooseOpen(false)}
          onOk={(value) => createFromTemplate(value.selectedRowKeys)}
          // onOk={handleOk}
        />
        :null}
      </>
      :null
    }
  </Space>
}