import React, {useEffect, useState} from 'react'
import {Col, Empty, Row, Spin, Card} from "antd";
import {Form as RjsfForm} from "@rjsf/antd"
import {LookupSelect, LookupTree, DatePicker} from "../attribute/jsonForm"
import validator from "@rjsf/validator-ajv6";
import {DragOutlined, LoadingOutlined} from "@ant-design/icons";
import apiRequest from "../general/apiRequest";
//import {/*LookupSelect, LookupTree, */widgets} from "../attribute/jsonForm/index"
// import { JsonForms } from '@jsonforms/react'
// import {
//   materialRenderers as renderers,
//   materialCells as cells,
// } from '@jsonforms/material-renderers';
// //import $RefParser from '@apidevtools/json-schema-ref-parser';

const  ObjectFieldTemplate = (props) => {
  return (
    <>
{/*
      {(Object.keys(props.schema.properties).length > 1)?props.title: null}
      {props.description}
      {props.properties.map(element => <div className="property-wrapper">{element.content}</div>)}
*/}
      {props.properties.map((element, ix) => {
        return <span key={ix}>{element.content}</span>
      })}
    </>
  );
}

const widgets = {
  LookupSelectWidget: LookupSelect,
  LookupTreeWidget: LookupTree,
  DatePickerWidget: DatePicker,
}

export const NodeDataPanel = (props) => {
  const [changed, setChanged] = useState(false)
  const [nodeData, setNodeData] = useState({})
  const [nodeForm, setNodeForm] = useState(null)
  const loadNodeData = async id => {
    if (id) {
      const rd = await apiRequest.get(`/nodeData/withSchema/${id}`)
      if (rd && rd.state && rd.data) {
        setNodeData(rd.data)
        setNodeForm(rd.form)
      }
    }
  }
  const saveNodeData = async data => {
    const rd = await apiRequest.put(`/nodeData`, JSON.stringify(data))
    if (rd && rd.state && rd.data) setNodeData(rd.data)
  }
  const handleBlur = (id, v) => {
    if (changed) saveNodeData(nodeData)
  }
  const handleChange = ({formData}, evt) => {
    const newData = {...nodeData, data: formData}
    setNodeData(newData)
    setChanged(true)
  }
  // const handleSpacialChange = (name, value) => {
  //   setChanged(true)
  //   const newData = {...nodeData, data: {[name]: value}}
  //   setNodeData(newData)
  // }
  useEffect(() => {
    loadNodeData(props.itemId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderCardTitle = (editable, fromTemplate) => {
    if (editable)
      return {title:<>
      <Row gutter={[16,16]}>
        {(!fromTemplate && editable)
          ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
          :null}
      </Row>
    </>,
        extra: props.extra,
      }
    else return {title: null}
  }
  return (
    <Card
//      headStyle={props.editable?{backgroundColor: "#EEE"}:{backgroundColor: "#FAFAFA"}}
//      bodyStyle={{backgroundColor: "#FAFAFA"}}
      {...renderCardTitle(props.editable, props.fromTemplate)}
/*
      title={<>
        <Row gutter={[16,16]}>
          {(!props.fromTemplate && props.editable)
            ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
            :null}
          <Col span={5}>{(nodeForm && nodeForm.schema)?nodeForm.schema?.title:""}</Col>
        </Row>
      </>
      }
      extra={<>
        {(props.editable)?props.extra:null}
      </>}
*/
      >
        <Row key={"ND" + nodeData.id}>
          <Col span={8}>
          {(!nodeData || !nodeForm)?
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "red", }} />}>
              <Empty description="... laden" />
            </Spin>
          :<RjsfForm
              key={nodeData.id}
              className="ant-form ant-form-vertical ant-form-small"
              noValidate
              widgets={widgets}
              formData={nodeData.data}
              validator={validator}
              schema={nodeForm.schema}
              uiSchema={nodeForm.uiSchema}
              showErrorList={false}
              onChange={handleChange}
              onBlur={handleBlur}
              readonly={!props.editable}
              templates={{ObjectFieldTemplate}}
            />
          }
          </Col>
        </Row>
      </Card>
  )
}
