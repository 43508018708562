import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux'
import {Link, useNavigate} from "react-router-dom";
import {Menu} from "antd";
import {
  AppstoreOutlined, FundViewOutlined,
  IdcardOutlined, InboxOutlined, InteractionOutlined, NodeExpandOutlined,
  SettingOutlined, SnippetsOutlined, UserOutlined,
} from "@ant-design/icons";
import {ModalAddresses} from "./dialog/modalAddress";
import {sysRight} from "./common/cmnRights";
import apiRequest from "./general/apiRequest";
import {ModalImportSchema} from "./core/importModule/dialog/modalImportSchema";
import {ModalCatalog} from "./dialog/modalCatalog";
import {isAdminUser, isImpExt, isMrpExt, isTempExt} from "./system/checkTenants";
import {ModalChangePassword} from "./dialog/modalChangePassword";
import useCredentials from "./system/UseCredentials";
import {setLoggedInUser} from "./store/loggedInUserSlice";

const apiUrlRoleRights    = "/role/rights"
const apiUrlLogout        = "/logout"

const apiUrlSwitchUser    = "/switchUser"

export const TopNavbar = ({...props}) => {
  const [addressRights, setAddressRights] = useState([])
  const [roleRights, setRoleRights] = useState([]);
  const [catalogRights, setCatalogRights] = useState([]);
  const [addressVisible, setAddressVisible] = useState(false)
  const [importSchemaVisible, setImportSchemaVisible] = useState(false)
  const [catalogVisible, setCatalogVisible] = useState(false)
  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false)
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [credentials] = useCredentials()
  const userId = credentials?.user.id
  const user2Id = credentials?.user2.id
  const tenantId = credentials?.tenant.id
  const loadAddressRights = () => {
    apiRequest.get("/person/rights")
      .then((rd) => {
        if (rd && rd.state && rd.rights) setAddressRights(rd.data)
      })
      .catch(error => console.log("loadAddressRights in TopNavbar: " + error))
  }
  const loadRoleRights = () => {
    apiRequest.get(apiUrlRoleRights)
      .then((rd) => {
        if (rd && rd.state && rd.rights) setRoleRights(rd.rights)
      })
      .catch(error => console.log("loadRoleRights in TopNavbar: " + error))
  }
  const loadCatalogRights = () => {
    apiRequest.get("/catalog/rights")
      .then((rd) => {
        if (rd && rd.state && rd.rights) setCatalogRights(rd.rights)
      })
      .catch(error => console.log("loadCatalogRights in TopNavbar: " + error))
  }
  const logout = () => {
    apiRequest.get(apiUrlLogout)
      .then(rd => {
        window.localStorage.removeItem('cred')
        navigation("/login")
      })
      .catch(error => console.log("TopNavbar in logout: " + error))
  }
  const getUser = (value) => {
    const userData = (value && value.user)?<p style={{textDecoration: "none", lineHeight: ".9em", fontSize: ".8em", color: "#000096", paddingLeft: ".5em"}}>{value.user.lastName} {value.user.firstName}</p>:null
    return <>{userData}</>
  }
  useEffect(() => {
    loadAddressRights()
    loadRoleRights()
    loadCatalogRights()
  }, [])
  useEffect(() => {
    loadRoleRights()
  }, [userId])
  const listRight = (addressRights.indexOf(sysRight.LIST) >= 0)
  const showRR = roleRights.indexOf(sysRight.LIST) > 0
  const getMenuItems = (visible, key, label, icon, onClick, style, disabled = false, children, type) => {
    if (visible) {
      switch (type) {
        case "divider": return {type}
        default:
          return {key, label, icon, onClick, style, disabled, children}
          // if (!disabled) return {key, label, icon, onClick, style, children, type}
          // return {key, label, icon, onClick, style, disabled, children, type}
      }
    } return null
  }
  const hasCatalogRights = () => isAdminUser(userId) || ((catalogRights.indexOf(sysRight.LIST) >= 0) && isTempExt(tenantId))
  const switchBackToAdmin = () => {
    // if (credentials.user.id !== credentials.user2.id)
      apiRequest.put(apiUrlSwitchUser, JSON.stringify({userId: credentials.user2.id}))
        .then(rd => {
          if (rd && rd.state && rd.data) {
            dispatch(setLoggedInUser(rd.data))
            navigation("/ras/projectlist")
          }
        })
        .catch(error => console.log("TopNavbar in switchBackToAdmin: " + error))
  }
  const items = [
    getMenuItems(true, "nmProject", null, <AppstoreOutlined />, () => {navigation("/ras/projectlist")},{padding: "0 5px"}, false),
    getMenuItems(true, "nmAddress", null, <IdcardOutlined />, () => {setAddressVisible(true)},{padding: "0 5px"}, !listRight),
    getMenuItems(true, "nmSetting", null, <SettingOutlined />, null,{padding: "0 5px"}, false,
      [
        getMenuItems(isAdminUser(userId), "nmSummarize", "Summen", <FundViewOutlined />, () => navigation("/ras/summarize"),{padding: "0 5px"},),
        getMenuItems(isTempExt(tenantId), "nmTemplate", "Templates", <SnippetsOutlined />, () => navigation("/ras/template"),{padding: "0 5px"},!isAdminUser(userId)),
        getMenuItems(isMrpExt(tenantId), "nmattributeMeta", "Attribute", <NodeExpandOutlined />, () => navigation("/ras/attributeMeta"),{padding: "0 5px"},!isAdminUser(userId)),
        getMenuItems(isImpExt(tenantId), "nmImportSchema", "Import/Export", <InteractionOutlined />, () => {setImportSchemaVisible(true)},{padding: "0 5px"},!isAdminUser(userId)),

        getMenuItems(hasCatalogRights(), "nmCatalog", "Katalog", <InboxOutlined />, () => {setCatalogVisible(true)},{padding: "0 5px"},false),

      ].filter(it => it !== null),
    ),
    getMenuItems(true, "nmAccount", null, <UserOutlined style={{fontSize: "1em", borderRadius: "50%", border: "1px solid", padding: ".3em" }} />, null,{padding: "0 5px", }, false,[
      getMenuItems(true, "mnUserDesc1", (<span style={{color: "rgba(0, 0, 0, 0.85)", textDecoration: "underline"}}>Meine Einstellungen</span>), null, null, {padding: "0 5px", cursor:"default"}, false),
      // getMenuItems(true, "mnUserDesc2", (getUser(credentials)), null, null, {padding: "0 5px", cursor:"default"}, false),
      getMenuItems(true, "mnUserDesc2", (getUser(credentials)), null, null, {padding: "0 5px", cursor:"default"}, false),
      getMenuItems(userId !== user2Id && isAdminUser(user2Id),"mnDivider1", null, null, null, null, null, null, "divider"),
      getMenuItems(userId !== user2Id && isAdminUser(user2Id), "nmUserSwitchBack", "Admin Benutzer", null, () => switchBackToAdmin(), {padding: "0 5px"}, null),
      // getMenuItems(userId !== user2Id,"mnDivider1", null, null, null, null, null, null, "divider"),
      // getMenuItems(userId !== user2Id, "nmUserSwitchBack", "Admin Benutzer", null, () => switchBackToAdmin(), {padding: "0 5px"}, null),
      getMenuItems(showRR,"mnDivider2", null, null, null, null, null, null, "divider"),
      getMenuItems(showRR, "nmRole", <Link to={"/ras/security"} >Rechte und Rollen</Link>, null,null,{padding: "0 5px"}, false,),
      {key: "mnDivider3", type: "divider"},
      getMenuItems(userId === user2Id, "nmChangePassword", "Passwort Ändern", null, () => setPasswordChangeVisible(true), {padding: "0 5px"}, false,),
      getMenuItems(true, "nmLogout", "Abmelden", null, () => logout(), {padding: "0 5px"}, false,),
    ].filter(it => it != null)
    ),
  ].filter(it => it != null)
  return <>
    <Menu
      className={"cses-main-menu"}
      mode="horizontal"
      key={"test"}
      selectable={false}
      items={items}
      />
    {(addressVisible)?
      <ModalAddresses
        isAdminUser={isAdminUser(userId)}
        panelTypes={new Set(["subjectPerson","subjectOrganisation"])}
        addressVisible={addressVisible}
        open={addressVisible}
        doAddressVisible={setAddressVisible}
        doOpen={setAddressVisible}
      />
      :null}
    <ModalImportSchema
      visible={importSchemaVisible}
      open={importSchemaVisible}
      doImportSchemaVisible={setImportSchemaVisible}
      doOpen={setImportSchemaVisible}
      />
    <ModalCatalog
      visible={catalogVisible}
      open={catalogVisible}
      doCatalogVisible={setCatalogVisible}
      doOpen={setCatalogVisible}
    />
    <ModalChangePassword
      visible={passwordChangeVisible}
      open={passwordChangeVisible}
      doVisible={setPasswordChangeVisible}
      doOpen={setPasswordChangeVisible}
    />
  </>
}
