import {createSlice} from "@reduxjs/toolkit";

const loggedInUserSlice = createSlice({
  name: "loggedInUser",
  initialState: {
        user: {},
        user2: {},
        tenant: {},
        organisation: {},
  },
  reducers: {
    setLoggedInUser(state, action) {
      const {payload} = action
//      state.loggedInUser = [...state.loggedInUser, payload]
      state.user = payload.user
      state.user2 = payload.user2
      state.organisation = payload.organisation
      state.tenant = payload.tenant
    },
  }
})

export const { setLoggedInUser } = loggedInUserSlice.actions
export const  getLoggedInUser = (state) => state.loggedInUser
export default loggedInUserSlice.reducer