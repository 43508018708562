import React, {useEffect, useState} from 'react';
import {Button, Col, Input, List, Popconfirm, Row} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {TreeSelectCatalog} from "../common/cmnTreeSelectCatalog";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";

const apiUrlListRestGoal            = "/restgoal/byParent"
const apiUrlShowRestGoal            = "/restgoal/show"
const apiUrlSaveRestGoal            = "/restgoal"
const apiUrlCreateRestGoal          = "/restgoal"
const apiUrlDeleteRestGoal          = "/restgoal"


export const RestorationGoalList = ({workId, parentType, rightsParent = [], ...props}) => {
  const [goals, setGoals] = useState([])
  useEffect(() => {
    loadRestGoals(workId)
  }, [workId])
  const handleAdd = (id) => {
    apiRequest.post(apiUrlCreateRestGoal + "/" + id)
      .then((rd) => {
        if (rd && rd.state && rd.state) loadRestGoals(workId)
      })
      .catch(error => console.log("handleAdd in RestGoalList: " + error))
  }
  const loadRestGoals = (id) => {
    apiRequest.get(apiUrlListRestGoal + "/" + id)
      .then((rd) => {
        if (rd && rd.state && rd.data) {
          setGoals(rd.data)
//          setRights(rd.rights)
        }
      })
      .catch(error => console.log("loadRestGoals in RestGoalList: " + error))
  }
  const handleDelete = id => {
    apiRequest.delete(apiUrlDeleteRestGoal + "/" + id)
      .then((rd) => {
        if (rd && rd.state && rd.data) loadRestGoals(workId)
      })
      .catch(error => console.log("loadRestGoals in RestGoalList: " + error))
  }
  return <div>
    {(parentType!=="workpiece") ?
    <div style={{textAlign: "right", "margin": "auto 1em 1em auto"}}>
      <Button type={"primary"} ghost={true} shape={"round"} style={{ marginBottom: 16 }} onClick={() => {handleAdd(workId)}} >Neues Ziel</Button>
    </div>
      : null}
    <List
      itemLayout={"vertical"}
      dataSource={goals}
      renderItem={item =>(
        <List.Item>
          <RestorationGoal
            item={item}
            readOnly={(parentType==="workpiece")}
            rightsParent={rightsParent}
            handleDelete={(id) => {handleDelete(id)}}
          />
        </List.Item>
      )}
    />
  </div>
}
const defaultRestorationGoal = {
  id: "",
  parentId: "",
  categoryId: "",
  restorationGoalId: "",
  restorationGoalName: "",
  restorationGoalText: "",
}
export const RestorationGoal = ({handleDelete, item, readOnly, rightsParent, ...props}) => {
  const [restorationGoal, setRestorationGoal] = useState({...defaultRestorationGoal})
  const [isChanged, setIsChanged] = useState(false)
  useEffect(() => {
    loadRestorationGoal(item)
  }, [item])
  const loadRestorationGoal = (id) => {
    apiRequest.get(apiUrlShowRestGoal + "/" + id    )
      .then(rd => {
        if (rd && rd.state && rd.data) setRestorationGoal(rd.data)
      })
      .catch(error => console.log("loadRestorationGoal in RestorationGoal: " + error))
  }
  const saveRestorationGoal = () => {
    if (!readOnly && isChanged) {
      apiRequest.put(apiUrlSaveRestGoal, JSON.stringify({...restorationGoal}))
        .then(rd => {
//          console.log("RestorationGoal saved")
          setIsChanged(false)
        })
        .catch(error => console.log("saveRestorationGoal in RestorationGoal: " + error))
    }
  }
  const handleChildChange = (evt) => {
    if (!readOnly) {
      const name = evt.target.name
      const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
      setRestorationGoal(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleTreeChange = (name, value) => {
    if (!readOnly) {
      setRestorationGoal(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const deleteRestorationGoal = (id) => {if (!readOnly) handleDelete(id)}
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  return <Row gutter={[16,16]}>
    <Col span={6}>
      <Row gutter={[16,16]}>
        <Col span={24}>
          <TreeSelectCatalog
//             style={{width:"100%"}}
            catalogTree={"3b99bd67-b26a-4a78-ba6f-99f55442f9b9"}
            showempty={true}
            disableempty={false}
            textempty="--- keine Auswahl ---"
            allowClear={false}
            value={restorationGoal.categoryId}
            onChange={(tsData, tsLabel, tsExtra) => {handleTreeChange("categoryId", tsData)}}
            onBlur={saveRestorationGoal}
            readOnly={!update} disabled={!update}
          />
        </Col>
      </Row>
      <Row gutter={[16,16]}>
        <Col span={24}>
          {(!readOnly && update) ?
            <Popconfirm
              placement="topRight"
              title={"Möchten Sie das Restaurierungsziel löschen"}
              onConfirm={() => {deleteRestorationGoal(item)}}
              okText="Ja"
              cancelText="Nein"
              cancelButtonProps={{danger: false, type: "primary"}}
              okButtonProps={{danger: true, ghost: true}}
            >
              <Button danger={"true"} ghost={true} shape={"round"} style={{ margin: 8 }} icon={<DeleteOutlined  />} >Löschen</Button>
            </Popconfirm>
            : null}
        </Col>
      </Row>
    </Col>
    <Col span={18}>
      <Input.TextArea
        name={"restorationGoalText"}
        rows={6}
        value={restorationGoal.restorationGoalText}
        readOnly={readOnly && !update}
        style={{resize: (readOnly) ? "none" : "auto"}}
        onChange={(evt) => {handleChildChange(evt)}}
        onBlur={saveRestorationGoal}
      />
    </Col>
  </Row>
}

// class RestGoalList1 extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       rights: [],
//       goals: [],
//     }
//   }
//   componentDidMount = () => {this.loadRestGoals(this.props.workId)}
//   handleAdd = async id => {
//     const rd = await apiRequest.post(apiUrlCreateRestGoal + "/" + id)
//     if (rd && rd.state && rd.state) this.loadRestGoals(this.props.workId)
//   }
//   loadRestGoals = async id => {
//     const rd = await apiRequest.get(apiUrlListRestGoal + "/" + id)
//     if (rd && rd.state && rd.data) this.setState({goals: rd.data, rights: rd.rights, loading: false})
//   }
//   handleDelete = async id => {
//     const rd = await apiRequest.delete(apiUrlDeleteRestGoal + "/" + id)
//     if (rd && rd.state && rd.data) this.loadRestGoals(this.props.workId)
//   }
//   render = () => {
//     const listData = this.state.goals
//     return (
//       <div>
//         {(this.props.parentType!=="workpiece") ?
//         <div style={{textAlign: "right", "margin": "auto 1em 1em auto"}}>
//           <Button type={"primary"} ghost={true} shape={"round"} style={{ marginBottom: 16 }} onClick={() => {this.handleAdd(this.props.workId)}} >Neues Ziel</Button>
//         </div>
//           : null}
//         <List
//           itemLayout={"vertical"}
//           dataSource={listData}
//           renderItem={item =>(
//             <List.Item>
//               <RestGoal
//                 restorationGoal={item}
//                 readOnly={(this.props.parentType==="workpiece")}
//                 rightsParent={this.props.rightsParent}
//                 handleDelete={(id) => {this.handleDelete(id)}}
//               />
//             </List.Item>
//           )}
//         />
//       </div>
//     )
//   }
// }
// class RestGoal extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { goal: {
//         id: "",
//         parentId: "",
//         categoryId: "",
//         restorationGoalId: "",
//         restorationGoalName: "",
//         restorationGoalText: ""
//       },
//     }
//   }
//   componentDidMount = () => {
//     this.loadGoal(this.props.restorationGoal)
//   }
//   componentDidUpdate = (prevProps, prevState, snapshot) => {
//     if (this.props.restorationGoal !== prevProps.restorationGoal)
//     this.loadGoal(this.props.restorationGoal)
//   }
//   loadGoal = async id => {
//     const rd = await apiRequest.get(apiUrlShowRestGoal + "/" + id    )
//     if (rd && rd.state && rd.data) this.setState({goal: rd.data})
//   }
//   handleSaveGoal = async () => {
//     if (!this.props.readOnly) {
//       const goalJson = JSON.stringify({...this.state.goal})
//       await apiRequest.put(apiUrlSaveRestGoal, goalJson)
//     }
//   }
//   handleBlur = () => {if (!this.props.readOnly) this.handleSaveGoal()}
//   handleChildChange = evt => {
//     if (!this.props.readOnly) {
//       const name = evt.target.name
//       const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
//       this.setState({goal: {...this.state.goal, [name]: value}})
//       this.isChanged = true
//     }
//   }
//   handleTreeChange = (name, value) => {if (!this.props.readOnly) this.setState({goal: {...this.state.goal, [name]: value}})}
//   handleDelete = (id) => {if (!this.props.readOnly) this.props.handleDelete(id)}
//   render = () => {
//     const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
//     return (
//       <>
//         <Row gutter={[16,16]}>
//           <Col span={6}>
//             <Row gutter={[16,16]}>
//               <Col span={24}>
//                 <TreeSelectCatalog
// //                  style={{width:"100%"}}
//                   catalogTree={"3b99bd67-b26a-4a78-ba6f-99f55442f9b9"}
//                   showempty={true}
//                   disableempty={false}
//                   textempty="--- keine Auswahl ---"
//                   allowClear={false}
//                   value={this.state.goal.categoryId}
//                   onChange={(tsData, tsLable, tsExtra) => {this.handleTreeChange("categoryId", tsData)}}
//                   onBlur={(evt) => {this.handleBlur()}}
//                   readOnly={!update} disabled={!update}
//                 />
//               </Col>
//             </Row>
//             <Row gutter={[16,16]}>
//               <Col span={24}>
//                 {(!this.props.readOnly && update) ?
//                 <Popconfirm
//                   placement="topRight"
//                   title={"Möchten Sie das Restaurierungsziel löschen"}
//                   onConfirm={() => {this.handleDelete(this.props.restorationGoal)}}
//                   okText="Ja"
//                   cancelText="Nein"
//                   cancelButtonProps={{danger: false, type: "primary"}}
//                   okButtonProps={{danger: true, ghost: true}}
//                 >
//                   <Button danger={"true"} ghost={true} shape={"round"} style={{ margin: 8 }} icon={<DeleteOutlined  />} >Löschen</Button>
//                 </Popconfirm>
//                   : null}
//               </Col>
//             </Row>
//           </Col>
//           <Col span={18}>
//             <Input.TextArea
//               name={"restorationGoalText"}
//               rows={6}
//               value={this.state.goal.restorationGoalText}
//               readOnly={this.props.readOnly && !update}
//               style={{resize: (this.props.readOnly) ? "none" : "auto"}}
//               onChange={(evt) => {this.handleChildChange(evt)}}
//               onBlur={(evt) => {this.handleBlur()}}
//             />
//           </Col>
//         </Row>
//       </>
//     )
//   }
// }
// RestGoalList.defaultProps = {
//   rightsParent: [],
// }
