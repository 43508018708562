// import React, {useContext, useEffect, useImperativeHandle, useState} from 'react'
// import {Tree} from "antd";
import {
  AppstoreOutlined, BankOutlined,
  BlockOutlined, BorderOutlined,
} from "@ant-design/icons";
// import apiRequest from "../general/apiRequest";
// import {Link} from "react-router-dom";
// import useCredentials from "../system/UseCredentials";
// import CsescaContext from "../core/AppContext/CsescaContext";

const ProjectIcon     = BankOutlined
const BreakdownIcon   = BlockOutlined
const RoomIcon        = BorderOutlined
const WorkpieceIcon   = AppstoreOutlined

// const apiUrlProjectList                           = "/project/list"
// const apiUrlProjectListShared                     = "/project/listShared"
// const apiUrlBreakDownListDirectParentOnly         = "/breakdown/byDirectParentOnly"

// export const BimProductTree = React.forwardRef((props, ref) => {
//   useImperativeHandle(ref, () => ({
//     loadProjects,
//     expandedItems,
//   }))
//   const [state] = useContext(CsescaContext)
//   const [credentials] = useCredentials()
//   const {shared} = props
//   const [treeData, setTreeData] = useState([])
//   const [expandedKeys, setExpandedKeys] = useState([])
//   const [selectedKeys, setSelectedKeys] = useState([])
//   useEffect(() => {
//     loadProjects()
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [credentials.user.id])
//   const updateTreeData = (list, key, children) => {
//
//     return list.map((node) => {
//       if (node.id === key) {
//         return {...node, children,}
//       }
//       if (node.children) {
//         return {...node, children: updateTreeData(node.children, key, children),}
//       }
//       return node
//     })
//   }
//   const loadProjects = () => {
//     setExpandedKeys(prevState => [])
//     setTreeData(prevState => [])
//     const url = (!shared)?`${apiUrlProjectList}?template=false`:apiUrlProjectListShared
//     apiRequest.get(url)
//       .then( rd => {
//         if (rd && rd.state && rd.data) {
//           const nrd = rd.data.map(p => {
//             return {data: {...p}, id: p.id, key: p.id, name: createLink("project", p.id, p.name, false), selectable: false, isLeaf: false, icon: null, }
//           })
//           setTreeData(prevState => nrd)
//           setExpandedKeys(prevState => [...selectedKeys])
//         }
//       })
//   }
//   const createLink = (bType, id, name, multiAssigned) => {
//     // const style = {color: (multiAssigned)?"red":"#1890ff"}
//     const style = (!multiAssigned)
//       ?{color: "rgba(0, 0, 0, 0.7)", fontStyle: "inherited"}
//       :{color: "rgba(0, 0, 191, 0.7)", fontStyle: "italic"}
//     switch (bType) {
//       // case "project": return <Link to={"/ras/project/"+id} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",strokeWidth: "30", fontSize: "50px",color: "#408021", stroke: "white"}} />{name}</Link>
//       case "project": return <Link to={"/ras/project/"+id} title={name} style={style}><ProjectIcon style={{margin: "0 .2em",}} />{name}</Link>
//       case "breakdown": return <Link to={"/ras/breakdown/"+id} title={name} style={style}><BreakdownIcon style={{margin: "0 .2em",}} />{name}</Link>
//       case "room": return <Link to={"/ras/room/"+id} title={name} style={style}><RoomIcon style={{margin: "0 .2em",}} />{name}</Link>
//       case "workpiece": return <Link to={"/ras/workpiece/"+id} title={name} style={style}><WorkpieceIcon style={{margin: "0 .2em",}} />{name}</Link>
//       default: return null
//     }
//   }
//   const loadNodes = async parentId  => {
//     const rd = await apiRequest.get(apiUrlBreakDownListDirectParentOnly + "/" + parentId + "?template=" + false)
//     if (rd && rd.state && rd.data) {
// //      const nrd = rd.data.map (b => {return {data: {...b}, id: b.id, name: createLink(b.bType, b.id, b.name, b.multiAssigned), selectable: false, icon: getIcon(b.bType), isLeaf: (b.bType === "workpiece") }})
//       const nrd = rd.data.map (b => {
//         return {
//           data: {...b},
//           id: b.id,
//           name: createLink(b.bType, b.id, b.name, b.multiAssigned),
//           selectable: false,
//           icon: null,
//           isLeaf: (b.bType === "workpiece" || b.childrenAmount < 1)
//         }
//       })
//       return nrd
//     }
//     return []
//   }
//   const doExpanded = (expandedItemKeys, {expanded, node}) => {
//     if (expanded) {
//       loadNodes(node.key)
//         .then(children => {
//           setTreeData((prevState) => {
//             const ret = updateTreeData(prevState, node.key, children)
//             return ret
//           })
//           setExpandedKeys(expandedItemKeys)
//         })
//     } else setExpandedKeys(expandedItemKeys)
//   }
//   const expandedItems = (bcList = [], reset = false) => {
//     const expand = (list = []) => {
//       const item = list.shift()
//       if (item) {
//         const found = expandedKeys.find((element) => {
//           return element === item.id
//         })
//         if (!found) {
//           loadNodes(item.id)
//             .then(children => {
//               setTreeData((prevState) => {
//                 const ret = updateTreeData(prevState, item.id, children)
//                 return ret
//               })
//               setExpandedKeys((prevState) => {
//                 return [...prevState, item.id]
//               })
//               setSelectedKeys((prevState) => [...prevState, item.id])
//               if (list.length > 0) {
//                 expand(list)
//               }
//             })
//         } else {
//           setSelectedKeys((prevState) => [...prevState, item.id])
//           expand(list)
//         }
//       }
//     }
//     setSelectedKeys(prevState => [])
//     expand([...bcList])
//   }
//   return <>
//     <Tree
//       rootClassName={"cses-product-tree"}
//       showIcon={true}
//       onExpand={doExpanded}
//       expandedKeys={expandedKeys}
//       multiple={true}
//       selectedKeys={selectedKeys}
//       fieldNames={{title: "name", key: "id"}}
//       treeData={treeData}
//     />
//     {state.entityId}
//   </>
// })
// BimProductTree.defaultProps = {
//   shared: false
// }

export {ProjectIcon, BreakdownIcon, RoomIcon, WorkpieceIcon,}