/**
 * panel for OSW and Nominatim
 */

import React from 'react';
import {apiNominatim} from "../baseFetch";
import {Button, Card, Col, Empty, Form, Input, Modal, Row, notification, Space, Tooltip} from "antd";
import {MapContainer, TileLayer, Marker, useMapEvents, useMap} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'
import {SelectCountry} from "../common/cmnCountrySelect";
import {MapChoose} from "../dialog/modalGeolocationChoose";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import {
  DisconnectOutlined, DownOutlined,
  DragOutlined,
} from "@ant-design/icons";

const apiUrlLocationAddressShow          = "/locationAddress/show"
const apiUrlLocationAddressSave          = "/locationAddress"
const apiUrlLocationGEOListByParent      = "/locationGeocode/byParent"
const apiUrlLocationGeocodeSave          = "/locationGeocode"
const apiUrlLocationGeocodeDelete        = "/locationGeocode"
const apiUrlLocationGEOCreateLatLon      = "/locationGeocode/createLatLon"

export class LocationAddressPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationAddress: {
        id:           "",
        active:       true,
        position:     -1,
        parentId:    "",
        functionId:  "",
        street:       "",
        street2:      "",
        zip:          "",
        city:         "",
        citydistrict: "",
        countryId:      ""
      },
//      locationGeocodes: [],
      geocodeData: {
        latitude:  0,
        longitude: 0,
        zoom: 0
      },
      locationGeocode: {
        id:           "",
        active:       true,
        position:     -1,
        parentId:    "",
        latitude:  "",
        longitude: "",
        zoom: 0,
        altitude:  "",
        accuracy:  "",
        heading:   ""
      },
      disabled: true,
      bonds: {left: 0, top: 0, bottom: 0, right: 0},
      showModalMap: false,
//      myPosition: [48.54099175, 10.850526793257309],
    }
    this.isChanged = false
  }
  componentDidMount = () =>  {this.loadLocationAddress(this.props.itemId)}
  loadLocationAddress = async locationAddressId => {
    if (locationAddressId) {
      const rd = await apiRequest.get(apiUrlLocationAddressShow + "/" + locationAddressId)
      if (rd && rd.state && rd.data) {
        this.setState({locationAddress: rd.data})
        this.loadLocationGeocodes(rd.data.id)
        this.isChanged = false
      }
    }
  }
  loadLocationGeocodes = async locationGeocodeParentId => {
    const rd = await apiRequest.get(apiUrlLocationGEOListByParent  + "/" + locationGeocodeParentId)
    if (rd && rd.state && rd.data && rd.data.length > 0) {
      this.setState({locationGeocode: rd.data[0]})
      this.setState({geocodeData: {
          latitude:  rd.data[0].latitude,
          longitude: rd.data[0].longitude,
          zoom: rd.data[0].zoom
        }})
    }
  }
  handleAddressSave = async () => {
    await apiRequest.put(apiUrlLocationAddressSave, JSON.stringify({...this.state.locationAddress}))
  }
  handleGeocodeSave = async d => {
    await apiRequest.put(apiUrlLocationGeocodeSave, JSON.stringify(d))
  }
  handleGeocodeDelete = async geoId => {
    if (geoId) {
      const rd = await apiRequest.delete(`${apiUrlLocationGeocodeDelete}/${geoId}`)
      if (rd && rd.state)
        this.setState({locationGeocode: null})
    }
  }
  handleChildBlur = () => {this.handleAddressSave()}
  handleChildChange = (name, value) => {
    if (this.props.editable) {
      this.setState({locationAddress: {...this.state.locationAddress, [name]: value}})
      this.isChanged = true
    }
  }
  getOSMGeoData = () => {
    let searchString = "https://nominatim.openstreetmap.org/search?"
    if (this.state.locationAddress.street + this.state.locationAddress.zip + this.state.locationAddress.city !== "") {
      if (this.state.locationAddress.street && this.state.locationAddress.zip && this.state.locationAddress.city && this.state.locationAddress.countryId === "") {
        searchString = searchString +
          "street=" + encodeURIComponent(this.state.locationAddress.street) +
          "&postalcode=" + encodeURIComponent(this.state.locationAddress.zip) +
          "&city" + encodeURIComponent(this.state.locationAddress.city) +
          "&format=json&limit=1&zoom=10"
      } else {
        searchString = searchString + "q=" +
          encodeURIComponent(this.state.locationAddress.street) + " " +
          encodeURIComponent(this.state.locationAddress.zip) + " " +
          encodeURIComponent(this.state.locationAddress.city) +
          "&format=json&limit=1"
      }
      apiNominatim(searchString, this.setOSMGeoData)
    }
  }
  setOSMGeoData = async data => {
    if (data && data.length > 0) {
      const geoData = JSON.stringify({
        "parentId": this.props.itemId,
        "geoLatitude": parseFloat(data[0].lat),
        "geoLongitude": parseFloat(data[0].lon)
      })
      const rd = await apiRequest.post(apiUrlLocationGEOCreateLatLon, geoData)
      if (rd && rd.state)
        this.loadLocationAddress(this.props.itemId)
    } else notification.error({
      message: <b>Keine Geodaten gefunden</b>,
      description: "Unter Ihren Angeben wurden keine Geodaten in der OSM/Nominatim Datenbank gefunden",
      placement: "topRight",
      duration: 5,
      key: "GeoNotFund",
    })
//      message.error({content: <div><h5>Keine Daten</h5>Unter Ihren Daten wurde Location gefunden</div>, style: {marginTop: '20vh'}, duration: 50, key: "GeoNotFund"})
  }
  onEdit = (targetKey, action) => { this[action](targetKey) }
//  add = () => { this.getOSMGeoData() }
//  remove  = (targetKey) => {}
  showModalMap = () => {
//    if (this.props.editable) {
//      const read = this.props.rightsParent.indexOf(sysRight.READ) >= 0
      const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
      if (update)
        this.setState({showModalMap: true})
//    }
  }
  modalChangeLatLon = (data) => {
    if (this.state.geocodeData.latitude !== data.lat || this.state.geocodeData.longitude !== data.lng)
    {
      this.setState({geocodeData:{...this.state.geocodeData, latitude: data.lat, longitude: data.lng}})
    }
  }
  modalChangeZoom = (data) => {
    if (this.state.geocodeData.zoom !== data) {
      this.setState({geocodeData: {...this.state.geocodeData, zoom: data}})
    }
  }
  handleOk = () => {
    this.setState({showModalMap: false, locationGeocode: {...this.state.locationGeocode, ...this.state.geocodeData}})
    const data = {...this.state.locationGeocode, ...this.state.geocodeData}
    this.handleGeocodeSave(data)
  }
  handleCancel = () => {
    this.setState({ showModalMap: false })
  }
  DeleteButton = ({position, zoom}) => {
//    const parentMap = useMap()
//    const mapZoom = zoom || 0
    // const minimap = useMemo(
    //   () => (<Button type={"link"} icon={<DeleteOutlined />} />),
    //   [],
    // )
  }
  render/*Edit*/ = () => {
/*
    function DeleteButton ({position, zoom}) {
      const parentMap = useMap()
      const mapZoom = zoom || 0
      const minimap = useMemo(
        () => (<Button type={"link"} icon={<DeleteOutlined />} />),
        [],
      )
    }
*/
    const {editable} = this.props
    const { /*bounds, disabled,*/ showModalMap } = this.state;
//    const read = this.props.rightsParent.indexOf(sysRight.READ) >= 0
    const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
    const mPosition = (this.state.locationGeocode?.id)? [this.state.locationGeocode.latitude, this.state.locationGeocode.longitude] : [48.54099175, 10.850526793257309]
    const zoom = (this.state.locationGeocode?.id)? this.state.locationGeocode.zoom : 12
    const self = this
    function MyDrag() {
      /*const map =*/ useMapEvents({
        dragend: (e) => {
//          console.log("Center: " + e.target.getCenter())
        },
        click: (event) => {self.showModalMap()},
//        dblclick: (event) => {console.log("DblClick Map")},
//        dblclick: (event) => {console.log("DblClick Map"); return <Modal visible={true} destroyOnClose={true} title="Title" >Karte</Modal>},
//        click: (event) => {console.log("Click Map")},
      })
      return null
    }
    function ChangeView({ center, zoom }) {
      const map = useMap()
      map.setView(center, zoom)
      return null
    }
//    const mapCoverStyle = {width: "200px", height: "150px", position: "relative", cursor: (update)?"pointer":"default"}
    const mapCoverStyle = {width: "100%", height: "200px", position: "relative", cursor: (update)?"pointer":"default"}
//    if (!this.props.editable) mapCoverStyle.cursor = "default"
    return(
      <Card
        // headStyle={editable?{backgroundColor: "#EEE"}:{backgroundColor: "#FAFAFA"}}
        // bodyStyle={{backgroundColor: "#FAFAFA"}}
        title={(editable)?
          <Row gutter={[16,16]}>
            {(!this.props.fromTemplate)
              ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
              :null
            }
          </Row>
          :null
        }
        extra={<>
        {editable
          ?<Tooltip title={"Karte löschen"}>
            <Button type={"text"} icon={<DisconnectOutlined />} onClick={() => {this.handleGeocodeDelete(this.state.locationGeocode?.id)}} style={{marginTop: ".7em"}}  />
          </Tooltip>
          :null
        }
          {this.props.extra}
        </>
/*
        <>
          {(!this.props.fromTemplate)?
            <>
              <Button type={"text"} icon={<InsertRowAboveOutlined />}/>
              <Button type={"text"} icon={<InsertRowBelowOutlined />}/>
              <Popconfirm
                placement="topLeft"
                title={"Möchten Sie den Panel löschen?"}
                okText="Ja"
                cancelText="Nein"
                onConfirm={() => {this.props.onDelete("location", this.state.locationGeocode.id)}}
                cancelButtonProps={{danger: false, type: "primary"}}
                okButtonProps={{danger: true, ghost: true}}
              >
                <Button type={"text"} icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
            :null}
        </>
*/
      }
      >
        <Row gutter={[16,16]}>
          <Col span={4}>
            <div style={mapCoverStyle}>
              {(this.state.locationGeocode?.id)
                ?<MapContainer
                  style={{width: "100%", height: "100%"}}
//                  bounds={bounds}
//                  center={mPosition}
//                  zoom={zoom}
                  scrollWheelZoom={false}
                  zoomControl={false}
                  dragging={false}
                  doubleClickZoom={false}
//                  style={{cursor: "pointer"}}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' /*contributors*/
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <MyDrag />
                  <ChangeView center={mPosition} zoom={zoom} />
                  <Marker position={mPosition} icon={new Icon({iconUrl: markerIconPng, iconSize: [12, 20], iconAnchor: [12, 20]})}/>
{/*
                  <DeleteButton />
*/}
                  </MapContainer>
                :<Empty description={(editable)?<Button type="link" onClick={() => {this.getOSMGeoData()}}>Karte Erstellen</Button>:null} ></Empty>}
              <Modal
                width={1000}
                destroyOnClose={true}
                open={showModalMap}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <MapChoose
                  editable={editable}
                  changeLatLon={this.modalChangeLatLon}
                  changeZoom={this.modalChangeZoom}
                  geocodeData={this.state.geocodeData}
                />
              </Modal>
            </div>
          </Col>
          <Col span={14}>
            <Form name="location-adress-panel-form"
              layout={"vertical"}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Row gutter={[16,16]}>
                <Col span={14}>
                    <Space.Compact>
                      <Form.Item label={"Straße - Nr. / Zusatz"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0'}} >
                        <Input
                          name={"street"} value={this.state.locationAddress.street} style={{width: "100%"}}
//                              bordered={this.props.editable}
                          onChange={(e) => {this.handleChildChange(e.target.name,e.target.value)}}
                          onBlur={() => this.handleChildBlur()}
                          readOnly={!update} disabled={!update}
                        />
                      </Form.Item>
                      <Form.Item label={"Zusatz"}  style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 4px'}} >
                        <Input
                          name={"street2"} value={this.state.locationAddress.street2} style={{width: "100%"}}
//                              bordered={this.props.editable}
                          onChange={(e) => {this.handleChildChange(e.target.name,e.target.value)}}
                          onBlur={() => this.handleChildBlur()}
                          readOnly={!update} disabled={!update}
                        />
                      </Form.Item>
                    </Space.Compact>
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                    <Space.Compact>
                    <Form.Item label={"PLZ"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(20% - 4px)', margin: '0 4px 0 0'}} >
                      <Input
                        name={"zip"} value={this.state.locationAddress.zip} style={{width: "100%"}}
//                            bordered={this.props.editable}
                        onChange={(e) => {this.handleChildChange(e.target.name,e.target.value)}}
                        onBlur={() => this.handleChildBlur()}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Ort"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(34% - 8px)', margin: '0 4px'}} >
                      <Input
                        name={"city"} value={this.state.locationAddress.city} style={{width: "100%"}}
//                            bordered={this.props.editable}
                        onChange={(e) => {this.handleChildChange(e.target.name,e.target.value)}}
                        onBlur={() => this.handleChildBlur()}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Bezirk"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(34% - 8px)', margin: '0 4px'}} >
                      <Input
                        name={"citydistrict"} value={this.state.locationAddress.citydistrict} style={{width: "100%"}}
//                            bordered={this.props.editable}
                        onChange={(e) => {this.handleChildChange(e.target.name,e.target.value)}}
                        onBlur={() => this.handleChildBlur()}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    <Form.Item label={"Land"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(12% - 4px)', margin: '0 0 0 4px' }} >
                      <SelectCountry
                        name={"countryId"} value={this.state.locationAddress.countryId} style={{width: "100%"}}
//                            bordered={this.props.editable}
                        suffixIcon={this.props.editable?<DownOutlined />:null}
                        // showArrow={this.props.editable}
                        open={(!this.props.editable)?false:undefined}
                        popupMatchSelectWidth={false}
                        onChange={(v , o) => {this.handleChildChange("countryId", v)}}
                        onBlur={() => this.handleChildBlur()}
                        readOnly={!update} disabled={!update}
                      />
                    </Form.Item>
                    </Space.Compact>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    )
  }
}
