import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Drawer, Form, Input, Row, Space, Table, Tabs} from "antd";
import {ModalDraggable} from "./ModalDraggable"
import {SearchOutlined, UserAddOutlined, UserDeleteOutlined, UserSwitchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {SelectOrganisation} from "../common/cmnOrganisationSelect";
import {ButtonRights} from "../common/cmnButtonWithRights";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import PanelDndContainer, {parentTypes} from "../panel/panelDndContainer";
import {Account} from "../base/baseAccount";
import {isAdminUser} from "../system/checkTenants";
import {useDispatch} from "react-redux";
import {setLoggedInUser} from "../store/loggedInUserSlice";
import useCredentials from "../system/UseCredentials";
import {useNavigate} from "react-router-dom";

const apiUrlPersonList             = "/person/list"
const apiUrlOrganisationList       = "/org/list"

const apiUrlPersonShow             = "/person/show"

const apiUrlPersonSave             = "/person"
const apiUrlPersonCreate           = "/person"
const apiUrlPersonDelete           = "/person"

const apiUrlOrganisationShow       = "/org/show"

const apiUrlOrganisationSave       = "/org"
const apiUrlOrganisationCreate     = "/org"
const apiUrlOrganisationDelete     = "/org"

const apiUrlSwitchUser             = "/switchUser"

export const ModalAddresses = (props) => {
  const [persons, setPersons] = useState([])
  const [organisations, setOrganisations] = useState([])
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [activeTab, setActiveTab] = useState("")
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchColumn] = useState(-1)
  const searchInput = useRef(null)
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [credentials] = useCredentials()
  const userId = credentials.user.id
  useEffect(() => {
    loadPersons()
    loadOrganisations()
  }, [])
  const loadPersons = () => {
    apiRequest.get(apiUrlPersonList)
      .then(rd => {
        if (rd && rd.state && rd.data) setPersons(rd.data)
      })
      .catch(error => console.log("loadPersons in ModalAddresses: " + error))
  }
  const loadOrganisations = () => {
    apiRequest.get(apiUrlOrganisationList)
      .then(rd => {
        if (rd && rd.state && rd.data) setOrganisations(rd.data)
      })
      .catch(error => console.log("loadOrganisations in ModalAddresses: " + error))
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suchen
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small" style={{ width: 90 }}
          >
            Löschen
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchColumn(dataIndex)
              // this.setState({
              //   searchText: selectedKeys[0],
              //   searchedColumn: dataIndex,
              // })
            }}
          >
            Filtern
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
//    this.setState({searchText: selectedKeys[0], searchedColumn: dataIndex,})
    setSearchText(selectedKeys[0])
    setSearchColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  const doSelectionChange = (value, panel) => {
    if (selectedRowKeys.toString() !== value.toString()) {
      setSelectedRowKeys(value)
      setActiveTab(panel)
      setDrawerVisible(true)
//      this.setState({selectedRowKeys: value, activeTab: panel, drawerVisible: true})
    }
    else {
      setSelectedRowKeys([])
      setActiveTab(panel)
      setDrawerVisible(false)
//      this.setState({selectedRowKeys: [], activeTab: panel, drawerVisible: false})
    }
  }
  const addPerson = () => {
    apiRequest.post(apiUrlPersonCreate)
      .then(rd => {
        if (rd && rd.state && rd.data) {
          loadPersons()
          doSelectionChange(rd.data, "subjectPerson")
        }
      })
      .catch(error => console.log("addPerson in ModalAddresses: " + error))
  }
  const deletePerson = (rowData) => {
    apiRequest.delete(apiUrlPersonDelete + "/" + rowData.id)
      .then(rd => {
        loadPersons()
        setSelectedRowKeys([])
      })
      .catch(error => console.log("deletePerson in ModalAddresses: " + error))
  }
  const addOrganisation = () => {
    apiRequest.post(apiUrlOrganisationCreate)
      .then(rd => {
        if (rd && rd.state && rd.data) {
          loadOrganisations()
          doSelectionChange(rd.data, "subjectOrganisation")
        }
      })
      .catch(error => console.log("addOrganisation in ModalAddresses: " + error))
  }
  const deleteOrganisation = rowData =>{
    apiRequest.delete(apiUrlOrganisationDelete + "/" + rowData.id)
      .then(rd => {
        loadOrganisations()
        setSelectedRowKeys([])
      })
      .catch(error => console.log("deleteOrganisation in ModalAddresses: " + error))
  }
  const switchUser = (userId) => {
    apiRequest.put(apiUrlSwitchUser, JSON.stringify({userId: userId}))
      .then(rd => {
        if (rd && rd.state && rd.data) {
          dispatch(setLoggedInUser(rd.data))
          navigation("/ras/projectlist")
          // window.localStorage.setItem('cred', JSON.stringify({
          //   user: rd.data.user.id,
          //   tenant: rd.data.tenant.id,
          //   loaded: true
          // }))
          // window.alert(`Switch to user id => ${JSON.stringify(rd.data)}`)
          props.doOpen(false)
          // props.doAddressVisible(false)
        }
      })
      .catch(error => console.log("ModalAddresses in switchUser: " + error))
  }
  const renderDrawer = () =>{
    return(
      <Card
        title={<>
        {(activeTab === "subjectPerson")
          ?"Personen"
          :null}
        {(activeTab === "subjectOrganisation")
          ?"Organisationen"
            :null}
        </>}
      >
        {(activeTab === "subjectPerson")?
          <>
            <PersonData workId={selectedRowKeys.toString()}/>
            {(props.isAdminUser)
            ?<Account personId={selectedRowKeys.toString()} />
            :null}
          </>
        : null}
        {(activeTab === "subjectOrganisation")?
          <>
            <OrganisationData workId={selectedRowKeys.toString()}/>
          </>
          : null}
        <>
            <PanelDndContainer
              title={"Adressen / Kontakt"}
              bordered={false}
              styles={{body: {padding: "12px 0"}, head: {padding: 0}}}
              bodyStyle={{padding: "12px 0"}}
              headStyle={{padding: 0}}
              key={"AddressContact" + selectedRowKeys.toString()}
              projectId="00000000-0000-0000-0000-000000000000"
              workId={selectedRowKeys.toString()}
//TODO parenTypes anpassen für Person und Organisation
              parentType={parentTypes.address}
              panelView="line"
              dragable={false}
              panelTypes={["communication", "address"]}
              panelStyle="smallList"
              rightsParent={[sysRight.CREATE,sysRight.UPDATE]}
            />
        </>
      </Card>
    )
  }
  return (<>
      {(props.open)?
      <ModalDraggable
        titleName="Adressen Verwalten"
        width={"900px"}
        style={{ top: 20, left: 50}}
        open={props.open}
        // open={props.addressVisible}
        onCancel={() => {props.doOpen(!props.open)}}
        // onCancel={() => {props.doAddressVisible(!props.addressVisible)}}
        onOk={() => {props.doOpen(!props.open)}}
        // onOk={() => {props.doAddressVisible(!props.addressVisible)}}
        footer={null}
      >
      <h2>Adressen Verwalten</h2>
        <Tabs defaultactivekey={(props.panelTypes.has("subjectPerson"))?"subjectPerson":"subjectOrganisation"}
              destroyInactiveTabPane={true}
        >
          {(props.panelTypes.has("subjectPerson")) ?
            <Tabs.TabPane tab="Personen" key="subjectPerson" >
              <Table dataSource={persons}
                     rowSelection={{type: "radio",
                       onChange: (selectedKeys) => {doSelectionChange(selectedKeys, "subjectPerson") },
                       selectedRowKeys: selectedRowKeys,
                       columnWidth: 0,
                       renderCell: () => ""
                     }}
//                        onRow={(row, index) => {
//                          return {
// //                           onDoubleClick: e =>{console.log("Person doubleclick: " + row.id)},
// //                       onClick: e =>{this.props.doChange([row.id])}
//                            onClick: e =>{this.doSelectionChange([row.id], "subjectPerson")}
//                          }
//                        }}
                     columns={
                       [
                         { title: "Titel", dataIndex: "academicTitle",
                           width: 50,
                           ...getColumnSearchProps('academicTitle'),
                           sorter: (a, b) => a.academicTitle.toLowerCase().localeCompare(b.academicTitle.toLowerCase()),
                           sortDirections: ['descend', 'ascend'],
                           onCell: (row, index) => {return {onClick: e =>{doSelectionChange([row.id], "subjectPerson")}}}
                         },
                         { title: "Nachname", dataIndex: "lastName",
                           width: 150, ...getColumnSearchProps('lastName'),
                           sorter: (a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()),
                           sortDirections: ['descend', 'ascend'],
                           onCell: (row, index) => {return {onClick: e =>{doSelectionChange([row.id], "subjectPerson")}}}
                         },
                         { title: "Vorname", dataIndex: "firstName",
                           width: 150, ...getColumnSearchProps('firstName'),
                           sorter: (a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
                           sortDirections: ['descend', 'ascend'],
                           onCell: (row, index) => {return {onClick: e =>{doSelectionChange([row.id], "subjectPerson")}}}
                         },
                         { title: "2. Vorname", dataIndex: "middleName",
                           width: 150,
                           ...getColumnSearchProps('middleName'),
                           sorter: (a, b) => a.middleName.toLowerCase().localeCompare(b.middleName.toLowerCase()),
                           sortDirections: ['descend', 'ascend'],
                           onCell: (row, index) => {return {onClick: e =>{doSelectionChange([row.id], "subjectPerson")}}}
                         },
                         { title: <>
                             <ButtonRights
                               titlePrefix=""
                               buttonText=""
                               rightTypes={[sysRight.CREATE]}
                               entityName={"person"}
                               handleCreate={() => {addPerson()}}
                               icon={<UserAddOutlined style={{ fontSize: '1.5em'}} />}
                             />
                           </>,
                           align: "right",
                           render: (data, row, ix) => {return (
                             <>
                               {isAdminUser(userId)
                               ?<Button
                                 type="text"
                                 icon={<UserSwitchOutlined style={{fontSize: '1.5em', color: "forestgreen"}}/>}
                                 onClick={() => {switchUser(row.id)}}
                               />
                               :null}
                               <ButtonRights
                                 titlePrefix=""
                                 rightTypes={[sysRight.DELETE]}
                                 entityName={"person"}
                                 icon={<UserDeleteOutlined style={{ fontSize: '1.5em'}} />}
                                 handleDelete={() => {deletePerson(row)}}
                               />
                             </>
                           )}
                         },
                       ]}
                     rowKey={record => record.id}/>
            </Tabs.TabPane>
            : null }
          {(props.panelTypes.has("subjectOrganisation")) ?
            <Tabs.TabPane tab="Firma/Büro" key="subjectOrganisation">
              <Table dataSource={organisations}
                     rowSelection={{type: "radio",
                       onChange: (selectedKeys) => {doSelectionChange(selectedKeys, "subjectOrganisation") },
                       selectedRowKeys: selectedRowKeys,
                       columnWidth: 0,
                       renderCell: () => ""
                     }}
//                        onRow={(row, index) => {
//                          return {
// //                           onDoubleClick: e =>{console.log("Person doubleclick: " + row.id)},
// //                           onClick: e =>{this.props.doSelectionChange([row.id], "subjectOrganisation") }
//                            onClick: () => {this.doSelectionChange([row.id], "subjectOrganisation") }
//                          }
//                        }}
                     columns={
                       [
                         { title: "Firma", dataIndex: "name1",
                           width: 50,
                           ...getColumnSearchProps('name1'),
                           sorter: (a, b) => a.name1.toLowerCase().localeCompare(b.name1.toLowerCase()),
                           sortDirections: ['descend', 'ascend'],
                           onCell: (row, index) => {return {onClick: e =>{doSelectionChange([row.id], "subjectOrganisation")}}}
                         },
                         { title: "Firma Zusatz", dataIndex: "name2",
                           width: 150,
                           ...getColumnSearchProps('name2'),
                           sorter: (a, b) => a.name2.toLowerCase().localeCompare(b.name2.toLowerCase()),
                           sortDirections: ['descend', 'ascend'],
                           onCell: (row, index) => {return {onClick: e =>{doSelectionChange([row.id], "subjectOrganisation")}}}
                         },
                         { title: <>
                             <ButtonRights
                               titlePrefix=""
                               buttonText=""
                               rightTypes={[sysRight.CREATE]}
                               entityName={"organisation"}
                               handleCreate={() => {addOrganisation()}}
                               icon={<UserAddOutlined style={{ fontSize: '1.5em'}} />}
                             />
                             </>,
                           align: "right",
                           render: (data, item, ix) => {return (
                             <>
                               <ButtonRights
                                 titlePrefix=""
                                 rightTypes={[sysRight.DELETE]}
                                 entityName={"organisation"}
                                 icon={<UserDeleteOutlined style={{ fontSize: '1.5em'}} />}
                                 handleDelete={() => {deleteOrganisation(item)}}
                               />
                             </>
                           )}
                         },
                       ]                   }
                     rowKey={record => record.id}/>
            </Tabs.TabPane>
            : null }
        </Tabs>
        <Drawer
//           getContainer={() => document.getElementById("root")}
          title="Adressen Verwalten"
          open={drawerVisible}
          width={800}
          onClose={() =>{setDrawerVisible(false); setSelectedRowKeys([]); loadPersons(); loadOrganisations()}}
          // onClose={() =>{this.setState({drawerVisible: false, selectedRowKeys: []}); this.loadPersons(); this.loadOrganisations()}}
        >
          {renderDrawer()}
        </Drawer>
      </ModalDraggable>
        :null}
    </>)
}

const defaultPerson = {
  id: "",
  active: true,
  position: -1,
  tenantId: "",
  title: "",
  salutation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  academicTitle: "",
  officialTitle: "",
  nobilityTitle: "",
  birthDate: "",
  organisationId: "",
}
const PersonData = ({workId}) => {
  const [person, setPerson] = useState({...defaultPerson})
  const [isChanged, setIsChanged] = useState(false)
  useEffect(() => loadPerson(workId), [workId])
  const loadPerson = (personId) => {
    if (personId)
      apiRequest.get(apiUrlPersonShow + "/" + personId)
        .then(rd =>{
          if (rd && rd.state && rd.data) {
            setPerson(rd.data)
            setIsChanged(false)
          }
        })
        .catch(error => console.log("loadPerson in PersonData: " + error))
  }
  const savePerson = () => {
    if (isChanged)
      apiRequest.put(apiUrlPersonSave, JSON.stringify({...person}))
        .then(rd => setIsChanged(false))
        .catch(error => console.log("savePerson in PersonData: " + error))
  }
  const handleChange = evt => {
    const name = evt.target.name
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    setPerson(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
  }
  const handleChangeSelect = (name, value) => {
    setPerson(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
  }
  const handleBlur = () => savePerson()
  return (<Row>
      <Col span={24}>
        <Form name="person-data-form"
          layout={"vertical"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={[16,12]}>
            <Col span={24}>
              <Row>
                <Col span={24}>
                    <Form.Item label={"Anrede"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(30% - 4px)', margin: '0 4px 0 0'}} >
                      <Input name={"title"} value={person.title} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                    <Form.Item label={"Titel"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 4px'}} >
                      <Input name={"academicTitle"} value={person.academicTitle} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                    <Form.Item label={"Amtstitel"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(40% - 4px)', margin: '0 0 0 4px'}} >
                      <Input name={"officialTitle"} value={person.officialTitle} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                    <Form.Item label={"Vorname"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(30% - 4px)', margin: '0 4px 0 0'}} >
                      <Input name={"firstName"} value={person.firstName} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                    <Form.Item label={"2. Vorname"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 4px'}} >
                      <Input name={"middleName"} value={person.middleName} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                    <Form.Item label={"Nachname"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(40% - 4px)', margin: '0 0 0 4px'}} >
                      <Input name={"lastName"} value={person.lastName} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                    <Form.Item label={"Briefanrede"}  style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0'}} >
                      <Input name={"salutation"} value={person.salutation} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                    <Form.Item label={"Firma / Büro"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 4px'}} >
                      <SelectOrganisation name={"organisationId"} value={person.organisationId} style={{width: "100%"}}
                             onChange={(value) => {handleChangeSelect("organisationId", value)}}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                </Col>
                </Row>
{/*
              <Row>
                <Col span={24}>
                    <Form.Item label={"Adelstitel Titel"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100%)', margin: '0 0px'}} >
                      <Input name={"nobilityTitle"} value={this.state.person.nobilityTitle} style={{width: "100%"}}
                             onChange={(e) => {this.handleChange(e)}}
                             onBlur={() => this.handleBlur()}
                      />
                    </Form.Item>
                </Col>
              </Row>
*/}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>)
}
const defaultOrganisation = {
  id: "",
  active: true,
  position: -1,
  tenantId: "",
  name1: "",
  name2: "",
}
const OrganisationData = ({workId}) => {
  const [organisation, setOrganisation] = useState({...defaultOrganisation})
  const [isChanged, setIsChanged] = useState(false)
  useEffect(() => loadOrganisation(workId), [workId])
  const loadOrganisation = (organisationId) => {
    if (organisationId) {
      apiRequest.get(apiUrlOrganisationShow + "/" + organisationId)
        .then(rd => {
          if (rd && rd.state && rd.data) {
            setOrganisation(rd.data)
            setIsChanged(false)
          }
        })
        .catch(error => console.log("loadOrganisation in OrganisationData: " + error))
    }
  }
  const saveOrganisation = () => {
    if (isChanged)
      apiRequest.put(apiUrlOrganisationSave, JSON.stringify({...organisation}))
        .then(rd => setIsChanged(false))
        .catch(error => console.log("saveOrganisation in OrganisationData: " + error))
  }
  const handleChange = evt => {
    const name = evt.target.name
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    setOrganisation(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
  }
  const handleBlur = () => saveOrganisation()
  return (<Row>
      <Col span={24}>
        <Form name="adress-form" layout={"horizontal"} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
          <Row gutter={[16,12]}>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item label={"Firma"} style={{ marginBottom: 0 }} >
                    <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100%)', margin: '0 0px'}} >
                      <Input name={"name1"} value={organisation.name1} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={"Firma Zusatz"} style={{ marginBottom: 0 }} >
                    <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100%)', margin: '0 0px'}} >
                      <Input name={"name2"} value={organisation.name2} style={{width: "100%"}}
                             onChange={handleChange}
                             onBlur={handleBlur}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>)
}
