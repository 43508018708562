import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {ModalDraggable} from "../../dialog/ModalDraggable";
import apiRequest from "../../general/apiRequest";

const apiUrlBreakdownList        = "/breakdown/byParent"

export const ModalBreakdownChoose = (props) => {
  const [breakdowns, setBreakdowns] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  useEffect(() => {
    const loadBreakdowns = async () => {
      const rd = await apiRequest.get(apiUrlBreakdownList + "/" + props.parentId + "?isTemplate=true")
      // const rd = await apiRequest.get(apiUrlBreakdownList + "/" + props.parentId + "?template=true")
      if (rd && rd.state && rd.data) setBreakdowns(rd.data)
    }
    loadBreakdowns()
  },[props.parentId])
  const onSelectChange = (selRowKeys, selRows) => {
    const sel = (selRowKeys !== "")? selRowKeys.toString().split(","): []
    setSelectedRowKeys(sel)
  }
  const columns=[
    { title: "Kennzeichnungsnr.", dataIndex: "codeNumber", width: 150,
      onCell: (row, index) => {return {onClick: e => onSelectChange(row.id, row)}},},
    { title: "Projekt", dataIndex: "name", width: 300,
      onCell: (row, index) => {return {onClick: e => onSelectChange(row.id, row)}},},
    { title: ""},
    ]
  const rowSelection = {
    type: "radio",
    columnWidth: 0,
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    renderCell: () => ""
  };
  return (
      <ModalDraggable
        open={props.open}
        maskClosable={true}
        titleName="Template Projekt Auswählen"
        width={"900px"}
        style={{ top: 20, left: 50}}
        onCancel={() => props.onCancel(false)}
        onOk={() => props.onOk({selectedRowKeys})}
      >
        <h2>Template auswählen</h2>
        <Table
          columns={columns}
          dataSource={breakdowns}
          rowSelection={rowSelection}
          rowKey={record => record.id}
        />
      </ModalDraggable>
  )
}