import React, {useEffect, useState} from 'react';
import {Row, Col, DatePicker, Form, Empty, Button} from "antd";
import dayjs from 'dayjs';
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import PanelDndContainer, {parentTypes} from "../panel/panelDndContainer";

const apiUrlShowRestoration            = "/restoration/byParent"
const apiUrlSaveRestoration            = "/restoration"
const apiUrlCreateRestoration          = "/restoration"

const defaultRestoration = {
  id: null,
  position: -1,
  projectId: "",
  parentId: "",
  completionDate: null,
}
export const Restoration = ({editable, parentId, projectId, panelStyle = "default", rightsParent = [], viewEntities = "large", ...props}) => {
  const [restoration, setRestoration] = useState(defaultRestoration)
  useEffect(() => {
    loadRestoration(parentId)
  }, [parentId]);
  const loadRestoration = (parentId) => {
    if (parentId) {
      apiRequest.get(apiUrlShowRestoration + "/" + parentId)
        .then((rd) => {
          if (rd && rd.state && rd.data && rd.data.length > 0)
            setRestoration(rd.data[0])
        })
        .catch(error => console.log("loadRestoration in Restoration: " + error))
    }
  }
  const handleSave = () => {
    apiRequest.put(apiUrlSaveRestoration, JSON.stringify(restoration))
      .then((rd) => {
        if (rd && rd.state) console.log("Capture Saved")
      })
      .catch(error => console.log("handleSave in Restoration: " + error))
  }
  const createRestoration = () => {
    apiRequest.post(apiUrlCreateRestoration + "/" + parentId)
      .then((rd) => {
        if (rd && rd.state && rd.data) setRestoration(rd.data)
      })
      .catch(error => console.log("createRestoration in Restoration: " + error))
  }
  const DatePickerChange = (name, date, dateString) => {
    setRestoration(prevState => {
      return {...prevState, [name]: date}
    })
    // const newDate = new Date(date).getTime()
    // this.setState({restoration:{...this.state.restoration, completionDate: newDate}})
  }
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  const completionDate = (restoration && restoration.completionDate) ? dayjs(new Date(restoration.completionDate)): null
  return <div>
    {(restoration && restoration.id !== null)?
      <>
        <Row gutter={[16,16]}>
          <Col span={12}>{(editable)
            ?<Form.Item label="Fertigstelldatum" >
              <DatePicker
                name="completionDate"
                placeholder={"Datum der Fertigstellung"}
                value={completionDate}
                onChange={(date, dateString) => {DatePickerChange("completionDate", date, dateString)}}
                format={"DD.MM.YYYY"}
                onBlur={() => handleSave()}
                readOnly={!editable || !update} disabled={!update}
              />
            </Form.Item>
            :<>
              <h4><b>Restaurierung</b></h4>
              <h6><b>Fertigstelldatum: {(completionDate) ? completionDate.format('DD.MM.YYYY') : ""}</b>
              </h6>
            </>}
          </Col>
        </Row>
          <PanelDndContainer
            key={"PLRestorationEdit"}
            panelStyle={panelStyle}
            editable={editable}
            viewEntities={viewEntities}
            projectId={projectId}
            workId={restoration.id}
            parentType={parentTypes.restoration}
            panelTypes={["text", "media"]}
            rightsParent={rightsParent}
        />
      </>
      :<Empty description="Keine Daten">{(rightsParent.indexOf(sysRight.CREATE) >= 0)?<Button onClick={createRestoration}>Erstellen</Button>:null}</Empty>}
  </div>
}

export class Restoration1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      restoration: {
        id: null,
        position: -1,
        projectId: "",
        parentId: "",
        completionDate: null
      }
    }
  }
  componentDidMount() {
    this.loadRestoration(this.props.parentId)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.restoration?.parentId !== this.props.parentId) {
      this.loadRestoration(this.props.parentId)
    }
  }
  loadRestoration = async (parentId) => {
    if (parentId) {
      const rd = await apiRequest.get(apiUrlShowRestoration + "/" + parentId)
      if (rd && rd.state && rd.data && rd.data.length > 0)
        this.setState({restoration: rd.data[0]})
    }
  }
  handleSave = async () => {
    await apiRequest.put(apiUrlSaveRestoration, JSON.stringify(this.state.restoration))
  }
  createRestoration = async () => {
    const rd = await apiRequest.post(apiUrlCreateRestoration + "/" + this.props.parentId)
    if (rd && rd.state && rd.data) {
      this.setState({restoration: rd.data})
    }
  }
  DPChange = (name, date, dateString) => {
    const newDate = new Date(date).getTime()
    this.setState({restoration:{...this.state.restoration, completionDate: newDate}})
  }
  renderView = () => {
    const completionDate = (this.state.restoration && this.state.restoration.completionDate) ? dayjs(new Date(this.state.restoration.completionDate)): null
    if (this.state.restoration) {
      return (
        <div key={"PLRestorationView" + this.state.restoration.id}>
          <Row gutter={[16, 16]}>
            <Col span={12} style={{margin: "2em 0 0 4.5em"}}>
              <h4><b>Restaurierung</b></h4>
              <h6><b>Fertigstelldatum: {(completionDate) ? completionDate.format('DD.MM.YYYY') : ""}</b></h6>
            </Col>
          </Row>
          <PanelDndContainer
            key={"PLRestorationView"}
            panelStyle={this.props.panelStyle}
            editable={this.props.editable}
            viewEntities={this.props.viewEntities}
            projectId={this.props.projectId}
            workId={this.state.restoration.id}
            parentType={parentTypes.restoration}
            panelTypes={["text", "media"]}
          />
        </div>
      )
    }
  }
  renderEdit = () => {
    const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)    // console.log("Date: " + completionDate)
    const create = (this.props.rightsParent.indexOf(sysRight.CREATE) >= 0)
    const completionDate = (this.state.restoration && this.state.restoration.completionDate) ? dayjs(new Date(this.state.restoration.completionDate)): null
    return (
      <div>
        {(this.state.restoration && this.state.restoration.id !== null)?
          <>
            <Row gutter={[16,16]}>
              <Col span={12}>
                <Form.Item label="Fertigstelldatum" >
                  <DatePicker
                    name="completionDate"
                    placeholder={"Datum der Fertigstellung"}
                    value={completionDate}
                    onChange={(date, dateString) => {this.DPChange("completionDate", date, dateString)}}
                    format={"DD.MM.YYYY"}
                    onBlur={() => this.handleSave()}
                    readOnly={!this.props.editable || !update} disabled={!update}
                  />
                </Form.Item>
              </Col>
            </Row>
            <PanelDndContainer
              key={"PLRestorationEdit"}
              panelStyle={this.props.panelStyle}
              editable={this.props.editable}
              viewEntities={this.props.viewEntities}
              projectId={this.props.projectId}
              workId={this.state.restoration.id}
              parentType={parentTypes.restoration}
              panelTypes={["text", "media"]}
              rightsParent={this.props.rightsParent}
            />
          </>
          :<Empty description="Keine Daten">{(create)?<Button onClick={this.createRestoration}>Erstellen</Button>:null}</Empty>
        }
      </div>
    )
  }
  render() {
    return (this.props.editable)? this.renderEdit():this.renderView()
  }
}

Restoration.defaultProps = {
  rightsParent: [],
  panelStyle: "default",
  editable: true,
  viewEntities: "large",
}