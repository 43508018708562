import React, {useEffect, useState} from "react";
import apiRequest from "../../../general/apiRequest";
import {Link} from "react-router-dom";


const defParent = {
  id            : "",
  name1         : "",
  name2         : "",
  itemType      : "",
}
const apiUrlGetParent             = "/workpiece/getParent"

export const WorkpieceParent = ({workpieceId, ...props}) => {
  const [parent, setParent] = useState({...defParent})
  useEffect(() => {
    loadParent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workpieceId])
  const loadParent = () => {
    if (workpieceId)
      apiRequest.get(`${apiUrlGetParent}/${workpieceId}`)
        .then(rd => {
          if (rd && rd.state && rd.data) setParent(rd.data)
        })
        .catch(error => console.log("Error in WorkpieceParent: " + error))
  }
  return <div className={"parent"}>
    {(parent.itemType === "project")
      ?<Link to={`/ras/${parent.itemType}/${parent.id}`}>
        {parent.codeNumber}<br />{parent.name}
      </Link>
      :<Link to={`/ras/${parent.itemType}/${parent.id}`}>
        {parent.codeNumber}<br />{parent.name}
      </Link>
    }
  </div>
}
