import React, {useEffect, useState} from "react"
import apiRequest from "../../../general/apiRequest";
import {Button, Card, Col, Form, Input, InputNumber, Popconfirm, Row, Table, Tooltip} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {SourceTypeSelect} from "../../../common/cmnSourceTypeSelect";
import {EntitySelect} from "../../../common/cmnEntitySelect";
import {ImportSchemaRowTypeDrawer, ImportSchemaRowTypeTable} from "./ImportSchemaRowType";
import useCredentials from "../../../system/UseCredentials";

const apiUrlImportSchemaShow                = "/importSchema/show"
const apiUrlImportSchemaSave                = "/importSchema"
const apiUrlImportSchemaList                = "/importSchema/list"
const apiUrlImportSchemaCreate              = "/importSchema"
const apiUrlImportSchemaDelete              = "/importSchema"

const importSchemaNew = {
  id: null,
  active: true,
  name: "Neu",
  description: "",
  sourceTypeId: "2eda3b1d-6b1c-11ed-9d8f-18cc1881fa4b",
  anchorNodeMetaId: null,
  sheet: 0,
  headerRow: 0,
  firstDataRow: 0,
  lastDataRow: 0,
  firstDataColumn: 0,
  lastDataColumn: 0,
  tenantId: null,
}

export const ImportSchemaTable = (props) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  const [importSchemas, setImportSchemas] = useState([])
  const {/*anchorRefId,*/ selectedImportSchemaRowKey, setSelectedImportSchemaRowKey} = props
  useEffect(() => {loadImportSchemas()}, [])
  const loadImportSchemas = async () => {
    const res = await apiRequest.get(apiUrlImportSchemaList)
    if (res && res.state && res.data) setImportSchemas(res.data)
  }
  const createImportSchema = async () => {
    const res = await apiRequest.post(apiUrlImportSchemaCreate, JSON.stringify({...importSchemaNew, tenantId: tenantId}))
    if (res && res.state && res.data) {
      setImportSchemas(importSchemas.concat(res.data))
    }
  }
  const deleteImportSchema = async (schemaId) => {
    const res = await apiRequest.delete(apiUrlImportSchemaDelete + "/" +schemaId)
    if (res && res.state) {
      setImportSchemas(importSchemas.filter(elm => (elm.id !== schemaId)))
    }
  }
  const doSelectionChange = (value) => {
    // const is = [...importSchemas]
    // const ar = is.find(item => item.id = value)
    // anchorRefId.current = ar
//    const ai = importSchemas.find(item => item.id === value)
//    anchorRefId.current = ai.anchorNodeMetaId
    // const ai = importSchemas.findIndex(item => item.id === value)
    // anchorRefId.current = importSchemas[ai].anchorNodeMetaId
    setSelectedImportSchemaRowKey(/*prevVal => */value)
  }
  return <Table
    rowKey={"id"}
    rowSelection={{
      type: "radio",
//      onChange: (selectedKeys) => {doSelectionChange(selectedKeys)},
      onChange: (selectedKeys) => {doSelectionChange((selectedKeys.length > 0)?selectedKeys[0]:null)},
      selectedRowKeys: [selectedImportSchemaRowKey],
      columnWidth: 0,
      renderCell: () => "",
    }}
    dataSource={importSchemas}
    columns={[
      {title: "Name", dataIndex: "name", ellipsis: true, width: 240,
        onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}}
      },
      {title: <Tooltip title={"Schema hinzufügen"}><Button type={"link"} icon={<PlusOutlined />} onClick={createImportSchema} /></Tooltip>,
        align: "right",
        render: (data, row, ix ) =>
          <Popconfirm
            placement={"top"}
            title={"Möchten Sie den Datensatz löschen"}
            onConfirm={() => {deleteImportSchema(row.id)}}
            okText="Ja"
            cancelText="Nein"
            cancelButtonProps={{danger: false, type: "primary"}}
            okButtonProps={{danger: true, ghost: true}}
          >
            <Button type={"link"} danger icon={<DeleteOutlined />} />
          </Popconfirm>
      }
    ]}
  />
}
export const ImportSchemaContainer = (props) => {
//  const [selectedRowTypeRowKey, setSelectedRowTypeRowKey] = useState(null)
  const {anchorRefId/*, schemaId*/, selectedImportSchemaRowKey, selectedRowTypeRowKey, setSelectedRowTypeRowKey} = props
  useEffect(() => {}, [selectedImportSchemaRowKey, selectedRowTypeRowKey])
  const doSelectionChange = value => {
    setSelectedRowTypeRowKey(value)
  }
  return <>
    <Row>
      <Col span={24}>
        <ImportSchemaEdit
          importSchemaAnchor={anchorRefId}
          schemaId={selectedImportSchemaRowKey}
        />

      </Col>
      <Col span={24}>
        <ImportSchemaRowTypeTable
          schemaId={selectedImportSchemaRowKey}
          selectedRowTypeRowKey={selectedRowTypeRowKey}
          setSelectedRowTypeRowKey={doSelectionChange}
        />
      </Col>
    </Row>
    <ImportSchemaRowTypeDrawer
      importSchemaAnchor={anchorRefId}
      selectedRowTypeRowKey={selectedRowTypeRowKey}
      setSelectedRowTypeRowKey={doSelectionChange}
      destroyOnClose
    />
  </>
}
export const ImportSchemaEdit = (props) => {
  const [importSchema, setImportSchema] = useState({})
  const [isChanged, setIsChanged] = useState(false)
  const {importSchemaAnchor} = props
  useEffect(() => {
    loadImportSchema()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.schemaId])
  const handleBlur = async () => {
    if (isChanged) {
      await apiRequest.put(apiUrlImportSchemaSave, JSON.stringify(importSchema))
      setIsChanged(false)
    }
  }
  const handleChange = (name, value) => {
    setImportSchema(prevImportSchema => {
      if (prevImportSchema?.anchorNodeMetaId !== importSchema?.anchorNodeMetaId) {
        importSchemaAnchor.current = prevImportSchema?.anchorNodeMetaId
      }
      return {...importSchema, [name]: value}
    })
//    setImportSchema({...importSchema, [name]: value})
    setIsChanged(true)
  }
  const loadImportSchema = async () => {
//    if (props.schemaId && props.schemaId.length > 0) {
    if (props?.schemaId) {
//      const res = await apiRequest.get(apiUrlImportSchemaShow + "/" + props.schemaId[0])
      const res = await apiRequest.get(apiUrlImportSchemaShow + "/" + props.schemaId)
      if (res && res.state && res.data) {
        importSchemaAnchor.current = res.data.anchorNodeMetaId
        setImportSchema(res.data)
      }
    } else setImportSchema({})
  }
  return <Card title={null}>
    <Form name="import-schema-form" layout={"vertical"} preserve={false}>
      <Row><Col span={24}>
        <Form.Item label={"Name"} style={{ marginBottom: 0, }} >
          <Input
            name={"name"}
            value={importSchema.name}
            onChange={(e) => {handleChange(e.target.name, e.target.value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Col></Row>
      <Row><Col span={24}>
        <Form.Item label={"Beschreibung"} style={{ marginBottom: 0, }} >
          <Input.TextArea
            name={"description"}
            value={importSchema.description}
            autoSize={{ minRows: 4, maxRows: 8 }}
            onChange={(e) => {handleChange(e.target.name, e.target.value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Col></Row>
      <Row><Col span={24}>
        <Form.Item label={"Quellformat"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 4px)', margin: '0 4px 0 0', }} >
          <SourceTypeSelect
            name={"sourceTypeId"}
            value={importSchema.sourceTypeId}
            style={{ width: "100%" }}
            onChange={(value) => {handleChange("sourceTypeId", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item label={"Anker-Objekttyp"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 8px)', margin: '0 4px', }} >
          <EntitySelect
            name={"anchorNodeMetaId"}
            value={importSchema.anchorNodeMetaId}
            style={{ width: "100%" }}
            allowClear
            onChange={(value) => {handleChange("anchorNodeMetaId", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Col></Row>
      <Row><Col span={24}>
        <Form.Item label={"Arbeitsblatt"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 4px)', margin: '0 4px 0 0', }} >
          <InputNumber
            name={"sheet"}
            value={importSchema.sheet}
            style={{width: "100%"}}
            controls={false}
            decimalSeparator={","}
            min={0}
            precision={0}
            onChange={(value) => {handleChange("sheet", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item label={"erste Datenzeile"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 8px)', margin: '0 4px', }} >
          <InputNumber
            name={"firstDataRow"}
            value={importSchema.firstDataRow}
            style={{width: "100%"}}
            controls={false}
            decimalSeparator={","}
            min={0}
            precision={0}
            onChange={(value) => {handleChange("firstDataRow", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item label={"erste Datenspalte"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 4px)', margin: '0 0 0 4px', }} >
          <InputNumber
            name={"firstDataColumn"}
            value={importSchema.firstDataColumn}
            style={{width: "100%"}}
            controls={false}
            decimalSeparator={","}
            min={0}
            precision={0}
            onChange={(value) => {handleChange("firstDataColumn", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Col></Row>
      <Row><Col span={24}>
        <Form.Item label={"Kopfzeile"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 4px)', margin: '0 4px 0 0', }} >
          <InputNumber
            name={"headerRow"}
            value={importSchema.headerRow}
            style={{width: "100%"}}
            controls={false}
            decimalSeparator={","}
            min={0}
            precision={0}
            onChange={(value) => {handleChange("headerRow", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item label={"letzte Datenzeile"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 8px)', margin: '0 4px', }} >
          <InputNumber
            name={"lastDataRow"}
            value={importSchema.lastDataRow}
            style={{width: "100%"}}
            controls={false}
            decimalSeparator={","}
            min={0}
            precision={0}
            onChange={(value) => {handleChange("lastDataRow", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item label={"letzte Datenspalte"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33.33% - 4px)', margin: '0 0 0 4px', }} >
          <InputNumber
            name={"lastDataColumn"}
            value={importSchema.lastDataColumn}
            style={{width: "100%"}}
            controls={false}
            decimalSeparator={","}
            min={0}
            precision={0}
            onChange={(value) => {handleChange("lastDataColumn", value)}}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Col></Row>
    </Form>
  </Card>
}
