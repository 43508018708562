import React, {useEffect, useState} from 'react'
import apiRequest from "../general/apiRequest";
import {Card, Table} from "antd";

const apiUrlProjectSummarize        = "/project/summarize"

export const ProjectSummarize = (props) => {
  const [summarize, setSummarize] = useState([])
  useEffect(() => loadSummarize(), [])
  const loadSummarize = async () => {
    const rd = await apiRequest.get(apiUrlProjectSummarize)
    if (rd && rd.state && rd.data)
      setSummarize(rd.data)
  }
  return (
    <Card
      title={"Projektzusammenfassung"}
    >
      <Table
        dataSource={summarize}
        rowKey={record => record.id}
//        pagination={}
        columns={[
          {title: "Projekt", dataIndex: "name", width: 360, },
          {title: "Strukturen", dataIndex: "breakdownAmount", width: 96, align: "right", },
          {title: "Räume", dataIndex: "roomAmount", width: 96, align: "right", },
          {title: "Objekte", dataIndex: "workpieceAmount", width: 96, align: "right", },
          {title: "Dateiengrösse (MB)", dataIndex: "documentSize", width: 96, align: "right", },
          {title: "", }
        ]}
      />
    </Card>
  )
}