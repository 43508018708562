import React, {createContext, useState} from "react";

const contextState = {
  breadcrumbType: "",
  entityName: "",
  pageType: "",
  entityId: null,
  state: "",
  favorite: null,
  breadCrumbList: [],
}

//const CsescaContext = createContext([contextState, undefined])
const CsescaContext = createContext()

const CsescaProvider = (props) => {
  const [state, setState] = useState(contextState)
  const handleState = (value) =>  setState(value)
  return <CsescaContext.Provider value={[state, handleState]}>
    {props.children}
  </CsescaContext.Provider>
}
export {CsescaContext as default, CsescaProvider}