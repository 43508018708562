import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Empty} from "antd";
import {RestorationGoal} from "./rascalRestorationGoal";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import PanelDndContainer, {parentTypes} from "../panel/panelDndContainer";

const apiUrlShowPlaning                    = "/planning/byParent"
const apiUrlCreatePlaning                  = "/planning"
//const apiUrlRestorationGoalForProject      = "/restorationgoal/forproject"
// const planningTextTypeId                  = "23cee8fd-ce1c-4f6a-a8d4-6185483562dd"

const defaultPlaning = {
  id: null,
  position: -1,
  projectId: "",
  parentId: "",
}
export const Planning = ({editable, panelStyle, parentId, projectId, rightsParent, viewEntities, ...props}) => {
  const [planning, setPlanning] = useState({...defaultPlaning})
  const [restorationGoals/*, setRestorationGoals*/] = useState([])
  useEffect(() => {
    loadPlaning(parentId)
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);
  const loadPlaning = (parentId) => {
    if (parentId) {
      apiRequest.get(apiUrlShowPlaning + "/" + parentId)
        .then((rd) => {
          if (rd && rd.state && rd.data && rd.data.length > 0) setPlanning(rd.data[0])
        })
        .catch(error => console.log("loadPlaning in Planning: " + error))
    }
  }
  const createPlanning = () => {
    apiRequest.post(apiUrlCreatePlaning + "/" + parentId)
      .then((rd) => {
//TODO Check why categoryId is empty      await this.loadRestorationGoals(this.props.projectId, this.props.categoryId)
        if (rd && rd.state && rd.data) setPlanning(rd.data)
      })
      .catch(error => console.log("createPlanning in Planning: " + error))
  }
  return <div key={"PLPlaningEditMain" + planning?.id}>
    {(!editable)
      ?<Row gutter={[16,16]}>
        <Col span={12} style={{margin: "2em 0 0 4.5em"}}>
          <h4><b>Planung</b></h4>
        </Col>
      </Row>
    :null}
    <Row gutter={[16,16]}>
      <Col span={20}>
        {restorationGoals.map(restorationGoal =>(<RestorationGoal item={restorationGoal} readOnly={true}/>))}
      </Col>
      <Col span={4} />
    </Row>
    {(planning && planning.id !== null)?
    <PanelDndContainer
      key={"PLPlaningEdit" + planning.id}
      panelStyle={panelStyle}
      editable={editable}
      viewEntities={viewEntities}
      projectId={projectId}
      workId={planning.id}
      parentType={parentTypes.planing}
      panelTypes={["text"]}
      rightsParent={rightsParent}
    />
      :<Empty description="Keine Daten">{(rightsParent.indexOf(sysRight.CREATE) >= 0)?<Button onClick={createPlanning}>Erstellen</Button>:null}</Empty>
    }
  </div>
}

// export class Planning extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       planning: {
//         id: null,
//         position: -1,
//         projectId: "",
//         parentId: ""
//       },
//       goals: [],
//       planningList: [],
//       isLoading: false
//     }
//   }
//   componentDidMount = () => {
//     const parentId = this.props.parentId
//     this.loadPlaning(parentId)
//   }
//   componentDidUpdate = (prevProps, prevState, snapshot) => {
//     if (prevState.planning?.parentId !== this.props.parentId) {
// //    if (prevState.planning?.parentId !== this.state.planning?.parentId) {
//       this.loadPlaning(this.props.parentId)
//     }
//   }
//   loadPlaning = async (parentId) => {
//     if (parentId) {
//       const rd = await apiRequest.get(apiUrlShowPlaning + "/" + parentId)
//       if (rd && rd.state && rd.data && rd.data.length > 0)
//         this.setState({planning: rd.data[0]})
//     }
//   }
//   loadRestorationGoals =  async (workpieceId, categoryId) => {
//     if (categoryId) {
//       const rd = await apiRequest.get(apiUrlRestorationGoalForProject + "/" + workpieceId + "/" + categoryId)
//       if (rd.data) await this.setState({goals: rd.data})
//     }
//   }
//   createPlanning = async () => {
//     const rd = await apiRequest.post(apiUrlCreatePlaning + "/" + this.props.parentId)
//     if (rd && rd.state && rd.data) {
//       this.setState( {planning: rd.data})
// //TODO Check why categoryId is empty      await this.loadRestorationGoals(this.props.projectId, this.props.categoryId)
//     }
//   }
//   renderView = () => {
//     return (
//     <div key={"PLPlaningViewMain" + this.state.planning.id}>
//       <Row gutter={[16,16]}>
//         <Col span={12} style={{margin: "2em 0 0 4.5em"}}>
//           <h4><b>Planung</b></h4>
//         </Col>
//       </Row>
//       <Row gutter={[16,16]}>
//         <Col span={20}>
//           {this.state.goals.map(goal =>(<RestorationGoal item={goal} readOnly={true}/>))}
//         </Col>
//         <Col span={4} />
//       </Row>
//         <PanelDndContainer
//           key={"PLPlaningView" + this.state.planning.id}
//           panelStyle={this.props.panelStyle}
//           editable={this.props.editable}
//           viewEntities={this.props.viewEntities}
//           projectId={this.props.projectId}
//           workId={this.state.planning.id}
//           parentType={parentTypes.planing}
//           panelTypes={["text"]}
//           rightsParent={this.props.rightsParent}
//         />
//     </div>
//     )
//   }
//   renderEdit = () => {
//     const create = (this.props.rightsParent.indexOf(sysRight.CREATE) >= 0)
//     const {planning} = this.state
//     return (
//       <div key={"PLPlaningEditMain" + planning?.id}>
//         <Row gutter={[16,16]}>
//           <Col span={20}>
//             {this.state.goals.map(goal =>(<RestorationGoal item={goal} readOnly={true}/>))}
//           </Col>
//           <Col span={4} />
//         </Row>
//         {(this.state.planning && this.state.planning.id !== null)?
//         <PanelDndContainer
//           key={"PLPlaningEdit" + this.state.planning.id}
//           panelStyle={this.props.panelStyle}
//           editable={this.props.editable}
//           viewEntities={this.props.viewEntities}
//           projectId={this.props.projectId}
//           workId={this.state.planning.id}
//           parentType={parentTypes.planing}
//           panelTypes={["text"]}
//           rightsParent={this.props.rightsParent}
//         />
//           :<Empty description="Keine Daten">{(create)?<Button onClick={this.createPlanning}>Erstellen</Button>:null}</Empty>
//         }
//       </div>
//     )
//   }
//   render = () => {
//     return (this.props.editable)? this.renderEdit():this.renderView()
//   }
// }
//
// Planning.defaultProps = {
//   rightsParent: [],
//   panelStyle: "default",
//   editable: true,
//   viewEntities: "large",
// }
