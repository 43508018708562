import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlTextFunctionList         = "baseTextFunction/list"

export const SelectTextFunction = (props) => {
  const [textFunction, setTextFunction] = useState([])
  const {readOnly, ...rest} = props
  const loadTextFunction = async () => {
    const rd = await apiRequest.get(apiUrlTextFunctionList)
    if (rd && rd.state && rd.data) setTextFunction(rd.data)
  }
  useEffect(() => {loadTextFunction()}, [])
  return (
    <Select
      className={readOnly? 'readOnly': undefined}
      key={"coreTextFunction"}
//      style={{width: "100%"}}
      fieldNames={{label: "name", value: "id"}}
      dropdownAlign={{ points: ["tr", "br"] }}
      {...rest}
      onChange={!readOnly && props.onChange}
      onBlur={!readOnly && props.onBlur}
      allowClear={!readOnly && props.allowClear}
      open={readOnly ? false : undefined}
      options={textFunction}
    />
  )
}

SelectTextFunction.defaultProps = {
  readOnly: false,
}
