import React from 'react';
import {NodeDataPanel} from "../panel/NodeDataPanel";
// import validator from "@rjsf/validator-ajv6";
// import Form from "@rjsf/antd"
// import {useParams} from "react-router-dom";
// import apiRequest from "../general/apiRequest";
// import Form from "@rjsf/core"

// const testSchema = {
//   title: "A registration form",
//   description: "A simple form example. Demonstrating ui options",
//   type: "object",
//   required: [
//     "firstName",
//     "lastName"
//   ],
//   properties: {
//     firstName: {
//       type: "string",
//       title: "First name",
//       default: "Chuck",
//       description: null,
//       help: null,
//       rawHelp:null,
//       extra:null
//     },
//     lastName: {
//       type: "string",
//       title: "Last name"
//     },
//     telephone: {
//       type: "string",
//       title: "Telephone",
//       minLength: 10
//     },
//     "date": {
//       "type": "string",
//       "format": "date"
//     }
//   }
// }

// const testUiSchema = {
//   "ui:options":  {
//     expandable: false
//   },
//   "ui:submitButtonOptions": {
//     "submitText": "Confirm Details",
//     "norender": true,
//     "props": {
//       "disabled": true,
//       "className": "btn btn-info"
//     }
//   },
//   "firstName": {
//     "ui:autofocus": true,
//     "ui:emptyvalue": "",
//     "ui:autocomplete": "family-name",
//     "ui:description": null,
//     "ui:help": null
//   },
//   "lastName": {
//     "ui:title": "Surname",
//     "ui:emptyvalue": "",
//     "ui:autocomplete": "given-name"
//   },
//   "age": {
//     "ui:widget": "updown",
//     "ui:title": "Age of person",
//     "ui:description": "(earthian year)"
//   },
//   "bio": {
//     "ui:widget": "textarea"
//   },
//   "password": {
//     "ui:widget": "password",
//     "ui:help": "Hint: Make it strong!"
//   },
//   "alt-datetime": {
//     "ui:widget": "alt-datetime"
//   },
//   "telephone": {
//     "ui:options": {
//       "inputType": "tel"
//     }
//   }
// }

// const loadData = async (ctx, id, setData) => {
//   const rd = await apiRequest.get("/nodeData/withShema/")
//   if (rd && rd.state && rd.data && (typeof setData === "function")) setData(rd.data)
// }

export const TestForm = (props)=> {
  return <div style={{width: "50%", margin: "auto, 0px"}}>
    <NodeDataPanel />
  </div>
}