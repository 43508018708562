import React from 'react';
import {Select} from "antd";

export const typeRoleItems = [
  {id: "5deede7c-a07f-414e-861d-64a248d52e5e", name: "Verbundene Zellen Erkennen"},
  {id: "6e2a84c4-4373-44e6-85dc-5f735cebd955", name: "Javascript Auswertung"},
]

export const SelectTypeRole = (props) => {
//  const {showempty, emptydisable, emptytext, emptyvalue, yesText, noText, yesValue, noValue, ...rest} = props
  return <Select
    key={"RtrSelect"}
    {...props}
    options={typeRoleItems}
    fieldNames={{label: "name", value: "id"}}
    dropdownAlign={{ points: ["tr", "br"] }}
  />
}