// export const Rights = {
//   list: "099ef0a7-249f-4d0a-a53a-1e252c8270c4",
//   reference: "afd4301f-f9d0-42f7-aea3-4c4cfc038afc",
//   read: "d6f1ea66-6f7a-4eb1-8ef2-2d217b3d000f",
//   create: "9ad6d2f8-0988-429c-bf7e-85dd5d098ad0",
//   update: "7312e725-5e73-4098-a474-17bad318ed3e",
//   delete: "9729d5d7-b5a0-4908-b5c4-6bc3c12588a2",
// }
const LIST          = "099ef0a7-249f-4d0a-a53a-1e252c8270c4"
const REFERENCE     = "afd4301f-f9d0-42f7-aea3-4c4cfc038afc"
const READ          = "d6f1ea66-6f7a-4eb1-8ef2-2d217b3d000f"
const CREATE        = "9ad6d2f8-0988-429c-bf7e-85dd5d098ad0"
const UPDATE        = "7312e725-5e73-4098-a474-17bad318ed3e"
const DELETE        = "9729d5d7-b5a0-4908-b5c4-6bc3c12588a2"

export const rSet = new Set ([REFERENCE, LIST, READ, CREATE, UPDATE, DELETE])

export const systemRights = new Set ([REFERENCE, LIST, READ, CREATE, UPDATE, DELETE])


export const sysRight = {REFERENCE, LIST, READ, CREATE, UPDATE, DELETE}