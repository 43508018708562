import React, {useContext, useEffect, useState} from "react";
import {BC_RAVE_DEFAULT} from "./const";
import apiRequest from "../../general/apiRequest";
import {Breadcrumb, Dropdown} from "antd";
import {Link} from "react-router-dom";
import {CaretDownOutlined} from "@ant-design/icons";
import CsescaContext from "../../core/AppContext/CsescaContext";

const apiUrl = {
  apiUrlProjectList:    "project/list",
  breadcrumbList:       "breadcrumb",
  breadcrumbChildList:  "breadcrumb/childs",
}

export const MainBreadcrumb = ({root = {key: "dashboard", title: "Dashboard"}, ...props}) => {
  const [state] = useContext(CsescaContext)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  useEffect(() => {
    loadBreadcrumb({name: state.entityName, id: state.entityId, breadcrumbType: state.breadcrumbType})
    // eslint-disable-next-line react-hooks/exhaustive-deps
//    }, [state.entityId, state.entityName, state.pageType, state.breadcrumbType])
    }, [state])
  const loadBreadcrumb = (entity) => {
    if (entity.id && entity.name && entity?.breadcrumbType === BC_RAVE_DEFAULT) {
      apiRequest.get(`${apiUrl.breadcrumbList}/${entity.name}/${entity.id}`)
        .then(rd => {
          if (rd && rd.state && rd.data) {
            const startBC = [{...root} , {key: "bc-projects", title: <Link to={`/ras/projectlist`}>Projekte</Link>}]
            const followedBC = rd.data.reverse().map((bcItem, ix) => {
              return {
                key: bcItem.id,
                title: <MainBreadcrumbDropdown breadcrumbItem={bcItem} latestChild={(ix === breadcrumbs.length - 1)} />,
              }
            })
            setBreadcrumbs(_ => [...startBC, ...followedBC])
//            if (bimExpand && bimExpand.current && bimExpand.current.expandedItems && rd.data.length > 0) bimExpand.current.expandedItems(rd.data, false)
          }
        })
    }
  }
  return <div className={"ces-breadcrumb-container"}>{/*state.breadcrumbType*/}
    <Breadcrumb
      className="ces-breadcrumb"
      separator={"|"}
      items={breadcrumbs}
    />
  </div>
}

const MainBreadcrumbDropdown = ({breadcrumbItem, latestChild, ...props}) => {
  const [breadcrumbChildren, setBreadcrumbChildren] = useState([])
  useEffect(() => {
    loadBreadcrumbChildren(breadcrumbItem)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [breadcrumbItem.id]
  )
  const loadBreadcrumbChildren = (item) => {
    if (item) {
      if (item.refType !== "project") {
        apiRequest.get(`${apiUrl.breadcrumbChildList}/${item.parentId}`)
          .then(rd => {
            if (rd && rd.state && rd.data) {
              setBreadcrumbChildren(_ => rd.data
                .filter((it => it.id !== breadcrumbItem.id))
              )}
          })
      } else {
        apiRequest.get(`${apiUrl.apiUrlProjectList}?template=false`)
          .then(rd => {
            if (rd && rd.state && rd.data) {
              setBreadcrumbChildren(_ => rd.data
                .filter((it)=> it.id !== breadcrumbItem.id)
                .map((item) => {return {id:item.id, refType: "project", parentId: null, name: item.name}})
              )}
          })
      }
    }
  }
  const getItems = () => {
    return breadcrumbChildren.map((item, ix) => {
      return {key: item.id, label: <Link to={`/ras/${item.refType}/${item.id}`}>{item.name}</Link>}
    })
  }
  return <Dropdown.Button
      icon={(breadcrumbChildren.length > 0)?<CaretDownOutlined />:null}
      arrow={breadcrumbChildren.length > 0}
      menu={{items: getItems()}}
      type={"text"}
    ><Link to={`/ras/${breadcrumbItem.refType}/${breadcrumbItem.id}`}>{breadcrumbItem.name}</Link></Dropdown.Button>
}
