import {combineReducers, configureStore} from "@reduxjs/toolkit"
import loggedInUserReducer from "./loggedInUserSlice"
import nodeMetaReducer from "../attribute/slice/mrpNodeMetaSlice"
import propertyMetaReducer from "../attribute/slice/mrpPropertyMetaSlice"
import propertyGroupReducer from "../attribute/slice/mrpPropertyGroupSlice"
import nodeMetaDrawerReducer from "../attribute/sliceUI/NodeMetaUISlice"
import unauthorizedReducer from "../general/msgUnauthorizedSlice"
import forbiddenReducer from "../general/msgForbiddenSlice"

// const rootReducer = reduceReducers({
const rootReducer = combineReducers({
  loggedInUser: loggedInUserReducer,
  nodeMeta: nodeMetaReducer,
  propertyMeta: propertyMetaReducer,
  propertyGroup: propertyGroupReducer,
  // propertyGroupReducer,
})
// const rootReducer = reduceReducers({
//     loggedInUser:{},
//     nodeMeta,
//     // propertyMeta,
//     // propertyGroup,
//   },
//   loggedInUserReducer,
//   nodeMetaReducer,
//   // propertyMetaReducer,
//   // propertyGroupReducer,
// )
const attrReducer = combineReducers({
  attr: combineReducers({
    nodeMetaDrawer: nodeMetaDrawerReducer,
  }),
  messages: combineReducers({
    unauthorized: unauthorizedReducer,
    forbidden: forbiddenReducer,
  })
})
export default configureStore({
 reducer: combineReducers({
   data: rootReducer,
   ui: attrReducer,
}),
})
