import React from "react";
import {Dropdown} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {useRaveItemMenu} from "../rave/Menu/RaveItemMenu";


export const RowDropMenu = ({contextMenuInfo, ...props}) => {
  // const [menuList, buildMenuList] = useRaveItemMenu()
  const [items, buildMenuList] = useRaveItemMenu()
  return <Dropdown.Button
    type={"link"}
    icon={<EllipsisOutlined style={{fontSize: "2em", fontWeight: "bold"}} rotate={90} />}
    // menu={{items: menuList}}
    menu={{items}}
    trigger={["click"]}
    onOpenChange={() => buildMenuList(contextMenuInfo)}
  >
  </Dropdown.Button>
}
