import React from 'react';
import {Select} from "antd";

const sourceTypes = [{id: "2eda3b1d-6b1c-11ed-9d8f-18cc1881fa4b", name: "Excel"},{id: "018617a2-0745-3ad3-25be-c455008c891c", name: "CSV"},]
export const SourceTypeSelect = ({allowClear, showEmpty, disableEmpty, textEmpty, onBlur, onChange, readOnly, ...props}) => {
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={sourceTypes}
  />
}
/*
    {(showEmpty) ?<SelectOption key={"ETY0"} value={null} disabled={disableEmpty}>{textEmpty}</SelectOption>:null}
    {sourceTypes.map(sourceType => (<SelectOption key={sourceType.id} value={sourceType.id} >{sourceType.name}</SelectOption>))}
  </Select>
*/
