/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 09.01.2024
 *
 * Project: csescaclient
 *
 * ProjectMeta in package  (project: csescaclient ) © 09.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {raveMeta} from "../raveMeta";

export const raveWorkpieceMeta= {
  ...raveMeta,
  domain: "workpiece",
  module: {
    ...raveMeta.module,
    routeDomain: "workpiece",
  },
  defaultData: {
    ...raveMeta.defaultData,
    projectId: "",
    parentId: "",
    breakdownTypeId: "",
    codeNumber: "",
    name: "",
    categoryId: "",
    craftId: "",
    cClassId: "",
    uClassId: "",
    conservation: false,
    detailPlanningId: "",
    constrYearText: "",
    constrYearEndText: "",
    enClassification: true,
    parentName: "",
    isTemplate: null,
    templateId: null,
    cloudUrl: "",
  }
}
