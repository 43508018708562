import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlTexttypeShowEntity     = "/texttype/showbyentity"

export const SelectTextType = ({allowClear, textTypeEntityId, onBlur, onChange, readOnly = false, ...props}) => {
  const [textType, setTextType] = useState([])
  useEffect(() => {
    loadTextType()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textTypeEntityId])
  const loadTextType = () => {
    apiRequest(`${apiUrlTexttypeShowEntity}/${textTypeEntityId}`)
      .then((rd) => {
        if (rd && rd.state && rd.data) setTextType(rd.data)
      })
      .catch(error => console.log("loadTextType in SelectTextType: " + error))
  }
  return (
    <Select
      fieldNames={{label: "name", value: "id"}}
      {...props}
      onChange={!readOnly ? onChange : undefined}
      onBlur={!readOnly ? onBlur : undefined}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      options={textType}
    />
  )
}
