import React, {useEffect, useState} from "react"
import {Button, Card, Drawer, Form, Input, Popconfirm, Table, Tooltip} from "antd";
import apiRequest from "../../../general/apiRequest";
import {CloseOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {SelectTargetWithoutSelf} from "../importWidgets/SelectTargetWithoutSelf";
import {SelectTargetEdgeMeta} from "../importWidgets/SelectTargetEdgeMeta";
import {SelectIdentProperty} from "../importWidgets/SelectTargetIdentProperty";
import {SelectScope} from "../../../attribute/select/SelectScope";
import {SelectCatalog} from "../../../common/cmnSelectCatalog";
import {ImportSchemaDefinitionTable} from "./ImportSchemaDefinition";

const apiUrl = {
  importSchemaTargetShow:                 "/importSchemaTarget/show",
  importSchemaTargetSave:                 "/importSchemaTarget",
  importSchemaTargetListByRowType:        "/importSchemaTarget/listByRowType",
  importSchemaTargetCreate:               "/importSchemaTarget",
  importSchemaTargetDelete:               "/importSchemaTarget",
}

const importSchemaTargetNew = {
  id: null,
  active: true,
  importRowTypeId: null,
  name: "Neuer Eintrag",
  description: "",
  parentTargetId: null,
  scopeId: null,
  targetEdgeMetaId: null,
  identPropertyId: null,
  identExpression: "",
  insertPolicyId: null,
}
export const ImportSchemaTargetTable = (props) => {
  const [importSchemaTarget, setImportSchemaTarget] = useState([])
  const [selectedTargetRowKey, setSelectedTargetRowKey] = useState(null)
  const {importSchemaAnchor} = props
  useEffect(() => {loadImportTargets(props.refId)}, [props.refId])
  const loadImportTargets = async (parentId) => {
    if (parentId) {
      const res = await apiRequest.get(`${apiUrl.importSchemaTargetListByRowType}/${parentId}`)
      if (res && res.state && res.data) setImportSchemaTarget(res.data)
    }
  }
  const createImportTarget = async () => {
//    if (props.schemaId && props.schemaId.length >0) {
    if (props.refId) {
      const rowTypeId = props.refId
      const res = await apiRequest.post(apiUrl.importSchemaTargetCreate, JSON.stringify({...importSchemaTargetNew, importRowTypeId: rowTypeId}))
      if (res && res.state && res.data) {
        loadImportTargets(rowTypeId)
      }
    }
  }
  const deleteTarget = async (targetId) => {
    await apiRequest.delete(`${apiUrl.importSchemaTargetDelete}/${targetId}`)
    await loadImportTargets(props.refId)
  }
  const defaultColumns = [
    {title: "Ziele", dataIndex: "name", ellipsis:true, width: 140,
      onCell: (row) => {return {onClick: e => {setSelectedTargetRowKey(row.id)}}}
    },
    {title: <Tooltip
        title={"Zeilentyp hinzufügen"}>
        <Button
          type={"link"}
          icon={<PlusOutlined />}
          onClick={() => {createImportTarget()}}
        />
    </Tooltip>,
      align: "right",
      render: (data, row, ix ) =>
        <Popconfirm
          placement={"top"}
          title={"Möchten Sie den Datensatz löschen"}
          onConfirm={() => {deleteTarget(row.id)}}
          okText="Ja"
          cancelText="Nein"
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true, ghost: true}}
        >
          <Button type={"link"} danger icon={<DeleteOutlined />} />
        </Popconfirm>
    },
  ]
  return <>
    <Table
      rowKey={"id"}
      rowSelection={{
        type: "radio",
        onChange: (selectedKeys) => {setSelectedTargetRowKey((selectedKeys.length > 0)?selectedKeys[1]:null)},
        selectedRowKeys: [selectedTargetRowKey],
        columnWidth: 0,
        renderCell: () => "",
      }}
      columns={defaultColumns}
      dataSource={importSchemaTarget}
    />
    <ImportSchemaTargetDrawer
//      openDrawer={selectedTargetKey.length > 0}
      importSchemaAnchor={importSchemaAnchor}
      selectedTargetRowKey={selectedTargetRowKey}
      setSelectedTargetRowKey={setSelectedTargetRowKey}
//      destroyOnClose
    />
  </>
}
export const ImportSchemaTargetDrawer = (props) => {
  const {selectedTargetRowKey, setSelectedTargetRowKey, importSchemaAnchor} = props
  return <Drawer
    title={"Ziel-Schema"}
    open={!!selectedTargetRowKey}
//    destroyOnClose
    closable={false}
    onClose={() => {setSelectedTargetRowKey(null)}}
    getContainer={() => document.getElementById("ImportSchemaDrawerParent")}
    width={1000}
    extra={<Button type={"text"} icon={<CloseOutlined />} onClick={() => {setSelectedTargetRowKey(null)}} />}
    {...props}
  >
    <ImportSchemaTargetEdit
      importSchemaTargetId={selectedTargetRowKey}
      importSchemaAnchor={importSchemaAnchor}
    />
  </Drawer>
}

const ImportSchemaTargetEdit = (props) => {
  const [importSchemaTarget, setImportSchemaTarget] = useState({changed: false, data: {}})
  // const {importSchemaAnchor} = props
  useEffect(() => {loadImportSchemaTarget(props.importSchemaTargetId)}, [props.importSchemaTargetId])
  const loadImportSchemaTarget = async importSchemaTargetId => {
    if (importSchemaTargetId) {
      const res = await apiRequest.get(`${apiUrl.importSchemaTargetShow}/${importSchemaTargetId}`)
      if (res && res.state && res.data)
        setImportSchemaTarget({changed: false, data: res.data})
    }
  }
  const saveRowType = async () => {
    if (importSchemaTarget.changed) {
      const res = apiRequest.put(apiUrl.importSchemaTargetSave, JSON.stringify(importSchemaTarget.data))
      if (res && res.state && res.data) setImportSchemaTarget({changed: false, data: res.data})
    }
  }
  const handleChange = (name, value) => {
    setImportSchemaTarget({changed: true, data:{...importSchemaTarget.data, [name]: value}})
  }
  const handleBlur = () => saveRowType()
  return <Card title={null}>
    <Form name="inport-schema-target-form" layout={"vertical"} preserve={false}>
      <Form.Item label={"Name"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0', }}>
        <Input
          name={"name"}
          value={importSchemaTarget.data.name}
          maxLength={40}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Beschreibung"} style={{ marginBottom: 0, }}>
        <Input.TextArea
          name={"description"}
          value={importSchemaTarget.data.description}
          maxLength={2000}
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{resize: "none"}}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Identitätsausdruck"} style={{ marginBottom: 0, }}>
        <Input.TextArea
          name={"identExpression"}
          value={importSchemaTarget.data.identExpression}
          maxLength={2000}
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{resize: "none"}}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Übergeordnetes Target"} style={{ marginBottom: 0, /*display: 'inline-block', width: '100%',*/ }}>
        <SelectTargetWithoutSelf
          targetId={importSchemaTarget.data.id}
          name={"parentTargetId"}
          value={importSchemaTarget.data.parentTargetId}
          style={{resize: "none"}}
          onChange={(value) => {handleChange("parentTargetId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Scope"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0', }}>
        <SelectScope
          name={"scopeId"}
          value={importSchemaTarget.data.scopeId}
          fieldNames={{label: "name", value: "id"}}
          onChange={(value) => {handleChange("scopeId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"IdentProperty"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 4px', }}>
        <SelectIdentProperty
          name={"identPropertyId"}
          value={importSchemaTarget.data.identPropertyId}
          fieldNames={{label: "name", value: "id"}}
          onChange={(value) => {handleChange("identPropertyId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"TargetEdgemeta"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0', }}>
        <SelectTargetEdgeMeta
          name={"targetEdgemetaId"}
//          value={importSchemaAnchor.current?.anchorNodeMetaId}
          value={importSchemaTarget.data.targetEdgemetaId}
          onChange={(value) => {handleChange("targetEdgemetaId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"InsertPolicy"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 4px', }}>
        <SelectCatalog
          name={"insertPolicyId"}
          value={importSchemaTarget.data.insertPolicyId}
          catalogGroup={"f10528f4-13fc-425f-903b-3e0995ce5ad2"}
          fieldNames={{label: "name", value: "id"}}
          onChange={(value) => {handleChange("insertPolicyId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
    </Form>
    <ImportSchemaDefinitionTable
      refId={importSchemaTarget.data.id}
    />
  </Card>
}