import {DEFAULT_BASE_URL, DEFAULT_OPTIONS} from "./FetchDefault";
import apiRequest from "../../general/apiRequest";
import useSWR from "swr";
import {useNavigate} from "react-router-dom";


export const useFetchList = (meta, {
  referenceId = null,
  queryParams = null,
  filter = null,
  // baseUrl = DEFAULT_BASE_URL,
  // options = DEFAULT_OPTIONS,
  loading = null,
  empty = null,
  swfOptions = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    keepPreviousData: true,
  }
}) => {
//  const fetcher = (url) => apiRequest.get(url).then(r => r.data)
  const fetcher1 = ([url, filter]) => (!filter)
    ?apiRequest.get(url).then(r => r.data)
    :apiRequest.patch(url,JSON.stringify(filter)).then(r => r.data)


  const domainKey = `${meta.module.name}/${meta.domain}`
  const navigation = useNavigate()
  const swrKey = `/${domainKey}${meta.domainList}${referenceId?`/${referenceId}`:''}${queryParams?`?${queryParams}`:''}`
  const {data, mutate, revalidate, isLoading} =
    useSWR([swrKey, filter], ([swrKey, filter]) => fetcher1([swrKey, filter]), {
//    useSWR(swrKey, fetcher, {
      keepPreviousData: true,
      fallbackData: [],
      swfOptions,
      // revalidateIfStale: false,
      // revalidateOnFocus: false,
      // revalidateOnReconnect: false
    })
  const remove = (id) => {
//    console.log(`Delete ${id}`)
    mutate(
      async () => {await apiRequest.delete(`/${domainKey}/${id}`)},
      {revalidate: true, /*optimisticData: (currentData, displayedData) => currentData*/}
    )
  }
  const create = ({newData = null, urlExtension = null, urlParams = null, doNavigate = false}) => {
    const url = `/${domainKey}${urlExtension?`/${urlExtension.trimLeft('/')}`:''}${urlParams?`?${urlParams}`:''}`
//    console.log(`Create: ${JSON.stringify(newData)}\n/${url}`)
    apiRequest.post(url, newData?JSON.stringify(newData):undefined)
      .then(result => {
        mutate()
        if (doNavigate) navigation(`/${domainKey.replace('rave','ras')}/${result.data}`)
      })
  }
  if (isLoading && loading && Array.isArray(loading)) return {data: loading, revalidate, remove, create}
  if (!data?.length && empty && Array.isArray(empty)) return {data: empty, revalidate, remove, create}
  return {data, revalidate, remove, create}
}
