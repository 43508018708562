import React from "react"
import Draggable from "react-draggable"
import {Modal} from "antd";

export const ModalDraggableTest = (props) => {
  return (
    <Draggable>
      <div>
        <Modal title={"Test Draggable"} width={550} open={false} >
          <h2>ModalDraggableTest</h2>
        </Modal>
      </div>
    </Draggable>
  )
}