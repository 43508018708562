/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 12.07.2023
 *
 * Project: csescaclient
 *
 * CsescaPreviewOverlay in package  (project: csescaclient ) © 12.07.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {Button, Popconfirm} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import React from "react";


export const preview = (props) => {
  const {update, mediaRefId, deleteImage} = props
//  useEffect(() => {}, [update, mediaRefId])
  if (update && deleteImage && mediaRefId) return {
    // if (editable) return {
    visible: false,
    mask: <div
    >
      <div
        style={{position: "absolute", top: 0, right: 0, marginRight: ".5em",}}
      >
{/*
        <Button
          type="link"
          style={{padding: "0 2px", top: "10px"}}
          onClick={(e) => {window.confirm("Download Document");e.stopPropagation()}}
          icon={<DownloadOutlined style={{fontSize: "1.6em",}}/>}
        />
        <Button
          type="link"
          style={{padding: "0 2px", top: "10px"}}
          onClick={(e) => {window.confirm("Search vor Document");e.stopPropagation()}}
          icon={<SearchOutlined style={{fontSize: "1.6em",}}/>}
        />
*/}
        <Popconfirm
          placement="topRight"
          title={"Möchten Sie die Zuordung des Bildes löschen"}
          okText="Ja"
          cancelText="Nein"
          onConfirm={(e) => {
            deleteImage(mediaRefId);
            e.stopPropagation()
          }}
          onCancel={(e) => {e.stopPropagation()}}
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true, ghost: true}}
        >
          <Button
            type="link"
            danger={true}
            style={{padding: "0 2px", top: "10px",}}
            onClick={(e) => {e.stopPropagation()}}
            icon={<DeleteOutlined style={{fontSize: "1.6em",}}/>}
          />
        </Popconfirm>
      </div>
    </div>
  }
  else return false
}
