/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 25.07.2023
 *
 * Project: csescaclient
 *
 * panelRelocation in package  (project: csescaclient ) © 25.07.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import React, {useEffect, useState} from "react";
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import {Col, Input, Row} from "antd";
import {DragOutlined} from "@ant-design/icons";
import {RaveRelocationEntryList} from "./panelRaveRelocationEntry";

const apiUrlRaveRelocationShow               = "/raveRelocation/show"
const apiUrlRaveRelocationSave               = "/raveRelocation"

const defaultRelocation = {
  id: null,
  active: true,
  position: -1,
  parentId: null,
  name: "",
  filterId: null,
}
export const RaveRelocationPanel = ({editable, fromTemplate = false, projectId, ...props}) => {
  const [relocation, setRelocation] = useState(defaultRelocation)
  const [isChanged, setIsChanged] = useState(false)
  // const {editable, fromTemplate, projectId} = props
  const update = (props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
  useEffect(() => {loadRelocation(props.itemId)}
// eslint-disable-next-line react-hooks/exhaustive-deps
    , [])
  const loadRelocation = relocationId => {
//    if (relocationId) {
    apiRequest.get(`${apiUrlRaveRelocationShow}/${relocationId}`)
      .then(rd => {
        if (rd && rd.state && rd.data) setRelocation(prevState => rd.data)
      })
      .catch(error => console.log("Error in RaveRelocationPanel: " + error))

//    }
  }
//   const loadRelocationOld = async relocationId => {
// //    if (relocationId) {
//     const rd = await apiRequest.get(`${apiUrlRaveRelocationShow}/${relocationId}`)
//     if (rd && rd.state && rd.data) setRelocation(rd.data)
// //    }
//   }
  const handleSave = async () => {
    if (isChanged && relocation.parentId) {
      await apiRequest.put(apiUrlRaveRelocationSave, JSON.stringify(relocation))
      setIsChanged(false)
    }
  }
  const handleChildBlur = () => { handleSave() }
  const handleChildChange = (evt) => {
    if (props.editable) {
      const name = evt.target.name
      const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
      setRelocation(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  return <div className={"ant-card ant-card-bordered ant-card-small"}>
      <div className={"ant-card-head"}>
        <div className={"ant-card-head-wrapper"}>
          <div className={"ant-card-head-title"}>
            <Row gutter={[16,16]}>
              {(!fromTemplate && editable)
                ?<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
                :null
              }
              <Col span={5}>
                <Input
                  name={"name"}
                  value={relocation.name}
                  onBlur={() => {handleChildBlur()}}
                  onChange={(evt) => {handleChildChange(evt)}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
              </Col>
              <Col span={4}>Umlagerung</Col>
            </Row>
          </div>
          <div className={"ant-card-head-extra"}>
            {(editable)?props.extra:null}
          </div>
        </div>
      </div>
      <div className={"ant-card-body"}>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <RaveRelocationEntryList
              editable={editable}
              update={update}
              readOnly={!editable || !update}
              parentPanelId={relocation.id}
              projectId={projectId}
            />
          </Col>
        </Row>
      </div>
    </div>
}
