import React, {useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import {TopNavbar} from "./Header";
import {LoginForm} from "./rascalLogin";
import {ProjectMain} from './rascal/project/rascalProject'
import {ProjectList} from './rascal/project/rascalProjectList'
import {BreakdownMain} from "./rascal/breakdown/rascalBreakdown";
import {RoomMain} from "./rascal/room/rascalRoom";
import {WorkpieceMain} from "./rascal/workpiece/rascalWorkpiece";
import {DefaultSite} from "./baseDefault";
import {Security} from "./base/baseSecurity";
import {Templates} from "./rascal/template/rascalTemplate";
import {NodeMetaList} from "./attribute/mrpNodeMetaList";
import {TestForm} from "./attribute/TestForm";
import { Provider } from 'react-redux'
import store from "./store/store";
import {interceptor} from "./general/apiRequest";
import {TestData} from "./general/apiTest";
import {MessageUnauthorized} from "./general/MessageUnauthorized";
import {ModalDraggableTest} from "./dialog/ModalDraggableTest";
import {MessageForbidden} from "./general/MessageForbidden";
import {Button, /*Collapse as Accordion,*/ ConfigProvider} from "antd";
// import useLocalStorage from "./system/LocalStorage";
import {ProjectSummarize} from "./rascal/rascalProjectSummarize";
import {SwapOutlined} from "@ant-design/icons";

import localeDE from 'antd/es/locale/de_DE';
import {StyleProvider} from "@ant-design/cssinjs";
import {ModalForgetPassword} from "./dialog/modalForgotPassword";
import {ModalResetPassword} from "./dialog/modalResetPassword";
import {CsescaProvider} from "./core/AppContext/CsescaContext";
import {CsescaBreadcrumb} from "./core/BreadcrumbService/BreadcrumRegistry";
// import {FavoriteBimTree, MainBimTree, SharedBimTree, TemplateBimTree} from "./rave";
import {BreakdownList} from "./rascal/breakdown/rascalBreakdownList";
import {RoomList} from "./rascal/room/rascalRoomList";
import {WorkpieceList} from "./rascal/workpiece/rascalWorkpieceList";
import {parentTypes} from "./panel/panelDndContainer";
import useCredentials from "./system/UseCredentials";
// import {isSharedExt, isTempExt} from "./system/checkTenants";
import {Aside} from "./core/Siedebar/Aside/Aside";
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";

//import './App.css';

// const idExp = "([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})"

interceptor(store)


const bcRoot = {key: "bcDashboard", title: "Dashboard"}

const App = (props) => {
//  console.log("Render APP ...")
  return <Provider store={store}>
    <StyleProvider hashPriority="hight">
      <ConfigProvider componentSize={"small"}  theme={{ hashed: false,  token: {fontFamily: 'Arial, Helvetica, sans-serif'}}} locale={localeDE}>
        <BrowserRouter>
          <Pages/>
        </BrowserRouter>
      </ConfigProvider>
    </StyleProvider>
  </Provider>
}

// const raveItems = new Set(["project", "breakdown", "room", "workpiece"])

const Pages = (props) => {
  const checkMobile = () => {
    if (window.orientation === "undefined") return false
    if (!('ontouchstart' in window || navigator.maxTouchPoints > 0)) return false
    const mob = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    return mob
  }
  const [isMobile/*, _*/] = useState(checkMobile())
  const [sidebarCollapsed, setSidebarCollapsed] = useState(isMobile)

  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id

//  const [breadcrumbEntity, setBreadcrumbEntity] = useState([])
//  const match = useMatch('/ras/*')?.params["*"].split("/")[0]
//  const raveItem = raveItems.has(match)?match:null
//  const raveMatch = useMatch('/ras/'+raveItem+"/:id")

//  const [componentSize, setComponentSize] = useLocalStorage("ThemeSize", "small")

//  const treeRef = useRef()

//   const asideRef = useRef()
//
//   const [isResizing, setIsResizing] = useState(false);
//   const [sidebarWidth, setSidebarWidth] = useState(null);
//   const startResizing = useCallback((mouseDownEvent) => {
//     if (!sidebarWidth) setSidebarWidth(mouseDownEvent.clientX)
//     setIsResizing(true);
//   }, [sidebarWidth]);
//   const stopResizing = useCallback(() => {
//     setIsResizing(false);
//   }, []);
//   const resize = useCallback((mouseMoveEvent) => {
//     if (isResizing) {
// //      console.log("move" + asideRef.current.getBoundingClientRect().left)
//       setSidebarWidth(prevState => mouseMoveEvent.clientX /*- asideRef.current.getBoundingClientRect().left*/)
//     }
//   },[isResizing])
//   useEffect(() => {
//     window.addEventListener("mousemove", resize);
//     window.addEventListener("mouseup", stopResizing);
//     return () => {
//       window.removeEventListener("mousemove", resize);
//       window.removeEventListener("mouseup", stopResizing);
//     };
//   }, [resize, stopResizing]);
  const LoggedOut = (props) => {
    return <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        alignItems: "center",
        justifyItems: "center",
        height: "100vh"
    }}
    >
            <Outlet />
    </div>
  }
  const handleSidebarCollapse = () => setSidebarCollapsed(!sidebarCollapsed)
//  const handleBreadcrumb = (value) => setBreadcrumbEntity(value)
  const getCollapsed = () => (sidebarCollapsed)?" collapsed":""
  const getMobil = () => (isMobile)?" mobile":" desktop"
//  console.log("Render Pages ...")
  return (
    /*<ConfigProvider componentSize={componentSize} locale={localeDE}>*/
    <CsescaProvider>
      <MessageUnauthorized />
      <MessageForbidden />
      <Routes>
        <Route path={"/ras/*"} element={<div className="container">
          <div id="sidebar-toggle" className={"sidebar-toggle" + getCollapsed() + getMobil()}/>
          <header id="main-header" className="main-header">
            <div id="main-header-line" className="main-header-line">
              <TopNavbar /*componentSize={componentSize} onSizeChange={setComponentSize}*/ />
            </div>
          </header>
          <nav id="main-nav" className={"main-nav"}>
            <div style={{padding: ".4em 0px .4em", textAlign: "left",}}>
              <Button id="label-toggle" type={"text"} icon={<SwapOutlined />} onClick={handleSidebarCollapse} />
              {/*<CesBreadcrumb entity={breadcrumbEntity} bimExpand={treeRef} />*/}
              <CsescaBreadcrumb /*name={state.breadcrumbType}*/ root={bcRoot} />
            </div>
          </nav>
          <Aside /*ref={asideRef} sidebarWidth={sidebarWidth}*/ sidebarCollapsed={sidebarCollapsed} />
{/*
          <aside
            ref={asideRef}
            id="main-sidebar"
            className={"main-sidebar"}
            style={(sidebarWidth && !sidebarCollapsed)?{width: sidebarWidth + "px",}:{}}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Accordion
              className="ces-sidebar-accordion"
              defaultActiveKey={["MainBimTree"]}
              ghost
              items={[
                {
                  key: "FavoriteBimTree", label: <b>Favoriten</b>,
                  children: <FavoriteBimTree />,
                },
                {
                  key: "MainBimTree", label: <b>Eigene Projekte</b>,
                  children: <MainBimTree />,
                  // extra: <div onClick={e => e.stopPropagation()}>
                  //   <Button type={"text"} icon={<PlusOutlined />} />
                  //   <Button type={"text"} icon={<ReloadOutlined/>}
                  //     onClick={() => {if (treeRef.current && treeRef.current.loadProjects) treeRef.current.loadProjects()}}
                  //   />
                  // </div>
                },
                isSharedExt(tenantId)
                ?{ key: "SharedBimTree", label: <b>mir freigegebene Elemente</b>,
                  children: <SharedBimTree />,
                }:null,
                isTempExt(tenantId)
                ?{ key: "TemplateBimTree", label: <b>Template Projekte</b>,
                  children: <TemplateBimTree />,
                }:null,
              ].filter(it => it != null)
            }
            >
            </Accordion>
            <div id="main-sidebar-resizer" onMouseDown={startResizing} />
          </aside>
*/}
          <main id="main-content" className="main-content"><Outlet /></main>
          <footer id="main-footer" className="main-footer">
            <div id="footer-left" />
            <div id="footer-middle" />
            <div id="footer-right" />
          </footer>
        </div>
        } >
          <Route path={"testAPI"} element={<TestData />}/>
          <Route path={"home"} element={<Navigate replace to="/ras/projectlist" />} />
          <Route path={"template"} element={<Templates />} />
          <Route path={"modalDraggableTest"} element={<ModalDraggableTest />} />
          <Route path={"testForm/:id"} element={<TestForm />} />
          <Route path={"attributeMeta"} element={<NodeMetaList />} />
          {/*<Route path={"propertyMeta/:parentId"} element={<PropertyMeta />*/}
          <Route path={"projectlist"} element={<ProjectList/>}/>
          {/*<Route path={"address/person"} element={<PersonMainWrapper/>}/>*/}
          <Route path={"projectTemplateList"} element={<ProjectList isTemplate={true} workId={tenantId} parentType={parentTypes.tenant} />}/>
          <Route path={"project/:id"} element={<ProjectMain /*bimExpand={treeRef} hdlBreadcrumb={handleBreadcrumb}*/ />}/>
          <Route path={"breakdownTemplateList"} element={<BreakdownList isTemplate={true} workId={tenantId} parentType={parentTypes.tenant} projectId="" />}/>
          <Route path={"breakdown/:id"} element={<BreakdownMain /*bimExpand={treeRef} hdlBreadcrumb={handleBreadcrumb}*/ />}/>
          <Route path={"roomTemplateList"} element={<RoomList isTemplate={true} workId={tenantId} parentType={parentTypes.tenant} projectId="" />}/>
          <Route path={"room/:id"} element={<RoomMain /*bimExpand={treeRef} hdlBreadcrumb={handleBreadcrumb}*/ />}/>
          <Route path={"workpieceTemplateList"} element={<WorkpieceList isTemplate={true} workId={tenantId} parentType={parentTypes.tenant} projectId="" />}/>
          <Route path={"workpiece/:id"} element={<WorkpieceMain /*bimExpand={treeRef} hdlBreadcrumb={handleBreadcrumb}*/ />}/>
          <Route path={"summarize"} element={<ProjectSummarize />}/>
          <Route path={"security"} element={<Security />} />
        </Route>
        <Route element={<LoggedOut />}>
          <Route path={"/login"} /*component={LoginForm}*/ element={<LoginForm/>} />
          <Route path={"/forgetPassword"} element={<ModalForgetPassword/>} />
          <Route path={"/resetPassword/:resetHash"} element={<ModalResetPassword/>} />
          <Route path={"/*"} element={<DefaultSite/>}/>
        </Route>
      </Routes>
    </CsescaProvider>
  /*</ConfigProvider>*/
  )
}

export {App}
