/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 21.01.2024
 *
 * Project: csescaclient
 *
 * ProjectMenu in package  (project: csescaclient ) © 21.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import React, {useState} from "react";
import {Modal} from "antd";
import {useNavigate} from "react-router-dom";
import {CopyOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import {raveProjectMeta} from "../Project/ProjectMeta";
import {raveBreakdownMeta} from "../Breakdown/BreakdownMeta";
import {raveRoomMeta} from "../Room/RoomMeta";
import {raveWorkpieceMeta} from "../Workpiece";
import {raveFavoriteMeta} from "../Favorite/FavoriteMeta";
import {useFetchList} from "../../core/Fetch/useFetchList";
import useCredentials from "../../system/UseCredentials";
import apiRequest from "../../general/apiRequest";
import {raveRightMeta} from "../../core/Right/RightMeta";
import {sysRight} from "../../common/cmnRights";
import {tN} from "../../system/checkTenants";
import {mrpNodeMetaId} from "../../general/Entities";

const raveTypesDef = {
  "63bbfd74-ac46-4f52-9599-71e41ffcd7cc": raveProjectMeta,
  "e5ef1268-ea3b-4e04-ad9c-7f7f91aa6282": raveBreakdownMeta,
  "fc1a5bd0-9b05-404d-95ff-a40c923b924c": raveRoomMeta,
  "071779d8-89c8-423a-b242-5fc2763d4c19": raveWorkpieceMeta,
}
const uidCheck = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

const missing = [{key: "missing", label: "missing / wrong parameter", icon: <LoadingOutlined />},]
const loading = [{key: "loading", label: "...loading", icon: <LoadingOutlined />},]

const modalConfig = {
  title: "Bestätigung Löschen",
  content: "Möchten Sie den Datensatz löschen",
  okText: "Ja",
  cancelText: "Nein",
  cancelButtonProps: {danger: false, type: "primary"},
  okButtonProps: {danger: true, ghost: true},
}

export const useRaveItemMenu = (/*doCopy*/) => {
  // const { useToken } = theme
  // const { token } = useToken()
//  const [modalConfirm] = Modal.useModal()
  const [credentials] = useCredentials()
  const userId = credentials.user.id
  const tenantId = credentials.tenant.id
  const [menuList, setMenuList] = useState(loading)
  const navigate = useNavigate()
  const {data, remove: removeFilter, create: createFilter} = useFetchList(raveFavoriteMeta, {})
//  const {data: right} = useFetchEntityRight(raveRightMeta, {nodeMetaId: null, entityId: null})
  const buildMenuList = (newItem) => {
    if (!(newItem && raveTypesDef[newItem.nodeMetaId] && uidCheck.test(newItem?.id) && uidCheck.test(newItem?.nodeMetaId))) {setMenuList(missing);return;}
    apiRequest.get(`${raveRightMeta.module.name}/${raveRightMeta.domain}/${newItem.nodeMetaId}/${newItem?.id}`)
      .then(({data: rightList}) => {
        const right = new Set(rightList)
        const newIsFavoriteItem = data.find((e) => e.itemId === newItem.id)
        const rType = raveTypesDef[newItem.nodeMetaId]
        const navPath = `/${rType.module.routeBase}/${rType.domain}/${newItem.id}`
        const addFavorite = (newItem) => {
          const favorite ={
            id: null,
            active: true,
            position: 0,
            personId: userId,
            nodeMetaId: newItem.nodeMetaId,
            itemId: newItem.id,
            name: newItem.name,
          }
          createFilter({
            newData: favorite,
            urlExtension: null,
            urlParams: `isTemplate=${false}`,
            doNavigate: false,
          })
        }
        const deleteItem = (domainItem) => {
          if (domainItem?.fetchList?.remove && domainItem?.id) {
            // const remove = domainItem.fetchList.remove
            // window.alert("Lösche Datensatz aus Domain " + domainItem)
            console.log("Lösche Datensatz aus Domain " + JSON.stringify(domainItem))
            domainItem.fetchList.remove(domainItem.id)
            // remove(domainItem.id)
          }
        }
        // const handleDelete = (item) => {
        //   const handleOk = () => deleteItem(item)
        //   Modal.confirm({...modalConfig, onOk: handleOk})
        // }
        setMenuList(() => [
            {key: "Favorite", label: "Favorite", icon: (!newIsFavoriteItem)?<StarOutlined />:<StarFilled style={{color: "#FFD700"}} />,
              disabled: !(right.has(sysRight.UPDATE)),
              onClick: () => (!!newIsFavoriteItem)?removeFilter(newIsFavoriteItem.id):addFavorite(newItem)
            },
            {key: "Edit", label: "Bearbeiten", icon: <EditOutlined />,
              disabled: !(right.has(sysRight.UPDATE)),
              onClick: (/*itemId*/) => {navigate(navPath)}
            },
            (newItem.doCopy && typeof newItem.doCopy === 'function' && newItem.nodeMetaId === mrpNodeMetaId.workpiece)?{key: "CopyWP", label: tN(tenantId, "Objekt")+ " kopieren", icon: <CopyOutlined />,
              disabled: !(right.has(sysRight.CREATE)),
              onClick: () => newItem.doCopy({open: true, sourceId: newItem.id})
            }:null,
            (newItem.doCopy && typeof newItem.doCopy === 'function' && newItem.nodeMetaId === mrpNodeMetaId.room)?{key: "CopyRM", label: tN(tenantId, "Raum") + " kopieren", icon: <CopyOutlined />,
              disabled: !(right.has(sysRight.CREATE)),
              onClick: () => newItem.doCopy({open: true, sourceId: newItem.id})
            }:null,
            (newItem.doCopy && typeof newItem.doCopy === 'function' && newItem.nodeMetaId === mrpNodeMetaId.breakdown)?{key: "CopyBD", label: tN(tenantId, "Struktur") + " kopieren", icon: <CopyOutlined />,
              disabled: !(right.has(sysRight.CREATE)),
              onClick: () => newItem.doCopy({open: true, sourceId: newItem.id})
            }:null,
            (tenantId === newItem.tenantId && (!!newItem.fetchList))?{key: "Delete", label: "Delete", icon: <DeleteOutlined  />,
              danger: true,
              disabled: !(right.has(sysRight.DELETE) && tenantId === newItem.tenantId),
              onClick: () => Modal.confirm({...modalConfig, onOk: () => deleteItem(newItem)})}:null,
              // onClick: () => handleDelete(newItem) }:null,
          ].filter((its) => its !== null)
        )
      })
      .catch((error) => `Error buildMenuList in useRaveItemMenu ${error}`)
  }
  return [menuList, buildMenuList]
}
