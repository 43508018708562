import React, {useEffect, useState} from 'react';
import {Row, Col, DatePicker, Form, Empty, Button} from "antd";
import dayjs from 'dayjs';
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import PanelDndContainer, {parentTypes} from "../panel/panelDndContainer";

const apiUrlShowCapture            = "/capture/byParent"
const apiUrlSaveCapture            = "/capture"
const apiUrlCreateCapture          = "/capture"

const defaultCapture = {
  id: null,
  position: -1,
  projectId: "",
  parentId: "",
  captureDate: null,
}
export const Capture = ({editable, panelStyle = "default", parentId, projectId, rightsParent = [],  viewEntities = "large",...props}) => {
  const [capture, setCapture] = useState({...defaultCapture})
  useEffect(() => {
    loadCapture(parentId)
  }, [parentId]);
  const loadCapture = parentId => {
    if (parentId) {
      apiRequest.get(apiUrlShowCapture + "/" + parentId)
        .then((rd) => {
          if (rd && rd.state && rd.data && rd.data.length > 0)
            setCapture(rd.data[0])
        })
        .catch(error => console.log("loadCapture in Capture: " + error))
    }
  }
  const DatePickerChange = (name, date, dateString) => {
    setCapture(prevState => {
      return {...prevState, [name]: date}
    })
  }
  const handleSave = () => {
    apiRequest.put(apiUrlSaveCapture, JSON.stringify(capture))
      .then((rd) => {
//        if (rd && rd.state) console.log("Capture Saved")
      })
      .catch(error => console.log("loadCapture in Capture: " + error))
  }
  const createCapture = () => {
    apiRequest.post(apiUrlCreateCapture + "/" + parentId)
      .then((rd) => {
        if (rd && rd.state && rd.data)
          setCapture(rd.data)
      })
      .catch(error => console.log("createCapture in Capture: " + error))
  }
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  const captureDate = (capture && capture.captureDate) ? dayjs(new Date(capture.captureDate)): null
  return <div key={"PLCaptureShowMain" + capture.id}>
    {(capture && capture.id !== null) ?
      <>
        <Row gutter={[16, 16]}>
          <Col span={12}>{(editable)
            ?<Form.Item label="Erfassungsdatum:">
              <DatePicker
                name="completionDate"
                placeholder={"Datum der Fertigstellung"}
                value={captureDate}
                onChange={(date, dateString) => {
                  DatePickerChange("captureDate", date, dateString)
                }}
                format={"DD.MM.YYYY"}
                onBlur={() => handleSave()}
                readOnly={!editable || !update} disabled={!update}
              />
            </Form.Item>
            :<>
              <h4><b>Veränderungen / Schäden</b></h4>
              <h6><b>Erfassungsdatum: {(captureDate)? captureDate.format('DD.MM.YYYY'):null}</b></h6>
            </>}
        </Col>
      </Row>
        <PanelDndContainer
          key={"PLCaptureEdit"}
          panelStyle={panelStyle}
          enabled={editable}
          viewEntities={viewEntities}
          projectId={projectId}
          workId={capture.id}
          parentType={parentTypes.capture}
          panelTypes={["text", "media", "raveDamage"]}
          rightsParent={rightsParent}
        />
      </>
      :<Empty description="Keine Daten">{(rightsParent.indexOf(sysRight.CREATE) >= 0)?<Button onClick={createCapture}>Erstellen</Button>:null}</Empty>}
  </div>
}

// export class Capture1 extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       capture: {
//         id: null,
//         position: -1,
//         projectId: "",
//         parentId: "",
//         captureDate: null
//       }
//     }
//   }
//   componentDidMount = () => {
//     this.loadCapture(this.props.parentId);
//   }
//   componentDidUpdate = (prevProps, prevState, snapshot) => {
//     if (prevState.capture?.parentId !== this.props.parentId) {
//       this.loadCapture(this.props.parentId);
//     }
//   }
//   loadCapture = async parentId => {
//     if (parentId) {
//       const rd = await apiRequest.get(apiUrlShowCapture + "/" + parentId)
//       if (rd && rd.state && rd.data && rd.data.length > 0)
//         this.setState({capture: rd.data[0]})
//     }
//   }
//   DPChange = (name, date, dateString) => {
//     this.setState({capture:{...this.state.capture, captureDate: date}})
//   }
//   handleSave = async () => {
//     /*const rd = */await apiRequest.put(apiUrlSaveCapture, JSON.stringify(this.state.capture))
//   }
//   createCapture = async () => {
//     const rd = await apiRequest.post(apiUrlCreateCapture + "/" + this.props.parentId)
//     if (rd && rd.state && rd.data) this.setState({capture: rd.data})
//   }
//   renderView = () => {
//     const captureDate = (this.state.capture && this.state.capture.captureDate) ? dayjs(new Date(this.state.capture.captureDate)): null
//     if (this.state.capture)
//       return (
//       <div key={"PLCaptureShowMain" + this.state.capture.id}>
//         <Row gutter={[16,16]}>
//           <Col span={12} style={{margin: "2em 0 0 4.5em"}}>
//             <h4><b>Veränderungen / Schäden</b></h4>
//             <h6><b>Erfassungsdatum: {(captureDate)? captureDate.format('DD.MM.YYYY'):null}</b></h6>
//           </Col>
//         </Row>
//         <PanelDndContainer
//           key={"PLCaptureShow"}
//           panelStyle={this.props.panelStyle}
//           editable={this.props.editable}
//           viewEntities={this.props.viewEntities}
//           projectId={this.props.projectId}
//           workId={this.state.capture.id}
//           parentType={parentTypes.capture}
//           panelTypes={["text", "media", "raveDamage"]}/>
//       </div>
//     )
//   }
//   renderEdit = () => {
//     const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
//     const create = (this.props.rightsParent.indexOf(sysRight.CREATE) >= 0)
//     const captureDate = (this.state.capture && this.state.capture.captureDate) ? dayjs(new Date(this.state.capture.captureDate)): null
//     return (
//       <div>
//         {(this.state.capture && this.state.capture.id !== null)?
//           <>
//             <Row gutter={[16,16]}>
//               <Col span={12}>
//                 <Form.Item label="Erfassungsdatum:" >
//                   <DatePicker
//                     name="completionDate"
//                      placeholder={"Datum der Fertigstellung"}
//                      value={captureDate}
//                      onChange={(date, dateString) => {this.DPChange("completionDate", date, dateString)}}
//                      format={"DD.MM.YYYY"}
//                      onBlur={() => this.handleSave()}
//                      readOnly={!this.props.editable || !update} disabled={!update}
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//             <PanelDndContainer
//               key={"PLCaptureEdit"}
//               panelStyle={this.props.panelStyle}
//               enabled={this.props.editable}
//               viewEntities={this.props.viewEntities}
//               projectId={this.props.projectId}
//               workId={this.state.capture.id}
//               parentType={parentTypes.capture}
//               panelTypes={["text", "media", "raveDamage"]}
//               rightsParent={this.props.rightsParent}
//             />
//           </>
//           :<Empty description="Keine Daten">{(create)?<Button onClick={this.createCapture}>Erstellen</Button>:null}</Empty>
//         }
//       </div>
//     )
//   }
//   render() {
//     return (this.props.editable)? this.renderEdit():this.renderView()
//   }
// }
//
// Capture.defaultProps = {
//   rightsParent: [],
//   panelStyle: "default",
//   editable: true,
//   viewEntities: "large",
// }
