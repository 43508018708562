import React, {useEffect, useState} from "react"
import apiRequest from "../../../general/apiRequest";
import {SelectTypeRole, typeRoleItems} from "../../../common/cmnTypeRuleSelect";
import {Button, Card, Drawer, Form, Input, InputNumber, Popconfirm, Switch, Table, Tooltip} from "antd";
import {CloseOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {ImportSchemaTargetTable} from "./ImportSchemaTarget";

const apiUrl = {
  importSchemaRowTypeList:            "/importSchemaRowType/list",
  importSchemaRowTypeCreate:          "/importSchemaRowType",
  importSchemaRowTypeSave:            "/importSchemaRowType",
  importSchemaRowTypeDelete:          "/importSchemaRowType",
  importSchemaRowTypeShow:            "/importSchemaRowType/show",
}

const importSchemaRowTypeNew = {
  id: null,
  active: true,
  importSchemaId: null,
  name: "Neuer Eintrag",
  description: "",
  rowTypeRuleId: "6e2a84c4-4373-44e6-85dc-5f735cebd955",
  rowTypeParams: "",
  rowTypeExpression: "",
  rowCount: 0,
  discardRow: false,
}
export const ImportSchemaRowTypeTable = (props) => {
  const [importSchemaRowTypes, setImportSchemaRowTypes] = useState([])
  const {selectedRowTypeRowKey, setSelectedRowTypeRowKey} = props
  useEffect(() => {loadImportSchemaRowTypes(props.schemaId)}, [props.schemaId, selectedRowTypeRowKey])
  const loadImportSchemaRowTypes = async parentId => {
    if (parentId) {
//      const res = await apiRequest.get(`${apiUrl.importSchemaRowTypeList}/${parentId[0]}`)
      const res = await apiRequest.get(`${apiUrl.importSchemaRowTypeList}/${parentId}`)
      if (res && res.state && res.data)
        setImportSchemaRowTypes(res.data)
      else
        setImportSchemaRowTypes([])
    } else setImportSchemaRowTypes([])
  }
  const createRowType = async () => {
//    if (props.schemaId && props.schemaId.length >0) {
    if (props.schemaId) {
      const schemaId = props.schemaId
      const res = await apiRequest.post(apiUrl.importSchemaRowTypeCreate, JSON.stringify({...importSchemaRowTypeNew, importSchemaId: schemaId}))
      if (res && res.state && res.data) {
        loadImportSchemaRowTypes(props.schemaId)
      }
    }
  }
  const deleteRowType = async (rowTypeId) => {
    /*const res = */await apiRequest.delete(`${apiUrl.importSchemaRowTypeDelete}/${rowTypeId}`)
    await loadImportSchemaRowTypes(props.schemaId)
  }
  const doSelectionChange = value => {
    setSelectedRowTypeRowKey(value)
  }
  const columns = [
    {title: "Name", dataIndex: "name", ellipsis:true, width: 140,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
    },
    {title: "Beschreibung", dataIndex: "description", ellipsis:true, width: 140,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
    },
    {title: "Regel", dataIndex: "rowTypeRuleId", ellipsis:true, width: 150,
      render: (data, row, ix ) => typeRoleItems.find((item) => data === item.id).name,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
    },
    {title: "Parameter", dataIndex: "rowTypeParams", ellipsis:true, width: 150,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
    },
    {title: "Ausdruck", dataIndex: "rowTypeExpression", ellipsis:true, width: 50,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
    },
    {title: "rowCount", dataIndex: "rowCount", ellipsis:true, width: 50,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
    },
    {title: "discardRow", dataIndex: "discardRow", ellipsis:true, width: 50,
      onCell: (row) => {return {onClick: e => {doSelectionChange(row.id)}}},
      render: (data, row, ix) => <Switch
        style={{cursor: "default",}}
        disabled
        checked={data}
      />,
    },
//    {title: <Tooltip title={"Zeilentyp hinzufügen"}><Button type={"link"} disabled={(props.schemaId.length <= 0)} icon={<PlusOutlined />} onClick={() => {createRowType()}} /></Tooltip>,
    {title: <Tooltip title={"Zeilentyp hinzufügen"}><Button type={"link"} disabled={(!props.schemaId)} icon={<PlusOutlined />} onClick={() => {createRowType()}} /></Tooltip>,
      align: "right",
      render: (data, row, ix ) =>
        <Popconfirm
          placement={"top"}
          title={"Möchten Sie den Datensatz löschen"}
          onConfirm={() => {deleteRowType(row.id)}}
          okText="Ja"
          cancelText="Nein"
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true, ghost: true}}
        >
          <Button type={"link"} danger icon={<DeleteOutlined />} />
        </Popconfirm>
    }
  ]
  return <>
    <Table
      rowKey={"id"}
      rowSelection={{
        type: "radio",
        onChange: (selectedKeys) => {doSelectionChange((selectedKeys.length > 0)?selectedKeys[0]:null)},
        selectedRowKeys: [selectedRowTypeRowKey],
        columnWidth: 0,
        renderCell: () => "",
      }}
      columns={columns}
      dataSource={importSchemaRowTypes}
    />
{/*
    <ImportSchemaRowTypeDrawer
      selectedRowTypeRowKey={selectedRowTypeRowKey}
      setSelectedRowTypeRowKey={setSelectedRowTypeRowKey}
      destroyOnClose
    />
*/}
  </>
}
export const ImportSchemaRowTypeDrawer = (props) => {
  const {selectedRowTypeRowKey, setSelectedRowTypeRowKey, importSchemaAnchor} = props
  return <Drawer
    title={"Zeilentypen"}
    open={!!selectedRowTypeRowKey && importSchemaAnchor?.current}
    destroyOnClose
    closable={false}
    onClose={() => {setSelectedRowTypeRowKey(null)}}
    getContainer={() => document.getElementById("ImportSchemaDrawerParent")}
//    getContainer={false}
    style={{position: "absolut"}}
    width={1000}
    extra={<Button type={"text"} icon={<CloseOutlined />} onClick={() => {setSelectedRowTypeRowKey(null)}} />}
  >
    <ImportSchemaRowTypeEdit
      importSchemaAnchor={importSchemaAnchor}
      rowTypeId={selectedRowTypeRowKey}
    />
  </Drawer>
}
const ImportSchemaRowTypeEdit = (props) => {
  const [importSchemaRowType, setImportSchemaRowType] = useState({changed: false, data: {}})
  const {importSchemaAnchor} = props
//  const [selectedTargetKey, setSelectedTargetKey] = useState(null)
  useEffect(() => {loadImportSchemaRowType(props.rowTypeId)}, [props.rowTypeId])
  const loadImportSchemaRowType = async schemaId => {
    if (schemaId) {
      const res = await apiRequest.get(`${apiUrl.importSchemaRowTypeShow}/${schemaId}`)
      if (res && res.state && res.data)
        setImportSchemaRowType({changed: false, data: res.data})
    }
  }
  const saveRowType = async () => {
    if (importSchemaRowType.changed) {
      const res = apiRequest.put(apiUrl.importSchemaRowTypeSave, JSON.stringify(importSchemaRowType.data))
      if (res && res.state && res.data) setImportSchemaRowType({changed: false, data: res.data})
    }
  }
  const handleChange = (name, value) => {
    setImportSchemaRowType({changed: true, data:{...importSchemaRowType.data, [name]: value}})
  }
  const handleBlur = () => saveRowType()
  return <Card title={null}>
    <Form name="import-schema-rowtype-form" layout={"vertical"} preserve={false}>
      <Form.Item label={"Name"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px 0 0', }}>
        <Input
          name={"name"}
          value={importSchemaRowType.data.name}
          maxLength={40}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Zeilentyperegel"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(25% - 8px)', margin: '0 4px', }}>
        <SelectTypeRole
          name={"rowTypeRuleId"}
          value={importSchemaRowType.data.rowTypeRuleId}
          style={{resize: "none"}}
          onChange={(value) => {handleChange("rowTypeRuleId", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Zeilenanzahl"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(15% - 8px)', margin: '0 4px', }}>
        <InputNumber
          name={"rowCount"}
          value={importSchemaRowType.data.rowCount}
          style={{width: "100%"}}
          controls
          decimalSeparator={","}
          min={0}
          precision={0}
          onChange={(value) => {handleChange("rowCount", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"überspringen"} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(10% - 4px)', margin: '0 0 0 4px', }}>
        <Switch
          name={"discardRow"}
          checked={importSchemaRowType.data.discardRow}
          onChange={(value) => {handleChange("discardRow", value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Beschreibung"} style={{ marginBottom: 0, }}>
        <Input.TextArea
          name={"description"}
          value={importSchemaRowType.data.description}
          maxLength={2000}
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{resize: "none"}}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Zeilenparameter"} style={{ marginBottom: 0, }}>
        <Input
          name={"rowTypeParams"}
          value={importSchemaRowType.data.rowTypeParams}
          maxLength={200}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
      <Form.Item label={"Zeilenausdruck"} style={{ marginBottom: 0, }}>
        <Input.TextArea
          name={"rowTypeExpression"}
          value={importSchemaRowType.data.rowTypeExpression}
          maxLength={2000}
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{resize: "none"}}
          onChange={(e) => {handleChange(e.target.name, e.target.value)}}
          onBlur={handleBlur}
        />
      </Form.Item>
    </Form>
    <ImportSchemaTargetTable
      importSchemaAnchor={importSchemaAnchor}
      refId={props.rowTypeId}
    />
{/*
    <ImportSchemaTargetDrawer
      openDrawer={selectedTargetKey.length > 0}
      selectedRowTypeKeys={selectedTargetKey}
      setSelectedRowTypeKeys={setSelectedTargetKey}
//      destroyOnClose
    />
*/}
  </Card>
}
