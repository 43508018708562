import {Tabs} from "antd";
import {isTenantIdLigniteTenant, isUserIdLigniteAdmin, tN} from "../../system/checkTenants";
import {PanelCloud} from "../../panelCloud";
import PanelDndContainer, {parentTypes} from "../../panel/panelDndContainer";
import {WorkpieceList} from "../workpiece/rascalWorkpieceList";
import {ParticipantList} from "../Participant";
import React, {useEffect, useState} from "react";
import useCredentials from "../../system/UseCredentials";

export const RoomTabs = ({editable, ...props}) => {
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const userId = credentials?.user.id
  const [activeTab, setActiveTab] = useState("RM0")
  useEffect(() => setActiveTab("RM0"), [props.room.id])
  const panelTypes = ["text", "media", "measure", "location", "subjectPerson", "subjectOrganisation", "nodeData", "raveMultiAssignment"]
  const handleChangeTab = (activeKey) => setActiveTab(activeKey)
  const tabItems = () => {
    const items = []
    items.push(
      {
        key: "RM0", label: "Übersicht", destroyInactiveTabPane: true,
        children: <>
          {!isTenantIdLigniteTenant(tenantId)
            ?<PanelCloud
              key="PanelRoomCloudURL"
              cloudURL={props.room.cloudUrl}
            />
            : null}
          <PanelDndContainer
            key="PanelRoomBaseShow"
            fromTemplate={!!props.room.templateId}
            rightsParent={props.rights}
            editable={false}
            projectId={props.room.projectId}
            workId={props.room.id}
            workParentId={props.room.parentId}
            parentType={parentTypes.room}
            panelTypes={panelTypes}/>
        </>,
      },
      {
        key: "PJ1", label: tN(tenantId, "Raumdaten"), destroyInactiveTabPane: true,
        children: <PanelDndContainer
          key="PanelRoomBase"
          rightsParent={props.rights}
          editable={editable}
          fromTemplate={!!props.room.templateId}
          projectId={props.room.projectId}
          workId={props.room.id}
          workParentId={props.room.parentId}
          parentType={parentTypes.room}
          panelTypes={panelTypes}
        />,
      },
      {
        key: "RM4", label: tN(tenantId, "Objekte"), destroyInactiveTabPane: true,
        children: <WorkpieceList
          rightsParent={props.rights}
          projectId={props.room.projectId}
          tenantId={tenantId} workId={props.room.id}
          parentType={parentTypes.room}
        />,
      },
    )
    if (!isTenantIdLigniteTenant(tenantId)) items.push({
      key: "Media3", label: "Medien", destroyInactiveTabPane: true,
      children: <PanelDndContainer
        key="PanelRoomMedia"
        rightsParent={[]}
        projectId={props.room.projectId}
        workId={props.room.id}
        workParentId={props.room.parentId}
        parentType={parentTypes.room}
        panelTypes={["media"]}
      />,
    },)
    if (!isTenantIdLigniteTenant(tenantId) || isUserIdLigniteAdmin(userId)) items.push({
      key: "BDPCT", label: "Mitwirkende", destroyInactiveTabPane: true,
      children: <ParticipantList
        key="RoomParticipant"
        rightsParent={props.rights}
        projectId={props.room.projectId}
        workId={props.room.id}
        parentType={parentTypes.room}
      />,
    },)
    return items
  }
  return <Tabs
    defaultactivekey="RM1"
    destroyInactiveTabPane
    activeKey={activeTab}
    onChange={handleChangeTab}
    items={tabItems()}
  />
}
