import React, {useEffect, useState} from 'react';
import {TreeSelect} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrl = {
  CatalogTreeByRefWithoutLeafs: "/catalog/withoutleaf",
}

export const CatalogTreeWithoutLeafs = (props) => {
  const [catalogs, setCatalogs] = useState([])
  const {catalogTreeId, showempty, disableempty, textempty, ...rest} = props
  useEffect(() => {
    loadCatalogTree()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogTreeId])
  const loadCatalogTree = async () => {
    if (catalogTreeId) {
      const rd = await apiRequest.get(apiUrl.CatalogTreeByRefWithoutLeafs + "/" + catalogTreeId)
      if (rd && rd.state && rd.data)
        setCatalogs(rd.data)
      else
        setCatalogs([])
    } else setCatalogs([])
  }
  return (
    <TreeSelect
      key={"CTWL_" + catalogTreeId}
      style={{ width: '100%' }}
      fieldNames={{label: "name", value: "id", children: "children"}}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={catalogs}
      placeholder={"bitte auswählen"}
      {...rest}
    />
  )
}

CatalogTreeWithoutLeafs.defaultProps = {
  catalogTreeId: null,
  showempty: false,
  disableempty: false,
  textempty: "Please Choose",
}

