import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlAggregationTypeList         = "/aggregationType/list"

export const SelectAggregationType = ({allowClear, onBlur, onChange, valueEmpty, showEmpty, disableEmpty, textEmpty, readOnly, ...props}) => {
  const [aggregationTypes, setAggregationTypes] = useState([])
  useEffect(() => {
    loadSelectAggregationType()
  }, [])
  const loadSelectAggregationType = () => {
    apiRequest.get(apiUrlAggregationTypeList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setAggregationTypes(rd.data)
      })
      .catch(error => console.log("loadSelectAggregationType in SelectAggregationType: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    style={{width: "100%"}}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={aggregationTypes}
  />
/*
    {(showEmpty)?<SelectOption key={"dst0"} value={valueEmpty} disabled={disableempty}>{textEmpty}</SelectOption>:null}
    {aggregationTypes.map(item =><SelectOption key={item.id} value={item.id}>{item.name}</SelectOption>)}
  </Select>
*/
}
