/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 09.01.2024
 *
 * Project: csescaclient
 *
 * ProjectMeta in package  (project: csescaclient ) © 09.01.2024
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import {raveMeta} from "../raveMeta";

export const raveRoomMeta= {
  ...raveMeta,
  domain: "room",
  module: {
    ...raveMeta.module,
    routeDomain: "room",
  },
  defaultData: {
    ...raveMeta.defaultData,
    projectId: "",
    parentId: "",
    breakdownTypeId: "",
    codeNumber: "0815",
    name: "Test",
    oldNumber: "",
    oldName: "",
    rClassId: "",
    cClassId: "",
    uClassId: "",
    publicAccess: false,
    constrYearBeginText: "",
    constrYearEndText: "",
    enClassification: false,
    isTemplate: null,
    templateId: null,
    cloudUrl: "",
  }
}
// const raveRoomMetaBackup= {
//   keyId: "id",
//   domain: "room",
//   domainList: "List",
//   module: {
//     name: "rave",
//     show: "/",
//     routeBase: "ras",
//     routeDomain: "room",
//   },
//   routes: {
//     create: "",
//     delete: "",
//     save: "",
//   },
//   defaultData: {
//     id: "",
//     active: true,
//     position: -1,
//     tenantId: "",
//     projectId: "",
//     parentId: "",
//     breakdownTypeId: "",
//     codeNumber: "0815",
//     name: "Test",
//     oldNumber: "",
//     oldName: "",
//     rClassId: "",
//     cClassId: "",
//     uClassId: "",
//     publicAccess: false,
//     constrYearBeginText: "",
//     constrYearEndText: "",
//     enClassification: false,
//     isTemplate: false,
//     templateId: null,
//     cloudUrl: "",
//   }
// }
