import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Space} from "antd";
import {DownOutlined, FilePdfOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {PrintWorkpieceChoose} from "../../dialog/modalPrintCoose";
import apiRequest from "../../general/apiRequest";
import {tN} from "../../system/checkTenants";
import {WorkpieceTabs} from "./workpieceTabs";
import {WorkpieceData} from "./workpieceData";
import useCredentials from "../../system/UseCredentials";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {useFetchData} from "../../core/Fetch/useFetchData";
import {raveWorkpieceMeta} from "../../rave";
import {sysRight} from "../../common/cmnRights";

import {getRaveBreadcrumbType} from "../../rave";

export const  WorkpieceMain = ({...props}) => {
  const [state, setState] = useContext(CsescaContext)
  const [collapseDetail, setCollapseDetail] = useState(false)
  // const [workpiece, setWorkpiece] = useState({...defaultWorkpiece})
  // const [rights, setRights] = useState([])
  // const [isChanged, setIsChanged] = useState(false)
  const params = useParams()
  const paramsId = params.id
  const {data, rights, persist, recast} = useFetchData(raveWorkpieceMeta, params.id, {})
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  const breadcrumbType = getRaveBreadcrumbType(data, tenantId)
  useEffect(() => {
    doState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])
  const doState = () => setState({
    ...state,
    breadcrumbType: breadcrumbType,
    entityName: "workpiece",
    pageType: "rave",
    entityId: params.id,
    state: "done",
    breadCrumbList: [],
  })
  const onPrintChooseResult = (res) => {
    const reportUrl = ((!res?.newReport)?"/workpiece/sheet/":"/jasper/workpieceSheet/")
    // apiRequest.post(`/workpiece/sheet/${data.id}`, JSON.stringify(res.values),{responseType: "blob"})
    apiRequest.post(reportUrl + data.id, JSON.stringify(res.values),{responseType: "blob"})
      .then(doc => {
        const docURL = URL.createObjectURL(doc)
        const anchor = document.createElement("a")
        anchor.href = docURL
        anchor.download = "WP-Doc_" + data.codeNumber + "_" + data.name + ".pdf"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(docURL)
      })
  }
  const handleChildChange = ({target}) => {
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(target.name, target.type === "checkbox" ? target.checked : target.value)
  }
  const handleSelectChange = (name, value) => {
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(name, value)
  }
  return <Card
    className={"ces-rave-card"}
    style={(!!data.templateId)?{backgroundColor:"#FFFFFA"}: {}}
    title={<><h2><Button type={"text"} icon={<DownOutlined rotate={(!collapseDetail)?0:-90} />}style={{marginRight: ".2em"}} onClick={() => setCollapseDetail(!collapseDetail)} />{tN(tenantId, "Objekt")}</h2><i>{data.codeNumber}</i>{data.name}</>}
    extra={<Space>
      {(data.projectId)
      ?<PrintWorkpieceChoose
        buttonText="PDF..."
        buttonIcon={<FilePdfOutlined style={{fontSize: "2em"}} />}
        onResult={onPrintChooseResult}
//          projectId={this.state.data.id}
      />
      :null}
    </Space>}
  >
      <WorkpieceData
        collapseDetail={collapseDetail}
        tenantId={tenantId}
        workpiece={data}
        data={data}
        rights={rights}
        handleChildChange={(evt) => {handleChildChange(evt)}}
        handleSelectChange={(name, value) => {handleSelectChange(name, value)}}
        handleChildBlur={() => {persist()}}
      />
      <WorkpieceTabs
        data={data}
        editable={rights.indexOf(sysRight.UPDATE) >= 0}
        rights={rights}
        workpiece={data}
      />
  </Card>
}

