import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlEntityList           = "/entity/list"

export const EntitySelect = ({allowClear, onBlur, onChange, readOnly = false, ...props}) => {
  const [entities, setEntities] = useState([])
  useEffect(() => {
    loadEntities()
  }, [props.catalogGroup])
  const loadEntities = () => {
    apiRequest(apiUrlEntityList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setEntities(rd.data)
      })
      .catch(error => console.log("loadEntities in EntitySelect: " + error))
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    key={"ETY"}
    {...props}
    onChange={!readOnly ? onChange : undefined}
    onBlur={!readOnly ? onBlur : undefined}
    allowClear={!readOnly && allowClear}
    open={readOnly ? false : undefined}
    options={entities}
  />
/*
    {(showempty) ?<SelectOption key={"ETY0"} value={null} disabled={disableempty}>{textempty}</SelectOption>:null}
    {entities.map(entity => (<SelectOption key={entity.id} value={entity.id} >{entity.name}</SelectOption>))}
  </Select>
*/
}
