import React, {useEffect, useState} from 'react'
import { ImportOutlined, PlusOutlined} from "@ant-design/icons";
import {ModalWorkpieceChoose} from "../template/modalWorkpieceChoose";
import apiRequest from "../../general/apiRequest";
import {tN, canShowAddTemplateButton, canShowAddStandardButton} from "../../system/checkTenants";
import {Button, Space, Tooltip} from "antd";
import useCredentials from "../../system/UseCredentials";
import {sysRight} from "../../common/cmnRights";

const apiUrlWorkpieceRight                 = "/workpiece/rights"
// const apiUrlWorkpieceCreate                = "/workpiece"
// const apiUrlWorkpieceCreateFromTemplate    = "/workpiece/createFromTemplate"

export const WorkpieceListMenu = ({isTemplate = false, parentId, projectId, create, ...props}) => {
  const [chooseOpen, setChooseOpen] = useState(false)
  const [rights, setRights] = useState([]);
  // const navigation = useNavigate()
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  useEffect (() => {
    getRights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId])
  const getRights = () => {
    apiRequest.get(apiUrlWorkpieceRight + "/" + parentId)
      .then((rd) => {
        if (rd && rd.state && rd.rights) setRights(rd.rights)
      })
      .catch(error => console.log("getRights in WorkpieceListMenu: " + error))
  }
  // const createWorkpiece = (projectId, parentId) => {
  //   const url = (projectId)
  //   ?apiUrlWorkpieceCreate + "?projectId=" + projectId + "&parentId=" + parentId + "&template=" + isTemplate
  //   :apiUrlWorkpieceCreate + "?parentId=" + parentId + "&template=" + isTemplate
  //   apiRequest.post(url)
  //     .then((rd) => {
  //       if (rd && rd.state && rd.data) navigation("/ras/workpiece/" + rd.data)
  //     })
  //     .catch(error => console.log("createWorkpiece in WorkpieceListMenu: " + error))
  // }

  // const duplicateWorkpiece = (templateWorkpieceId) => {
  //   if (templateWorkpieceId.length > 0) {
  //     const url = apiUrlWorkpieceCreateFromTemplate + `?sourceWorkpieceId=${templateWorkpieceId}&newParentId=${parentId}&newProjectId=${parentId}&template=${isTemplate}`
  //     apiRequest.post(url)
  //       .then((rd) => {
  //         if (rd && rd.state && rd.data) navigation("/ras/workpiece/" + rd.data)
  //       })
  //       .catch(error => console.log("duplicateWorkpiece in WorkpieceListMenu: " + error))
  //   }
  // }

  const createFromTemplate = (selectedTemplateId) => {
    if (selectedTemplateId) create({
      newData: null,
      urlExtension: `createFromTemplate`,
      urlParams: `sourceWorkpieceId=${selectedTemplateId}&newParentId=${parentId}&newProjectId=${projectId}`,
      doNavigate: true,
    })
    setChooseOpen(false)
  }
  // const handleOk = (value) => {
  //   duplicateWorkpiece(value.selectedRowKeys)
  //   setChooseOpen(false)
  // }
  return (
    <Space>
      {canShowAddStandardButton(isTemplate, tenantId)
        ?<Tooltip title={tN(tenantId, "neues Objekt")} color={'cyan'} placement="topRight">
          <Button
            onClick={() => create({
              newData: null,
              urlExtension: null,
              urlParams: `projectId=${projectId}&parentId=${parentId}&isTemplate=${isTemplate}`,
              doNavigate: true,
            })}
            type={"link"}
            disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
            icon={<PlusOutlined style={{fontSize: "2em"}} />}
          />
        </Tooltip>
        :null}
      {(canShowAddTemplateButton(isTemplate, tenantId) && parentId)
        ?<>
          <Tooltip title={tN(tenantId, "neues Objekt aus Template...")} color={'cyan'} placement="topRight">
            <Button
              onClick={() => setChooseOpen(true)}
              type={"link"}
              disabled={!(rights.indexOf(sysRight.CREATE) >= 0)}
              icon={<ImportOutlined style={{fontSize: "2em"}} />}
            />
          </Tooltip>
        {(chooseOpen)?
          <ModalWorkpieceChoose
            parentId={tenantId}
            open={chooseOpen}
            onCancel={() => setChooseOpen(false)}
            onOk={(value) => createFromTemplate(value.selectedRowKeys)}
          />
          :null}
        </>
        :null
      }
    </Space>
  )
}