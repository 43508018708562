/**
 * modal window to choose persons and organisations
 */

import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button, Input, Space, Table, Tabs, Col, Row} from "antd";
import Draggable from 'react-draggable';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {SelectRole} from "../common/cmnRoleSelect";
import apiRequest from "../general/apiRequest";

const apiUrlPersonList            = "/person/list"
const apiUrlOrganisationList      = "/org/list"

const defaultRole = "cf5e7506-1143-47cb-ad04-1221ec0f3432"

export class PersonChoose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      disabled: true,
      bonds: {left: 0, top: 0, bottom: 0, right: 0},
//      activeTab: "subjectPerson",
      activeTab: "",
      selectedRowKeys: [],
      roleId: defaultRole,
      okButtonDisabled: true
    }
    this.draggableRef = React.createRef()
  }
  showModal = () => { this.setState({visible: true}) }
  handleOk = e => {
    this.setState({ visible: false })
    if (this.props.onResult) {
      this.props.onResult(
        {result: "ok",
          kind: this.state.activeTab,
          values: {subjectId: this.state.selectedRowKeys.toString(), roleId: this.state.roleId}
        }
      )
    }
    this.setState({selectedRowKeys:[], okButtonDisabled: true})
  }
  setRoleId = id => {console.log("Change Role: " + id ); this.setState({roleId: id})}
  handleCancel = e => {
    this.setState({ visible: false })
    if (this.props.onResult) {
      this.props.onResult(
        {result: "cancel",
          kind: this.state.activeTab,
          values: {subjectId: ""}
        }
      )
    }
    this.setState({selectedRowKeys:[], okButtonDisabled: true})
  }
  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = this.draggableRef?.current?.getBoundingClientRect();
    this.setState({
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y)
      }
    })
  }
  handleSelectionChange = (value, panel) => {
    this.setState({selectedRowKeys: value, activeTab: panel, okButtonDisabled: !(value !== "")})
  }
  render = () => {
    const { bounds, disabled } = this.state;
    const visible = this.state.visible
    return (
      <>
      <Button
        type={this.props.buttonType}
        icon={this.props.buttonIcon}
//        ghost={this.props.buttonGhost}
//        shape={this.props.buttonShape}
        onClick={this.showModal}
        readOnly={this.props.buttonDisabled}
        disabled={this.props.buttonDisabled}
        style={this.props.buttonStyle}
      >
        {this.props.buttonText}
      </Button>
        {(visible)?
      <Modal
        destroyOnClose={true}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                this.setState({
                  disabled: false,
                });
              }
            }}
            onMouseOut={() => {
              this.setState({
                disabled: true,
              });
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Kontakt / Organisation Auswählen
          </div>
        }
        open={visible}
        onOk={this.handleOk}
        okButtonProps={{disabled: this.state.okButtonDisabled}}
        onCancel={this.handleCancel}
        modalRender={modal => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => this.onStart(event, uiData)}
          >
            <div ref={this.draggableRef}>{modal}</div>
          </Draggable>
        )}
      >
        <PersonList
          doSelectionChange={this.handleSelectionChange}
          changeRoleId={this.setRoleId}
          selectedRowKeys={this.state.selectedRowKeys}
          panelTypes={this.props.panelTypes}
          chooseRole={this.props.chooseRole}
        />
      </Modal>
          :null}
      </>
    )
  }
}

// const PersonList2 = (props) => {
//   const navigation = useNavigate()
//   return <PersonListInternal
//     navigation={navigation}
//     {...props}
//   />
// }

const PersonList = ({panelTypes, chooseRole = defaultRole, selectedRowKeys = [], doSelectionChange, changeRoleId, ...props}) => {
  const [persons, setPersons] = useState([])
  const [organisations, setOrganisations] = useState([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  useEffect(() => {
    loadPersons()
    loadOrganisations()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
  , []);
  const loadPersons = () => {
    apiRequest.get(apiUrlPersonList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setPersons(rd.data)
      })
      .catch(error => console.log("loadPersons in PersonList: " + error))
  }
  const loadOrganisations = () => {
    apiRequest.get(apiUrlOrganisationList)
      .then((rd) => {
        if (rd && rd.state && rd.data) setOrganisations(rd.data)
      })
      .catch(error => console.log("loadOrganisations in PersonList: " + error))
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suchen
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Löschen
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
              // this.setState({
              //   searchText: selectedKeys[0],
              //   searchedColumn: dataIndex,
              // })
            }}
          >
            Filtern
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // })
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  return <div>
    <Tabs
      defaultactivekey={(panelTypes.has("subjectPerson"))?"subjectPerson":"subjectOrganisation"}
      destroyInactiveTabPane={true}
    >
      {(panelTypes.has("subjectPerson")) ?
      <Tabs.TabPane tab="Personen" key="subjectPerson" >
        <Table
          dataSource={persons}
          rowSelection={{type: "radio", onChange: (selectedKeys) => {doSelectionChange(selectedKeys, "subjectPerson") }, selectedRowKeys}}
          onRow={(row, index) => {
            return {
//              onDoubleClick: e =>{console.log("Person doubleclick: " + row.id)},
//              onClick: e =>{this.props.doChange([row.id])}
              onClick: e =>{doSelectionChange([row.id], "subjectPerson")}
            }
          }}
          columns={[
            { title: "Titel", dataIndex: "academicTitle", width: 50, ...getColumnSearchProps('academicTitle'), sorter: (a, b) => a.academicTitle.toLowerCase().localeCompare(b.academicTitle.toLowerCase()), sortDirections: ['descend', 'ascend']},
            { title: "Nachname", dataIndex: "lastName", width: 150, ...getColumnSearchProps('lastName'), sorter: (a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()), sortDirections: ['descend', 'ascend']},
            { title: "Vorname", dataIndex: "firstName", width: 150, ...getColumnSearchProps('firstName'), sorter: (a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()), sortDirections: ['descend', 'ascend']},
            { title: "zweiter Vorname", dataIndex: "middleName", width: 150, ...getColumnSearchProps('middleName'), sorter: (a, b) => a.middleName.toLowerCase().localeCompare(b.middleName.toLowerCase()), sortDirections: ['descend', 'ascend']},
            { title: ""},
            ]}
          rowKey={record => record.id}
        />
        {(chooseRole) ? <Row><Col span={12}><SelectRole style={{ width: "100%" }} onChange={changeRoleId} defaultValue={defaultRole} /></Col></Row>:null}
      </Tabs.TabPane>
      : null }
      {(panelTypes.has("subjectOrganisation")) ?
      <Tabs.TabPane tab="Organisationen" key="subjectOrganisation">
        <Table
          dataSource={organisations}
          rowSelection={{type: "radio", onChange: (selectedKeys) => {doSelectionChange(selectedKeys, "subjectOrganisation") }, selectedRowKeys: selectedRowKeys}}
          onRow={(row, index) => {
            return {
//              onDoubleClick: e =>{console.log("Person doubleclick: " + row.id)},
              onClick: e =>{doSelectionChange([row.id], "subjectOrganisation") }
            }
          }}
          columns={[
            { title: "Firma", dataIndex: "name1", width: 50, ...getColumnSearchProps('name1'), sorter: (a, b) => a.name1.toLowerCase().localeCompare(b.name1.toLowerCase()), sortDirections: ['descend', 'ascend']},
            { title: "Firma zusatz", dataIndex: "name2", width: 150, ...getColumnSearchProps('name2'), sorter: (a, b) => a.name2.toLowerCase().localeCompare(b.name2.toLowerCase()), sortDirections: ['descend', 'ascend']},
            { title: ""},
          ]}
          rowKey={record => record.id}/>
      </Tabs.TabPane>
      : null }
    </Tabs>
  </div>
}
