import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Skeleton, Space, Tooltip} from "antd"
import {
  DownOutlined,
  ExportOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {GEABChoose} from "../../dialog/modalGAEBChoose";
import {useNavigate, useParams} from "react-router-dom";
import {sysRight} from "../../common/cmnRights";
import {PrintProjectChoose} from "../../dialog/modalPrintProjectCoose";
import apiRequest from "../../general/apiRequest";
import {isExcelExt, tN} from "../../system/checkTenants";
import {ProjectTabs} from "./projectTabs";
import {ProjectData} from "./projectData";
import useCredentials from "../../system/UseCredentials";
import {useFetchData} from "../../core/Fetch/useFetchData";
import {raveProjectMeta} from "../../rave/Project/ProjectMeta";
import CsescaContext from "../../core/AppContext/CsescaContext";

import {getRaveBreadcrumbType} from "../../rave";

export const ProjectMain = ({...props}) => {
  const [state, setState] = useContext(CsescaContext)
  const [collapseDetail, setCollapseDetail] = useState(false)
//   const [project, setProject] = useState({...defaultProject})
//   const [rights, setRights] = useState([])
//   const [isChanged, setIsChanged] = useState(false)
  const navigation = useNavigate()
  const params = useParams()
  const paramsId = params.id
  const {data, rights, persist, recast} = useFetchData(raveProjectMeta, params.id, {})
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const breadcrumbType = getRaveBreadcrumbType(data, tenantId)
  useEffect(() => {
    doState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])
  const doState = () => setState({
    ...state,
    breadcrumbType: breadcrumbType,
    entityName: "project",
    pageType: "rave",
    entityId: params.id,
    state: "done",
    breadCrumbList: [],
  })
  const onPrintChooseResult = (res) => {
    const reportUrl = ((!res?.newReport)?"/project/sheet/":"/jasper/projectSheet/")
    apiRequest.post(reportUrl + data.id, JSON.stringify(res.values), {responseType: "blob"})
//    apiRequest.post("/project/sheet/" + data.id, JSON.stringify(res.values), {responseType: "blob"})
      .then(doc => {
        const docURL = URL.createObjectURL(doc)
        const anchor = document.createElement("a")
        anchor.href = docURL
        anchor.download = "WPDoc" + data.codeNumber + "_" + data.name + ".pdf"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(docURL)
      })
  }
  const onGAEBChooseResult = (res) => {
    apiRequest.post("/project/gaeb_d81/" + data.id, JSON.stringify(res.values),{responseType: "blob"})
      .then(xml => {
        const xmlURL = URL.createObjectURL(xml)
        const anchor = document.createElement("a")
        anchor.href = xmlURL
        anchor.download = "GAEB"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(xmlURL)
      })
  }
  const onExcelResult = () => {
    apiRequest.get("/project/excelsheet/" + data.id,{responseType: "blob"})
      .then(rd => {
        const xmlURL = URL.createObjectURL(rd)
        const anchor = document.createElement("a")
        anchor.href = xmlURL
        anchor.download = "Objekte"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(xmlURL)
      })
  }
  const handleChildChange = ({target}) => {
//    const update = (rights.indexOf(sysRight.UPDATE) >= 0)
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(target.name, target.type === "checkbox" ? target.checked : target.value)
  }
  return (data)
  ?<Card
    className={"ces-rave-card"}
    style={(!!data?.templateId)?{backgroundColor:"#FFFFFA"}: {}}
    title={<><h2><Button type={"text"} icon={<DownOutlined rotate={(!collapseDetail)?0:-90} />} onClick={() => setCollapseDetail(!collapseDetail)} />{tN(tenantId, "Projekt")}</h2><i>{data?.codeNumber}</i>&nbsp;{data?.name}</>}
    extra={<Space>
      <PrintProjectChoose
        buttonText="PDF..."
        buttonIcon={<FilePdfOutlined style={{fontSize: "2em"}} />}
        onResult={onPrintChooseResult}
        projectId={data.id}
      />
      <GEABChoose buttonText="GAEB..." onResult={onGAEBChooseResult} projectId={data.id} buttonIcon={<ExportOutlined style={{fontSize: "2em"}} />} />
      {(isExcelExt(tenantId))?
        <Tooltip title={"Erzeugt eine Excel-Liste mit " + tN(tenantId, "Objekt")} color={'cyan'} placement="topRight" >
          <Button
            type={"link"}
            icon={<FileExcelOutlined style={{fontSize: "2em"}} />}
            onClick={() => {onExcelResult()}}
          />
        </Tooltip>
        :null
      }
      <Tooltip title={"Projektübersicht"} color={'cyan'} placement="topRight">
        <Button
          type={"link"}
          icon={<ToTopOutlined style={{fontSize: "2em"}} />}
          onClick={() => {navigation("/ras/projectlist")}}
        />
      </Tooltip>
    </Space>}
  >
    <ProjectData
      collapseDetail={collapseDetail}
      project={data}
      data={data}
      rights={rights}
      handleChildChange={(evt) => {handleChildChange(evt)}}
      handleChildBlur={(evt) => {persist()}}
    />
    <ProjectTabs
      data={data}
      editable={rights.indexOf(sysRight.UPDATE) >= 0}
      project={data}
      rights={rights}
    />
  </Card>
  :<Skeleton />
}
