import React, {useState} from "react";
import {covImgFunc, layout, layoutForm, layoutImg1, layoutImg2, mapImgFunc} from "../rascalApiDef";
import {sysRight} from "../../common/cmnRights";
import {Button, Card, Checkbox, Col, Form, Input, Row, Skeleton} from "antd";
import {TitleImage} from "../../images/imageMapHover";
import {isLigniteTenant, isTenantIdLigniteTenant} from "../../system/checkTenants";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

export const ProjectData = ({collapseDetail, ...props}) => {
  // const {collapseDetail} = props
  const [collapseCloud, setCollapseCloud] = useState(false)
  const imgContext = {
    refererId: props.project.id,
    tenantId: props.project.tenantId,
    projectId: props.project.id,
    functionId: covImgFunc,
  }
  const read = props.rights.indexOf(sysRight.READ) >= 0
  const update = !(props.rights.indexOf(sysRight.UPDATE) >= 0)
  return <Card
    style={(!!props.project.templateId)?{backgroundColor:"#FFFFFA"}: {}}
    className={(!collapseDetail)?"":"ces-collapse"}
  >
    <Row gutter={[16, 12]}>
      <Col {...layoutImg1} className="data-container">
        {props.project.id
          ?<>
            <TitleImage
              key={"CI" + props.project.id}
              covImg={imgContext}
              imgFunction={covImgFunc}
              rightsParent={props.rights}
            />
          </>
          : null
        }
      </Col>
      <Col {...layoutImg2} className="data-container">
        {(props.project.id && !isLigniteTenant(props.project.tenantId))
          ? <>
            <TitleImage
              key={"MI" + props.project.id}
              covImg={imgContext}
              imgFunction={mapImgFunc}
              rightsParent={props.rights}
            />
          </>
          : null
        }
      </Col>
      <Col {...layoutForm}>
        <Form name="project-data-form" layout={"vertical"}>
          <Row gutter={[16, 12]}>
            <Col {...layout}>
              <Form.Item label="Projekt-Nr." style={{marginBottom: 0}}>
                {(read) ?
                  <Input
                    name="codeNumber" value={props.project.codeNumber}
                    maxLength={20}
                    onChange={(evt) => {props.handleChildChange(evt)}}
                    onBlur={(evt) => {props.handleChildBlur(evt)}}
                    readOnly={update}
                    disabled={update}
                  /> : <Skeleton.Input/>}
              </Form.Item>
            </Col>
            <Col {...layout} >
              <Form.Item label="Name" style={{marginBottom: 0}}>
                <Input
                  name="name" value={props.project.name}
                  maxLength={40}
                  onChange={(evt) => {props.handleChildChange(evt)}}
                  onBlur={(evt) => {props.handleChildBlur(evt)}}
                  readOnly={update}
                  disabled={update}
                />
              </Form.Item>
            </Col>
          </Row>
          {!isTenantIdLigniteTenant(props.project.tenantId) ?
            <>
              <Row gutter={[16, 12]}>
                <Col {...layout} >
                  <Form.Item label="Projekt Beginn / Ende" style={{marginBottom: 0}}>
                    <Form.Item style={{marginBottom: 0, display: 'inline-block', width: 'calc(50% )'}}>{read
                      ?<Input
                        name="projectBeginText" value={props.project.projectBeginText}
                        maxLength={40}
                        onChange={(evt) => {props.handleChildChange(evt)}}
                        onBlur={(evt) => {props.handleChildBlur(evt)}}
                        readOnly={update}
                        disabled={update}
                        />
                      :<Skeleton.Input/>}
                    </Form.Item>
                    <Form.Item style={{marginBottom: 0, display: 'inline-block', width: 'calc(50%)'}}>{read
                      ?<Input
                        name="projectEndText"
                        value={props.project.projectEndText}
                        maxLength={20}
                        onChange={(evt) => {props.handleChildChange(evt)}}
                        onBlur={(evt) => {props.handleChildBlur(evt)}}
                        readOnly={update}
                        disabled={update}
                      />
                      :<Skeleton.Input/>}
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col {...layout}>
                  <Form.Item label={" "} style={{marginBottom: 0}}>
                    <Checkbox
                      name={"enClassification"} checked={props.project.enClassification}
                      onChange={(evt) => {props.handleChildChange(evt)}}
                      onBlur={(evt) => {props.handleChildBlur(evt)}}
                      readOnly={update} disabled={update}
                    /><label style={{paddingLeft: "1em"}}>EN Klassifizierung</label>
                  </Form.Item>
                </Col>
              </Row>
            </>
            : null}
          {!isTenantIdLigniteTenant(props.project.tenantId) ?
            <Row>
              <Col span={24} offset={0} style={{textAlign: "right"}} >
                <Button type="link" onClick={() => {setCollapseCloud(!collapseCloud)}}>
                  {collapseCloud ? <UpOutlined/> : <DownOutlined/>}Ein/Aus
                </Button>
                {(collapseCloud) ?
                  <div>
                    <Form.Item label="Cloud-URL" style={{marginBottom: 0}}>
                      <input
                        name="cloudUrl" value={props.project.cloudUrl} style={{width: "100%"}}
                        onChange={(evt) => {props.handleChildChange(evt)}}
                        onBlur={(evt) => {props.handleChildBlur(evt)}}
                      />
                    </Form.Item>
                  </div>
                  : null
                }
              </Col>
            </Row>
            : null}
        </Form>
      </Col>
    </Row>
  </Card>
}
