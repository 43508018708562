import axios from "axios"
import {notification} from "antd"

export const idExp = "([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})"

const apiProtocol = "http://"
const apiPort = ":9000"
const apiBasePath = "localhost"
//export const apiURLOld = apiProtocol + apiBasePath + apiPort
export const apiUrl = (process.env.NODE_ENV === 'production') ? "" : apiProtocol + apiBasePath + apiPort

// Old Version
export function fetchInit(method, sndData = null) {
  return {
    method: method.toUpperCase(),
    credentials: "include",
    headers: {
      'Content-Type': 'application/json', /*, 'Csrf-Token': csrfToken */
      "Access-Control-Request-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Request-Method": "*",
    },
//    mode: 'cors',
    body: sndData
  }
}


const apiConfig = {
////  baseURL: 'https://localhost:8000/api/',
  baseURL: apiUrl + "/api",
  timeout: 100000,
  withCredentials: true,
  responseType: 'json',
  responseEncoding: 'utf8',
//  mode: "cors",
//  "api-key": '',
  headers: {
    'Content-Type': 'application/json', /*, 'Csrf-Token': csrfToken */
//    "Access-Control-Request-Headers": "*",
    "Access-Control-Allow-Origin": "*",
//    "Access-Control-Request-Method": "*",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300 // default
  },
}

const apiRequest = axios.create(apiConfig)
const apiLogin = axios.create(apiConfig)

const clientError = {
  name: "Not logged in",
  code: "ERR_BAD_REQUEST",
  message: "Request failed with status code 401",
  response: {
    status: 401,
    statusText:"Unauthorized",
    data: {data: null, state: false, rights: []}}
}

let abortController = null
// let apiStore = null



// const internalApi = {
//   async get(url, conf = {}) {
//     return await apiRequest.get(url, conf)
//   },
//   async post(url, data, conf = {}) {
//     return await apiRequest.post(url, data, conf)
//   },
//   async put(url, data, conf = {}) {
//     return await apiRequest.put(url, data, conf)
//   },
//   async delete(url, conf = {}) {
//     return await apiRequest.delete(url, conf)
//   },
//   async login(url, data, conf={}) {
//     return await apiRequest.post(url, data, conf)
//   },
//   async logout(url, conf= {}) {
//     return await apiRequest.get(url, conf)
//   }
// }

const interceptor = (store) => {
  // apiStore = store
  apiRequest.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
//      const {user, tenant} = store.getState().data.loggedInUser
//      console.log("apiCall" + conf.url)
      if (!abortController) abortController = new AbortController()
//      if (!abortController || (abortController && abortController.signal.aborted)) {
      if (abortController.signal.aborted) {
        throw clientError
        // throw {name: "Not logged in", message: "Your are Authenticated", response: {status: 401}}
      } else conf.signal = abortController.signal
//      if (conf.url.includes("Unauthorized")) return null
//      if (authHandling.cancel()) throw new axios.Cancel("not LoggedIn")
      return conf
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  apiRequest.interceptors.response.use(
    (res) => {
//      console.log("Data:\n" + JSON.stringify(res.data))
      return res.data
//      return Promise.resolve(res);
    },
    (err) => {
      // You can handle error here and trigger warning message without get in the code inside
      // switch (err.response.status) {
      if (err.response) {
        switch (true) {
          case (err.response.status === 401):
            store.dispatch({
              type: "unauthorized/show",
//            type: msgAction.actionsTypes.openModal,
              payload: {
                title: "API No Rights",
                message: err.message,
                code: err.code,
                status: err.response.status,
                statusText: err.response.statusText,
              }
            })
            return {data: null, state: false, rights: [], error: true,}
          case (err.response.status === 403):
            store.dispatch({
              type: "forbidden/show",
              payload: {
                title: "API No Rights",
                message: err.message,
                code: err.code,
                status: err.response.status,
                statusText: err.response.statusText,
              }
            })
            return {data: null, state: false, rights: [], error: true,}
//          break
          case (err.response.status >= 400 && err.response.status < 500):
            notification.warning({message: "Warnung", description: err.message, placement: "top",})
            break
          default:
            notification.error({message: "Fehler", description: err.message, placement: "top",})
            return {data: null, state: false, rights: [], error: true,}
//          break
        }
    } else return {data: null, state: false, rights: [], error: true,}
//      return Promise.reject(err)
//      return Promise.reject({data: null, state: false, rights: [], error: true,})
    }
  )
//  console.log("init interceptor: ")
  apiLogin.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  apiLogin.interceptors.response.use(
    (res) => {
//      console.log("Data:\n" + JSON.stringify(res.data))
      if (res.config.url !== "/forgetPassword") {
        if (res.data.state && res.data.data) {
          const payload = {...res.data.data.loggedInUser}
          store.dispatch({type: "loggedInUser/setLoggedInUser", payload: payload})
          window.localStorage.setItem('cred', JSON.stringify({
            user: payload.user.id,
            tenant: payload.tenant.id,
            loaded: true
          }))
//        abortController = new AbortController()
          return res.data
        }
      } else return res.data
      return {data: null, state: false, rights: [], error: true,}
//      return Promise.resolve(res);
    },
    (err) => {
      // You can handle error here and trigger warning message without get in the code inside
      switch (true) {
        case (err.response.status === 401):
        case (err.response.status === 403):
          store.dispatch({
            type: "unauthorized/show",
//            type: msgAction.actionsTypes.openModal,
            payload: {
              title: "API No Rights",
              message: err.message,
              code: err.code,
              status: err.response.status,
              statusText: err.response.statusText,
            }
          })
          return {data: null, state: false, rights: [], error: true,}
//          break
        default:
          store.dispatch({
            type: "unauthorized/show",
//            type: msgAction.actionsTypes.openModal,
            payload: {
              title: "API No Rights",
              message: err.message,
              code: err.code,
              status: err.response.status,
              statusText: err.response.statusText,
            }
          })
          return {data: null, state: false, rights: [], error: true,}
//          break
      }
//      return Promise.reject(err)
    }
  )
}


export default apiRequest
export {apiLogin}
export {interceptor}