import React from 'react'
import {Button, Card, Form, Input} from 'antd'
import { Row, Col } from 'antd'
import {SelectCommType} from "../common/cmnCommtypeSelect"
import {sysRight} from "../common/cmnRights"
import apiRequest from "../general/apiRequest";
import {DownCircleOutlined, DragOutlined, UpCircleOutlined} from "@ant-design/icons";

const apiUrlCommunicationShow    = "/communication/show"
const apiUrlCommunicationSave    = "/communication"

export class CommunicationPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communication: {
        id              : "",
        active          : true,
        position        : -1,
        parentId       : "",
        commTypeId    : "",
        commAddr       : ""
      },
      collapsed: false,
    }
    this.isChanged = false
  }
  componentDidMount = () => {
    this.loadCommunication(this.props.itemId)
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.itemId !== this.props.itemId){
      this.loadCommunication(this.props.itemId)
    }
  }
  loadCommunication = async communicationId => {
    const rd = await apiRequest.get(apiUrlCommunicationShow + "/" + communicationId)
    if (rd && rd.state && rd.data) this.setState({communication: rd.data})
  }
  handleSave = async () => {
    if (this.isChanged) {
      await apiRequest.put(apiUrlCommunicationSave, JSON.stringify({...this.state.communication}))
      this.isChanged = false
    }
  }
  handleChildBlur = () => { this.handleSave() }
  handleChildChange = (name, value) => {
    this.setState({communication: {...this.state.communication, [name]: value}})
    this.isChanged = true;
  }
  render = () => {
//    const read = this.props.rightsParent.indexOf(sysRight.READ) >= 0
    const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
    const bodyStyle = (!this.state.collapsed)?{}: {display: "none"}
    return (
      <Card
        bodyStyle={bodyStyle}
        title={
          <Row gutter={[16,16]}>
            <Col span={2}>
              {(!this.props.fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}
              {(!this.state.collapsed)
                ?<Button type="text" onClick={() => this.setState({...this.state, collapsed: true})} icon={<UpCircleOutlined   style={{ cursor: 'default', color: '#999' }}/>}/>
                :<Button type="text"  onClick={() => this.setState({...this.state, collapsed: false})} icon={<DownCircleOutlined style={{ cursor: 'default', color: '#999' }}/>}/>
              }
            </Col>
            <Col span={5}>Kommunikation</Col>
          </Row>
      }
        extra={<>
          {this.props.extra}
        </>}
      >{(!this.state.collapsed)
        ?<Form name="communication-panel-form"
          layout={"vertical"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={[8,0]}>
            <Col span={10}>
              <Form.Item label="Type" style={{ marginBottom: 0 }}>
                <SelectCommType
                  name={"commTypeId"}
                  value={this.state.communication.commTypeId}
                  style={{ width: "100%" }}
                  onChange={(value) => {this.handleChildChange("commTypeId", value)}} o
                  onBlur={() => {this.handleChildBlur()}}
                  readOnly={!this.props.editable || !update} disabled={!update}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="Name / Nr."  style={{ marginBottom: 0 }}>
                <Input
                  name={"commAddr"}
                  value={this.state.communication.commAddr}
                  style={{ width: "100%" }}
                  onChange={(e) => {this.handleChildChange("commAddr", e.target.value)}}
                  onBlur={() => {this.handleChildBlur()}}
                  readOnly={!this.props.editable || !update} disabled={!update}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        :null}
      </Card>
    )
  }
}
CommunicationPanel.defaultProps = {
  rightsParent: [],
  panelStyle: "default"
}